import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { ValidateEmail, axiosConfig, normalize } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { listUserRoles, addSubUserRoles } from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { Editor } from "@tinymce/tinymce-react";

const auth = new HelperClass();

class Adduser extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      agent_email: auth.getEmail(),
      name: "",
      comp_name: "",
      title: "",
      email: "",
      password: "",
      errorEmail: false,
      errorPass: false,
      errorcPass: false,
      errorName: false,
      errorMessage: "",
      errorCompName: false,
      errorTitle: false,
      disabled: false,
      successMessage: "",
      confirm_password: "",
      errorConfirm: false,
      disableResendBtn: false,
      phone: "",
      errorPhone: false,
      fieldsHide: false,
      selected_roles: [],
      selected_sub_roles: [],
      main_role: "",
      sub_role: "",
      user_id: "",
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    let user_id = this.props.props.match.params.id;
    const servicesResponce = await listUserRoles(
      auth.getAccount(),
      auth.getToken(),
      user_id
    );
    console.log("agentsResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        selected_roles: servicesResponce.data.roles_rights,
        selected_sub_roles: servicesResponce.data.roles_rights_sub,
        tableLoader: false,
        user_id: user_id,
      });
    } else {
      // window.location.replace("/error");
    }
  }

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("handleChange: ", name + " " + value);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  formSave = async () => {
    let { user_id } = this.state;
    var checkedValues = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        return parseInt(this.value);
      })
      .get();
    console.log("checkedValues: ", checkedValues);
    var checkedValuesSub = $("input:checkbox.vdrSelected2:checked")
      .map(function () {
        return parseInt(this.value);
      })
      .get();
    console.log("checkedValuesSub: ", checkedValuesSub);
    this.setState({
      errorMessage: "",
      disabled: true,
    });

    const saveResponce = await addSubUserRoles(
      auth.getAccount(),
      auth.getToken(),
      user_id,
      JSON.stringify(checkedValues),
      JSON.stringify(checkedValuesSub)
    );
    console.log("ADD ROLES RES: ", saveResponce.data);
    if (
      saveResponce.data.status === 403 ||
      saveResponce.data.errors === "authentication missing" ||
      saveResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (saveResponce.data.status === 404) {
      // window.location.replace("/error");
      this.setState({
        errorMessage: "There is some error while adding the roles.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      saveResponce.data.status === 200 &&
      saveResponce.data.message === "success"
    ) {
      this.setState({
        errorMessage: "",
        successMessage: "Sub user roles added successfully.",
        disabled: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while adding the roles.",
        successMessage: "",
        disabled: false,
      });
    }
  };

  handleChangeCheckbox = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange:", name + " " + value);
    //this.handlePercentage(name, value);
  };

  getChecked = async (role_id) => {
    let selected_roles = this.state.selected_roles;
    if (selected_roles.filter((e) => e.role_id === role_id).length > 0) {
      console.log(role_id + " " + true);
      return true;
    }
    console.log(role_id + " " + false);
    return false;
  };

  getCheckedsub = async (role_id) => {
    let selected_sub_roles = this.state.selected_sub_roles;

    if (
      selected_sub_roles.filter((item) => item.sub_role_id === role_id).length >
      0
    ) {
      return true;
    }

    return false;
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>

                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/sub_users")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Sub Users
                  </Link>
                  <Typography color="textPrimary" className="">
                    User Roles
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add Sub User Roles
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <div className="table-responsive">
                              <table className="table table-bordered">
                                <thead
                                  style={{ borderTop: "1px solid #e5e9f2" }}
                                >
                                  <tr>
                                    <th scope="col">Main Tab</th>
                                    <th scope="col">Sub Tab</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableData &&
                                  this.state.tableData.length > 0 ? (
                                    <>
                                      {this.state.tableData.map(
                                        (roles, idx) => (
                                          <tr key={`role${idx}`}>
                                            <td
                                              style={{
                                                width: "30%",
                                                verticalAlign: "middle",
                                              }}
                                            >
                                              <div className="custom-control custom-checkbox">
                                                <input
                                                  name="main_role"
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id={`main_tab${idx}`}
                                                  value={roles.id}
                                                  onChange={this.handleChange}
                                                  defaultChecked={
                                                    this.state.selected_roles.filter(
                                                      (e) =>
                                                        e.role_id === roles.id
                                                    ).length > 0
                                                      ? true
                                                      : false
                                                  }
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`main_tab${idx}`}
                                                >
                                                  {roles.alt}
                                                </label>
                                              </div>
                                            </td>
                                            <td>
                                              {roles.subroles &&
                                              roles.subroles.length > 0 ? (
                                                <>
                                                  {roles.subroles.map(
                                                    (subroles, ids) => (
                                                      <div
                                                        className="custom-control custom-checkbox"
                                                        key={`subrole${ids}`}
                                                        style={{
                                                          marginRight: "25px",
                                                          marginBottom: "15px",
                                                          marginTop: "10px",
                                                        }}
                                                      >
                                                        <input
                                                          name="sub_role"
                                                          type="checkbox"
                                                          className="custom-control-input vdrSelected2"
                                                          id={`sub_tab${idx}_${ids}`}
                                                          value={
                                                            subroles.tabdetailid
                                                          }
                                                          onChange={
                                                            this.handleChange
                                                          }
                                                          defaultChecked={
                                                            this.state.selected_sub_roles.filter(
                                                              (e) =>
                                                                e.sub_role_id ===
                                                                subroles.tabdetailid
                                                            ).length > 0
                                                              ? true
                                                              : false
                                                          }
                                                        />
                                                        <label
                                                          className="custom-control-label"
                                                          htmlFor={`sub_tab${idx}_${ids}`}
                                                        >
                                                          {subroles.alt}
                                                        </label>
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              ) : null}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <a
                                onClick={() =>
                                  this.addMenuHistory("/sub_users")
                                }
                                className="btn btn-lg btn-light"
                                disabled={this.state.disabled}
                                style={{ marginRight: "15px" }}
                              >
                                <em className="icon ni ni-step-back"></em>
                                Back
                              </a>
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save User Roles
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Adduser);
