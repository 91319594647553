import { React } from "react";
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAgntPermissions,
  saveAgentPermissions,
} from "./../../../config/api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class AgentPermissions extends Component {
  constructor() {
    super();
    this.state = {
      approve_agreement: false,
      reject_agreement: false,
      approve_kyc: false,
      reject_kyc: false,
      can_add_order: false,
      can_cancel_order: false,
      edit_order_information: false,
      add_order_discount: false,
      add_order_shipping: false,
      add_order_tax: false,
      add_order_custom_products: false,
      set_order_as_paid: false,
      can_add_quote: false,
      can_edit_quote: false,
      edit_quote_information: false,
      add_quote_discount: false,
      add_quote_shipping: false,
      add_quote_tax: false,
      add_quote_custome_products: false,
      add_optional_products: false,
      permissions: {
        approve_agreement: false,
        reject_agreement: false,
        approve_kyc: false,
        reject_kyc: false,
        can_add_order: false,
        can_cancel_order: false,
        edit_order_information: false,
        add_order_discount: false,
        add_order_shipping: false,
        add_order_tax: false,
        add_order_custom_products: false,
        set_order_as_paid: false,
        can_add_quote: false,
        can_edit_quote: false,
        edit_quote_information: false,
        add_quote_discount: false,
        add_quote_shipping: false,
        add_quote_tax: false,
        add_quote_custome_products: false,
        add_optional_products: false,
      },
      reseller_accountno: "",
      dateUpdated: null,
      selectedNames: [],
      successMessagePermission: "",
      errorMessagePermission: "",
    };
  }

  async componentDidMount() {
    try {
      const listPermissionsResponse = await listAgntPermissions(
        auth.getAccount(),
        auth.getToken()
      );

      console.log("Permissions Res: ", listPermissionsResponse.data);

      if (
        listPermissionsResponse.data.status === 403 ||
        listPermissionsResponse.data.errors === "authentication missing" ||
        listPermissionsResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (listPermissionsResponse.data.status === 404) {
        //window.location.replace("/error");
      } else if (
        listPermissionsResponse.data.status === 200 &&
        listPermissionsResponse.data.message === "success"
      ) {
        const data = listPermissionsResponse.data.data;

        this.setState({
          tableLoader: false,
          permissions: {
            approve_agreement: data[0].approve_agreement,
            reject_agreement: data[0].reject_agreement,
            approve_kyc: data[0].approve_kyc,
            reject_kyc: data[0].reject_kyc,
            can_add_order: data[0].can_add_order,
            can_cancel_order: data[0].can_cancel_order,
            edit_order_information: data[0].edit_order_information,
            add_order_discount: data[0].add_order_discount,
            add_order_shipping: data[0].add_order_shipping,
            add_order_tax: data[0].add_order_tax,
            add_order_custom_products: data[0].add_order_custom_products,
            set_order_as_paid: data[0].set_order_as_paid,
            can_add_quote: data[0].can_add_quote,
            can_edit_quote: data[0].can_edit_quote,
            edit_quote_information: data[0].edit_quote_information,
            add_quote_discount: data[0].add_quote_discount,
            add_quote_shipping: data[0].add_quote_shipping,
            add_quote_tax: data[0].add_quote_tax,
            add_quote_custome_products: data[0].add_quote_custome_products,
            add_optional_products: data[0].add_optional_products,
          },
        });
      } else {
        // Handle other cases or errors
      }
    } catch (error) {
      console.error("Error fetching agent permissions:", error);
    }
  }

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    this.setState(
      (prevState) => ({
        permissions: {
          ...prevState.permissions,
          [name]: checked,
        },
      }),
      () => {
        console.log("Updated Permissions State:", this.state.permissions);
      }
    );
  };

  saveAgentPermissionsFun = async () => {
    const servicesResponse = await saveAgentPermissions(
      auth.getAccount(),
      auth.getToken(),
      this.state.permissions
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessagePermission: "Permissions Updated Successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlert").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessagePermission: "Error updating permissions.",
      });
    } else {
      this.setState({
        errorMessagePermission: "Error failed to delete document.",
      });
    }
  };

  render() {
    const {
      approve_agreement,
      reject_agreement,
      approve_kyc,
      reject_kyc,
      can_add_order,
      can_cancel_order,
      edit_order_information,
      add_order_discount,
      add_order_shipping,
      add_order_tax,
      add_order_custom_products,
      set_order_as_paid,
      can_add_quote,
      can_edit_quote,
      edit_quote_information,
      add_quote_discount,
      add_quote_shipping,
      add_quote_tax,
      add_quote_custome_products,
      add_optional_products,
    } = this.state.permissions;
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Channel Partner Permissions"
                    icon="icon ni ni-setting"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {this.state.errorMessagePermission !== "" ? (
            <div className="example-alert" style={{ marginBottom: "15px" }}>
              <div className="alert alert-pro alert-danger">
                <div className="alert-text">
                  <h4>Error</h4>
                  <p>{this.state.errorMessagePermission}</p>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.successMessagePermission !== "" ? (
            <div className="example-alert" style={{ marginBottom: "15px" }}>
              <div className="alert alert-pro alert-success">
                <div className="alert-text">
                  <h4>Success</h4>
                  <p>{this.state.successMessagePermission}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="generic_permissions">
              <div className="card card-bordered">
              <div className="card-inner">
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label">&nbsp;</label>
                </div>
                <ul className="nk-kycfm-control-list g-3">
                  {approve_agreement !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.approve_agreement}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="approve_agreement"
                        id="approve_agreement"
                        data-title="Approve Agreement"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="approve_agreement"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Approve Agreement
                        </span>
                      </label>
                    </li>
                  )}
                  {reject_agreement !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.reject_agreement}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="reject_agreement"
                        id="reject_agreement"
                        data-title="Reject Agreement"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="reject_agreement"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Reject Agreement
                        </span>
                      </label>
                    </li>
                  )}
                  {approve_kyc !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.approve_kyc}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="approve_kyc"
                        id="approve_kyc"
                        data-title="Approve KYC"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="approve_kyc">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">Approve KYC</span>
                      </label>
                    </li>
                  )}
                  {reject_kyc !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.reject_kyc}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="reject_kyc"
                        id="reject_kyc"
                        data-title="Reject KYC"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="reject_kyc">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">Reject KYC</span>
                      </label>
                    </li>
                  )}

                  {can_add_order !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.can_add_order}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="can_add_order"
                        id="can_add_order"
                        data-title="Can Add Order"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="can_add_order">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Can Add Order
                        </span>
                      </label>
                    </li>
                  )}
                  {can_cancel_order !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.can_cancel_order}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="can_cancel_order"
                        id="can_cancel_order"
                        data-title="Can Cancel Order"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="can_cancel_order"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Can Cancel Order
                        </span>
                      </label>
                    </li>
                  )}
                  {edit_order_information !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.edit_order_information}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="edit_order_information"
                        id="edit_order_information"
                        data-title="Edit Order Information"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="edit_order_information"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Edit Order Information
                        </span>
                      </label>
                    </li>
                  )}
                  {add_order_discount !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_order_discount}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_order_discount"
                        id="add_order_discount"
                        data-title="Add Order Discount"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_order_discount"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Order Discount
                        </span>
                      </label>
                    </li>
                  )}
                  {add_order_shipping !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_order_shipping}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_order_shipping"
                        id="add_order_shipping"
                        data-title="Add Order Shipping"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_order_shipping"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Order Shipping
                        </span>
                      </label>
                    </li>
                  )}
                  {add_order_tax !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_order_tax}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_order_tax"
                        id="add_order_tax"
                        data-title="Add Order Tax"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="add_order_tax">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Order Tax
                        </span>
                      </label>
                    </li>
                  )}
                  {add_order_custom_products !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={
                          this.state.permissions.add_order_custom_products
                        }
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_order_custom_products"
                        id="add_order_custom_products"
                        data-title="Add Order Custom Products"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_order_custom_products"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Order Custom Products
                        </span>
                      </label>
                    </li>
                  )}
                  {set_order_as_paid !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.set_order_as_paid}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="set_order_as_paid"
                        id="set_order_as_paid"
                        data-title="Set Order as Paid"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="set_order_as_paid"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Set Order as Paid
                        </span>
                      </label>
                    </li>
                  )}
                  {can_add_quote !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.can_add_quote}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="can_add_quote"
                        id="can_add_quote"
                        data-title="Can Add Quote"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="can_add_quote">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Can Add Quote
                        </span>
                      </label>
                    </li>
                  )}
                  {can_edit_quote !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.can_edit_quote}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="can_edit_quote"
                        id="can_edit_quote"
                        data-title="Can Edit Quote"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="can_edit_quote"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Can Edit Quote
                        </span>
                      </label>
                    </li>
                  )}
                  {edit_quote_information !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.edit_quote_information}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="edit_quote_information"
                        id="edit_quote_information"
                        data-title="Edit Quote Information"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="edit_quote_information"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Edit Quote Information
                        </span>
                      </label>
                    </li>
                  )}
                  {add_quote_discount !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_quote_discount}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_quote_discount"
                        id="add_quote_discount"
                        data-title="Add Quote Discount"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_quote_discount"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Quote Discount
                        </span>
                      </label>
                    </li>
                  )}
                  {add_quote_shipping !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_quote_shipping}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_quote_shipping"
                        id="add_quote_shipping"
                        data-title="Add Quote Shipping"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_quote_shipping"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Quote Shipping
                        </span>
                      </label>
                    </li>
                  )}
                  {add_quote_tax !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_quote_tax}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_quote_tax"
                        id="add_quote_tax"
                        data-title="Add Quote Tax"
                        value="Yes"
                      />
                      <label className="nk-kycfm-label" htmlFor="add_quote_tax">
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Quote Tax
                        </span>
                      </label>
                    </li>
                  )}
                  {add_quote_custome_products !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={
                          this.state.permissions.add_quote_custome_products
                        }
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_quote_custome_products"
                        id="add_quote_custome_products"
                        data-title="Add Quote Custom Products"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_quote_custome_products"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Quote Custom Products
                        </span>
                      </label>
                    </li>
                  )}
                  {add_optional_products !== undefined && (
                    <li className="nk-kycfm-control-item">
                      <input
                        checked={this.state.permissions.add_optional_products}
                        onChange={this.handleCheckboxChange}
                        className="nk-kycfm-control"
                        type="checkbox"
                        name="add_optional_products"
                        id="add_optional_products"
                        data-title="Add Optional Products"
                        value="Yes"
                      />
                      <label
                        className="nk-kycfm-label"
                        htmlFor="add_optional_products"
                      >
                        <span className="nk-kycfm-label-icon">
                          <div className="label-icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 90 90"
                            >
                              <rect
                                x="5"
                                y="7"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="25"
                                y="27"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#e3e7fe"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <rect
                                x="15"
                                y="17"
                                width="60"
                                height="56"
                                rx="7"
                                ry="7"
                                fill="#fff"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="15"
                                y1="29"
                                x2="75"
                                y2="29"
                                fill="none"
                                stroke="#6576ff"
                                strokeMiterlimit="10"
                                strokeWidth="2"
                              />
                              <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                              <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                              <rect
                                x="22"
                                y="39"
                                width="20"
                                height="20"
                                rx="2"
                                ry="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <circle
                                cx="32"
                                cy="45.81"
                                r="2"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <path
                                d="M29,54.31a3,3,0,0,1,6,0"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="40"
                                x2="69"
                                y2="40"
                                fill="none"
                                stroke="#6576ff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="51"
                                x2="69"
                                y2="51"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="57"
                                x2="59"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="57"
                                x2="66"
                                y2="57"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="49"
                                y1="46"
                                x2="59"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                              <line
                                x1="64"
                                y1="46"
                                x2="66"
                                y2="46"
                                fill="none"
                                stroke="#c4cefe"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                              />
                            </svg>
                          </div>
                        </span>
                        <span className="nk-kycfm-label-text">
                          Add Optional Products
                        </span>
                      </label>
                    </li>
                  )}
                </ul>
              </div>
              </div>
              <div className="col-md-12 text-right mt-4 mb-4 mr-4">
              <button
                className="btn btn-lg btn-primary"
                type="submit"
                onClick={this.saveAgentPermissionsFun}
              >
                Submit
              </button>
            </div>
              </div>
              
            </div>
            <div style={{ clear: "both" }}></div>
            
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentPermissions);
