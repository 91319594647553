import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getAgentAgreement,
  editAgreement,
  getSignatorySettings,
  getAgentAgreementSequence,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
//import { Editor } from "@tinymce/tinymce-react";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";
const animatedComponents = makeAnimated();
const auth = new HelperClass();

class Addagreement extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      agent_email: auth.getEmail(),
      agreement_title: "",
      agreement_content: "",
      agreement_instruction: "",
      max_seqno: 0,
      all_seqno: [],
      agreement_type: "msa",
      agreement_type: "",
      agreement_id: "",
      is_default: 0,
      isactive: 1,
      issignup: 1,
      ismandatory: 1,
      ispublished: 0,
      tableData: [],
      agentsList: [],
      selectedAgents: [],
      selectAll: false,
      signatoryDetails: [],
      signatory_id: "",
      signatory_details: null,
      isValueChanged: false,
      initial_agreement_content: "",
      published_text: "",
      copiedBadge: "",
      showTooltip: false,
      isEditorReady: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    let agreement_id = this.props.props.match.params.id;
    console.log(agreement_id);
    const servicesResponce = await getAgentAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let agents = servicesResponce.data.data[0][8].map((agent) => ({
        value: agent.accountno,
        label: agent.email,
      }));
      let selectedAgents = servicesResponce.data.data[0][7].map((agent) => ({
        value: agent,
        label: agent,
      }));

      let filteredAgents = agents.filter((agent) => {
        return selectedAgents.some(
          (selectedAgent) => selectedAgent.value === agent.value
        );
      });
      let temp = false;
      if (agents.length === selectedAgents.length) {
        temp = true;
      }

      this.setState({
        agreement_id: servicesResponce.data.data[0][5],
        agreement_title: servicesResponce.data.data[0][0],
        agreement_instruction: servicesResponce.data.data[0][3],
        isactive: servicesResponce.data.data[0][1],
        ismandatory: servicesResponce.data.data[0][2],
        agreement_content: servicesResponce.data.data[0][4],
        agreement_type: servicesResponce.data.data[0][6],
        selectedAgents: filteredAgents,
        agentsList: agents,
        selectAll: temp,
        signatory_id: servicesResponce.data.data[0][9],
        max_seqno: servicesResponce.data.data[0][10],
        initial_agreement_content: servicesResponce.data.data[0][4],
        tableLoader: false,
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
    this.get_signatory_settings();
  }

  getAgentAgreementSequence = async () => {
    const servicesResponce = await getAgentAgreementSequence(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getAgentAgreementSequence: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        max_seqno: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      if (!this.state.max_seqno || this.state.max_seqno === 0) {
        this.setState({
          max_seqno: servicesResponce.data.max_seqno,
        });
      }
      this.setState({
        all_seqno: servicesResponce.data.all_seqno,
      });
    } else {
      this.setState({
        max_seqno: 1,
      });
      //window.location.replace("/error");
    }
  };

  handleSelectChange = (selectedOptions) => {
    this.setState({ selectedAgents: selectedOptions }, () => {
      let temp = false;
      if (this.state.agentsList.length === this.state.selectedAgents.length) {
        temp = true;
      }
      this.setState({ selectAll: temp });
    });
  };

  handleSelectAllChange = () => {
    const { agentsList, selectAll } = this.state;
    if (!selectAll) {
      this.setState({
        selectedAgents: agentsList,
        selectAll: true,
      });
    } else {
      // Unselect all agents
      this.setState({
        selectedAgents: [],
        selectAll: false,
      });
    }
  };

  get_signatory_settings = async () => {
    const notesResponce = await getSignatorySettings(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      let all_clients = [];
      let first_value = null;
      if (notesResponce.data.data && notesResponce.data.data.length > 0) {
        for (let i = 0; i < notesResponce.data.data.length; i++) {
          if (notesResponce.data.data[i]["id"] == this.state.signatory_id) {
            first_value = notesResponce.data.data[i]["id"];
          }
          let newArr = {
            label:
              notesResponce.data.data[i]["name"] +
              " (" +
              notesResponce.data.data[i]["company"] +
              ")",
            value: notesResponce.data.data[i]["id"],
          };
          all_clients.push(newArr);
        }
      }
      console.log(
        "First Value: " +
          first_value +
          " this.state.signatory_id: " +
          this.state.signatory_id
      );
      this.setState({
        signatoryDetails: all_clients,
      });
      if (first_value && first_value !== "") {
        let get_initial_selection = this.getInitialSelectedOption(
          first_value,
          all_clients
        );
        this.setState(
          (prevState) => ({
            signatory_details: get_initial_selection,
            signatory_id: get_initial_selection.value,
          }),
          () => {
            // This callback function is called right after the state is updated
            //console.log("Updated state:", this.state.service_type_id);
          }
        );
      }
    } else {
      //window.location.replace("/error");
    }
  };

  getInitialSelectedOption = (value, all_clients) => {
    // Retrieve the initial selected option from your options array
    let options = all_clients; // Replace with your actual options array

    if (options) {
      return options.find((option) => option.value === value) || null;
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          signatory_id: changed_value.value,
          signatory_details: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.signatory_id);
        }
      );
    } else {
      this.setState(
        {
          signatory_id: null,
          signatory_details: null,
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  /*handleEditorChange = (event, editor) => {
    const content = editor.getData();
    let isValueChanged = false;
    if (content !== this.state.initial_agreement_content) {
      isValueChanged = true;
    }
    const editEditorHtml = document.getElementById("editEditorHtml");
    editEditorHtml.value = content;
    this.setState({
      agreement_content: content,
      isValueChanged: isValueChanged,
    });
  };*/

  handleEditorChange = (updatedContent) => {
    let isValueChanged = false;
    if (updatedContent !== this.state.initial_agreement_content) {
      isValueChanged = true;
    }
    //console.log('updatedContent: ',updatedContent);
    this.setState({
      agreement_content: updatedContent,
      isValueChanged: isValueChanged,
    });
  };

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  saveEditorHtml = () => {
    const pageContent = document.getElementById("editEditorHtml").value;
    let isValueChanged = false;
    if (pageContent !== this.state.initial_agreement_content) {
      isValueChanged = true;
    }
    this.setState(
      {
        agreement_content: pageContent,
        isValueChanged: isValueChanged,
        isEditorReady: false,
      },
      () => {
        if (this.editor && this.editor.ui.view.toolbar) {
          this.editor.ui.view.toolbar.element.remove();
        }
        this.editor = null;

        setTimeout(() => {
          this.setState({ isEditorReady: true });
          window.$("#modalEditorHtml").modal("hide");
        }, 500);
      }
    );
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    const {
      agreement_title,
      agreement_instruction,
      isactive,
      ismandatory,
      agreement_content,
      agreement_type,
      selectedAgents,
      signatory_id,
      max_seqno,
    } = this.state;

    var validated = true;

    if (agreement_title === "") {
      validated = false;
    } else if (agreement_instruction === "") {
      validated = false;
    } else if (agreement_content === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });
      let edit_type = "dont_apply";

      const saveResponce = await editAgreement(
        auth.getAccount(),
        auth.getToken(),
        agreement_title,
        agreement_instruction,
        this.state.isactive.toString(),
        this.state.ismandatory.toString(),
        agreement_content,
        this.state.agreement_id,
        agreement_type,
        selectedAgents.map((agent) => agent.value),
        signatory_id,
        edit_type,
        max_seqno
      );
      console.log("formSave: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Agreement updated successfully.",
          disabled: false,
        });

        setTimeout(() => {
          this.props.props.history.push("/agents/agreements");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleCheckboxChange = (agentEmail) => {
    const { selectedAgents, agentsList } = this.state;
    const selectedIndex = selectedAgents.indexOf(agentEmail);
    const newSelectedAgents = [...selectedAgents];

    if (selectedIndex === -1) {
      newSelectedAgents.push(agentEmail);
    } else {
      newSelectedAgents.splice(selectedIndex, 1);
    }

    // Update selectAll based on whether all agents are selected
    const allAgentsSelected = agentsList.every((agent) =>
      newSelectedAgents.includes(agent[0])
    );

    this.setState({
      selectedAgents: newSelectedAgents,
      selectAll: allAgentsSelected,
    });
  };

  formSaveAndPublish = async () => {
    const { isValueChanged } = this.state;
    if (isValueChanged) {
      this.setState({
        published_text:
          "Changes have been applied to the agreement content. As a result, this action will archive the agreement (if it has already been signed), prompting agents to re-sign the updated agreement. Are you certain you wish to proceed?",
      });
      window.$("#modalPublishAlert").modal("show");
    } else {
      this.setState({
        published_text:
          "No modifications have been made to the agreement content. This action will simply save this agreement for new agents, without necessitating re-signing by existing agents. Are you sure you want to proceed?",
      });
      window.$("#modalPublishAlert").modal("show");
    }
  };

  closwPublish = async () => {
    window.$("#modalPublishAlert").modal("hide");
  };

  closeAndInitiate = async () => {
    this.closwPublish();
    this.formSave();
  };

  submitPublish = async () => {
    window.scrollTo(0, 0);
    const {
      agreement_title,
      agreement_instruction,
      isactive,
      ismandatory,
      agreement_content,
      agreement_type,
      selectedAgents,
      signatory_id,
      max_seqno,
    } = this.state;

    var validated = true;

    if (agreement_title === "") {
      validated = false;
    } else if (agreement_instruction === "") {
      validated = false;
    } else if (agreement_content === "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      let edit_type = "apply";

      const saveResponce = await editAgreement(
        auth.getAccount(),
        auth.getToken(),
        agreement_title,
        agreement_instruction,
        this.state.isactive.toString(),
        this.state.ismandatory.toString(),
        agreement_content,
        this.state.agreement_id,
        agreement_type,
        selectedAgents.map((agent) => agent.value),
        signatory_id,
        edit_type,
        max_seqno
      );
      console.log("submitPublish: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "fail" &&
        saveResponce.data.note !== ""
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: saveResponce.data.note,
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Agreement updated successfully.",
          disabled: false,
        });
        window.$("#modalPublishAlert").modal("hide");
        setTimeout(() => {
          this.props.props.history.push("/agents/agreements");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleBadgeClick = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this.setState({ copiedBadge: content, showTooltip: true });
        setTimeout(() => {
          this.setState({ copiedBadge: "", showTooltip: false });
        }, 700);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalEditorHtml">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">Editor HTML</h5>
                </div>
                <div className="modal-body">
                  <textarea
                    className="prettyprint lang-html"
                    id="editEditorHtml"
                    defaultValue={this.state.agreement_content}
                  />
                </div>
                <div
                  className="modal-footer"
                  style={{ justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={() => this.saveEditorHtml()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/agents")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Channel Partners
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/agents/agreements")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Channel Partner Agreements
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Channel Partner Agreement
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5 class="card-title" style={{ marginBottom: "30px" }}>
                        Update Agreement
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_title"
                                name="agreement_title"
                                type="text"
                                label="Agreement Title"
                                value={this.state.agreement_title}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_instruction"
                                name="agreement_instruction"
                                type="text"
                                label="Agreement Instructions"
                                value={this.state.agreement_instruction}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="isactive"
                                name="isactive"
                                select
                                label="Select Status"
                                value={this.state.isactive}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Active
                                </option>
                                <option key="0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="ismandatory"
                                name="ismandatory"
                                select
                                label="Mandatory"
                                value={this.state.ismandatory}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement is mandatory"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Yes
                                </option>
                                <option key="0" value="0">
                                  No
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <div class="form-label-group">
                                <label class="form-label">
                                  &nbsp; <span class="text-danger"></span>
                                </label>
                              </div>
                              <MutextField
                                id="agreement_type"
                                name="agreement_type"
                                select
                                label="Agreement Type"
                                value={this.state.agreement_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="E-Sign or Acceptance"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="esign" value="esign">
                                  E-Signature
                                </option>
                                <option key="accept" value="accept">
                                  Acceptance Only
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label">
                                Choose Company Profile{" "}
                                  <span class="text-danger"></span>
                                </label>
                              </div>
                              <div class="form-control-group">
                                <Select2
                                  options={this.state.signatoryDetails}
                                  name="signatory_id"
                                  placeholder="Select a company profile of this agreement."
                                  value={this.state.signatory_details}
                                  autoComplete="off"
                                  emptyMessage="Signatory not found"
                                  onChange={this.handleChangeSearch}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="max_seqno"
                                name="max_seqno"
                                type="text"
                                label="Sequence #"
                                value={this.state.max_seqno}
                                onChange={this.handleChange}
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                              />
                              {this.state.all_seqno &&
                              this.state.all_seqno.length > 0 ? (
                                <div class="form-note">
                                  <strong>
                                    Already Added Seqno's:{" "}
                                    {this.state.all_seqno.join(", ")}
                                  </strong>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {this.state.agentsList &&
                          this.state.agentsList.length > 0 ? (
                            <div className="col-lg-6">
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={this.state.selectAll}
                                      onChange={this.handleSelectAllChange}
                                      name="selectAll"
                                    />
                                  }
                                  label="Select all Channel Partners"
                                  style={{
                                    width: "max-content",
                                    marginBottom: "10px",
                                    float: "right",
                                  }}
                                />
                                <Select2
                                  id="selectedAgents"
                                  value={this.state.selectedAgents}
                                  isMulti
                                  name="colors"
                                  options={this.state.agentsList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  onChange={this.handleSelectChange}
                                  styles={{
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div
                            className="col-lg-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <h6 style={{ margin: 0, marginRight: "10px" }}>
                              Agreement Content
                            </h6>
                            {this.state.isEditorReady ? (
                              <>
                                
                              </>
                            ) : null}
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              {this.state.isEditorReady ? (
                                <>
                                  {/*<CKEditor
                                    onReady={(editor) => {
                                      const editableElement =
                                        editor.ui.getEditableElement();
                                      if (
                                        editableElement &&
                                        editableElement.parentElement
                                      ) {
                                        editableElement.parentElement.insertBefore(
                                          editor.ui.view.toolbar.element,
                                          editableElement
                                        );
                                      }

                                      this.editor = editor;
                                    }}
                                    onError={(error, { willEditorRestart }) => {
                                      if (willEditorRestart) {
                                        if (
                                          this.editor &&
                                          this.editor.ui &&
                                          this.editor.ui.view &&
                                          this.editor.ui.view.toolbar
                                        ) {
                                          this.editor.ui.view.toolbar.element.remove();
                                        }
                                      }
                                    }}
                                    onChange={(event, editor) =>
                                      this.handleEditorChange(event, editor)
                                    }
                                    editor={DecoupledEditor}
                                    data={this.state.agreement_content}
                                    config={{
                                      cloudServices: {
                                        tokenUrl: fetchToken,
                                        uploadUrl:
                                          AGENT_SERVER_URL +
                                          "agents/editor_image_upload",
                                      },
                                      toolbar: {
                                        items: [
                                      "undo",
                                      "redo",
                                      "|",
                                      "bold",
                                      "italic",
                                      "underline",
                                      "strikethrough",
                                      "|",
                                      "fontFamily",
                                      "fontSize",
                                      "fontColor",
                                      "fontBackgroundColor",
                                      "|",
                                      "alignment",
                                      "indent",
                                      "outdent",
                                      "|",
                                      "numberedList",
                                      "bulletedList",
                                      "|",
                                      "link",
                                      "imageUpload",
                                      "insertTable",
                                      "|",
                                      "blockQuote",
                                    ],
                                      },
                                      fontSize: {
                                        options: [
                                          '8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '36px', '48px'
                                        ],
                                        supportAllValues: true  // Optional: Allows users to input custom values
                                      },
                                      fontColor: {
                                        colors: [
                                          {
                                            color: '#333',
                                            label: 'Default'
                                          },
                                          // You can add more colors if needed
                                        ]
                                      },
                                      style: {
                                        definitions: [
                                          {
                                            element: 'p',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h1',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h2',
                                            styles: {
                                              color: '#333'
                                            }
                                          },
                                          {
                                            element: 'h3',
                                            styles: {
                                              color: '#333'
                                            }
                                          }
                                        ]
                                      },
                                      language: "en",
                                    }}
                                  />*/}
                                  <RichTextEditorComponent
                                    agreementContent={
                                      this.state.agreement_content
                                    }
                                    onContentChange={this.handleEditorChange}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">
                                    Channel Partner Place Holders
                                  </th>
                                  <td>
                                    <ul class="preview-list customStyle-badgeTransfrom">
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGNOR_TITLE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGNOR_TITLE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_SIGNOR_TITLE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGNOR_NAME]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGNOR_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_SIGNOR_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_COMPANY_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_COMPANY_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_EMAIL]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_EMAIL]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_EMAIL]]" && (
                                            <div
                                              className="copyToClipboardTooltip active"
                                              style={{ marginLeft: "-20px" }}
                                            >
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_PHONE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_PHONE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_ADDRESS]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_CITY]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_CITY]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_STATE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_STATE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_ZIP]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_ZIP]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_COUNTRY]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_COUNTRY]]
                                        </a>
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_ADDRESS]]" && (
                                            <div
                                              className="copyToClipboardTooltip active"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[CONTRACT_DATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CONTRACT_DATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGN]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGN]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_SIGN]]" && (
                                            <div
                                              className="copyToClipboardTooltip active"
                                              style={{ marginLeft: "-27px" }}
                                            >
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_499_FILER_ID]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_499_FILER_ID]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_499_FILER_ID]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_FULL_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_FULL_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_FULL_ADDRESS]]" && (
                                            <div
                                              className="copyToClipboardTooltip active"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                {/*<tr>
                                  <th scope="row">Company Place Holders</th>
                                  <td>
                                    <ul class="preview-list customStyle-badgeTransfrom">
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_TITLE]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_TITLE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_SIGNOR_TITLE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_NAME]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_SIGNOR_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_SIGNOR_ADDRESS]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>

                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_SIGN]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_SIGN]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_SIGNOR_SIGN]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_COMPANY_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_SIGNOR_COMPANY_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_CONTRACT_DATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[VENDOR_CONTRACT_DATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>*/}
                                <tr>
                                  <th scope="row">
                                    Company Profile Place Holders
                                  </th>
                                  <td>
                                    <ul class="preview-list customStyle-badgeTransfrom">
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_TITLE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_TITLE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_TITLE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_NAME]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_EMAIL]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_EMAIL]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_EMAIL]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_PHONENO]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_PHONENO]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_PHONENO]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_FULL_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_FULL_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_FULL_ADDRESS]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_ADDRESS]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_CITY]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_CITY]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_CITY]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_STATE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_STATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_STATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_ZIP]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_ZIP]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_ZIP]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_COUNTRY]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_COUNTRY]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_COUNTRY]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_SIGN]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_SIGN]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_SIGN]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_COMPANY_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_COMPANY_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_CONTRACT_DATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_CONTRACT_DATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Input Fields</th>
                                  <td>
                                    <ul class="preview-list customStyle-badgeTransfrom">
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_LABEL_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_LABEL_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_LABEL_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTFIELD]]"
                                            )
                                          }
                                        >
                                          [[INPUT_TEXTFIELD]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTFIELD]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTFIELD_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_TEXTFIELD_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTFIELD_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTAREA]]"
                                            )
                                          }
                                        >
                                          [[INPUT_TEXTAREA]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTAREA]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTAREA_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_TEXTAREA_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTAREA_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      {/* <li className="preview-item">
                                        <a className="badge badge-outline-gray" onClick={() => this.handleBadgeClick("[[INPUT_CHECKBOX]]")}>
                                          [[INPUT_CHECKBOX]]
                                        </a>
                                        {this.state.showTooltip && this.state.copiedBadge === "[[INPUT_CHECKBOX]]" && (
                                          <div className="copyToClipboardTooltip active">
                                            <span>Copied to clipboard!</span>
                                          </div>
                                        )}
                                      </li> */}
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_CHECKBOX_OPTION_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_CHECKBOX_OPTION_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_CHECKBOX_OPTION_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      {/* <li className="preview-item">
                                        <a className="badge badge-outline-gray" onClick={() => this.handleBadgeClick("[[INPUT_RADIO]]")}>
                                          [[INPUT_RADIO]]
                                        </a>
                                        {this.state.showTooltip && this.state.copiedBadge === "[[INPUT_RADIO]]" && (
                                          <div className="copyToClipboardTooltip active">
                                            <span>Copied to clipboard!</span>
                                          </div>
                                        )}
                                      </li> */}
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                                style={{ marginRight: "15px" }}
                              >
                                Save
                              </button>
                              <button
                                type="button"
                                onClick={() => this.formSaveAndPublish()}
                                className="btn btn-lg btn-success"
                                disabled={this.state.disabled}
                                title=""
                              >
                                Save & Publish
                              </button>
                            </div>
                            <div class="example-alert mt-4">
                              <div class="alert alert-light">
                                <ul>
                                  <li>
                                    - <b>Save: </b>Allows for the agreement to
                                    be saved without notifying agents of any
                                    changes.
                                  </li>
                                  <li>
                                    - <b>Save & Publish: </b>This action will
                                    archive this signed agreement for agents,
                                    requiring them to sign this agreement again.{" "}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
            {/***** Publish Agreement ****/}
            <div class="modal fade" tabindex="-1" id="modalPublishAlert">
              <div
                class="modal-dialog modal-lg modal-dialog-top"
                role="document"
              >
                <div class="modal-content">
                  <a
                    href="#"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em class="icon ni ni-cross"></em>
                  </a>
                  <div class="modal-header">
                    <h5 class="modal-title">Save and Publish</h5>
                  </div>
                  <div class="modal-body">
                    <p>{this.state.published_text}</p>
                  </div>
                  <div class="modal-footer" style={{ justifyContent: "right" }}>
                    <div class="d-flex justify-content-end">
                      <a
                        class="btn btn-lg btn-light"
                        style={{ marginRight: "15px", cursor: "pointer" }}
                        onClick={() => this.closwPublish()}
                      >
                        No
                      </a>
                      {this.state.isValueChanged === true ? (
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          onClick={() => this.submitPublish()}
                        >
                          Yes
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="btn btn-lg btn-primary"
                          onClick={() => this.closeAndInitiate()}
                        >
                          Yes
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/***** Publish Agreement ****/}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addagreement);
