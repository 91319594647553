import HelperClass from "./../../config/helperClass";
import { LOGO } from "./../../config/config";
import { THEME_COLOR } from "./../../config/config";
import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Notifications from "./Notifications";
import { FormLoader } from "../ContentLoaders/newLoaders/Form";
import { NotificationLoader } from "../ContentLoaders/newLoaders/NewNotificationLoader";
import Badge from "@material-ui/core/Badge";

import {
  getActivityLog,
  readAllNotifications,
  readAllAgentsNotifications,
  readAllClientsNotifications,
} from "./../../config/api_calls";

import $ from "jquery";
const auth = new HelperClass();

export default function Header(props) {
  // function profileView() {
  //   window.$("#modalForm").modal("show");
  // }
  const location = useLocation();
  const [toggle, setToggle] = useState(false);

  const [formLoader, setFormLoader] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [totalnotifications, setTotalNotifications] = useState("");
  const [agentnotifications, setAgentNotifications] = useState([]);
  const [totalagentnotifications, setTotalAgentNotifications] = useState("");
  const [clientnotifications, setClientNotifications] = useState([]);
  const [totalclientnotifications, setTotalClientNotifications] = useState("");
  const [disableAutoModal, setDisableAutoModal] = useState(
    localStorage.getItem("disableAutoModal") === "true"
  );
  function timeConvert(date) {
    const seconds = Math.floor((new Date() - date) / 1000);
    const interval = seconds / 31536000;

    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    const interval2 = seconds / 2592000;
    if (interval2 > 1) {
      return Math.floor(interval2) + " months ago";
    }
    const interval3 = seconds / 86400;
    if (interval3 > 1) {
      return Math.floor(interval3) + " days ago";
    }
    const interval4 = seconds / 3600;
    if (interval4 > 1) {
      return Math.floor(interval4) + " hours ago";
    }
    const interval5 = seconds / 60;
    if (interval5 > 1) {
      return Math.floor(interval5) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  const handleCheckboxChange = () => {
    const newValue = !disableAutoModal;
    setDisableAutoModal(newValue);
    localStorage.setItem("disableAutoModal", String(newValue));
  };

  const getNotifications = async (userType) => {
    try {
      const getActivityLogs_res = await getActivityLog(
        auth.getAccount(),
        auth.getToken()
      );
      //console.log("getActivityLogs_res: ", getActivityLogs_res.data);
      let notificationData;
      if (userType === "admin") {
        
        notificationData = getActivityLogs_res.data.admin;
        setTotalNotifications(notificationData.length);
      }
      if (userType === "agent") {
        
        notificationData = getActivityLogs_res.data.agent.data;
        setTotalAgentNotifications(notificationData.length);
      } else if (userType === "client") {
        
        notificationData = getActivityLogs_res.data.client.data;
        setTotalClientNotifications(notificationData.length);
      }

      let i;
      if (getActivityLogs_res.data.status === 200) {
        let notificationArray = [];
        for (i = 0; i < 10; i++) {
          if (notificationData[i]) {
            const timeAdded = new Date(notificationData[i].date_added);
            notificationArray.push({
              title: notificationData[i].log_title,
              time: timeConvert(timeAdded),
              is_read: notificationData[i].is_read,
              log_url: notificationData[i].log_url,
              log_type: notificationData[i].log_type,
              accountno: notificationData[i].accountno,
              agreement_accountno: notificationData[i].agreement_accountno,
              credit_app_id: notificationData[i].credit_app_id,
              rfq_accountno: notificationData[i].rfq_accountno,
              orderno: notificationData[i].orderno,
            });
          }
        }

        if (userType === "admin") {
          setNotifications(notificationArray);
        } else if (userType === "agent") {
          setAgentNotifications(notificationArray);
        } else if (userType === "client") {
          setClientNotifications(notificationArray);
        }

        setFormLoader(false);
      } else if (getActivityLogs_res.data.message === "no_notifications") {
        //console.log("No notifications yet");
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  const handleNotifications = () => {
    getNotifications();
  };
  /*useEffect(() => {
    getNotifications();
  }, []);*/

  const markAllAsRead = async () => {
    const res = await readAllNotifications(auth.getAccount(), auth.getToken());
    if (res.data.status === 200) {
      setNotifications(
        notifications.map((notification) => ({ ...notification, is_read: 1 }))
      );
      setTotalNotifications(0);
    }
  };

  const markAllAsReadAgent = async () => {
    const res = await readAllAgentsNotifications(
      auth.getAccount(),
      auth.getToken()
    );
    if (res.data.status === 200) {
      setAgentNotifications(
        agentnotifications.map((notification) => ({
          ...notification,
          is_read: 1,
        }))
      );
      setTotalAgentNotifications(0);
    }
  };

  const markAllAsReadClient = async () => {
    const res = await readAllClientsNotifications(
      auth.getAccount(),
      auth.getToken()
    );
    if (res.data.status === 200) {
      setClientNotifications(
        clientnotifications.map((notification) => ({
          ...notification,
          is_read: 1,
        }))
      );
      setTotalClientNotifications(0);
    }
  };

  useEffect(() => {
    getNotifications("admin");
    getNotifications("agent");
    getNotifications("client");

    if (
      location.pathname === "/dashboard" &&
      (notifications.some((notification) => notification.is_read === 0) ||
        agentnotifications.some((notification) => notification.is_read === 0) ||
        clientnotifications.some((notification) => notification.is_read === 0))
    ) {
      setToggle(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (
      location.pathname === "/dashboard" &&
      !disableAutoModal &&
      (notifications.some((notification) => notification.is_read === 0) ||
        agentnotifications.some((notification) => notification.is_read === 0) ||
        clientnotifications.some((notification) => notification.is_read === 0))
    ) {
      setToggle(true);
    }
  }, [notifications, agentnotifications, clientnotifications]);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (
        toggle &&
        !document.getElementById("notification-modal").contains(event.target)
      ) {
        setToggle(false);
      }
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, [toggle]);

  function classCheck() {
    var menu_items = $(".nk-sidebar");
    if (menu_items.hasClass("nk-sidebar-active")) {
    } else {
      $(".nk-sidebar").addClass("nk-sidebar-active");
    }
  }

  function navigateLink(link) {
    props.mainProps.history.push(link);
  }

  const handleToggle = () => {
    setToggle(!toggle);
  };

  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <a
              className="nk-nav-toggle nk-quick-nav-icon"
              data-target="sidebarMenu"
            >
              <em className="icon ni ni-menu" onClick={() => classCheck()}></em>
            </a>
          </div>

          <div className="nk-header-brand d-xl-none">
            <a href="/" className="logo-link">
              <img className="logo-light logo-img" src={LOGO} alt="logo" />
              <img className="logo-dark logo-img" src={LOGO} alt="logo-dark" />
            </a>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              {props.isNdaSigned === true ? (
                <li>
                  {/*<a
                    className="btn"
                    style={{ backgroundColor: THEME_COLOR, color: "white" }}
                  >
                    <em className="icon ni ni-share-fill"></em>
                    <span>ShareLink</span>{" "}
                  </a>*/}
                </li>
              ) : null}

              <li className="dropdown user-dropdown">
                <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                    <div className="user-info d-none d-md-block">
                      <div className="user-status">{auth.getName()}</div>
                      <div className="user-name dropdown-indicator">
                        {auth.getCompName()}
                      </div>
                    </div>
                  </div>
                </a>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>{auth.getCompName().substring(0, 2)}</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">{auth.getCompName()}</span>
                        <span className="sub-text">{auth.getEmail()}</span>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li>
                        <a href="#" onClick={props.modalOpen}>
                          <em className="icon ni ni-user-alt"></em>
                          <span>View Profile</span>
                        </a>
                      </li>
                      {auth.getUserType() !== "Sub" ? (
                        <li>
                          <a style={{ cursor: "pointer" }} href="/sub_users">
                            <em className="icon ni ni-user-list"></em>
                            <span>Sub Users</span>
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li
                        onClick={() => auth.logout()}
                        style={{ cursor: "pointer" }}
                      >
                        <a>
                          <em className="icon ni ni-signout"></em>
                          <span>Sign out</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li class="dropdown notification-dropdown mr-n1">
                <a
                  class="dropdown-toggle nk-quick-nav-icon"
                  onClick={handleToggle}
                >
                  <div
                    class={
                      notifications.some(
                        (notification) => notification.is_read === 0
                      ) ||
                      agentnotifications.some(
                        (notification) => notification.is_read === 0
                      ) ||
                      clientnotifications.some(
                        (notification) => notification.is_read === 0
                      )
                        ? "icon-status icon-status-info"
                        : "icon-status"
                    }
                  >
                    <em class="icon ni ni-bell"></em>
                  </div>
                </a>
                <div
                  className={`card-aside card-aside-right user-aside custom-dropdown-menu   dropdown-menu-right  toggle-slide toggle-slide-right${
                    toggle ? " content-active" : ""
                  }`}
                  id="notification-modal"
                  style={{
                    overflowY: "auto",
                    maxHeight: "200px",
                    scrollbarWidth: "thin",
                  }}
                >
                  <div className="card-inner-group" data-simplebar>
                    <div className="p-2">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>Notifications</h5>
                        <a
                          onClick={handleToggle}
                          style={{
                            fontSize: "1.5rem",
                            paddingLeft: "0.5rem",
                            cursor: "pointer",
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                      </div>

                      {/* <div class="dropdown-head-notification">
                        <span class="sub-title nk-dropdown-title">
                          Notifications
                        </span>
                        <a href="#" onClick={markAllAsRead}>
                          Mark All as Read
                        </a>
                      </div> */}
                    </div>
                    <div class="card card-preview" id="headerDivNotification">
                      <div
                        class="card-inner"
                        style={{ padding: "0 1rem 1rem 1rem" }}
                      >
                        <ul class="nav nav-tabs">
                          <li class="nav-item flex-fill">
                            <a
                              class="nav-link active"
                              data-toggle="tab"
                              href="#tabItem9"
                              style={{ marginTop: 2 }}
                            >
                              <div>
                                {totalclientnotifications > 0 ? (
                                  <Badge
                                    badgeContent={totalclientnotifications}
                                    color="error"
                                  >
                                    Clients
                                  </Badge>
                                ) : (
                                  <>
                                    <em class="icon ni ni-user"></em>
                                    <span>Clients</span>
                                  </>
                                )}
                              </div>
                            </a>
                          </li>
                          <li class="nav-item flex-fill">
                            <a
                              class="nav-link"
                              data-toggle="tab"
                              href="#tabItem10"
                              style={{
                                marginTop: 2,
                              }}
                            >
                              <div>
                                {totalagentnotifications > 0 ? (
                                  <Badge
                                    badgeContent={totalagentnotifications}
                                    color="error"
                                  >
                                    Channel Partners
                                  </Badge>
                                ) : (
                                  <>
                                    <em class="icon ni ni-user"></em>
                                    <span>Channel Partners</span>
                                  </>
                                )}
                              </div>
                            </a>
                          </li>
                          <li class="nav-item flex-fill">
                            <a
                              class="nav-link "
                              data-toggle="tab"
                              href="#tabItem11"
                              style={{
                                marginTop: 2,
                              }}
                            >
                              <div>
                                {totalnotifications > 0 ? (
                                  <Badge
                                    badgeContent={totalnotifications}
                                    color="error"
                                  >
                                    Admin
                                  </Badge>
                                ) : (
                                  <>
                                    <em class="icon ni ni-user"></em>
                                    <span>Admin</span>
                                  </>
                                )}
                              </div>
                            </a>
                          </li>
                        </ul>
                        <div class="tab-content ">
                          <div class="tab-pane active" id="tabItem9">
                            <div class="dropdown-head-notification m-3">
                              {/* <span class="sub-title nk-dropdown-title">
                                Notifications
                              </span> */}
                              <a href="#" onClick={markAllAsReadClient}>
                                Mark All as Read
                              </a>
                            </div>
                            <div class="dropdown-body">
                              <div class="nk-notification">
                                {formLoader === true ? (
                                  NotificationLoader()
                                ) : (
                                  <>
                                    {clientnotifications.length > 0 ? (
                                      clientnotifications.map(
                                        (notification, index) => (
                                          <Notifications
                                            key={index}
                                            notification={notification}
                                          />
                                        )
                                      )
                                    ) : (
                                      <div
                                        class="nk-notification-item dropdown-inner"
                                        style={{ paddingLeft: "0.75rem" }}
                                      >
                                        <div class="nk-notification-icon">
                                          <em class="icon icon-circle bg-warning-dim ni ni-info"></em>
                                        </div>
                                        <div class="nk-notification-content">
                                          <div class="nk-notification-text">
                                            You have no client notifications
                                            yet.
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div class="dropdown-foot center">
                                      <a href="/notifications/client">
                                        View All
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane" id="tabItem10">
                            <div class="dropdown-head-notification m-3">
                              {/* <span class="sub-title nk-dropdown-title">
                                Notifications
                              </span> */}
                              <a href="#" onClick={markAllAsReadAgent}>
                                Mark All as Read
                              </a>
                            </div>
                            <div class="dropdown-body">
                              <div class="nk-notification">
                                {formLoader === true ? (
                                  NotificationLoader()
                                ) : (
                                  <>
                                    {agentnotifications.length > 0 ? (
                                      agentnotifications.map(
                                        (notification, index) => (
                                          <Notifications
                                            key={index}
                                            notification={notification}
                                          />
                                        )
                                      )
                                    ) : (
                                      <div class="nk-notification-item dropdown-inner">
                                        <div class="nk-notification-icon">
                                          <em class="icon icon-circle bg-warning-dim ni ni-info"></em>
                                        </div>
                                        <div class="nk-notification-content">
                                          <div class="nk-notification-text">
                                            You have no agent notifications yet.
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div class="dropdown-foot center">
                                      <a href="/notifications/agent">
                                        View All
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="tab-pane" id="tabItem11">
                            <div class="dropdown-head-notification m-3">
                              {/* <span class="sub-title nk-dropdown-title">
                                Notifications
                              </span> */}
                              <a href="#" onClick={markAllAsRead}>
                                Mark All as Read
                              </a>
                            </div>
                            <div class="dropdown-body">
                              <div class="nk-notification">
                                {formLoader === true ? (
                                  NotificationLoader()
                                ) : (
                                  <>
                                    {notifications.length > 0 ? (
                                      notifications.map(
                                        (notification, index) => (
                                          <Notifications
                                            key={index}
                                            notification={notification}
                                          />
                                        )
                                      )
                                    ) : (
                                      <div class="nk-notification-item dropdown-inner">
                                        <div class="nk-notification-icon">
                                          <em class="icon icon-circle bg-warning-dim ni ni-info"></em>
                                        </div>
                                        <div class="nk-notification-content">
                                          <div class="nk-notification-text">
                                            You have no admin notifications yet.
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div class="dropdown-foot center">
                                      <a href="/notifications/admin">
                                        View All
                                      </a>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="custom-control custom-checkbox mb-4"
                      style={{ paddingLeft: "3rem" }}
                    >
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={disableAutoModal}
                        onChange={handleCheckboxChange}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck1"
                      >
                        Don't show this again
                      </label>
                    </div>
                    {/* <div class="dropdown-body">
                      <div class="nk-notification">
                        {formLoader === true ? (
                          FormLoader()
                        ) : (
                          <>
                            {notifications.length > 0 ? (
                              notifications.map((notification, index) => (
                                <Notifications
                                  key={index}
                                  notification={notification}
                                />
                              ))
                            ) : (
                              <div class="nk-notification-item dropdown-inner">
                                <div class="nk-notification-icon">
                                  <em class="icon icon-circle bg-warning-dim ni ni-info"></em>
                                </div>
                                <div class="nk-notification-content">
                                  <div class="nk-notification-text">
                                    You have <span>no notifications</span> yet
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div> */}
                    {/* <div class="dropdown-foot center">
                      <a href="/notifications">View All</a>
                    </div> */}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
