import { React } from "react";
import { Component } from "react";
import { Prompt } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listAllServices,
  addNewBundle,
  addCsvFile,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";

import { tableLoaderListingBlocks } from "../../../components/ContentLoaders/table_loader_listing_blocks";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import Radio from "@material-ui/core/Radio";
import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";

import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class Addbundle extends Component {
  constructor() {
    super();
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    this.state = {
      serviceData: [],
      actualServiceData: [],
      select_service: "1",
      chosenService: [],
      select_service_did: "",
      select_service_tfn: "",
      select_service_teams: "",
      select_service_term: "",
      select_service_ivr: "",
      select_service_tfn_term: "",
      select_service_az_term: "",
      select_service_live_agent: "",
      select_service_sms: "",
      select_service_cnam: "",

      select_service_did_error: false,
      select_service_tfn_error: false,
      select_service_teams_error: false,
      select_service_term_error: false,
      select_service_ivr_error: false,
      select_service_tfn_term_error: false,
      select_service_az_term_error: false,
      select_service_live_agent_error: false,
      select_service_sms_error: false,
      select_service_cnam_error: false,

      product_desc: "",
      errorMessage: "",
      start_date: datestring_today,
      tableLoaderListingBlocks: true,
      serviceId: "",
      bundle_title: "",
      per_month_rate: "",
      per_year_rate: "",
      did_service: false,
      type_limited_did: false,
      type_limited_tfn: false,
      type_limited_term: false,
      type_limited_teams: false,
      type_limited_ivr: false,
      type_limited_live_agent: false,
      type_limited_tfn_term: false,
      type_limited_az_term: false,
      type_limited_sms: false,
      type_limited_cnam: false,

      did_mou: "",
      tfn_mou: "",
      term_mou: "",
      teams_mou: "",
      ivr_mou: "",
      live_agent_mou: "",
      tfn_term_mou: "",
      az_term_mou: "",
      sms_mou: "",
      cnam_mou: "",

      did_mou_error: false,
      tfn_mou_error: false,
      term_mou_error: false,
      teams_mou_error: false,
      ivr_mou_error: false,
      live_agent_mou_error: false,
      tfn_term_mou_error: false,
      az_term_mou_error: false,
      sms_mou_error: false,
      cnam_mou_error: false,

      did_sessions: "",
      tfn_sessions: "",
      term_sessions: "",
      teams_sessions: "",
      ivr_sessions: "",
      live_agent_sessions: "",
      tfn_term_sessions: "",
      az_term_sessions: "",
      sms_sessions: "",
      cnam_sessions: "",

      did_sessions_error: false,
      tfn_sessions_error: false,
      term_sessions_error: false,
      teams_sessions_error: false,
      ivr_sessions_error: false,
      live_agent_sessions_error: false,
      tfn_term_sessions_error: false,
      az_term_sessions_error: false,
      sms_sessions_error: false,
      cnam_sessions_error: false,

      successMessage: "",
      tableLoader: true,
      radio_buttons: false,
      radio_type: "b",

      marketing_content: "link",
      marketing_link: "",
      marketing_pdf: "",
      file: [],
      fileName: "",
      status_text: "Inactive",
      package_status: 0,

      rate_settings_tab: false,
      misc_settings_tab: false,
      porting_settings_tab: false,
      desc_settings_tab: false,
      radio_type: "b",
      isBlocking: true,
      show_description: "",
      service_setting_title: "",
      service_porting_settings: [],
      service_misc_settings: [],
      misc_rows: [],
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listAllServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Service Data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoaderListingBlocks: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let type_did = [];
      let type_tfn = [];
      let type_teams = [];
      let type_term = [];
      let type_ivr = [];
      let type_tfn_term = [];
      let type_az_term = [];
      let type_live_agent = [];
      let type_sms = [];
      let type_cnam = [];

      let all_data = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        all_data = servicesResponce.data.data;
        for (var i = 0; i < all_data.length; i++) {
          if (all_data[i].service_type === "did") {
            type_did.push(all_data[i]);
          }
          if (all_data[i].service_type === "tfn") {
            type_tfn.push(all_data[i]);
          }
          if (all_data[i].service_type === "teams") {
            type_teams.push(all_data[i]);
          }
          if (all_data[i].service_type === "term") {
            type_term.push(all_data[i]);
          }
          if (all_data[i].service_type === "ivr") {
            type_ivr.push(all_data[i]);
          }
          if (all_data[i].service_type === "tfn_term") {
            type_tfn_term.push(all_data[i]);
          }
          if (all_data[i].service_type === "az_term") {
            type_az_term.push(all_data[i]);
          }
          if (all_data[i].service_type === "live_agent") {
            type_live_agent.push(all_data[i]);
          }
          if (all_data[i].service_type === "sms") {
            type_sms.push(all_data[i]);
          }
          if (all_data[i].service_type === "cnam") {
            type_cnam.push(all_data[i]);
          }
        }
      }

      var inner_array = {
        did: type_did,
        tfn: type_tfn,
        teams: type_teams,
        term: type_term,
        ivr: type_ivr,
        tfn_term: type_tfn_term,
        az_term: type_az_term,
        live_agent: type_live_agent,
        sms: type_sms,
        cnam: type_cnam,
      };
      this.setState({
        serviceData: inner_array,
        actualServiceData: all_data,
        tableLoaderListingBlocks: false,
      });
      console.log("Rendered Services: ", this.state.serviceData);
    } else {
      this.setState({
        tableLoaderListingBlocks: false,
      });
      //window.location.replace("/error");
    }
  }

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("setMarkup: ", name + " " + value);
    let service_data = this.state.actualServiceData;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: Number.parseFloat(service_data[i][3]).toFixed(4),
          defaultMrc: Number.parseFloat(service_data[i][4]).toFixed(4),
          rate_type: service_data[i][5],
          defaultRate: Number.parseFloat(service_data[i][5]).toFixed(4),
          service_title: service_data[i][1],
          service_desc: service_data[i][2],
          //product_desc: service_data[i][2],
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type: service_data[i][9],
          rate_settings_tab: true,
          isBlocking: false,
        });
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  open_current_tab = (link) => {
    this.setState({
      [link]: true,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange:", name + " " + value);
    //this.handlePercentage(name, value);
  };

  handleChangeRadio = async (event) => {
    const { target } = event;
    const value = target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });
    //console.log("handleChange:", name + " " + value);
  };

  handleChangeMrc = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });
    //this.handlePercentage(name, value);
    //console.log("handleChange:", name + " " + value);
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };
  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
      isBlocking: false,
    });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
      isBlocking: false,
    });
    //await this.sendFileData(event.target.files[0]);
  };

  handlePercentage = async (name, value) => {
    //Allowed Percentages for new_mrc, agent_mrc_comission, rate, agent_rate_comission, sms_in, sms_out, sms_in_commission, sms_out_commission
    if (name === "new_mrc") {
      if (value > this.state.defaultMrc) {
        let mrc_diff = value - this.state.defaultMrc;
        let mrc_percentage = (mrc_diff / 100) * this.state.agent_mrc_comission;

        this.setState({
          mrc_comission_error_text:
            "Agent Commission in percentage (Applied on Gross Revenue). (Estimated: $" +
            mrc_percentage.toFixed(2) +
            ")",
        });
      }
    }

    if (name === "agent_mrc_comission") {
      if (this.state.new_mrc > this.state.defaultMrc) {
        let mrc_diff = this.state.new_mrc - this.state.defaultMrc;
        let mrc_percentage = (mrc_diff / 100) * value;

        this.setState({
          mrc_comission_error_text:
            "Agent Commission in percentage (Applied on Gross Revenue). (Estimated: $" +
            mrc_percentage.toFixed(2) +
            ")",
        });
      }
    }
  };

  handleChangeCheckboxMarketing = async (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.value : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });

    console.log("handleChangeCheckbox:", name + " " + value);
  };

  saveFilePdf = (e) => {
    this.setState({
      file: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };

  handleChangeCheckbox = async (type) => {
    if (type === "did") {
      if (this.state.type_limited_did === true) {
        this.setState({
          type_limited_did: false,
        });
      } else {
        this.setState({
          type_limited_did: true,
        });
      }
    }
    if (type === "tfn") {
      if (this.state.type_limited_tfn === true) {
        this.setState({
          type_limited_tfn: false,
        });
      } else {
        this.setState({
          type_limited_tfn: true,
        });
      }
    }
    if (type === "term") {
      if (this.state.type_limited_term === true) {
        this.setState({
          type_limited_term: false,
        });
      } else {
        this.setState({
          type_limited_term: true,
        });
      }
    }
    if (type === "ivr") {
      if (this.state.type_limited_ivr === true) {
        this.setState({
          type_limited_ivr: false,
        });
      } else {
        this.setState({
          type_limited_ivr: true,
        });
      }
    }
    if (type === "teams") {
      if (this.state.type_limited_teams === true) {
        this.setState({
          type_limited_teams: false,
        });
      } else {
        this.setState({
          type_limited_teams: true,
        });
      }
    }
    if (type === "tfn_term") {
      if (this.state.type_limited_tfn_term === true) {
        this.setState({
          type_limited_tfn_term: false,
        });
      } else {
        this.setState({
          type_limited_tfn_term: true,
        });
      }
    }
    if (type === "az_term") {
      if (this.state.type_limited_az_term === true) {
        this.setState({
          type_limited_az_term: false,
        });
      } else {
        this.setState({
          type_limited_az_term: true,
        });
      }
    }
    if (type === "live_agent") {
      if (this.state.type_limited_live_agent === true) {
        this.setState({
          type_limited_live_agent: false,
        });
      } else {
        this.setState({
          type_limited_live_agent: true,
        });
      }
    }
    if (type === "sms") {
      if (this.state.type_limited_sms === true) {
        this.setState({
          type_limited_sms: false,
        });
      } else {
        this.setState({
          type_limited_sms: true,
        });
      }
    }

    console.log("handleChangeCheckbox:", type);
  };

  handleChangeMisc = (idx) => (e) => {
    const { name, value } = e.target;
    const misc_rows = [...this.state.misc_rows];
    misc_rows[idx][name] = value;

    this.setState({
      misc_rows,
      isBlocking: false,
    });
    console.log("handleChangeMisc: ", misc_rows);
    console.log("handleChangeMisc idx: ", idx);
  };

  handleAddRow = () => {
    const item = {
      misc_title: "",
      misc_type: "",
      misc_rate: "",
    };
    this.setState({
      misc_rows: [...this.state.misc_rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      misc_rows: this.state.misc_rows.slice(0, -1),
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const misc_rows = [...this.state.misc_rows];
    misc_rows.splice(idx, 1);
    this.setState({ misc_rows });
  };

  sendFileData = async (filedata) => {
    const saveResponces = await addCsvFile(auth.getAccount(), auth.getToken());
    console.log("rate_deck_file: ", saveResponces);
  };

  formSave = async (e) => {
    e.preventDefault();
    let {
      select_service_did,
      select_service_tfn,
      select_service_teams,
      select_service_term,
      select_service_ivr,
      select_service_tfn_term,
      select_service_az_term,
      select_service_live_agent,
      select_service_sms,
      select_service_cnam,
      product_desc,
      bundle_title,
      per_month_rate,
      per_year_rate,
      did_service,
      type_limited_did,
      type_limited_tfn,
      type_limited_term,
      type_limited_teams,
      type_limited_ivr,
      type_limited_live_agent,
      type_limited_tfn_term,
      type_limited_az_term,
      type_limited_sms,
      type_limited_cnam,
      did_mou,
      tfn_mou,
      term_mou,
      teams_mou,
      ivr_mou,
      live_agent_mou,
      tfn_term_mou,
      az_term_mou,
      sms_mou,
      cnam_mou,
      did_sessions,
      tfn_sessions,
      term_sessions,
      teams_sessions,
      ivr_sessions,
      live_agent_sessions,
      tfn_term_sessions,
      az_term_sessions,
      sms_sessions,
      cnam_sessions,
      marketing_content,
      package_status,
    } = this.state;

    var is_validated = true;
    this.setState({
      select_service_did_error: false,
      select_service_tfn_error: false,
      select_service_teams_error: false,
      select_service_term_error: false,
      select_service_ivr_error: false,
      select_service_tfn_term_error: false,
      select_service_az_term_error: false,
      select_service_live_agent_error: false,
      select_service_sms_error: false,
      select_service_cnam_error: false,
      did_mou_error: false,
      tfn_mou_error: false,
      term_mou_error: false,
      teams_mou_error: false,
      ivr_mou_error: false,
      live_agent_mou_error: false,
      tfn_term_mou_error: false,
      az_term_mou_error: false,
      sms_mou_error: false,
      cnam_mou_error: false,
      did_sessions_error: false,
      tfn_sessions_error: false,
      term_sessions_error: false,
      teams_sessions_error: false,
      ivr_sessions_error: false,
      live_agent_sessions_error: false,
      tfn_term_sessions_error: false,
      az_term_sessions_error: false,
      sms_sessions_error: false,
      cnam_sessions_error: false,
      errorMessage: "",
      successMessage: "",
    });
    if (bundle_title === "") {
      is_validated = false;
    } else if (per_month_rate === "") {
      is_validated = false;
    } else if (per_year_rate === "") {
      is_validated = false;
    }

    let packages_status = "0";
    if (package_status) {
      packages_status = "1";
    }

    var main_selected_services = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();

    if (main_selected_services.length === 0) {
      this.setState({
        errorMessage: "Please choose the rate.",
        successMessage: "",
        disabled: false,
      });
      is_validated = false;
    } else {
      for (var i = 0; i < main_selected_services.length; i++) {
        if (main_selected_services[i] === "did") {
          if (select_service_did === "") {
            this.setState({
              select_service_did_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_did === true) {
              if (did_mou === "") {
                this.setState({ did_mou_error: true });
                is_validated = false;
              } else if (did_sessions === "") {
                this.setState({ did_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "tfn") {
          if (select_service_tfn === "") {
            this.setState({
              select_service_tfn_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_tfn === true) {
              if (tfn_mou === "") {
                this.setState({ tfn_mou_error: true });
                is_validated = false;
              } else if (tfn_sessions === "") {
                this.setState({ tfn_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "term") {
          if (select_service_term === "") {
            this.setState({
              select_service_term_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_term === true) {
              if (term_mou === "") {
                this.setState({ term_mou_error: true });
                is_validated = false;
              } else if (term_sessions === "") {
                this.setState({ term_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "teams") {
          if (select_service_teams === "") {
            this.setState({
              select_service_teams_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_teams === true) {
              if (teams_mou === "") {
                this.setState({ teams_mou_error: true });
                is_validated = false;
              } else if (teams_sessions === "") {
                this.setState({ teams_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "ivr") {
          if (select_service_ivr === "") {
            this.setState({
              select_service_ivr_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_ivr === true) {
              if (ivr_mou === "") {
                this.setState({ ivr_mou_error: true });
                is_validated = false;
              } else if (ivr_sessions === "") {
                this.setState({ ivr_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "live_agent") {
          if (select_service_live_agent === "") {
            this.setState({
              select_service_live_agent_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_live_agent === true) {
              if (live_agent_mou === "") {
                this.setState({ live_agent_mou_error: true });
                is_validated = false;
              } else if (live_agent_sessions === "") {
                this.setState({ live_agent_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "tfn_term") {
          if (select_service_tfn_term === "") {
            this.setState({
              select_service_tfn_term_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_tfn_term === true) {
              if (tfn_term_mou === "") {
                this.setState({ tfn_term_mou_error: true });
                is_validated = false;
              } else if (tfn_term_sessions === "") {
                this.setState({ tfn_term_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "az_term") {
          if (select_service_az_term === "") {
            this.setState({
              select_service_az_term_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_az_term === true) {
              if (az_term_mou === "") {
                this.setState({ az_term_mou_error: true });
                is_validated = false;
              } else if (az_term_sessions === "") {
                this.setState({ az_term_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "sms") {
          if (select_service_sms === "") {
            this.setState({
              select_service_sms_error: true,
            });
            is_validated = false;
          } else {
            if (type_limited_sms === true) {
              if (sms_mou === "") {
                this.setState({ sms_mou_error: true });
                is_validated = false;
              } else if (sms_sessions === "") {
                this.setState({ sms_sessions_error: true });
                is_validated = false;
              }
            }
          }
        } else if (main_selected_services[i] === "cnam") {
          if (select_service_cnam === "") {
            this.setState({
              select_service_cnam_error: true,
            });
            is_validated = false;
          } else {
          }
        }
      }
    }

    console.log("checkedValues: ", main_selected_services);

    console.log("is_validated: ", is_validated);
    if (is_validated === false) {
      document
        .getElementById("Services_Block_Ratedeck")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return this.setState({
        errorMessage:
          "Please enter the required fields or check the highlighted text fields. ",
      });
    } else {
      this.setState({ errorMessage: "" });

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      let marketing_link = "";
      if (marketing_content === "link") {
        marketing_link = this.state.marketing_link;
      }

      const servicesResponce = await addNewBundle(
        auth.getAccount(),
        auth.getToken(),
        bundle_title,
        per_month_rate,
        per_year_rate,
        select_service_did,
        select_service_tfn,
        select_service_teams,
        select_service_term,
        select_service_tfn_term,
        select_service_az_term,
        select_service_live_agent,
        select_service_sms,
        product_desc,
        type_limited_did,
        type_limited_tfn,
        type_limited_term,
        type_limited_teams,
        type_limited_ivr,
        type_limited_live_agent,
        type_limited_tfn_term,
        type_limited_az_term,
        type_limited_sms,
        did_mou,
        tfn_mou,
        term_mou,
        teams_mou,
        ivr_mou,
        live_agent_mou,
        tfn_term_mou,
        az_term_mou,
        sms_mou,
        did_sessions,
        tfn_sessions,
        term_sessions,
        teams_sessions,
        ivr_sessions,
        live_agent_sessions,
        tfn_term_sessions,
        az_term_sessions,
        sms_sessions,
        JSON.stringify(main_selected_services),
        select_service_cnam,
        type_limited_cnam,
        cnam_mou,
        cnam_sessions,
        marketing_content,
        marketing_link,
        packages_status
      );
      console.log("forSaveResponce: ", servicesResponce.data);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        //window.location.replace("/error");
        this.setState({
          successMessage: "",
          disabled: false,
          errorMessage: "There is some error while adding the package.",
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        let bundle_id = servicesResponce.data.bundle_id;
        if (marketing_content === "pdf") {
          const marketing_link_array = await this.uploadFile(bundle_id);
          console.log("marketing_link_array: ", marketing_link_array);
        }
        this.setState({
          successMessage: "Package added successfully.",
          errorMessage: "",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/services");
        }, 3000);
      } else {
        this.setState({
          successMessage: "",
          disabled: false,
          errorMessage: "There is some error while adding the package.",
        });
      }
      document
        .getElementById("Services_Block_Ratedeck")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
    }
  };

  uploadFile = async (bundle_id) => {
    //e.preventDefault();
    const { image_type, reseller_accountno } = this.state;
    if (this.state.fileName !== "") {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", this.state.file);
      formData.append("image_type", image_type);
      formData.append("accountno", reseller_accountno);
      formData.append("bundle_id", bundle_id);
      formData.append("fileName", this.state.fileName);

      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/bundle_marketing_pdf",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES ", res.data.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            return "error";
            this.setState({
              marketing_pdf: "",
            });
          } else if (res.data.status === 404) {
            return "error";
            this.setState({
              marketing_pdf: "",
            });
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            return res.data.data;
            this.setState({
              marketing_pdf: res.data.data,
            });
          } else {
            return "error";
            this.setState({
              marketing_pdf: "",
            });
          }
        });
    } else {
      return "error";
      this.setState({
        marketing_pdf: "",
      });
    }
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleFile = async (event) => {
    this.setState({
      picture: event.target.files[0],
      pictureName: event.target.files[0].name,
    });
  };

  show_service_settings = async (type) => {
    let title = "";
    let actual_service_data = this.state.actualServiceData;
    console.log("actual_service_data: ", actual_service_data);
    let selected_service = "";
    if (type === "did") {
      selected_service = this.state.select_service_did;
    } else if (type === "tfn") {
      selected_service = this.state.select_service_tfn;
    } else if (type === "term") {
      selected_service = this.state.select_service_term;
    } else if (type === "teams") {
      selected_service = this.state.select_service_teams;
    } else if (type === "ivr") {
      selected_service = this.state.select_service_ivr;
    } else if (type === "tfn_term") {
      selected_service = this.state.select_service_tfn_term;
    } else if (type === "az_term") {
      selected_service = this.state.select_service_az_term;
    } else if (type === "live_agent") {
      selected_service = this.state.select_service_live_agent;
    } else if (type === "sms") {
      selected_service = this.state.select_service_sms;
    } else if (type === "cnam") {
      selected_service = this.state.select_service_cnam;
    }

    if (selected_service === "") {
      window.$("#modalServiceSettingsError").modal("show");
    } else {
      let chosenService = [];

      for (var i = 0; i < actual_service_data.length; i++) {
        if (actual_service_data[i].id == selected_service) {
          console.log(
            "selected_service: ",
            selected_service + " " + actual_service_data[i].id
          );
          chosenService.push(actual_service_data[i]);
          title = actual_service_data[i].service_title;
        }
      }

      console.log("chosenService: ", chosenService);
      this.setState({
        service_setting_title: title,
        chosenService: chosenService,
      });
      window.$("#modalServiceSettings").modal("show");
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block_Ratedeck">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          <Prompt
            when={this.state.isBlocking === false}
            message={() =>
              `Are you sure you want to leave this page without saving the changes.`
            }
          />
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/services")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Services
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Package
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  {this.state.errorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Add New Service</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.successMessage}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* DESCRIPTION MODAL START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDescription"
                  >
                    <div
                      className="modal-dialog modal-lg modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em className="icon ni ni-table-view"></em> Short
                            Description
                          </h5>
                        </div>
                        <div className="modal-body">
                          <p>
                            {this.state.show_description !== ""
                              ? this.state.show_description
                              : "No Description"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* DESCRIPTION MODAL End */}
                  {/* SERVICE SETTINGS MODAL START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalServiceSettings"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em className="icon ni ni-table-view"></em>{" "}
                            {this.state.service_setting_title}
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.chosenService ? (
                            <>
                              <div>
                                {this.state.chosenService.map(
                                  (services, idx) => (
                                    <div
                                      className="card card-bordered"
                                      key={`servi${idx}`}
                                    >
                                      <div className="card-inner-group">
                                        {services.service_type === "did" ||
                                        services.service_type === "tfn" ||
                                        services.service_type === "teams" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                {services.service_title}
                                                <span className="badge badge-dim badge-primary badge-pill">
                                                  {services.service_type ===
                                                  "did"
                                                    ? "DID Origination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "tfn"
                                                    ? "Toll Free Origination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "term"
                                                    ? "Voice Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "teams"
                                                    ? "Microsoft Teams UCAAS"
                                                    : ""}
                                                  {services.service_type ===
                                                  "ivr"
                                                    ? "Voyce Admins"
                                                    : ""}
                                                  {services.service_type ===
                                                  "tfn_term"
                                                    ? "Toll Free Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "az_term"
                                                    ? "International A-Z Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "live_agent"
                                                    ? "Live Agent"
                                                    : ""}
                                                  {services.service_type ===
                                                  "sms"
                                                    ? "SMS Inbound / Outbound"
                                                    : ""}
                                                </span>
                                              </h6>
                                            </div>
                                            <div
                                              className="sp-plan-desc sp-plan-desc-mb"
                                              style={{
                                                marginBottom: "15px",
                                                borderBottom:
                                                  "1px solid #f6f6f6",
                                                paddingBottom: "7px",
                                              }}
                                            >
                                              <ul className="row gx-1">
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      NRC Buy Rate{" "}
                                                      <Tooltip
                                                        title="NRC Buy Rate"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_nrc.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      MRC Buy Rate
                                                      <Tooltip
                                                        title="MRC Buy Rate"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_mrc.toFixed(
                                                      4
                                                    )}
                                                  </p>
                                                </li>
                                                {services.main_type !==
                                                  "ratedeck" &&
                                                services.per_min_type !==
                                                  "unmetered" ? (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Minute Buy Rate
                                                        <Tooltip
                                                          title="Rate Per Minute Buy Rate"
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.default_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                ) : null}
                                                {services.per_min_type ===
                                                "unmetered" ? (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Session Buy
                                                        Rate
                                                        <Tooltip
                                                          title="Rate Per Minute Buy Rate"
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.default_session_rate.toFixed(
                                                        4
                                                      )}
                                                    </p>
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      NRC
                                                      <Tooltip
                                                        title="Non-Recurring Charge – A one-time fee that is deducted on service setup."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Spiff
                                                      <Tooltip
                                                        title="Sales Performance Incentive Fund is an Incentive amount earned against a sale / subscription."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services.nrc.toFixed(4)} /
                                                    $
                                                    {services.agent_nrc_comission.toFixed(
                                                      2
                                                    )}
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      MRC{" "}
                                                      <Tooltip
                                                        title="Monthly Recurring Charge - what you pay each month for the service."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    ${services.mrc.toFixed(4)} /
                                                    $
                                                    {(
                                                      (services.mrc -
                                                        services.default_mrc) *
                                                      (services.agent_mrc_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    (
                                                    {
                                                      services.agent_mrc_comission
                                                    }
                                                    %)
                                                  </p>
                                                </li>
                                                {services.main_type !==
                                                  "ratedeck" &&
                                                services.per_min_type !==
                                                  "unmetered" ? (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Minute{" "}
                                                        <Tooltip
                                                          title="Rate assigned per minute."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        / Commission{" "}
                                                        <Tooltip
                                                          title="Commission amount for agent, taken out of profit."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.rate.toFixed(4)}{" "}
                                                      / $
                                                      {(
                                                        (services.rate -
                                                          services.default_rate) *
                                                        (services.agent_rate_comission /
                                                          100)
                                                      ).toFixed(2)}{" "}
                                                      (
                                                      {
                                                        services.agent_rate_comission
                                                      }
                                                      %)
                                                    </p>
                                                  </li>
                                                ) : null}
                                                {services.per_min_type ===
                                                "unmetered" ? (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Session{" "}
                                                        <Tooltip
                                                          title="Rate assigned per session."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        / Commission{" "}
                                                        <Tooltip
                                                          title="Commission amount for agent, taken out of profit."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.rate.toFixed(4)}{" "}
                                                      / $
                                                      {(
                                                        (services.rate -
                                                          services.default_session_rate) *
                                                        (services.agent_rate_comission /
                                                          100)
                                                      ).toFixed(2)}{" "}
                                                      (
                                                      {
                                                        services.agent_rate_comission
                                                      }
                                                      %)
                                                    </p>
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                        {services.service_type === "term" ||
                                        services.service_type === "ivr" ||
                                        services.service_type ===
                                          "live_agent" ||
                                        services.service_type === "tfn_term" ||
                                        services.service_type === "az_term" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                {services.service_title}{" "}
                                                <span className="badge badge-dim badge-primary badge-pill">
                                                  {services.service_type ===
                                                  "did"
                                                    ? "DID Origination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "tfn"
                                                    ? "Toll Free Origination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "term"
                                                    ? "DID Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "teams"
                                                    ? "Microsoft Teams UCAAS"
                                                    : ""}
                                                  {services.service_type ===
                                                  "ivr"
                                                    ? "Voyce Admins"
                                                    : ""}
                                                  {services.service_type ===
                                                  "tfn_term"
                                                    ? "Toll Free Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "az_term"
                                                    ? "International A-Z Termination"
                                                    : ""}
                                                  {services.service_type ===
                                                  "live_agent"
                                                    ? "Live Agent"
                                                    : ""}
                                                  {services.service_type ===
                                                  "sms"
                                                    ? "SMS Inbound / Outbound"
                                                    : ""}
                                                  {services.main_type ===
                                                  "ratedeck"
                                                    ? " ( Rate Deck )"
                                                    : ""}
                                                </span>
                                              </h6>
                                            </div>
                                            <div
                                              className="sp-plan-desc sp-plan-desc-mb"
                                              style={{
                                                marginBottom: "15px",
                                                borderBottom:
                                                  "1px solid #f6f6f6",
                                                paddingBottom: "7px",
                                              }}
                                            >
                                              <ul className="row gx-1">
                                                {services.main_type ===
                                                "ratedeck" ? null : (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Minute Buy Rate{" "}
                                                        <Tooltip
                                                          title="Rate Per Minute Cost"
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.rate.toFixed(4)}
                                                    </p>
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                {services.main_type ===
                                                "ratedeck" ? (
                                                  <>
                                                    {/*<li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Agent Commission
                                                      </span>{" "}
                                                      {services[14]}%
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services[24]}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Download Rate Deck
                                                      </span>{" "}
                                                      <a
                                                        onClick={() =>
                                                          this.downloadCsv(
                                                            services[25]
                                                          )
                                                        }
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        className="btn btn-outline-primary"
                                                      >
                                                        <em className="icon ni ni-download"></em>{" "}
                                                        Download Rate Deck
                                                      </a>
                                                    </p>
                                                  </li>*/}
                                                  </>
                                                ) : (
                                                  <li className="col-sm-4">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Per Minute{" "}
                                                        <Tooltip
                                                          title="Rate assigned per minute."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        / Commission{" "}
                                                        <Tooltip
                                                          title="Commission amount for agent, taken out of profit."
                                                          placement="top"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>
                                                      </span>{" "}
                                                      $
                                                      {services.rate.toFixed(4)}{" "}
                                                      / $
                                                      {(
                                                        (services.rate -
                                                          services.default_rate) *
                                                        (services.agent_rate_comission /
                                                          100)
                                                      ).toFixed(2)}{" "}
                                                      (
                                                      {
                                                        services.agent_rate_comission
                                                      }
                                                      %)
                                                    </p>
                                                  </li>
                                                )}
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                        {services.service_type === "sms" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                {services[1]}{" "}
                                                <span className="badge badge-dim badge-primary badge-pill">
                                                  New
                                                </span>
                                              </h6>
                                            </div>
                                            <div
                                              className="sp-plan-desc sp-plan-desc-mb"
                                              style={{
                                                marginBottom: "15px",
                                                borderBottom:
                                                  "1px solid #f6f6f6",
                                                paddingBottom: "7px",
                                              }}
                                            >
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      SMS In Buy Rate{" "}
                                                      <Tooltip
                                                        title="Cost for SMS IN Services."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_sms_in
                                                      ? services.default_sms_in.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      SMS Out Buy Rate{" "}
                                                      <Tooltip
                                                        title="Cost for SMS Out Services"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_sms_out
                                                      ? services.default_sms_out.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      SMS In{" "}
                                                      <Tooltip
                                                        title="Price applied to SMS In Services."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.sms_in.toFixed(4)}{" "}
                                                    / $
                                                    {(
                                                      (services.sms_in -
                                                        services.default_sms_in) *
                                                      (services.sms_in_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    ({services.sms_in_comission}
                                                    %)
                                                  </p>
                                                </li>
                                                <li className="col-sm-5">
                                                  <p>
                                                    <span className="text-soft">
                                                      SMS Out{" "}
                                                      <Tooltip
                                                        title="Price applied to SMS Out Services."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.sms_out.toFixed(
                                                      4
                                                    )}{" "}
                                                    / $
                                                    {(
                                                      (services.sms_out -
                                                        services.default_sms_out) *
                                                      (services.sms_out_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    (
                                                    {services.sms_out_comission}
                                                    %)
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                        {services.service_type === "cnam" ? (
                                          <div className="card-inner">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                {services[1]}{" "}
                                                <span className="badge badge-dim badge-primary badge-pill">
                                                  New
                                                </span>
                                              </h6>
                                            </div>
                                            <div
                                              className="sp-plan-desc sp-plan-desc-mb"
                                              style={{
                                                marginBottom: "15px",
                                                borderBottom:
                                                  "1px solid #f6f6f6",
                                                paddingBottom: "7px",
                                              }}
                                            >
                                              <ul className="row gx-1">
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Update CNAM (MRC) Buy Rate{" "}
                                                      <Tooltip
                                                        title="Cost for Update CNAM (MRC)."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_cnam_mrc
                                                      ? services.default_cnam_mrc.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      DIP Charge Buy Rate{" "}
                                                      <Tooltip
                                                        title="Cost for DIP Charge"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_cnam_dip
                                                      ? services.default_cnam_dip.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Storage DIP Buy Rate{" "}
                                                      <Tooltip
                                                        title="Cost for Storage DIP"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.default_cnam_dip_local
                                                      ? services.default_cnam_dip_local.toFixed(
                                                          4
                                                        )
                                                      : "0.00"}
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="sp-plan-desc sp-plan-desc-mb">
                                              <ul className="row gx-1">
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Update CNAM (MRC){" "}
                                                      <Tooltip
                                                        title="Price applied to Update CNAM (MRC)."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.cnam_mrc.toFixed(
                                                      4
                                                    )}{" "}
                                                    / $
                                                    {(
                                                      (services.cnam_mrc -
                                                        services.default_cnam_mrc) *
                                                      (services.cnam_mrc_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    (
                                                    {
                                                      services.cnam_mrc_comission
                                                    }
                                                    %)
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      DIP Charges{" "}
                                                      <Tooltip
                                                        title="Price applied to DIP Charges."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.cnam_dip.toFixed(
                                                      4
                                                    )}{" "}
                                                    / $
                                                    {(
                                                      (services.cnam_dip -
                                                        services.default_cnam_dip) *
                                                      (services.cnam_dip_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    (
                                                    {
                                                      services.cnam_dip_comission
                                                    }
                                                    %)
                                                  </p>
                                                </li>
                                                <li className="col-sm-4">
                                                  <p>
                                                    <span className="text-soft">
                                                      Storage DIP{" "}
                                                      <Tooltip
                                                        title="Price applied to Storage DIP."
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>{" "}
                                                      / Commission{" "}
                                                      <Tooltip
                                                        title="Commission amount for agent, taken out of profit"
                                                        placement="top"
                                                      >
                                                        <em
                                                          className="icon ni ni-info"
                                                          style={{
                                                            color: "#6576ff",
                                                          }}
                                                        ></em>
                                                      </Tooltip>
                                                    </span>{" "}
                                                    $
                                                    {services.cnam_dip_local.toFixed(
                                                      4
                                                    )}{" "}
                                                    / $
                                                    {(
                                                      (services.cnam_dip_local -
                                                        services.default_cnam_dip_local) *
                                                      (services.cnam_dip_local_comission /
                                                        100)
                                                    ).toFixed(2)}{" "}
                                                    (
                                                    {
                                                      services.cnam_dip_local_comission
                                                    }
                                                    %)
                                                  </p>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        ) : null}
                                        {services.service_type === "did" ||
                                        services.service_type === "tfn" ||
                                        services.service_type === "teams" ? (
                                          <>
                                            {services.porting_settings &&
                                            services.porting_settings.length >
                                              0 ? (
                                              <div className="card-inner">
                                                <div className="sp-plan-head">
                                                  <h6 className="title">
                                                    Porting Rates
                                                  </h6>
                                                </div>

                                                <div
                                                  className="sp-plan-desc sp-plan-desc-mb"
                                                  style={{
                                                    marginBottom: "15px",
                                                    borderBottom:
                                                      "1px solid #f6f6f6",
                                                    paddingBottom: "7px",
                                                  }}
                                                >
                                                  <ul className="row gx-1">
                                                    <li className="col-sm-5">
                                                      <p>
                                                        <span className="text-soft">
                                                          Portin Buy Rate
                                                        </span>{" "}
                                                        $
                                                        {services.porting_settings[0].portin_default_rate.toFixed(
                                                          4
                                                        )}
                                                      </p>
                                                    </li>
                                                    <li className="col-sm-5">
                                                      <p>
                                                        <span className="text-soft">
                                                          Portout Buy Rate
                                                        </span>{" "}
                                                        $
                                                        {services.porting_settings[0].portout_default_rate.toFixed(
                                                          4
                                                        )}
                                                      </p>
                                                    </li>
                                                  </ul>
                                                </div>
                                                <div className="sp-plan-desc sp-plan-desc-mb">
                                                  <ul className="row gx-1">
                                                    <li className="col-sm-5">
                                                      <p>
                                                        <span className="text-soft">
                                                          Portin Sell Rate /
                                                          Spiff
                                                        </span>{" "}
                                                        $
                                                        {services.porting_settings[0].portin_sell_rate.toFixed(
                                                          4
                                                        )}{" "}
                                                        / $
                                                        {services.porting_settings[0].portin_agent_spiff.toFixed(
                                                          2
                                                        )}
                                                      </p>
                                                    </li>
                                                    <li className="col-sm-5">
                                                      <p>
                                                        <span className="text-soft">
                                                          Portin Sell Rate /
                                                          Spiff
                                                        </span>{" "}
                                                        $
                                                        {services.porting_settings[0].portout_sell_rate.toFixed(
                                                          4
                                                        )}{" "}
                                                        / $
                                                        {services.porting_settings[0].portout_agent_spiff.toFixed(
                                                          2
                                                        )}
                                                      </p>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        ) : null}
                                        <div className="card-inner">
                                          {services.main_type !== "ratedeck" ? (
                                            <>
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Cancellation Fee
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Cancellation Fee
                                                      </span>{" "}
                                                      {services.cancellation_fee &&
                                                      services.cancellation_fee !==
                                                        "" ? (
                                                        <>
                                                          $
                                                          {services.cancellation_fee.toFixed(
                                                            4
                                                          )}
                                                        </>
                                                      ) : (
                                                        "$0.00"
                                                      )}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          ) : null}
                                          {services.main_type === "ratedeck" ? (
                                            <>
                                              <div className="sp-plan-head">
                                                <h6 className="title">
                                                  Rate Deck / Effective Date
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <ul className="row gx-1">
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Rate Deck
                                                      </span>{" "}
                                                      {services.rate_deck_file &&
                                                      services.rate_deck_file !==
                                                        "" ? (
                                                        <>
                                                          <a
                                                            onClick={() =>
                                                              this.downloadCsv(
                                                                services.rate_deck_file
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            className="btn btn-outline-primary"
                                                          >
                                                            <em className="icon ni ni-download"></em>{" "}
                                                            Download Rate Deck
                                                          </a>
                                                        </>
                                                      ) : null}
                                                    </p>
                                                  </li>
                                                  <li className="col-sm-5">
                                                    <p>
                                                      <span className="text-soft">
                                                        Effective Date
                                                      </span>{" "}
                                                      {services.effective_date &&
                                                      services.effective_date !==
                                                        "" ? (
                                                        <>
                                                          {this.format_date(
                                                            services.effective_date
                                                          )}
                                                        </>
                                                      ) : null}
                                                    </p>
                                                  </li>
                                                </ul>
                                              </div>
                                            </>
                                          ) : null}
                                          {services.misc_settings &&
                                          services.misc_settings.length > 0 ? (
                                            <>
                                              <div
                                                className="sp-plan-head"
                                                style={{
                                                  marginTop: "15px",
                                                  borderTop:
                                                    "1px solid #f6f6f6",
                                                  paddingTop: "15px",
                                                }}
                                              >
                                                <h6 className="title">
                                                  Miscellaneous Rates
                                                </h6>
                                              </div>
                                              <div className="sp-plan-desc sp-plan-desc-mb">
                                                <table className="table">
                                                  <thead className="thead-light">
                                                    <tr>
                                                      <th scope="col">Title</th>
                                                      <th scope="col">
                                                        Rate Type
                                                      </th>
                                                      <th scope="col">Rate</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {services.misc_settings.map(
                                                      (miscrate, idx) => (
                                                        <tr key={`mis${idx}`}>
                                                          <td>
                                                            {
                                                              miscrate.misc_title
                                                            }
                                                          </td>
                                                          <td>
                                                            {miscrate.misc_type ===
                                                            "per_minute"
                                                              ? "Per Minute"
                                                              : ""}
                                                            {miscrate.misc_type ===
                                                            "per_hour"
                                                              ? "Per Hour"
                                                              : ""}
                                                            {miscrate.misc_type ===
                                                            "one_time"
                                                              ? "One Time"
                                                              : ""}
                                                          </td>
                                                          <td>
                                                            {miscrate.misc_rate !==
                                                              "" &&
                                                            miscrate.misc_rate >
                                                              0 ? (
                                                              <>
                                                                $
                                                                {miscrate.misc_rate.toFixed(
                                                                  4
                                                                )}
                                                              </>
                                                            ) : (
                                                              "$0.00"
                                                            )}
                                                          </td>
                                                        </tr>
                                                      )
                                                    )}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          ) : null}
                                        </div>
                                        <div className="card-inner">
                                          <div className="sp-plan-head">
                                            <h6 className="title">
                                              Add / Update Dates
                                            </h6>
                                          </div>
                                          <div className="sp-plan-desc sp-plan-desc-mb">
                                            <ul className="row gx-1">
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Date Added
                                                  </span>{" "}
                                                  {services.add_datetime !== ""
                                                    ? this.format_date(
                                                        services.add_datetime
                                                      )
                                                    : null}
                                                </p>
                                              </li>
                                              <li className="col-sm-5">
                                                <p>
                                                  <span className="text-soft">
                                                    Date Updated
                                                  </span>{" "}
                                                  {services.update_datetime !==
                                                  ""
                                                    ? this.format_date(
                                                        services.update_datetime
                                                      )
                                                    : null}
                                                </p>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <div className="card-inner">
                                          <div className="sp-plan-head-group">
                                            <div className="sp-plan-head">
                                              <h6 className="title">
                                                Short Description
                                              </h6>
                                              <span className="ff-italic text-soft">
                                                {services.product_desc}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SERVICE SETTINGS MODAL End */}
                  {/* SERVICE SETTINGS ERROR MODAL START */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalServiceSettingsError"
                  >
                    <div
                      className="modal-dialog modal-lg modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em className="icon ni ni-table-view"></em>{" "}
                            {this.state.service_setting_title}
                          </h5>
                        </div>
                        <div className="modal-body">
                          <div className="example-alert">
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Notice</h4>
                                <p>
                                  Please select a service to view the rates.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* SERVICE SETTINGS ERROR MODAL End */}

                  <form
                    method="post"
                    className="serviceForm"
                    encType="multipart/form-data"
                    onSubmit={this.formSave}
                    id="serviceForm"
                  >
                    {/* NEW DESIGN HTML */}
                    <div className="nk-block-head nk-block-head-lg ">
                      <div className="nk-block-head-content">
                        <h2 className="nk-block-title fw-normal">
                          Add New Package
                        </h2>
                        <div className="nk-block-des">
                          <p>
                            Please add all the fields carefully while adding the
                            new rate deck.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="nk-block">
                      <div className="card card-bordered">
                        <div className="nk-kycfm">
                          <div
                            className="nk-kycfm-head"
                            style={{ background: "#f5f6fa" }}
                          >
                            <div className="nk-kycfm-count">01</div>
                            <div className="nk-kycfm-title">
                              <h5 className="title">Package Details</h5>
                              <p className="sub-title">
                                Add initial package details
                              </p>
                            </div>
                          </div>

                          {/* .nk-kycfm-head */}
                          <div className="nk-kycfm-content">
                            <div className="row g-4">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="Package title."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Package Title{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="bundle_title"
                                      name="bundle_title"
                                      type="text"
                                      label="Package Title"
                                      defaultValue={this.state.bundle_title}
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              {/* .col */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="This is the rate per month which will be charged monthly."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Rate Per Month (Charge Monthly){" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="per_month_rate"
                                      name="per_month_rate"
                                      type="text"
                                      label="0.00"
                                      defaultValue={this.state.per_month_rate}
                                      onChange={this.handleChange}
                                      onKeyPress={() => {
                                        this.checkNumber();
                                      }}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="This is the rate per month which will be charged yearly."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Rate Per Month (Charge Yearly){" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="per_year_rate"
                                      name="per_year_rate"
                                      type="text"
                                      label="0.00"
                                      defaultValue={this.state.per_year_rate}
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      onKeyPress={() => {
                                        this.checkNumber();
                                      }}
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="This is the status of the package. Active package will be visible for agents."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Status{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <div className="custom-control custom-switch">
                                      <input
                                        type="checkbox"
                                        className="custom-control-input vdrSelected3"
                                        id="package_status"
                                        name="package_status"
                                        defaultChecked={
                                          this.state.package_status
                                        }
                                        value="1"
                                        onChange={this.handleChange}
                                      />
                                      <label
                                        className="custom-control-label"
                                        htmlFor="package_status"
                                      >
                                        {this.state.package_status
                                          ? "Active"
                                          : "Inactive"}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* .col */}
                            </div>
                            {/* .row */}
                          </div>

                          {/* .nk-kycfm-content */}
                          {this.state.select_service !== "" ? (
                            <>
                              {this.state.tableLoaderListingBlocks === true ? (
                                <div className="col-md-12">
                                  {tableLoaderListingBlocks()}
                                </div>
                              ) : (
                                <div
                                  className="nk-kycfm-head"
                                  style={{
                                    background: "#f5f6fa",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    this.open_current_tab("rate_settings_tab");
                                  }}
                                  title="Click to open the Rate Settings"
                                >
                                  <div className="nk-kycfm-count">02</div>
                                  <div className="nk-kycfm-title">
                                    <h5 className="title">
                                      Rates Setting{" "}
                                      <sup style={{ color: "red" }}>*</sup>
                                    </h5>
                                    <p className="sub-title">
                                      Please select at least one rate and choose
                                      its service.
                                    </p>
                                  </div>
                                </div>
                              )}

                              {this.state.rate_settings_tab === true ? (
                                <div className="nk-kycfm-content">
                                  <div className="row g-4">
                                    {this.state.serviceData &&
                                    this.state.serviceData.did.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-did"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="did"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-did"
                                                >
                                                  DID Origination
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-4 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of DID Origination. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_did"
                                                            name="select_service_did"
                                                            select
                                                            label="Select DID Origination Service"
                                                            value={
                                                              this.state
                                                                .select_service_did
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="DID Origination Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_did_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets100"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.did.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_did
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "did"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_did"
                                                            id="type_limited_did"
                                                            data-title="Unlimited"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_did"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_did ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="did_mou"
                                                              name="did_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .did_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .did_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="did_sessions"
                                                              name="did_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .did_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .did_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "did"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.tfn.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-tfn"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="tfn"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-tfn"
                                                >
                                                  Toll Free Origination
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of Toll Free Origination. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_tfn"
                                                            name="select_service_tfn"
                                                            select
                                                            label="Select Toll Free Origination Service"
                                                            value={
                                                              this.state
                                                                .select_service_tfn
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Toll Free Origination Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_tfn_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets101"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.tfn.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_tfn
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "tfn"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_tfn"
                                                            id="type_limited_tfn"
                                                            data-title="Limited"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_tfn"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_tfn ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="tfn_mou"
                                                              name="tfn_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .tfn_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .tfn_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="tfn_sessions"
                                                              name="tfn_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .tfn_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .tfn_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "tfn"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.term.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-term"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="term"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-term"
                                                >
                                                  DID Termination
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of DID Termination. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_term"
                                                            name="select_service_term"
                                                            select
                                                            label="Select DID Termination Service"
                                                            value={
                                                              this.state
                                                                .select_service_term
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="DID Termination Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_term_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets102"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.term.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is limited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_term
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "term"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_term"
                                                            id="type_limited_term"
                                                            data-title="Unlimited"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_term"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_term ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="term_mou"
                                                              name="term_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .term_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .term_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="term_sessions"
                                                              name="term_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .term_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .term_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "term"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {/* {this.state.serviceData &&
                                    this.state.serviceData.ivr.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-ivr"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="ivr"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-ivr"
                                                >
                                                  Voyce Admins
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of Voyce Admins. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_ivr"
                                                            name="select_service_ivr"
                                                            select
                                                            label="Select Voyce Admins Service"
                                                            value={
                                                              this.state
                                                                .select_service_ivr
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Voyce Admins Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_ivr_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets102"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.ivr.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_ivr
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "ivr"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_ivr"
                                                            id="type_limited_ivr"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_ivr"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_ivr ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="ivr_mou"
                                                              name="ivr_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .ivr_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .ivr_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="ivr_sessions"
                                                              name="ivr_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .ivr_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .ivr_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "ivr"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}*/}
                                    {this.state.serviceData &&
                                    this.state.serviceData.teams.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-teams"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="teams"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-teams"
                                                >
                                                  Microsoft Teams UCAAS
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of Microsoft Teams UCAAS. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_teams"
                                                            name="select_service_teams"
                                                            select
                                                            label="Select Microsoft Teams UCAAS Service"
                                                            value={
                                                              this.state
                                                                .select_service_teams
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Microsoft Teams UCAAS Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_teams_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets102"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.teams.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_teams
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "teams"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_teams"
                                                            id="type_limited_teams"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_teams"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_teams ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="teams_mou"
                                                              name="teams_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .teams_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .teams_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="teams_sessions"
                                                              name="teams_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .teams_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .teams_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "teams"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.tfn_term.length >
                                      0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-tfn-term"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="tfn_term"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-tfn-term"
                                                >
                                                  Toll Free Termination
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of Toll Free Termination. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_tfn_term"
                                                            name="select_service_tfn_term"
                                                            select
                                                            label="Select Toll Free Termination Service"
                                                            value={
                                                              this.state
                                                                .select_service_tfn_term
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Toll Free Termination Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_tfn_term_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets102"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.tfn_term.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_tfn_term
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "tfn_term"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_tfn_term"
                                                            id="type_limited_tfn_term"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_tfn_term"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_tfn_term ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="tfn_term_mou"
                                                              name="tfn_term_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .tfn_term_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .tfn_term_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="tfn_term_sessions"
                                                              name="tfn_term_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .tfn_term_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .tfn_term_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "tfn_term"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.az_term.length >
                                      0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-az-term"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="az_term"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-az-term"
                                                >
                                                  International A-Z Termination
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of International A-Z Termination. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_az_term"
                                                            name="select_service_az_term"
                                                            select
                                                            label="Select International A-Z Termination Service"
                                                            value={
                                                              this.state
                                                                .select_service_az_term
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="International A-Z Termination Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_az_term_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets102"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.az_term.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_az_term
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "az_term"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_az_term"
                                                            id="type_limited_az_term"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_az_term"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_az_term ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="az_term_mou"
                                                              name="az_term_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .az_term_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .az_term_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="az_term_sessions"
                                                              name="az_term_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .az_term_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .az_term_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "az_term"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.live_agent.length >
                                      0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-live-agent"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="live_agent"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-live-agent"
                                                >
                                                  Live Agent
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of Live Agent. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_live_agent"
                                                            name="select_service_live_agent"
                                                            select
                                                            label="Select Live Agent Service"
                                                            value={
                                                              this.state
                                                                .select_service_live_agent
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="Live Agent Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_live_agent_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets12"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.live_agent.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_live_agent
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "live_agent"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_live_agent"
                                                            id="type_limited_live_agent"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_live_agent"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_live_agent ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="live_agent_mou"
                                                              name="live_agent_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .live_agent_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .live_agent_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="live_agent_sessions"
                                                              name="live_agent_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .live_agent_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .live_agent_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "live_agent"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.sms.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-sms"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="sms"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-sms"
                                                >
                                                  SMS Inbound / Outbound
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of SMS Inbound / Outbound. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_sms"
                                                            name="select_service_sms"
                                                            select
                                                            label="Select SMS Inbound / Outbound Service"
                                                            value={
                                                              this.state
                                                                .select_service_sms
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="SMS Inbound / Outbound Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_sms_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets122"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.sms.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                      </div>
                                                      <ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_sms
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "sms"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_sms"
                                                            id="type_limited_sms"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_sms"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_sms ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="sms_mou"
                                                              name="sms_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .sms_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .sms_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="sms_sessions"
                                                              name="sms_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .sms_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .sms_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "sms"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.serviceData &&
                                    this.state.serviceData.cnam.length > 0 ? (
                                      <div
                                        className="nk-block"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="card card-bordered sp-plan">
                                          <div className="row no-gutters">
                                            <div className="col-md-3">
                                              <div className="custom-control custom-control-lg custom-checkbox align-switch-div">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id="cp1-package-cnam"
                                                  name="did_service"
                                                  defaultChecked={
                                                    this.state.did_service
                                                  }
                                                  value="cnam"
                                                  onChange={this.handleChange}
                                                  required=""
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor="cp1-package-cnam"
                                                >
                                                  CNAM
                                                </label>
                                              </div>
                                            </div>
                                            <div className="col-md-7">
                                              <div className="sp-plan-info card-inner">
                                                <div className="row gx-0 gy-3">
                                                  <div className="col-xl-10 col-sm-8">
                                                    <div
                                                      className="col-xl-8 col-md-8 col-lg-8 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      <div className="form-group">
                                                        <div className="form-label-group">
                                                          <label className="form-label">
                                                            <Tooltip
                                                              title="These are the services of CNAM. You can select one service from the list."
                                                              placement="right"
                                                            >
                                                              <em
                                                                className="icon ni ni-info"
                                                                style={{
                                                                  color:
                                                                    "#6576ff",
                                                                }}
                                                              ></em>
                                                            </Tooltip>{" "}
                                                            Select Service{" "}
                                                            <span className="text-danger">
                                                              *
                                                            </span>
                                                          </label>
                                                        </div>
                                                        <div className="form-control-group">
                                                          <MutextField
                                                            id="select_service_cnam"
                                                            name="select_service_cnam"
                                                            select
                                                            label="Select CNAM Service"
                                                            value={
                                                              this.state
                                                                .select_service_cnam
                                                            }
                                                            onChange={
                                                              this.setMarkUp
                                                            }
                                                            SelectProps={{
                                                              native: true,
                                                            }}
                                                            helperText="CNAM Services."
                                                            variant="outlined"
                                                            error={
                                                              this.state
                                                                .select_service_cnam_error
                                                            }
                                                            fullWidth
                                                          >
                                                            <option
                                                              key="sets222"
                                                              value=""
                                                            ></option>
                                                            {this.state.serviceData.cnam.map(
                                                              (
                                                                services,
                                                                index
                                                              ) => (
                                                                <option
                                                                  key={`sets${index}`}
                                                                  value={
                                                                    services.id
                                                                  }
                                                                >
                                                                  {
                                                                    services.service_title
                                                                  }
                                                                </option>
                                                              )
                                                            )}
                                                          </MutextField>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div
                                                      className="col-xl-4 col-md-4 col-lg-4 col-sm-12"
                                                      style={{ float: "left" }}
                                                    >
                                                      {/*<div className="form-label-group">
                                                        <label className="form-label">
                                                          <Tooltip
                                                            title="Unlimited / Limited. Default value is Unlimited."
                                                            placement="right"
                                                          >
                                                            <em
                                                              className="icon ni ni-info"
                                                              style={{
                                                                color:
                                                                  "#6576ff",
                                                              }}
                                                            ></em>
                                                          </Tooltip>{" "}
                                                          Quota Type{" "}
                                                          <span className="text-danger">
                                                            *
                                                          </span>
                                                        </label>
                                                            </div>*/}
                                                      {/*<ul
                                                        className="nk-kycfm-control-list g-3"
                                                        style={{ width: "33%" }}
                                                      >
                                                        <li className="nk-kycfm-control-item">
                                                          <input
                                                            defaultChecked={
                                                              this.state
                                                                .type_limited_sms
                                                            }
                                                            onChange={() =>
                                                              this.handleChangeCheckbox(
                                                                "sms"
                                                              )
                                                            }
                                                            className="nk-kycfm-control vdrSelected2"
                                                            type="checkbox"
                                                            name="type_limited_sms"
                                                            id="type_limited_sms"
                                                            data-title="Quota Type"
                                                            value={true}
                                                          />
                                                          <label
                                                            className="nk-kycfm-label"
                                                            htmlFor="type_limited_sms"
                                                          >
                                                            <span className="nk-kycfm-label-icon">
                                                              <div className="label-icon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  viewBox="0 0 71.9904 75.9285"
                                                                >
                                                                  <path
                                                                    d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                                    transform="translate(-3.9995 -2.101)"
                                                                    fill="#6476ff"
                                                                  />
                                                                </svg>
                                                              </div>
                                                            </span>
                                                            <span className="nk-kycfm-label-text">
                                                              Limited
                                                            </span>
                                                          </label>
                                                        </li>
                                                          </ul>*/}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              {/*<div
                                                className="sp-plan-info card-inner"
                                                style={{ paddingTop: "0px" }}
                                              >
                                                <div className="row gx-4 gy-3">
                                                  {this.state
                                                    .type_limited_cnam ? (
                                                    <div className="col-xl-12 col-md-12 col-sm-12">
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              MOU{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="sms_mou"
                                                              name="sms_mou"
                                                              type="text"
                                                              label="Minutes of Use"
                                                              defaultValue={
                                                                this.state
                                                                  .sms_mou
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .sms_mou_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="col-xl-5 col-md-5 col-sm-12"
                                                        style={{
                                                          float: "left",
                                                        }}
                                                      >
                                                        <div className="form-group">
                                                          <div className="form-label-group">
                                                            <label className="form-label">
                                                              <Tooltip
                                                                title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                                placement="right"
                                                              >
                                                                <em
                                                                  className="icon ni ni-info"
                                                                  style={{
                                                                    color:
                                                                      "#6576ff",
                                                                  }}
                                                                ></em>
                                                              </Tooltip>{" "}
                                                              Session{" "}
                                                              <span className="text-danger">
                                                                *
                                                              </span>
                                                            </label>
                                                          </div>
                                                          <div className="form-control-group">
                                                            <MutextField
                                                              id="sms_sessions"
                                                              name="sms_sessions"
                                                              type="text"
                                                              label="Session "
                                                              defaultValue={
                                                                this.state
                                                                  .sms_sessions
                                                              }
                                                              onChange={
                                                                this
                                                                  .handleChange
                                                              }
                                                              variant="outlined"
                                                              onKeyPress={() => {
                                                                this.checkNumber();
                                                              }}
                                                              error={
                                                                this.state
                                                                  .sms_sessions_error
                                                              }
                                                              fullWidth
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>*/}
                                            </div>
                                            <div className="col-md-2">
                                              <div className="sp-plan-action card-inner">
                                                <div className="sp-plan-note ">
                                                  <div className="card-action">
                                                    <a
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() =>
                                                        this.show_service_settings(
                                                          "cnam"
                                                        )
                                                      }
                                                      className="link link-sm text-primary"
                                                    >
                                                      View Rates{" "}
                                                      <em className="icon ni ni-chevron-right"></em>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}

                              <div
                                className="nk-kycfm-head"
                                style={{
                                  background: "#f5f6fa",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.open_current_tab("desc_settings_tab");
                                }}
                                title="Click to open the short description section"
                              >
                                <div className="nk-kycfm-count">03</div>
                                <div className="nk-kycfm-title">
                                  <h5 className="title">Short Description</h5>
                                  <p className="sub-title">
                                    Short description of the service
                                  </p>
                                </div>
                              </div>
                              {/* .nk-kycfm-head */}
                              {this.state.desc_settings_tab === true ? (
                                <div className="nk-kycfm-content">
                                  <div className="row g-4">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <TextareaAutosize
                                        maxrows={4}
                                        name="product_desc"
                                        id="product_desc"
                                        aria-label=""
                                        placeholder=""
                                        defaultValue={this.state.product_desc}
                                        onChange={this.handleChange}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          borderColor: "rgba(0, 0, 0, 0.125)",
                                          borderRadius: "4px",
                                        }}
                                        variant="outlined"
                                      />
                                    </div>
                                    <div className="col-md-12">
                                      <ul
                                        className="nk-kycfm-control-list g-3"
                                        style={{ width: "33%" }}
                                      >
                                        <li className="nk-kycfm-control-item">
                                          <input
                                            className="nk-kycfm-control"
                                            type="radio"
                                            name="marketing_content"
                                            id="marketing_link"
                                            data-title="Marketing Link"
                                            onChange={
                                              this.handleChangeCheckboxMarketing
                                            }
                                            checked={
                                              this.state.marketing_content ===
                                              "link"
                                            }
                                            value="link"
                                          />
                                          <label
                                            className="nk-kycfm-label"
                                            htmlFor="marketing_link"
                                          >
                                            <span className="nk-kycfm-label-icon">
                                              <div className="label-icon">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 120 118"
                                                >
                                                  <path
                                                    d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
                                                    transform="translate(0 -1)"
                                                    fill="#f6faff"
                                                  ></path>
                                                  <rect
                                                    x="18"
                                                    y="32"
                                                    width="84"
                                                    height="50"
                                                    rx="4"
                                                    ry="4"
                                                    fill="#fff"
                                                  ></rect>
                                                  <rect
                                                    x="26"
                                                    y="44"
                                                    width="20"
                                                    height="12"
                                                    rx="1"
                                                    ry="1"
                                                    fill="#e5effe"
                                                  ></rect>
                                                  <rect
                                                    x="50"
                                                    y="44"
                                                    width="20"
                                                    height="12"
                                                    rx="1"
                                                    ry="1"
                                                    fill="#e5effe"
                                                  ></rect>
                                                  <rect
                                                    x="74"
                                                    y="44"
                                                    width="20"
                                                    height="12"
                                                    rx="1"
                                                    ry="1"
                                                    fill="#e5effe"
                                                  ></rect>
                                                  <rect
                                                    x="38"
                                                    y="60"
                                                    width="20"
                                                    height="12"
                                                    rx="1"
                                                    ry="1"
                                                    fill="#e5effe"
                                                  ></rect>
                                                  <rect
                                                    x="62"
                                                    y="60"
                                                    width="20"
                                                    height="12"
                                                    rx="1"
                                                    ry="1"
                                                    fill="#e5effe"
                                                  ></rect>
                                                  <path
                                                    d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
                                                    transform="translate(0 -1)"
                                                    fill="#798bff"
                                                  ></path>
                                                  <path
                                                    d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                                    transform="translate(0 -1)"
                                                    fill="#6576ff"
                                                  ></path>
                                                  <path
                                                    d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                                    transform="translate(0 -1)"
                                                    fill="none"
                                                    stroke="#6576ff"
                                                    stroke-miterlimit="10"
                                                    stroke-width="2"
                                                  ></path>
                                                  <line
                                                    x1="40"
                                                    y1="22"
                                                    x2="57"
                                                    y2="22"
                                                    fill="none"
                                                    stroke="#fffffe"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></line>
                                                  <line
                                                    x1="40"
                                                    y1="27"
                                                    x2="57"
                                                    y2="27"
                                                    fill="none"
                                                    stroke="#fffffe"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></line>
                                                  <line
                                                    x1="40"
                                                    y1="32"
                                                    x2="50"
                                                    y2="32"
                                                    fill="none"
                                                    stroke="#fffffe"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></line>
                                                  <line
                                                    x1="30.5"
                                                    y1="87.5"
                                                    x2="30.5"
                                                    y2="91.5"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></line>
                                                  <line
                                                    x1="28.5"
                                                    y1="89.5"
                                                    x2="32.5"
                                                    y2="89.5"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></line>
                                                  <line
                                                    x1="79.5"
                                                    y1="22.5"
                                                    x2="79.5"
                                                    y2="26.5"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></line>
                                                  <line
                                                    x1="77.5"
                                                    y1="24.5"
                                                    x2="81.5"
                                                    y2="24.5"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></line>
                                                  <circle
                                                    cx="90.5"
                                                    cy="97.5"
                                                    r="3"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-miterlimit="10"
                                                  ></circle>
                                                  <circle
                                                    cx="24"
                                                    cy="23"
                                                    r="2.5"
                                                    fill="none"
                                                    stroke="#9cabff"
                                                    stroke-miterlimit="10"
                                                  ></circle>
                                                </svg>
                                              </div>
                                            </span>
                                            <span className="nk-kycfm-label-text">
                                              Marketing Link
                                            </span>
                                          </label>
                                        </li>
                                        <li className="nk-kycfm-control-item">
                                          <input
                                            checked={
                                              this.state.marketing_content ===
                                              "pdf"
                                            }
                                            onChange={
                                              this.handleChangeCheckboxMarketing
                                            }
                                            className="nk-kycfm-control"
                                            type="radio"
                                            name="marketing_content"
                                            id="pdf_select"
                                            data-title="PDF"
                                            value="pdf"
                                          />
                                          <label
                                            className="nk-kycfm-label"
                                            htmlFor="pdf_select"
                                          >
                                            <span className="nk-kycfm-label-icon">
                                              <div className="label-icon">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 90 90"
                                                >
                                                  <rect
                                                    x="15"
                                                    y="5"
                                                    width="56"
                                                    height="70"
                                                    rx="6"
                                                    ry="6"
                                                    fill="#e3e7fe"
                                                    stroke="#6576ff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></rect>
                                                  <path
                                                    d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                                                    fill="#fff"
                                                    stroke="#6576ff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></path>
                                                  <polyline
                                                    points="60 16 60 31 75 31.07"
                                                    fill="none"
                                                    stroke="#6576ff"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                    stroke-width="2"
                                                  ></polyline>
                                                  <path
                                                    d="M57.16,60.13c-.77,0-1.53,0-2.28.08l-.82.07c-.28-.31-.55-.63-.81-1a32.06,32.06,0,0,1-4.11-6.94,28.83,28.83,0,0,0,.67-3.72,16.59,16.59,0,0,0-.49-7.29c-.29-.78-1-1.72-1.94-1.25S46.08,42.2,46,43.28a11,11,0,0,0,.12,2.63,20.88,20.88,0,0,0,.61,2.51c.23.76.49,1.51.77,2.25-.18.59-.37,1.16-.56,1.72-.46,1.28-1,2.49-1.43,3.65l-.74,1.7C44,59.52,43.18,61.26,42.25,63a27.25,27.25,0,0,0-5.72,2.85,12.36,12.36,0,0,0-2.26,2A4.33,4.33,0,0,0,33,70.24a1.62,1.62,0,0,0,.59,1.39,2.36,2.36,0,0,0,2,.27c2.19-.48,3.86-2.48,5.29-4.15a46.09,46.09,0,0,0,3.27-4.41h0a47.26,47.26,0,0,1,6.51-1.63c1.06-.18,2.15-.34,3.26-.44a15.74,15.74,0,0,0,2.54,2.07,11.65,11.65,0,0,0,2.28,1.16,15.78,15.78,0,0,0,2.45.65,7,7,0,0,0,1.3.07c1,0,2.4-.44,2.49-1.71a1.93,1.93,0,0,0-.2-1C64,61,61.33,60.55,60.1,60.34A17,17,0,0,0,57.16,60.13Zm-16,4.68c-.47.75-.91,1.44-1.33,2-1,1.48-2.2,3.25-3.9,3.91a3,3,0,0,1-1.2.22c-.4,0-.79-.21-.77-.69a2,2,0,0,1,.3-.89,5,5,0,0,1,.7-1,11.3,11.3,0,0,1,2.08-1.79,24.17,24.17,0,0,1,4.4-2.33C41.36,64.49,41.27,64.65,41.18,64.81ZM47,45.76a9.07,9.07,0,0,1-.07-2.38,6.73,6.73,0,0,1,.22-1.12c.1-.3.29-1,.61-1.13.51-.15.67,1,.73,1.36a15.91,15.91,0,0,1-.36,5.87c-.06.3-.13.59-.21.88-.12-.36-.24-.73-.35-1.09A19.24,19.24,0,0,1,47,45.76Zm3.55,15A46.66,46.66,0,0,0,45,62a14.87,14.87,0,0,0,1.38-2.39,29.68,29.68,0,0,0,2.42-5.92,28.69,28.69,0,0,0,3.87,6.15l.43.51C52.22,60.48,51.36,60.6,50.52,60.74Zm13.15,2.64c-.07.41-.89.65-1.27.71A6.84,6.84,0,0,1,59,63.74a10,10,0,0,1-2.15-1.06,12.35,12.35,0,0,1-1.9-1.51c.73,0,1.47-.07,2.21-.06a18.42,18.42,0,0,1,2.23.15,7.6,7.6,0,0,1,4,1.63C63.63,63.07,63.7,63.24,63.67,63.38Z"
                                                    fill="#6576ff"
                                                  ></path>
                                                </svg>
                                              </div>
                                            </span>
                                            <span className="nk-kycfm-label-text">
                                              Marketing PDF
                                            </span>
                                          </label>
                                        </li>
                                        {/* .nk-kycfm-control-item */}
                                      </ul>
                                    </div>
                                    {this.state.marketing_content === "link" ? (
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <div className="form-label-group">
                                            <label className="form-label">
                                              <Tooltip
                                                title="Marketing Link."
                                                placement="right"
                                              >
                                                <em
                                                  className="icon ni ni-info"
                                                  style={{
                                                    color: "#6576ff",
                                                  }}
                                                ></em>
                                              </Tooltip>{" "}
                                              Marketing Link{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </label>
                                          </div>
                                          <div className="form-control-group">
                                            <MutextField
                                              required
                                              id="marketing_link"
                                              name="marketing_link"
                                              type="text"
                                              label="Marketing Link"
                                              defaultValue={
                                                this.state.marketing_link
                                              }
                                              onChange={this.handleChange}
                                              variant="outlined"
                                              fullWidth
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.marketing_content === "pdf" ? (
                                      <div className="col-md-12">
                                        <div class="form-group">
                                          <label
                                            class="form-label"
                                            for="default-06"
                                          >
                                            <Tooltip
                                              title="Marketing PDF."
                                              placement="right"
                                            >
                                              <em
                                                className="icon ni ni-info"
                                                style={{
                                                  color: "#6576ff",
                                                }}
                                              ></em>
                                            </Tooltip>{" "}
                                            Marketing PDF
                                          </label>
                                          <div class="form-control-wrap">
                                            <div class="custom-file">
                                              <input
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) =>
                                                  this.saveFilePdf(e)
                                                }
                                                defaultValue={this.state.file}
                                                multiple=""
                                                class="custom-file-input"
                                                id="customFile"
                                                name="customFile"
                                              />
                                              <label
                                                class="custom-file-label"
                                                for="customFile"
                                              >
                                                Choose Marketing File
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              <div className="nk-kycfm-footer">
                                <div className="nk-kycfm-action pt-2 text-right">
                                  <a
                                    onClick={() =>
                                      this.addMenuHistory("/services")
                                    }
                                    className="btn btn-lg btn-light"
                                    disabled={this.state.disabled}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <em className="icon ni ni-step-back"></em>
                                    Back
                                  </a>
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-plus-round"></em>
                                    Save Package
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* NEW DESIGN HTML */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addbundle);
