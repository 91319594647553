import { Link } from "react-router-dom";
export default function PageTitleBreadCrumb(props) {
  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">
            {props.icon !== "" ? (
              <em className={props.icon} style={{ marginRight: "15px" }}></em>
            ) : null}
            {props.name.map((links, index, arr) => (
              <>
                {links.url !== "" ? (
                  <Link to={links.url}>{links.title}</Link>
                ) : (
                  <>{links.title}</>
                )}
                {arr.length - 1 !== index ? (
                  <>
                    {" "}
                    <em
                      class="icon ni ni-forward-ios"
                      style={{ fontSize: "1.25rem" }}
                    ></em>{" "}
                  </>
                ) : null}
              </>
            ))}
          </h3>
        </div>
      </div>
    </div>
  );
}
