import axios from "axios";

import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const leftMenu = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/getLeftMenuLinks",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const validateRights = async (adminAccount, adminToken, link) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/validate_rights",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            link: link,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getResellerSettings = async (
  adminAccount,
  adminToken,
  user_accountno
) => {
  return await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/getSettings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            user_accountno: user_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch: ", error);
    }
  });
};

export const saveResellerSettings = async (
  adminAccount,
  adminToken,
  user_accountno,
  url,
  key,
  auth,
  apiUrl_retail,
  apiKey_retail,
  authKey_retail
) => {
  return await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/saveSettings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            user_accountno: user_accountno,
            url: url,
            key: key,
            auth: auth,
            url_retail: apiUrl_retail,
            key_retail: apiKey_retail,
            auth_retail: authKey_retail,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch: ", error);
    }
  });
};

export const getClients = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/getClients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getReports = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/getReports",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getReportsData = async (
  adminAccount,
  adminToken,
  reseller_accountno,
  link,
  start_date,
  end_date,
  type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/" + link,
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            reseller_accountno: reseller_accountno,
            start_date: start_date,
            end_date: end_date,
            type: type,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getReportsCsv = async (
  adminAccount,
  adminToken,
  reseller_accountno,
  company_id,
  start_date,
  end_date,
  file_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/get_csv_reports/",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            reseller_accountno: reseller_accountno,
            start_date: start_date,
            end_date: end_date,
            company_id: company_id,
            file_type: file_type,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getOriginationSessions = async (
  adminAccount,
  adminToken,
  reseller_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "admin_reports/get_origination_sessions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            reseller_accountno: reseller_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listTerminationSessions = async (
  adminAccount,
  adminToken,
  reseller_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "admin_reports/get_termination_sessions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            reseller_accountno: reseller_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSixMonthGrowth = async (
  adminAccount,
  adminToken,
  reseller_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/get_six_months_growth",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            reseller_accountno: reseller_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const profileUpdate = async (
  adminAccount,
  adminToken,
  full_name,
  password,
  email
) => {
  return await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/profileUpdate",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            full_name: full_name,
            password: password,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch: ", error);
    }
  });
};

export const getServices = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getSingleService = async (
  adminAccount,
  adminToken,
  product_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_single_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            product_id: product_id,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const saveNewService = async (
  adminAccount,
  adminToken,
  product_title,
  service_type,
  mrc,
  nrc,
  rate,
  sms_in,
  sms_out,
  product_desc,
  session_unmetered,
  portout_charges,
  portin_charges,
  cnam_dip_local,
  cnam_dip,
  cnam_mrc
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/save_new_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            product_title: product_title,
            service_type: service_type,
            mrc: mrc,
            nrc: nrc,
            rate: rate,
            sms_in: sms_in,
            sms_out: sms_out,
            product_desc: product_desc,
            session_unmetered: session_unmetered,
            portout_charges: portout_charges,
            portin_charges: portin_charges,
            cnam_dip_local: cnam_dip_local,
            cnam_dip: cnam_dip,
            cnam_mrc: cnam_mrc,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const updateService = async (
  adminAccount,
  adminToken,
  product_title,
  service_type,
  mrc,
  nrc,
  rate,
  sms_in,
  sms_out,
  product_id,
  e_product_desc,
  e_session_unmetered,
  e_portout_charges,
  e_portin_charges,
  e_cnam_dip_local,
  e_cnam_dip,
  e_cnam_mrc
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/update_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            product_title: product_title,
            service_type: service_type,
            mrc: mrc,
            nrc: nrc,
            rate: rate,
            sms_in: sms_in,
            sms_out: sms_out,
            product_id: product_id,
            product_desc: e_product_desc,
            session_unmetered: e_session_unmetered,
            portout_charges: e_portout_charges,
            portin_charges: e_portin_charges,
            cnam_dip_local: e_cnam_dip_local,
            cnam_dip: e_cnam_dip,
            cnam_mrc: e_cnam_mrc,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

/******###### Agreements #####*****/
export const getAgreements = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getSingleAgreement = async (
  adminAccount,
  adminToken,
  agreement_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_single_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const addAgreement = async (
  adminAccount,
  adminToken,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  ispublished,
  agreement_content,
  agreement_type,
  is_default
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/add_new_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            ispublished: ispublished,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            is_default: is_default,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const editAgreement = async (
  adminAccount,
  adminToken,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  ispublished,
  agreement_content,
  agreement_type,
  agreement_id,
  is_default
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/edit_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            ispublished: ispublished,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            agreement_id: agreement_id,
            is_default: is_default,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const deleteAgreement = async (
  adminAccount,
  adminToken,
  agreement_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/delete_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getSubpoena = async (adminAccount, adminToken) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_subpoena",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getSubpoenaDetail = async (
  adminAccount,
  adminToken,
  subpoena_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin/get_subpoena_detail",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            subpoena_id: subpoena_id,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getCSVData = async (adminAccount, adminToken, file_name) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/get_did_csv",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            file_name: file_name,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getDidDetail = async (
  adminAccount,
  adminToken,
  reseller_accountno,
  request_did,
  start_date,
  end_date,
  ref_number
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/get_did_detail",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            request_did: request_did,
            reseller_accountno: reseller_accountno,
            start_date: start_date,
            end_date: end_date,
            ref_number: ref_number,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const createSubpoena = async (
  adminAccount,
  adminToken,
  reseller_accountno,
  request_did,
  start_date,
  end_date,
  ref_number,
  subscriber_settings,
  billing_settings,
  other_did_settings,
  contract_settings,
  client_comm_settings,
  payment_settings,
  notes,
  did_companies
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "admin_reports/create_subpoena",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: adminAccount,
            request_did: request_did,
            reseller_accountno: reseller_accountno,
            start_date: start_date,
            end_date: end_date,
            ref_number: ref_number,
            subscriber_settings: subscriber_settings,
            billing_settings: billing_settings,
            other_did_settings: other_did_settings,
            contract_settings: contract_settings,
            client_comm_settings: client_comm_settings,
            payment_settings: payment_settings,
            notes: notes,
            did_companies: did_companies,
          }),
          {
            headers: {
              Authorization: `bearer ${adminToken}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};
