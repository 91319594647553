import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listCannedEmails,
  listAgentEmails,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class CannedEmails extends Component {
  constructor() {
    super();
    this.state = {
      listEmailsData: [],
      listAgentEmailsData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      title: "",
      name: "",
      logo: "",
      delete_id: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      assigned_services: [],
      agentColumns: [],
      columns: [
        { name: "Title" },
        { name: "Subject" },
        { name: "Date Added" },
        {
          name: "Active",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.emailActive(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/CannedEmails/editemail/" +
                                        tableMeta.rowData[4]
                                    )
                                  }
                                  title="Edit Email"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span> Edit Email </span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    window.open(
                                      "/CannedEmails/previewEmail/" +
                                        tableMeta.rowData[4],
                                      "_blank"
                                    )
                                  }
                                  title="Edit Email"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-eye"></em>
                                  <span> Preview Email </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  async componentDidMount() {
    const listEmailResponce = await listCannedEmails(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("emails Res: ", listEmailResponce.data);
    if (
      listEmailResponce.data.status === 403 ||
      listEmailResponce.data.errors === "authentication missing" ||
      listEmailResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listEmailResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listEmailResponce.data.status === 200 &&
      listEmailResponce.data.message === "success"
    ) {
      this.setState({
        listEmailsData: listEmailResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  emailActive = (value, tableMeta) => {
    let active = tableMeta.rowData[3];
    if (active === "YES") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  agnetEmailActive = (value, tableMeta) => {
    let active = tableMeta.rowData[3];
    if (active === "YES") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  getAgentEmails = async () => {
    this.setState({
      tableLoader: true,
    });
    const listEmailResponce = await listAgentEmails(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("agent emails Res: ", listEmailResponce.data);
    if (
      listEmailResponce.data.status === 403 ||
      listEmailResponce.data.errors === "authentication missing" ||
      listEmailResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listEmailResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listEmailResponce.data.status === 200 &&
      listEmailResponce.data.message === "success"
    ) {
      this.setState({
        listAgentEmailsData: listEmailResponce.data.data,
        tableLoader: false,
        agentColumns: [
          { name: "Title" },
          { name: "Subject" },
          { name: "Date Added" },
          {
            name: "Active",
            options: {
              customBodyRender: (value, tableMeta) => {
                return <div>{this.agnetEmailActive(value, tableMeta)}</div>;
              },
            },
          },
          {
            name: "Actions",
            options: {
              customBodyRender: (value, tableMeta) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              href="#"
                              className="dropdown-toggle btn btn-icon btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/CannedEmails/edit_agent_email/" +
                                          tableMeta.rowData[4]
                                      )
                                    }
                                    title="Edit Email"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span> Edit Email </span>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      window.open(
                                        "/CannedEmails/previewAgentEmail/" +
                                          tableMeta.rowData[4],
                                        "_blank"
                                      )
                                    }
                                    title="Edit Email"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <em class="icon ni ni-eye"></em>
                                    <span> Preview Email </span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
        ],
      });
    } else {
      //window.location.replace("/error");
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Email?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Email?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer", color: "#fff" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer", color: "#fff" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteEmail();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}

          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle name="Canned Emails" icon="icon ni ni-mail" />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  <div className="row g-gs">
                    <div className="col-xxl-12">
                      <div className="nk-block nk-block-lg">
                        <div className="card card-bordered">
                          <div className="card-inner">
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  data-toggle="tab"
                                  href="#adminEmails"
                                >
                                  <em className="icon ni ni-user"></em>
                                  <span>Admin Emails</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#agentEmails"
                                  onClick={() => this.getAgentEmails()}
                                >
                                  <em className="icon ni ni-users"></em>
                                  <span>Agent Emails</span>
                                </a>
                              </li>
                            </ul>
                            <div className="tab-content">
                              <div className="tab-pane active" id="adminEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.columns}
                                    tableData={this.state.listEmailsData}
                                    title="Admin Canned Emails"
                                  />
                                )}
                              </div>
                              <div className="tab-pane" id="agentEmails">
                                {this.state.tableLoader === true ? (
                                  tableLoader()
                                ) : (
                                  <DataTable
                                    columns={this.state.agentColumns}
                                    tableData={this.state.listAgentEmailsData}
                                    title="Agent Canned Emails"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CannedEmails);
