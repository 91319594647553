import { React, createRef } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getAgentSignup,
  regenerateAgentLink,
  cancelAgentLink,
  getSmtpSettings,
  addSmtpSettings,
  verifySmtpSettings,
  wholesaleSetupConfig,
  VerifyWholeSaleConfig,
  SubmitwholesaleConfig,
  getIntegration,
  getQuickBookAuth,
  getCallBackData,
  deleteQuickBookIntegration,
  manageVocieAlertConnection,
  deleteVoiceAlertsIntegration,
  manageMetabaseConnection,
  deleteMetabaseIntegration,
  SubmitMetadataConfig,
  sendRequestIntegration
} from "./../../../config/reseller_api_calls";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import PageTitle from "../ExtraComponents/PageTitle";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";


const auth = new HelperClass();

class Integrations extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      agentId: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      copied2: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      reg_button_login: false,
      cancel_button: false,
      tableLoader: true,
      login_url: "",
      logo: "",
      signature_image: "",
      smtp_host: "",
      smtp_user: "",
      smtp_password: "",
      smtp_method: "tls",
      smtp_port: "",
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
      from_email: "",
      errorFromEmail: "",
      is_working: "",
      integration_type: "",
      wholesale_data: [],
      wholesale_title: "",
      wholesale_url: "",
      wholesale_key: "",
      wholesale_auth: "",
      wholesale_active: 0,
      wholesale_is_verified: 0,
      IntErrorMessage: "",
      IntSuccessMessage: "",
      integration_type_title: "",
      integrations: [],
      dataLoaded: true,
      dataFetched: false,
      getQuickBookAuthData: "",
      getcallbackData: "",
      errorQuickBooks: "",
      successQuickBooks: "",
      integration_id: "",
      name: "",
      email: "",
      comment: "",
      integration_type_list: [],
      searchTerm: "",
      selectedCategory: "all",
      availableCategories: [],
      isDropdownOpen: false,
      super_admin_email: "",
    };
    this.dropdownRef = createRef();
  }

  async componentDidMount() {
    const previousUrl = sessionStorage.getItem("previousUrl");
    if (previousUrl) {
      this.setState({ tableLoader: true, dataLoaded: false, dataFetched: false });

      const urlParams = new URLSearchParams(window.location.search);
      const paramCode = urlParams.get("code");
      const realmId = urlParams.get("realmId");

      if (paramCode) {
        await this.getCallBackData(paramCode, realmId);
      }
      return;
    }
    const servicesResponce = await getAgentSignup(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        login_url: servicesResponce.data.login_url,
        logo: servicesResponce.data.logo,
        signature_image: servicesResponce.data.signature,
        tableLoader: false,
      });

      const urlParams = new URLSearchParams(window.location.search);
      const paramCode = urlParams.get("code");
      const realmId = urlParams.get("realmId");

      if (paramCode) {
        await this.getCallBackData(paramCode, realmId);
      }
    } else {
      // window.location.replace("/error");
    }
    await this.get_integration_data();
    await this.get_smtp_settings();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  getCallBackData = async (code, realmId) => {
    this.setState({
      errorQuickBooks: "",
      successQuickBooks: "",
    });
    const servicesResponce = await getCallBackData(
      auth.getAccount(),
      auth.getToken(),
      code,
      realmId
    );
    console.log("getCallBackData: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 400) {
      this.setState({
        errorQuickBooks:
          " There is some error while getting connecting with quick books.",
        successQuickBooks: "",
        connected: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message ===
      "Authorization successful and tokens logged."
    ) {
      this.setState({
        errorQuickBooks: "",
        successQuickBooks: "Authorization successful.",
      });
      setTimeout(() => {
        this.setState({
          errorQuickBooks: "",
          successQuickBooks: "",
        });
        setTimeout(() => {
          this.setState({
            errorQuickBooks: "",
            successQuickBooks: "",
          });
  
          const previousUrl = sessionStorage.getItem("previousUrl");
          if (previousUrl && previousUrl !== "") {
            window.location.replace(previousUrl);
          } else {
            window.history.replaceState(null, "", window.location.pathname);
            window.location.reload();
          }
        }, 3000);
        //window.history.replaceState(null, "", window.location.pathname);
        //window.location.reload();
      }, 3000);
    } else {
      this.setState({
        errorQuickBooks: "There is some error while getting integration data",
        successQuickBooks: "Success getting integration data.",
      });
    }
  };

  handleQuickBookIntegration = () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    window.$("#modalQuickBookIntegration").modal("show");
  };

  deleteQuickBookIntegration = async () => {
    this.setState({ isProcessing: true });
    const servicesResponse = await deleteQuickBookIntegration(
      auth.getAccount(),
      auth.getToken()
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessage: "Connection successfully revoked.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlert").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    } else {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    }
  };

  get_integration_data = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getIntegration(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_integration_data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting integration data.",
        successMessage: "",
        dataFetched: true,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const integrationTypeList = servicesResponce.data.data.map((integration) => ({
        title: integration.integration_title,
        status: integration.status,
      }));

      const availableCategories = [
        ...new Set(servicesResponce.data.integrationCategory.map((category) => category.title))
      ];

      this.setState({
        integrations: servicesResponce.data.data,
        getcallbackData: servicesResponce.data.quickLogData,
        errorMessage: "",
        successMessage: "Success getting integration data.",
        dataLoaded: true,
        dataFetched: true,
        integration_type_list: integrationTypeList,
        availableCategories: availableCategories,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting integration data.",
        successMessage: "",
        dataFetched: true,
      });
    }
  };

  getQuickBookAuth = async () => {
    const servicesResponce = await getQuickBookAuth(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("getQuickBookAuth: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      // Open the authUrl in a new tab
      window.open(servicesResponce.data.authUrl, "_blank");
    } else {
      // Handle error case or other conditions if needed
      console.error("There is some error while getting integration data.");
    }
  };

  handleMetabaseIntegration = (integration_id) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      integration_id: integration_id,
    });
    window.$("#modalMetabaseIntegration").modal("show");
  };

  manageMetabaseConnection = async (integration_id) => {
    this.setState({
      errorQuickBooks: "",
      successQuickBooks: "",
      loading_button_meta: true,
    });
    const servicesResponce = await manageMetabaseConnection(
      auth.getAccount(),
      auth.getToken(),
      integration_id
    );
    console.log("manageMetabaseConnection: ", servicesResponce.data);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorQuickBooks: "There is some error while building the connection.",
        successQuickBooks: "",
        loading_button_meta: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "Status updated successfully"
    ) {
      this.setState(
        {
          successQuickBooks: "Connection build successfully.",
          errorQuickBooks: "",
          loading_button_meta: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 2000);
        }
      );
    } else {
      this.setState({
        errorQuickBooks: "There is some error while building the connection.",
        successQuickBooks: "",
        loading_button_meta: false,
      });
    }
  };

  deleteMetabaseIntegration = async () => {
    this.setState({ isProcessing: true });
    const servicesResponse = await deleteMetabaseIntegration(
      auth.getAccount(),
      auth.getToken(),
      this.state.integration_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "Status updated successfully"
    ) {
      this.setState(
        {
          successMessage: "Connection successfully revoked.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 2000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    } else {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    }
  };

  modalHideMetaDel = () => {
    window.$("#modalMetabaseIntegration").modal("hide");
  };

  regenerate_link = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await regenerateAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link generated successfully.",
        reg_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while updating the signup link.",
        successMessage: "",
        reg_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  cancel_link = async () => {
    this.setState({
      cancel_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await cancelAgentLink(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("cancel_link: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signup_link: servicesResponce.data.data,
        errorMessage: "",
        successMessage: "Link removed successfully.",
        cancel_button: false,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while removing the signup link.",
        successMessage: "",
        cancel_button: false,
      });
      // window.location.replace("/error");
    }
    setTimeout(() => {
      this.setState({ successMessage: "", errorMessage: "" });
    }, 5000);
  };

  get_smtp_settings = async () => {
    this.setState({
      reg_button: true,
      errorMessage: "",
      successMessage: "",
    });
    const servicesResponce = await getSmtpSettings(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("get_smtp_settings: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
        reg_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        smtp_host: servicesResponce.data.data[0].smtp_host,
        smtp_user: servicesResponce.data.data[0].smtp_user,
        smtp_password: servicesResponce.data.data[0].smtp_password,
        smtp_method: servicesResponce.data.data[0].smtp_method,
        smtp_port: servicesResponce.data.data[0].smtp_port,
        from_email: servicesResponce.data.data[0].from_email,
        is_working: servicesResponce.data.data[0].is_working,
        super_admin_email: servicesResponce.data.data[0].super_admin_email,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "no_record"
    ) {
      this.setState({
        smtp_host: "",
        smtp_user: "",
        smtp_password: "",
        smtp_method: "tls",
        smtp_port: "",
        from_email: "",
        is_working: "no",
      });
    } else {
      this.setState({
        errorMessage: "There is some error while getting SMTP settings.",
        successMessage: "",
        reg_button: false,
      });
    }
    //this.validateEmailAdd("sajid");
  };

  add_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_reg_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_reg_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await addSmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      //console.log("addSmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings added successfully.",
          smtp_reg_button: false,
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while adding SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_reg_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  //Test SMTP Settings

  verify_smtp_settings = async () => {
    let {
      smtp_host,
      smtp_user,
      smtp_password,
      smtp_method,
      smtp_port,
      from_email,
    } = this.state;
    this.setState({
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_test_button: true,
      errorHostName: "",
      errorUser: "",
      errorPassword: "",
    });
    var is_validated = true;

    if (smtp_host === "") {
      this.setState({
        errorHostName: "error",
        SmtpErrorMessage: "SMTP host is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_host !== "") {
      if (this.validateDomain(smtp_host) === false) {
        this.setState({
          errorHostName: "error",
          SmtpErrorMessage: "Invalid domain/host format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }

    if (smtp_user === "") {
      this.setState({
        errorUser: "error",
        SmtpErrorMessage: "Username is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    } else if (smtp_user !== "") {
      if (this.validateEmailAdd(smtp_user) === false) {
        //console.log("smtp_user: ", smtp_user);
        this.setState({
          errorUser: "error",
          SmtpErrorMessage: "Invalid username (email) format.",
          smtp_test_button: false,
        });
        is_validated = false;
      }
    }
    if (smtp_password === "") {
      this.setState({
        errorPassword: "error",
        SmtpErrorMessage: "Password is required.",
        smtp_test_button: false,
      });
      is_validated = false;
    }
    if (is_validated) {
      const servicesResponce = await verifySmtpSettings(
        auth.getAccount(),
        auth.getToken(),
        smtp_host,
        smtp_user,
        smtp_password,
        smtp_method,
        smtp_port,
        from_email
      );
      //console.log("verifySmtpSettings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          SmtpErrorMessage: "",
          SmtpSuccessMessage: "SMTP settings tested successfully.",
          smtp_test_button: false,
          is_working: "yes",
        });
      } else {
        this.setState({
          SmtpErrorMessage: "There is some error while testing SMTP settings.",
          SmtpSuccessMessage: "",
          smtp_test_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({ SmtpErrorMessage: "", SmtpSuccessMessage: "" });
    }, 5000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  integration_settings = async (integration_type) => {
    this.setState({
      wholesale_data: "",
      wholesale_title: "",
      wholesale_url: "",
      wholesale_key: "",
      wholesale_auth: "",
      wholesale_active: "",
      wholesale_is_verified: "",
      integration_type: "",
      integration_type_title: "",
    });
    if (integration_type === "voycetel") {
      this.setState({
        integration_type_title: "Lynk Telecom",
      });
      window.$("#modalIntegrationSettings").modal("show");
      await this.wholesale_setup_config(integration_type);
    }
    if (integration_type === "voyceadmins") {
      this.setState({
        integration_type_title: "Lynk AI",
      });
      window.$("#modalIntegrationSettings").modal("show");
      await this.wholesale_setup_config(integration_type);
    }
    if (integration_type === "metabase") {
      this.setState({
        integration_type_title: "Metabase",
      });
      window.$("#modalIntegrationSettingsMeta").modal("show");
      await this.wholesale_setup_config(integration_type);
    }
  };

  SubmitMetadataConfig = async () => {
    const {
      wholesale_url,
      wholesale_key,
      integration_type,
    } = this.state;
    this.setState({
      loading_button: true,
      IntErrorMessage: "",
    });
    if (wholesale_url === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API URL.",
      });
    } else if (wholesale_key === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API Key.",
      });
    } else {
      const servicesResponce = await SubmitMetadataConfig(
        auth.getAccount(),
        auth.getToken(),
        wholesale_url,
        wholesale_key,
        integration_type
      );

      console.log("wholesale_setup_submit: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while updating the details.",
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          IntSuccessMessage:
            "Integration details added / updated successfully.",
          IntErrorMessage: "",
          loading_button: false,
        });
      } else {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while updating the details.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ IntErrorMessage: "", IntSuccessMessage: "" });
      window.location.reload();
    }, 3000);
  };

  wholesale_setup_config = async (integration_type) => {
    const servicesResponce = await wholesaleSetupConfig(
      auth.getAccount(),
      auth.getToken(),
      integration_type
    );

    console.log("wholesale_setup_config: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        formLoader: false,
        IntErrorMessage: "There is some error while getting the details.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        wholesale_data: servicesResponce.data.data,
        wholesale_title: servicesResponce.data.data[0].integration_title,
        wholesale_url: servicesResponce.data.data[0].api_url,
        wholesale_key: servicesResponce.data.data[0].api_key,
        wholesale_auth: servicesResponce.data.data[0].api_auth,
        wholesale_active: servicesResponce.data.data[0].active,
        wholesale_is_verified: servicesResponce.data.data[0].is_verified,
        integration_type: servicesResponce.data.data[0].integration_type,
        IntErrorMessage: "",
        formLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        wholesale_url: "",
        wholesale_key: "",
        wholesale_auth: "",
        wholesale_is_verified: 0,
        wholesale_active: 0,
        IntErrorMessage: "",
        formLoader: false,
      });
    } else {
      this.setState({
        formLoader: false,
        IntErrorMessage: "There is some error while getting the details.",
      });
    }
  };

  VerifyWholeSaleConfig = async () => {
    const {
      wholesale_url,
      wholesale_key,
      wholesale_auth,
      wholesale_active,
      integration_type,
    } = this.state;
    this.setState({
      loading_button: true,
      IntErrorMessage: "",
    });
    if (wholesale_url === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API URL.",
      });
    } else if (wholesale_key === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API Key.",
      });
    } else if (wholesale_auth === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API Auth.",
      });
    } else {
      const servicesResponce = await VerifyWholeSaleConfig(
        auth.getAccount(),
        auth.getToken(),
        wholesale_url,
        wholesale_key,
        wholesale_auth,
        wholesale_active,
        integration_type
      );

      console.log("wholesale_setup_verify : ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while verifying the details.",
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          IntSuccessMessage: "Integration details verified successfully.",
          IntErrorMessage: "",
          loading_button: false,
          wholesale_is_verified: 1,
        });
      } else {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while verifying the details.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ IntErrorMessage: "", IntSuccessMessage: "" });
      window.location.reload();
    }, 5000);
  };

  submitWholeSaleConfig = async () => {
    const {
      wholesale_url,
      wholesale_key,
      wholesale_auth,
      wholesale_active,
      integration_type,
    } = this.state;
    this.setState({
      loading_button: true,
      IntErrorMessage: "",
    });
    if (wholesale_url === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API URL.",
      });
    } else if (wholesale_key === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API Key.",
      });
    } else if (wholesale_auth === "") {
      this.setState({
        loading_button: false,
        IntErrorMessage: "Please enter the API Auth.",
      });
    } else {
      const servicesResponce = await SubmitwholesaleConfig(
        auth.getAccount(),
        auth.getToken(),
        wholesale_url,
        wholesale_key,
        wholesale_auth,
        wholesale_active,
        integration_type
      );

      console.log("wholesale_setup_submit: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while updating the details.",
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          IntSuccessMessage:
            "Integration details added / updated successfully.",
          IntErrorMessage: "",
          loading_button: false,
        });
      } else {
        this.setState({
          loading_button: false,
          IntErrorMessage: "There is some error while updating the details.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ IntErrorMessage: "", IntSuccessMessage: "" });
      window.location.reload();
    }, 5000);
  };

  modalHideDel = () => {
    window.$("#modalQuickBookIntegration").modal("hide");
  };

  handleVoiceAlertsIntegration = (integration_id) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      integration_id: integration_id,
    });
    window.$("#modalVoiceAlertsIntegration").modal("show");
  };

  manageVocieAlertConnection = async (integration_id) => {
    this.setState({
      errorQuickBooks: "",
      successQuickBooks: "",
      loading_button: true,
    });
    const servicesResponce = await manageVocieAlertConnection(
      auth.getAccount(),
      auth.getToken(),
      integration_id
    );
    console.log("manageVocieAlertConnection: ", servicesResponce.data);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        errorQuickBooks: "There is some error while building the connection.",
        successQuickBooks: "",
        loading_button: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "Status updated successfully"
    ) {
      this.setState(
        {
          successQuickBooks: "Connection build successfully.",
          errorQuickBooks: "",
          loading_button: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 2000);
        }
      );
    } else {
      this.setState({
        errorQuickBooks: "There is some error while building the connection.",
        successQuickBooks: "",
        loading_button: false,
      });
    }
  };

  deleteVoiceAlertsIntegration = async () => {
    this.setState({ isProcessing: true });
    const servicesResponse = await deleteVoiceAlertsIntegration(
      auth.getAccount(),
      auth.getToken(),
      this.state.integration_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "Status updated successfully"
    ) {
      this.setState(
        {
          successMessage: "Connection successfully revoked.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 2000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    } else {
      this.setState({
        errorMessage: "Error while connection revoking.",
      });
    }
  };

  modalHideVoiceDel = () => {
    window.$("#modalVoiceAlertsIntegration").modal("hide");
  };

  setRequestIntegration = async () => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      name: auth.getCompName(),
      email: auth.getEmail(),
      comment: "",
      integration_type: "",
    });
    window.$("#modalRequestIntegration").modal("show");
  };


  saveRequestIntegration = async () => {
    const { name, email, super_admin_email, comment, integration_type } = this.state;
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });
    let super_admin_emails = super_admin_email.trim();
    console.log("super_admin_email: ", super_admin_email);
    if (name === "") {
      this.setState({
        errorMessage: "Please enter the name.",
        successMessage: "",
        disabled: false,
      });
    } else if (email === "") {
      this.setState({
        errorMessage: "Please enter the email address.",
        successMessage: "",
        disabled: false,
      });
    } else if (this.validateEmailAdd(email) === false) {
      this.setState({
        errorMessage: "Please enter the valid email address.",
        successMessage: "",
        disabled: false,
      });
    } else if (super_admin_emails === "") {
      this.setState({
        errorMessage: "Please enter the admin email address.",
        successMessage: "",
        disabled: false,
      });
    } else if (this.validateEmailAdd(super_admin_emails) === false) {
      this.setState({
        errorMessage: "Please enter the valid to email address.",
        successMessage: "",
        disabled: false,
      });
    } else if (integration_type === "") {
      this.setState({
        errorMessage: "Please select the inegration type.",
        successMessage: "",
        disabled: false,
      });
    } else if (comment === "") {
      this.setState({
        errorMessage: "Please enter the comment.",
        successMessage: "",
        disabled: false,
      });
    } else {
      const servicesResponce = await sendRequestIntegration(
        auth.getAccount(),
        auth.getToken(),
        name,
        email,
        super_admin_emails,
        comment,
        integration_type
      );

      console.log("integrationResponce.data.data: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          errorMessage:
            "There is some error while sending the request.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Integration request send successfully.",
          disabled: false,
          name: "",
          email: "",
          super_admin_email: "",
          comment: "",
          integration_type: "",
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          errorMessage:
            "There is some error while sending invite to the client.",
          successMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        errorMessage: "",
        successMessage: "",
      });
    }, 4000);
  };

  handleSearchChange = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({ searchTerm });
  };

  handleFilterChange = (e) => {
    const selectedCategory = e.target.value;
    this.setState({ selectedCategory });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  handleClickOutside = (event) => {
    // If the click happens outside the dropdown, close it
    if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  render() {
    const { integrations, dataLoaded, dataFetched, getcallbackData, searchTerm, selectedCategory } =
      this.state;

    if (!dataLoaded && !dataFetched) {
      return (
        <div className="nk-content ">
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                {this.state.errorQuickBooks !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorQuickBooks}</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                {this.state.successQuickBooks !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successQuickBooks}</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="text-center" style={{ marginTop: "20%" }}>
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const filteredIntegrations = integrations.filter((integration) => {
      const matchesSearchTerm = integration.integration_title.toLowerCase().includes(searchTerm || "");
      const matchesCategory = selectedCategory === "all" || integration.category === selectedCategory;
      return matchesSearchTerm && matchesCategory;
    });

    const availableIntegrations = filteredIntegrations.map((integration) => ({
      key: integration.integration_title,
      imgSrc: `../../assets/images/integrations/${integration.img}`,
      alt: `${integration.integration_title} Image`,
      name: `${integration.integration_title} Integration`,
      settingsKey: integration.integration_type,
      status: integration.status,
      id: integration.id,
      active: integration.active,
      category: integration.category,
    }));

    const activeIntegrations = availableIntegrations.filter(
      (integration) => integration.status === "Yes"
    );

    const showNoIntegrationAlert = activeIntegrations.length === 0;

    const connectionStatus = getcallbackData[0]?.connection;

    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* <div
                className="nk-block-head nk-block-head-sm"
                style={{ padding: "0px" }}
              >
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      {" "}
                      <em className="icon ni ni-exchange"></em> Integrations
                    </h3>
                    <div className="nk-block-des text-soft">
                      <p> &nbsp;</p>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Integrations" icon="icon ni ni-exchange" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => this.setRequestIntegration()}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-exchange"></em>
                              &nbsp;Request For Integration
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md-6">
                  <div className="form-group position-relative">
                    <input
                      type="text"
                      className="form-control with-icon"
                      placeholder="Search Integration Apps"
                      onChange={this.handleSearchChange}
                    />
                    <em className="icon ni ni-search inside-icon"></em>
                  </div>
                </div>

                <div className="col-md-6" style={{ position: 'relative' }} ref={this.dropdownRef}>
                  <div className="form-group position-relative">
                    <select
                      className="form-control with-icon-select select-no-default-arrow"
                      onChange={this.handleFilterChange}
                      onClick={this.toggleDropdown}
                    >
                      <option value="all">All</option>
                      {this.state.availableCategories.map((category, index) => (
                        <option key={index} value={category}>
                          {category}
                        </option>
                      ))}
                    </select>
                    <em className="icon ni ni-filter select-icon"></em>
                    <em className={`icon ni ${this.state.isDropdownOpen ? 'ni-upword-alt-fill' : 'ni-downward-alt-fill'} select-arrow-icon`}></em>
                  </div>
                </div>

              </div>

              {this.state.errorQuickBooks !== "" ? (
                <div className="example-alert mt-2 mb-4">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorQuickBooks}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              {this.state.successQuickBooks !== "" ? (
                <div className="example-alert mt-2 mb-4">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{this.state.successQuickBooks}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row">
                {showNoIntegrationAlert && dataFetched ? (
                  <div className="alert alert-pro alert-warning col-md-12">
                    <div className="alert-text">
                      <h6>No Integration Available</h6>
                      <p>
                        Sorry, there are currently no integrations available.
                      </p>
                    </div>
                  </div>
                ) : (
                  <>
                    {activeIntegrations.map((integration) => (
                      <div className="col-md-3" key={integration.key}>
                        <div
                          className="card card-bordered"
                          style={{ alignItems: "center" }}
                        >
                          <img
                            src={integration.imgSrc}
                            className="card-img-top img_settings"
                            alt={integration.alt}
                          />
                          <div className="card-inner" style={{ width: "100%" }}>
                            <h6>{integration.name}</h6>
                            <p style={{ fontSize: "0.85rem", color: "#6c757d", marginBottom: "10px", }}>
                              {integration.category}
                            </p>
                            {integration.key === "QuickBooks" ? (
                              <div className="d-flex justify-content-between align-items-center">
                                <a
                                  className={`btn ${connectionStatus === "connected"
                                    ? ""
                                    : "btn-primary"
                                    } btn-block`}
                                  onClick={() => {
                                    if (connectionStatus !== "connected") {
                                      this.getQuickBookAuth();
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#ffffff",
                                    backgroundColor:
                                      connectionStatus === "connected"
                                        ? "#2CA01C"
                                        : "",
                                    borderColor:
                                      connectionStatus === "connected"
                                        ? "#2CA01C"
                                        : "",
                                  }}
                                >
                                  <em
                                    className={`icon ni ${connectionStatus === "connected"
                                      ? "ni-check"
                                      : "ni-plus"
                                      }`}
                                  ></em>{" "}
                                  {connectionStatus === "connected"
                                    ? "Connected"
                                    : "Connect"}
                                </a>
                                {connectionStatus === "connected" && (
                                  <Tooltip
                                    title="Revoke Connection"
                                    placement="top"
                                  >
                                    <a
                                      className="btn btn-danger ml-2"
                                      onClick={this.handleQuickBookIntegration}
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                      }}
                                    >
                                      <em className="icon ni ni-cross"></em>
                                    </a>
                                  </Tooltip>
                                )}
                              </div>
                            ) : integration.key === "VoiceAlerts" ? (
                              <div className="d-flex justify-content-between align-items-center">
                                <a
                                  className={`btn ${integration.active === 1
                                    ? ""
                                    : "btn-primary"
                                    } btn-block`}
                                  onClick={() => {
                                    if (integration.active === 0) {
                                      this.manageVocieAlertConnection(
                                        integration.id
                                      );
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#ffffff",
                                    backgroundColor:
                                      integration.active === 1 ? "#2CA01C" : "",
                                    borderColor:
                                      integration.active === 1 ? "#2CA01C" : "",
                                  }}
                                >
                                  <em
                                    className={`icon ni ${this.state.loading_button
                                      ? ""
                                      : integration.active === 1
                                        ? "ni-check"
                                        : "ni-plus"
                                      }`}
                                  ></em>{" "}
                                  {this.state.loading_button
                                    ? "Connecting..."
                                    : integration.active === 1
                                      ? "Connected"
                                      : "Connect"}
                                </a>
                                {integration.active === 1 && (
                                  <Tooltip
                                    title="Revoke Connection"
                                    placement="top"
                                  >
                                    <a
                                      className="btn btn-danger ml-2"
                                      onClick={() =>
                                        this.handleVoiceAlertsIntegration(
                                          integration.id
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        color: "white",
                                      }}
                                    >
                                      <em className="icon ni ni-cross"></em>
                                    </a>
                                  </Tooltip>
                                )}
                              </div>
                            ) : integration.key === "Metabase" ? (
                              <div className="d-flex justify-content-between align-items-center">
                                <a
                                  className={`btn ${integration.active === 1 ? "btn-sm" : "btn-large btn-primary"} btn-block`}
                                  onClick={() => {
                                    if (integration.active === 0) {
                                      this.manageMetabaseConnection(
                                        integration.id
                                      );
                                    }
                                  }}
                                  style={{
                                    cursor: "pointer",
                                    color: "#ffffff",
                                    backgroundColor:
                                      integration.active === 1 ? "#2CA01C" : "",
                                    borderColor:
                                      integration.active === 1 ? "#2CA01C" : "",
                                  }}
                                >
                                  <em
                                    className={`icon ni ${this.state.loading_button_meta
                                      ? ""
                                      : integration.active === 1
                                        ? "ni-check"
                                        : "ni-plus"
                                      }`}
                                  ></em>{" "}
                                  {this.state.loading_button_meta
                                    ? "Connecting..."
                                    : integration.active === 1
                                      ? "Connected"
                                      : "Connect"}
                                </a>
                                {integration.active === 1 && (
                                  <>
                                    <Tooltip
                                      title="Revoke Connection"
                                      placement="top"
                                    >
                                      <a
                                        className="btn btn-sm btn-danger ml-2"
                                        onClick={() =>
                                          this.handleMetabaseIntegration(
                                            integration.id
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                        }}
                                      >
                                        <em className="icon ni ni-cross"></em>
                                      </a>
                                    </Tooltip>
                                    <Tooltip
                                      title="Edit Info"
                                      placement="top"
                                    >
                                      <a
                                        className="btn btn-sm btn-light ml-2"
                                        onClick={() =>
                                          this.integration_settings(
                                            integration.settingsKey
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "white",
                                          color: "#007bff",
                                        }}
                                      >
                                        <em className="icon ni ni-edit"></em>
                                      </a>
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                            ) : (
                              <a
                                className="btn btn-primary btn-block"
                                onClick={() =>
                                  this.integration_settings(
                                    integration.settingsKey
                                  )
                                }
                                style={{ cursor: "pointer", color: "#ffffff" }}
                              >
                                <em className="icon ni ni-plus"></em> Connect
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* <div className="col-md-3">
                  <div
                    className="card card-bordered"
                    style={{ alignItems: "center" }}
                  >
                    <img
                      src="../../assets/images/integrations/quickbooks.png"
                      className="card-img-top img_settings"
                      alt="Static Integration Image"
                    />
                    <div className="card-inner" style={{ width: "100%" }}>
                      <h6>Quick Books</h6>
                      <div className="d-flex justify-content-between align-items-center">
                        <a
                          className={`btn ${
                            connectionStatus === "connected"
                              ? "btn-success"
                              : "btn-primary"
                          } btn-block`}
                          onClick={() => {
                            if (connectionStatus !== "connected") {
                              this.getQuickBookAuth();
                            }
                          }}
                          style={{ cursor: "pointer", color: "#ffffff" }}
                        >
                          <em className="icon ni ni-plus"></em>{" "}
                          {connectionStatus === "connected"
                            ? "Connected"
                            : "Connect"}
                        </a>
                        {connectionStatus === "connected" && (
                          <Tooltip title="Remove Connection" placement="top">
                            <a
                              className="btn btn-danger ml-2"
                              onClick={this.handleQuickBookIntegration}
                              style={{ cursor: "pointer", color: "white" }}
                            >
                              <em className="icon ni ni-trash"></em>
                            </a>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              {/*<div className="row g-gs">
                <div className="col-md-3">
                  <div
                    className="card card-bordered"
                    style={{ alignItems: "center" }}
                  >
                    <img
                      src="../../assets/images/integrations/voice.png"
                      className="card-img-top img_settings"
                      alt=""
                    />
                    <div className="card-inner" style={{ width: "100%" }}>
                      <h6>Lynk Telecom Integration</h6>

                      <a
                        className="btn btn-primary btn-block"
                        onClick={() => this.integration_settings("voycetel")}
                        style={{ cursor: "pointer", color: "#ffffff" }}
                      >
                        <em className="icon ni ni-plus"></em> Connect
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div
                    className="card card-bordered"
                    style={{ alignItems: "center" }}
                  >
                    <img
                      src="../../assets/images/integrations/voice-adm.png"
                      className="card-img-top img_settings"
                      alt=""
                    />
                    <div className="card-inner" style={{ width: "100%" }}>
                      <h6>Lynk AI Integration</h6>

                      <a
                        className="btn btn-primary btn-block"
                        onClick={() => this.integration_settings("voyceadmins")}
                        style={{ cursor: "pointer", color: "#ffffff" }}
                      >
                        <em className="icon ni ni-plus"></em> Connect
                      </a>
                    </div>
                  </div>
                </div>
              </div>*/}
            </div>
          </div>
          <div className="modal fade" tabindex="-1" id="modalDefault">
            <div
              className="modal-dialog modal-dialog-top modal-lg"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em class="icon ni ni-exchange"></em> Exciting Announcement:
                    Integrations Coming Soon!
                  </h5>
                </div>
                <div className="modal-body">
                  <p>
                    We are thrilled to share some exciting news that will
                    elevate your experience with our platform to new heights.
                    Our team has been hard at work behind the scenes, and we're
                    on the verge of introducing a game-changing feature –
                    Integrations!
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/** START INTEGRATION SETTINGS MODAL **/}
          <div
            className="modal fade"
            tabindex="-1"
            id="modalIntegrationSettings"
          >
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em className="icon ni ni-exchange"></em>{" "}
                    {this.state.integration_type_title}
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.IntErrorMessage !== "" ? (
                    <div className="example-alert mt-2 mb-2">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.IntErrorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.IntSuccessMessage !== "" ? (
                    <div className="example-alert mt-2 mb-2">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.IntSuccessMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.formLoader === true ? (
                    FormLoader()
                  ) : (
                    <>
                      <div className="form-group mt-2">
                        <MutextField
                          required
                          id="wholesale_title"
                          name="wholesale_title"
                          type="text"
                          label="API Title"
                          value={this.state.wholesale_title}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-2">
                        <MutextField
                          required
                          id="wholesale_url"
                          name="wholesale_url"
                          type="text"
                          label="API URL"
                          value={this.state.wholesale_url}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-1">
                        <MutextField
                          required
                          id="wholesale_key"
                          name="wholesale_key"
                          type="text"
                          label="API Key"
                          value={this.state.wholesale_key}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-1">
                        <MutextField
                          required
                          id="wholesale_auth"
                          name="wholesale_auth"
                          type="text"
                          label="API AUTH"
                          value={this.state.wholesale_auth}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-1">
                        <MutextField
                          id="wholesale_active"
                          name="wholesale_active"
                          select
                          label="Select Status"
                          value={this.state.wholesale_active}
                          onChange={this.handleChange}
                          SelectProps={{
                            native: true,
                          }}
                          variant="outlined"
                          fullWidth
                        >
                          <option key="1" value="1">
                            Active
                          </option>
                          <option key="0" value="0">
                            Inactive
                          </option>
                        </MutextField>
                      </div>

                      <div className="d-flex justify-content-end">
                        {/* {this.state.loading_button === false ? (
                          <>
                            {this.state.wholesale_is_verified === 0 ? (
                              <>
                                {this.state.wholesale_data &&
                                this.state.wholesale_data.length > 0 ? (
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.VerifyWholeSaleConfig();
                                    }}
                                    disabled={this.state.disabled}
                                    href="#"
                                    className="btn btn-lg btn-primary"
                                  >
                                    <em className="icon ni ni-done"></em> Verify
                                    Details
                                  </a>
                                ) : (
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.submitWholeSaleConfig();
                                    }}
                                    disabled={this.state.disabled}
                                    href="#"
                                    className="btn btn-lg btn-primary"
                                  >
                                    <em className="icon ni ni-file-docs"></em>{" "}
                                    Submit
                                  </a>
                                )}
                              </>
                            ) : this.state.wholesale_is_verified === 1 ? (
                              <button
                                disabled
                                type="button"
                                className="btn btn-lg btn-primary"
                              >
                                <em className="icon ni ni-done"></em> Verified
                              </button>
                            ) : null}
                          </>
                        ) : null} */}
                        {this.state.loading_button === true ? (
                          <button
                            className="btn btn-primary btn-lg"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Loading... </span>
                          </button>
                        ) : (
                          <>
                            {this.state.wholesale_data &&
                              this.state.wholesale_data.length > 0 ? (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.VerifyWholeSaleConfig();
                                }}
                                disabled={this.state.disabled}
                                href="#"
                                className="btn btn-lg btn-primary"
                              >
                                <em className="icon ni ni-done"></em> Verify
                                Details
                              </a>
                            ) : (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  this.submitWholeSaleConfig();
                                }}
                                disabled={this.state.disabled}
                                href="#"
                                className="btn btn-lg btn-primary"
                              >
                                <em className="icon ni ni-file-docs"></em>{" "}
                                Submit
                              </a>
                            )}
                          </>
                        )}
                      </div>

                      {/* <div className="example-alert mt-3">
                        <div className="alert alert-light ">
                          These configuration settings will integrate the
                          Wholsesale portal to the system.
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/** END INTEGRATION SETTINGS MODAL **/}
          {/* START DELETE MODAL  */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="modalQuickBookIntegration"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                    <h4 className="nk-modal-title">
                      Revoke QuickBooks Connection?
                    </h4>

                    <div className="nk-modal-text">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="caption-text">
                        Are you sure you want to revoke the connection?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteQuickBookIntegration();
                        }}
                        disabled={
                          this.state.disabled || this.state.isProcessing
                        } // disable button when processing
                      >
                        {this.state.isProcessing ? (
                          <span>REVOKING...</span>
                        ) : (
                          <span>REVOKE</span>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END DELETE MODAL  */}

          {/* START VOICE DELETE MODAL  */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="modalVoiceAlertsIntegration"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideVoiceDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                    <h4 className="nk-modal-title">
                      Revoke VoiceAlerts Connection?
                    </h4>

                    <div className="nk-modal-text">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="caption-text">
                        Are you sure you want to revoke the connection?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideVoiceDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteVoiceAlertsIntegration();
                        }}
                        disabled={
                          this.state.disabled || this.state.isProcessing
                        } // disable button when processing
                      >
                        {this.state.isProcessing ? (
                          <span>REVOKING...</span>
                        ) : (
                          <span>REVOKE</span>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END VOICE DELETE MODAL  */}
          {/* START Meta DELETE MODAL  */}
          <div
            className="modal fade"
            tabIndex="-1"
            id="modalMetabaseIntegration"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideMetaDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                    <h4 className="nk-modal-title">
                      Revoke Metabase Connection?
                    </h4>

                    <div className="nk-modal-text">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="caption-text">
                        Are you sure you want to revoke the connection?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideMetaDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteMetabaseIntegration();
                        }}
                        disabled={
                          this.state.disabled || this.state.isProcessing
                        } // disable button when processing
                      >
                        {this.state.isProcessing ? (
                          <span>REVOKING...</span>
                        ) : (
                          <span>REVOKE</span>
                        )}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* END Meta DELETE MODAL  */}

          {/** START Meta INTEGRATION SETTINGS MODAL **/}
          <div
            className="modal fade"
            tabindex="-1"
            id="modalIntegrationSettingsMeta"
          >
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em className="icon ni ni-exchange"></em>{" "}
                    {this.state.integration_type_title}
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.IntErrorMessage !== "" ? (
                    <div className="example-alert mt-1 mb-4">
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.IntErrorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.IntSuccessMessage !== "" ? (
                    <div className="example-alert mt-1 mb-4">
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.IntSuccessMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.formLoader === true ? (
                    FormLoader()
                  ) : (
                    <>
                      <div className="form-group mt-2">
                        <MutextField
                          required
                          id="wholesale_url"
                          name="wholesale_url"
                          type="text"
                          label="API URL"
                          value={this.state.wholesale_url}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>
                      <div className="form-group mt-1">
                        <MutextField
                          required
                          id="wholesale_key"
                          name="wholesale_key"
                          type="text"
                          label="API Key"
                          value={this.state.wholesale_key}
                          onChange={this.handleChange}
                          variant="outlined"
                          fullWidth
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        {this.state.loading_button === true ? (
                          <button
                            className="btn btn-primary btn-lg"
                            type="button"
                            disabled
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span> Loading... </span>
                          </button>
                        ) : (
                          <>
                            <a
                              style={{ cursor: "pointer", color: "white" }}
                              onClick={() => {
                                this.SubmitMetadataConfig();
                              }}
                              disabled={this.state.disabled}
                              className="btn btn-lg btn-primary"
                            >
                              <em className="icon ni ni-file-docs"></em>{" "}
                              Submit
                            </a>

                          </>
                        )}
                      </div>

                      {/* <div className="example-alert mt-3">
                        <div className="alert alert-light ">
                          These configuration settings will integrate the
                          Wholsesale portal to the system.
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/** END Meta INTEGRATION SETTINGS MODAL **/}
          {/* New Client Modal Start */}
          <div className="modal fade" tabindex="-1" id="modalRequestIntegration">
            <div
              className="modal-dialog modal-dialog-top modal-xl"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em
                      className="icon ni ni-exchange"
                      style={{ marginRight: "15px" }}
                    ></em>{" "}
                    Request For Integration
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.formLoader === true ? (
                    FormLoader()
                  ) : (
                    <div className="row g-4">
                      {this.state.errorMessage !== "" ? (
                        <div className="col-md-12 mb-3">
                          <div
                            className="example-alert example-alert-revoke"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-danger">
                              <div className="alert-text">
                                <h4>Error</h4>
                                <p>{this.state.errorMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div className="col-md-12 mb-3">
                          <div
                            className="example-alert example-alert-revoke"
                            style={{ marginBottom: "15px" }}
                          >
                            <div className="alert alert-pro alert-success">
                              <div className="alert-text">
                                <h4>Success</h4>
                                <p>{this.state.successMessage}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <MutextField
                              id="name"
                              name="name"
                              type="text"
                              label="Full Name"
                              value={this.state.name}
                              inputProps={{ maxLength: 64 }}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(
                                  /[^a-zA-Z0-9 ]/gi,
                                  ""
                                );
                              }}
                              helperText="Full name of the recipient."
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <MutextField
                              id="email"
                              name="email"
                              type="text"
                              label="Email Address"
                              value={this.state.email}
                              helperText="Email address of the recipient."
                              inputProps={{ maxLength: 100 }}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <MutextField
                              id="super_admin_email"
                              name="super_admin_email"
                              type="text"
                              label="To Email Address"
                              value={this.state.super_admin_email}
                              helperText="This is the email where your email will be sent."
                              inputProps={{ maxLength: 100, readOnly: true }}
                              onChange={this.handleChange}
                              variant="outlined"
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-control-group">
                            <div className="form-control-group">
                              <MutextField
                                required
                                id="integration_type"
                                name="integration_type"
                                select
                                label="Select Integration"
                                value={this.state.integration_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Kindly choose an integration."
                                variant="outlined"
                                fullWidth
                              >
                                <option key="select-none" value=""></option>
                                {(() => {
                                  // List of all possible options
                                  const allOptions = [
                                    "QuickBooks",
                                    "VoiceAlerts",
                                    "Metabase",
                                    "SalesForce",
                                    "Zapier",
                                    "Zendesk",
                                    "Paypal",
                                    "Stripe",
                                  ];

                                  // Filter out integrations where status is 1 or the title is already present in the list
                                  const availableOptions = allOptions.filter(option => {
                                    const isOptionInList = this.state.integration_type_list.some(
                                      integration => integration.title === option && integration.status === "Yes"
                                    );
                                    return !isOptionInList;
                                  });

                                  return availableOptions.map((option, index) => (
                                    <option key={`integration-${index}`} value={option}>
                                      {option}
                                    </option>
                                  ));
                                })()}
                              </MutextField>

                            </div>

                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="form-control-group">
                            <TextareaAutosize
                              maxrows={4}
                              name="comment"
                              id="comment"
                              aria-label=""
                              placeholder="Add Comment *"
                              inputProps={{ maxLength: 500 }}
                              //maxLength={500}
                              value={this.state.comment}
                              helperText="Maximum 500 characters"
                              onChange={this.handleChange}
                              style={{
                                width: "100%",
                                height: "100px",
                                borderColor: "rgba(0, 0, 0, 0.125)",
                                borderRadius: "4px",
                                padding: "5px"
                              }}
                              variant="outlined"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() => this.saveRequestIntegration()}
                          className="btn btn-primary"
                          disabled={this.state.disabled}
                        >
                          <span>Send Request</span>{" "}
                          <em className="icon ni ni-emails"></em>
                        </button>
                      </div>
                      <div className="col-md-12">
                        <div className="example-alert">
                          <div className="alert alert-light">
                            <ul className="list">
                              <li>
                                An email will be dispatched to the provided
                                email address.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* New Client Modal End */}

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Integrations);
