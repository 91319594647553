import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getFormDataEdit,
  saveFormResponse,
} from "./../../../config/forms_api_calls";
import {
  listActiveClients,
  listSingleClient,
} from "./../../../config/reseller_api_calls";

import { listAgents } from "./../../../config/api_calls";
import $ from "jquery";
import {
  AGENT_PORTAL_URL,
  APP_LIVE_URL,
  APP_URL,
} from "../../../config/config";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import FormRenderer from "../../../components/Reseller/formRender";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import MutextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormRendererEdit from "../../../components/Reseller/formRender_response_edit";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class EditFormResponse extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      showModal: false,
      modalContent: null,
      formAccNo: "",
      successMessageSaveResponse: "",
      errorMessageSaveResponse: "",
      form_accountno: "",
      form_token: "",
      formData: "",
      form_title: "",
      form_desc: "",
      responseMessage: "",
      allow_submit: 1,
      link_form: false,
      external_link: "",
      progressStep: 0,
      isSubmitted: 0,
      user_type: ""
    };
  }

  async componentDidMount() {
    let form_accountno = this.props.props.match.params.form_accountno;
    let form_token = this.props.props.match.params.form_token;
    let user_type = this.props.props.match.params.user_type;
    let client_accountno = this.props.props.match.params.client_accountno;
    const servicesResponce = await getFormDataEdit(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      form_token,
      user_type
    );
    console.log("getFormDataEdit: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let from_table = servicesResponce.data.from_table;
      const groupsData = servicesResponce.data.info[0]["groups"];
      const groups = groupsData ? JSON.parse(groupsData) : [];
      const isSubmitted = from_table === "submission" ? servicesResponce.data.data[0]["is_submitted"] : 0;
      const progress = from_table === "submission" ? servicesResponce.data.data[0]["progress"] : null;
      let progressStep = 0;
      if (progress) {
        const [current, total] = progress.split("/").map(Number);
        progressStep = current < total ? current : 0;
      }
      this.setState({
        formData: from_table === "submission" ? servicesResponce.data.data[0]["response"] : servicesResponce.data.info[0]["form_content"],
        form_title: servicesResponce.data.info[0]["form_title"],
        form_desc: servicesResponce.data.info[0]["form_desc"],
        allow_submit: servicesResponce.data.info[0]["allow_submit"],
        link_form: servicesResponce.data.info[0]["link_form"],
        external_link: servicesResponce.data.info[0]["external_link"],
        groups: groups,
        tableLoader: false,
        tableLoaderListing: false,
        progressStep: progressStep,
        totalSteps: groups.length,
        isSubmitted: isSubmitted,
        user_type: user_type
      });
    } else {
      //window.location.replace("/error");
    }
  }

  handleSubmitForm = (formData) => {
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.totalSteps);
  };

  saveResponse = async (response, progress) => {
    let form_accountno = this.props.props.match.params.form_accountno;
    let form_token = this.props.props.match.params.form_token;
    let user_type = this.props.props.match.params.user_type;
    let client_accountno = this.props.props.match.params.client_accountno;
    let isSubmitted = String(this.state.isSubmitted);
    window.scrollTo({ top: 0, behavior: "smooth" });
    const submitFormReponse = await saveFormResponse(
      auth.getAccount(),
      auth.getToken(),
      form_accountno,
      form_token,
      user_type,
      response,
      progress,
      client_accountno,
      isSubmitted
    );
    console.log("saveResponse response: ", submitFormReponse.data);

    if (
      submitFormReponse.data.status === 403 ||
      submitFormReponse.data.errors === "authentication missing" ||
      submitFormReponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (submitFormReponse.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while updating the form.</div>',
      });
    } else if (submitFormReponse.data.status === 200 && submitFormReponse.data.message === "form_draft") {
      this.setState({
        errorMessageSaveResponse: "",
        responseMessage:
          '<div class="alert alert-success alert-icon mb-3"><em class="icon ni ni-check-circle"></em> Response updated succesfully! PDF will be generated upon form completion</div>',
      });
      setTimeout(() => {
        this.setState({
          errorMessageSaveResponse: "",
          responseMessage:
            '',
          successMessageSaveResponse: "",
        });
      }, 3500);
    } else if (submitFormReponse.data.status === 200 && submitFormReponse.data.message === "form_complete") {
      this.setState({
        errorMessageSaveResponse: "",
        responseMessage:
          '<div class="alert alert-success alert-icon mb-3"><em class="icon ni ni-check-circle"></em> Form submission updated successfully!</div>',
      });
      /*setTimeout(() => {
        window.location.replace("/forms/view_form/" + form_accountno);
      }, 2500);*/
    } else{
      setTimeout(() => {
        this.setState({
          responseMessage:
            '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while updating the form.</div>',
          successMessageSaveResponse: "",
        });
      }, 2500);
    }
  };

  handleSaveDraft = (formData, currentStep) => {
    let response = JSON.stringify(formData);
    const draft_result = this.saveResponse(response, currentStep);
    return draft_result;
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory("/")}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() => this.addMenuHistory(`/forms/view_form/${this.props.props.match.params.form_accountno}`)}
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Forms
                      </Link>

                      <Typography color="textPrimary" className="">
                        Submit Form
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  {/*<div class="nk-block-head-content">
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/viewClient/" + this.state.form_accountno
                        )
                      }
                      href="#"
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() =>
                        this.addMenuHistory(
                          "/viewClient/" + this.state.form_accountno
                        )
                      }
                      href="#"
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>*/}
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block form_main_container">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.link_form === 1 ? (
                                <div className="nk-block mt-4">
                                  <div className="nk-block-content nk-error-ld">
                                    <h3 className="nk-error-title text-center">
                                      Link Only
                                    </h3>
                                    <div className="alert alert-light text-dark">
                                      This form contains an external link.{" "}
                                      <a
                                        href={this.state.external_link}
                                        target="_blank"
                                      >
                                        Click Here
                                      </a>{" "}
                                      to be redirected to the specified
                                      destination.
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <>
                                  <FormRendererEdit
                                    formData={JSON.parse(this.state.formData)}
                                    formTitle={this.state.form_title}
                                    formDescription={this.state.form_desc}
                                    onSubmit={this.handleSubmitForm}
                                    groups={this.state.groups}
                                    allow_submit={this.state.allow_submit}
                                    responseMessage={this.state.responseMessage}
                                    draftData={JSON.parse(this.state.formData)}
                                    saveDraft={this.handleSaveDraft}
                                    progressStep={this.state.progressStep}
                                    user_type={this.state.user_type}
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EditFormResponse);
