import React, { Component } from "react";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";
import axios from "axios";
import helpherClass from "../../config/helperClass";
const auth = new helpherClass();

const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};

export class Fileupload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage1: "",
      successMessage1: "",
      dnsList: [],
      file: [],
      fileName: "",
      logo_view: this.props.logo_image,
      signature_view: "",
    };
  }

  saveFile = (e) => {
    this.setState({
      file: e.target.files[0],
      fileName: e.target.files[0].name,
    });
  };

  hideMessage = () => {
    this.setState({
      successMessage1: "",
      errorMessage1: "",
    });
  };

  uploadFile = async (e) => {
    e.preventDefault();
    const { image_type, reseller_accountno } = this.props;
    if (this.state.fileName !== "") {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", this.state.file);
      formData.append("image_type", image_type);
      formData.append("accountno", reseller_accountno);
      formData.append("fileName", this.state.fileName);

      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/upload_image",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES " + image_type + ": ", res);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404) {
            this.setState({
              successMessage1: "",
              errorMessage1: "There is some error while adding the image.",
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              successMessage1: "Image added successfully.",
              errorMessage1: "",
              fileName: "",
              file: "",
              logo_view: res.data.data,
              signature_view: "",
            });
          } else {
            this.setState({
              successMessage1: "",
              errorMessage1: "There is some error while adding the image.",
            });
          }
        });
    } else {
      this.setState({
        successMessage1: "",
        errorMessage1: "Please select an image to upload.",
      });
    }
    setTimeout(() => {
      this.hideMessage();
    }, 5000);
  };

  render() {
    const { image_type, reseller_accountno, logo_image } = this.props;

    console.log(logo_image);
    return (
      <>
        {this.state.errorMessage1 !== "" ? (
          <div className="example-alert" style={{ marginBottom: "15px" }}>
            <div className="alert alert-pro alert-danger">
              <div className="alert-text">
                <h4>Error</h4>
                <p>{this.state.errorMessage1}</p>
              </div>
            </div>
          </div>
        ) : null}
        {this.state.successMessage1 !== "" ? (
          <div className="example-alert" style={{ marginBottom: "15px" }}>
            <div className="alert alert-pro alert-success">
              <div className="alert-text">
                <h4>Success</h4>
                <p>{this.state.successMessage1}</p>
              </div>
            </div>
          </div>
        ) : null}
        <div className="row g-gs">
          <div className="col-xxl-12 col-lg-12 col-md-12">
            <form
              encType="multipart/form-data"
              method="post"
              onSubmit={(e) => this.uploadFile(e)}
            >
              <div className="">
                <div
                  className="upload-btn-wrapper"
                  style={{
                    position: "relative",
                    overflow: "hidden",
                    display: "inline-block",
                  }}
                >
                  <button
                    style={{
                      border: "1px solid gray",
                      cursor: "pointer",
                      color: "#364a63",
                      backgroundColor: "white",
                      padding: "6px 20px",
                      borderRadius: "4px",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <em className="icon ni ni-upload"></em> Choose a file to
                    upload <small>(.jpg or .png only)</small>
                  </button>
                  <input
                    type="file"
                    name="file"
                    accept=".png, .jpg"
                    onChange={(e) => this.saveFile(e)}
                    defaultValue={this.state.file}
                    style={{
                      border: "1px solid gray",
                      position: "absolute",
                      left: "0",
                      top: "0",
                      opacity: "0",
                      fontSize: "100px",
                    }}
                  />
                </div>

                <button
                  className="btn btn-primary"
                  type="submit"
                  style={{ marginLeft: "25px", marginTop: "-34px" }}
                >
                  <em className="icon ni ni-upload"></em> Upload
                </button>
                <div>
                  <span className="badge badge-outline-primary">
                    {this.state.fileName}
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="col-xxl-12  col-lg-3 col-md-12">
            {this.state.logo_view !== "" ? (
              <img style={{ height: "60px" }} src={this.state.logo_view}></img>
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

export default Fileupload;
