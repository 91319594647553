import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { accountVerification } from "../../../config/api_calls";

export default class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    let apiHit = await accountVerification(this.props.match.params.token_code);

    console.log("apiHit.data: ", apiHit.data);
    if (
      apiHit.data.status === 404 ||
      apiHit.data.message === "Invalid Credientials"
    ) {
      window.location.replace("/error");
    } else if (
      apiHit.data.status === 200 &&
      apiHit.data.message === "success"
    ) {
      Cookies.set(
        "tkn__",
        CryptoJS.AES.encrypt(
          apiHit.data.token,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "acn__",
        CryptoJS.AES.encrypt(
          apiHit.data.accountno,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "eml__",
        CryptoJS.AES.encrypt(
          apiHit.data.email,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );
      Cookies.set(
        "comp_nme__",
        CryptoJS.AES.encrypt(
          apiHit.data.name,
          process.env.REACT_APP_SIGNATURE
        ).toString()
      );

      setTimeout(function () {
        window.location.replace("/dashboard");
      }, 2000);
    } else {
      // window.location.replace("/error");
    }
  }

  render() {
    return (
      <div className="text-center" style={{ marginTop: "20%" }}>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
}
