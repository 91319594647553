import React, { Component } from "react";
import { ValidateEmail, normalize, ValidateDomain } from "../../config/utility";
import {
  AppBar,
  Button,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { HTML5_FMT } from "moment";
const styles = {
  hidden: {
    display: "none",
  },
  importLabel: {
    color: "black",
  },
};

export class Domain extends Component {
  continue = () => {
    //e.preventDefault();
    this.props.nextStep();
  };

  constructor() {
    super();
    this.state = {
      errorMessage: "",
      dnsList: [],
    };
  }

  SubmitFirst = () => {
    const { values } = this.props;
    console.log("this.state.domain_name: ", values.name + " " + values.name);

    let is_validated = true;
    if (values.name === "") {
      this.setState({
        errorCompName: "error",
        errorMessage: "Agent Name is required.",
      });
      is_validated = false;
    } else if (values.title === "") {
      this.setState({
        errorCompName: "",
        errorName: "error",
        errorMessage: "Title is required.",
      });
      is_validated = false;
    } else if (values.domain !== "") {
      if (ValidateDomain(values.domain) === false) {
        this.setState({
          errorEmail: "error",
          errorName: "",
          errorMessage: "Invalid domain format.",
        });
        is_validated = false;
      }
    } else if (values.agent_phone === "") {
      this.setState({
        errorPhone: "error",
        errorMessage: "Phone is required.",
      });
      is_validated = false;
    } else if (normalize(values.agent_phone) === null) {
      this.setState({
        errorPhone: "error",
        errorMessage: "Invalid phone format.",
      });
      is_validated = false;
    } else if (ValidateEmail(values.agent_email) === false) {
      this.setState({
        errorEmail: "error",
        errorName: "",
        errorMessage: "Invalid email format.",
      });
      is_validated = false;
    } else if (values.agent_password !== "") {
      if (values.agent_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",

          errorPhone: "",
          errorMessage: "Use 8 characters or more for your password",
        });
        is_validated = false;
      } else if (values.c_password === "") {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Confirm password is required.",
        });
        is_validated = false;
      } else if (values.c_password.length < 8) {
        this.setState({
          errorPass: "error",
          errorEmail: "",
          errorPhone: "",
          errorMessage: "Use 8 characters or more for your confirm password",
        });
        is_validated = false;
      } else if (values.agent_password.trim() != values.c_password.trim()) {
        this.setState({
          errorPass: "error",

          errorEmail: "",

          errorPhone: "",
          errorMessage: "Password and confirm password does not match.",
        });
        is_validated = false;
      }
    }

    if (is_validated) {
      this.continue();
    } else {
    }

    /*if (
      values.title === "" &&
      values.name === "" &&
      values.domain === "" &&
      values.agent_email === "" &&
      values.agent_password === "" &&
      values.c_password === ""
    ) {
      this.setState({
        errorMessage: "Please enter the required values.",
      });
    } else {
      this.continue();
    }*/
  };

  render() {
    const { values, handleChange, VerifyConnection, onFileChange } = this.props;

    return (
      <>
        <div className="col-lg-12">
          <div className="nk-block">
            <div className="card card-stretch">
              <div className="card-inner-group ">
                {this.state.errorMessage !== "" ? (
                  <div
                    className="example-alert"
                    style={{ marginBottom: "15px" }}
                  >
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}

                <h6>&nbsp;</h6>

                <form method="post" encType="multipart/form-data">
                  <div className="row g-4">
                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="name"
                        name="name"
                        type="text"
                        label="Agent Name"
                        inputProps={{ maxLength: 100 }}
                        defaultValue={values.name}
                        onChange={handleChange("name")}
                        helperText="Enter the agent name."
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="title"
                        name="title"
                        type="text"
                        inputProps={{ maxLength: 30 }}
                        label="Agent Title"
                        defaultValue={values.title}
                        onChange={handleChange("title")}
                        helperText="Enter the title here."
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <TextField
                        id="domain"
                        name="domain"
                        type="text"
                        label="Domain"
                        defaultValue={values.domain}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleChange("domain")}
                        helperText="Enter the domain here. (example.com)"
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="agent_email"
                        name="agent_email"
                        type="email"
                        label="Email Address"
                        defaultValue={values.agent_email}
                        onChange={handleChange("agent_email")}
                        helperText="Enter your email address here."
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="agent_phone"
                        name="agent_phone"
                        type="number"
                        label="Phone Number"
                        inputProps={{ maxLength: 11 }}
                        defaultValue={values.agent_phone}
                        onChange={handleChange("agent_phone")}
                        helperText="Enter the phone number here."
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    {/*<div className="form-group col-md-6">
                      <TextField
                        required
                        id="logo"
                        name="logo"
                        type="file"
                        defaultValue={values.logo}
                        onChange={handleChange("logo")}
                        helperText="Enter the logo here."
                        variant="outlined"
                        fullWidth
                      />
                </div>*/}

                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="agent_password"
                        name="agent_password"
                        type="password"
                        label="Paasword"
                        defaultValue={values.agent_password}
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange("agent_password")}
                        helperText="Enter your password here."
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <TextField
                        required
                        id="c_password"
                        name="c_password"
                        type="password"
                        label="Confirm Password"
                        defaultValue={values.c_password}
                        inputProps={{ maxLength: 30 }}
                        onChange={handleChange("c_password")}
                        helperText="Enter the confirm password here."
                        variant="outlined"
                        fullWidth
                      />
                    </div>

                    <div style={{ clear: "both" }}></div>
                    <hr />
                    <div className="form-group text-right col-md-12">
                      <button
                        className="btn btn-lg btn-primary"
                        type="button"
                        disabled={this.state.disabled}
                        onClick={this.SubmitFirst}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Domain;
