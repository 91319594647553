import { Link } from "react-router-dom";
export default function PageTitle(props) {
  return (
    <div className="nk-block-head nk-block-head-sm">
      <div className="nk-block-between">
        <div className="nk-block-head-content">
          <h3 className="nk-block-title page-title">{props.name}</h3>
        </div>
      </div>
    </div>
  );
}
