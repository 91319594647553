import React, { useState, useEffect, useRef } from "react";
import { APP_LIVE_URL } from "../../config/config";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
const loadFonts = () => {
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Amita&family=Bad+Script&family=Damion&family=Dancing+Script&family=Felipa&family=Julee&family=Kaushan+Script&family=Lugrasimo&family=Pacifico&family=Sofia&family=Yellowtail&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);
};
const cursiveFonts = [
  { name: "Your Signature", value: "'Amita', cursive" },
  { name: "Your Signature", value: "'Bad Script', cursive" },
  { name: "Your Signature", value: "'Damion', cursive" },
  { name: "Your Signature", value: "'Dancing Script', cursive" },
  { name: "Your Signature", value: "'Felipa', cursive" },
  { name: "Your Signature", value: "'Julee', cursive" },
  { name: "Your Signature", value: "'Kaushan Script', cursive" },
  { name: "Your Signature", value: "'Lugrasimo', cursive" },
  { name: "Your Signature", value: "'Pacifico', cursive" },
  { name: "Your Signature", value: "'Sofia', cursive" },
  { name: "Your Signature", value: "'Yellowtail', cursive" },
  // Add more cursive fonts here
];
const FormRenderer = ({ formData, formTitle, formDesc, formGroups }) => {
  console.log("formGroups: ", formGroups);
  const [formState, setformData] = useState({});
  const [signatureType, setSignatureType] = useState("draw");
  const [signatureText, setSignatureText] = useState("");
  const [signatureClass, setSignatureClass] = useState("'Amita', cursive");
  useEffect(() => {
    loadFonts();
  }, []);

  const downloadPdf = async (d_f_name) => {
    //var url = d_f_name;
    if (d_f_name && d_f_name !== "") {
      let file_name = d_f_name;
      var url = APP_LIVE_URL + "files_data/form_uploads/" + d_f_name;
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "File not found.",
      });
      /*setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);*/
    }
  };
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <div className="form-renderer-container">
      <div className="form-section">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="nk-kycfm">
              <div className="nk-kycfm-head" style={{ background: "#f5f6fa" }}>
                <div className="nk-kycfm-title">
                  <h5 className="title">{formTitle || "Untitled Form"}</h5>
                  <p className="sub-title">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: formDesc,
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className="nk-kycfm-content">
                <div className="row g-gs">
                  {formGroups.map((groups, idx) => (
                    <div key={`grp_${idx}`} className="col-md-12">
                      <div className="mb-3">
                        <h5 className="title">{idx+1}. {groups.group_title}</h5>

                        <p
                          className="sub-title"
                          dangerouslySetInnerHTML={{
                            __html: groups.group_desc
                              ? groups.group_desc.replace(/\n/g, "<br />")
                              : "",
                          }}
                        ></p>
                      </div>
                      <div className="row g-gs">
                        {groups.questions
                          .map((questionId) => {
                            // Find the corresponding question from formData
                            const question = formData[0].questions.find(
                              (q) => q.id === questionId
                            );
                            return question;
                          })
                          .filter(Boolean) // Remove any undefined values if a question is not found
                          .sort((a, b) => a.sequenceNumber - b.sequenceNumber) // Sort by sequence number
                          .map((question) => {
                            return (
                              <div
                                key={question.id}
                                className={
                                  question.answerType === "label"
                                    ? "question-container col-sm-12"
                                    : "question-container col-sm-6"
                                }
                              >
                                <div className="form-group question-text mb-0">
                                  {question.answerType === "label" ? (
                                    <label
                                      className="form-label"
                                      style={{ fontSize: "1.275rem" }}
                                    >
                                      {question.questionText}{" "}
                                      {question.required && (
                                        <sup className="text-danger">*</sup>
                                      )}
                                    </label>
                                  ) : (
                                    <label className="form-label">
                                      {question.questionText}{" "}
                                      {question.required && (
                                        <sup className="text-danger">*</sup>
                                      )}
                                    </label>
                                  )}
                                  {question.imageUrl && (
                                    <>
                                      <a
                                        onClick={() =>
                                          downloadPdf(question.imageUrl)
                                        }
                                        style={{
                                          float: "right",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <span class="badge badge-outline-primary">
                                          <em class="icon ni ni-download"></em>{" "}
                                          Download File
                                        </span>
                                      </a>
                                    </>
                                  )}
                                </div>
                                {question.answerType === "input" && (
                                  <div className="form-group mb-0">
                                    <div className="form-control-wrap">
                                      <input
                                        className="form-control form-control-lg"
                                        value=""
                                        type="text"
                                        maxLength={question.maxLength || 100}
                                      />
                                    </div>
                                    <div
                                      className="helper-text"
                                      style={{ marginTop: "6px" }}
                                    >
                                      {question.maxLength && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Maximum length is {question.maxLength}{" "}
                                          characters.
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "number_only" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only numbers are allowed (e.g.
                                          123456).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "alphabet_only" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only alphabets are allowed (e.g.
                                          JohnDoe).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "alphanumeric" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only alphanumeric characters are
                                          allowed (e.g. John123).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat === "open" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          You can enter any characters.
                                          <br />
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                )}
                                {question.answerType === "fixed" && (
                                  <>
                                    <div className="form-group mb-0">
                                      <div className="form-control-wrap">
                                        <input
                                          className={`form-control form-control-lg`}
                                          
                                          value={question.answer}
                                          type="text"
                                          maxLength={255}
                                        />
                                      </div>
                                      
                                    </div>
                                    
                                  </>
                                )}
                                {question.answerType === "label" && (
                                  <div className="form-group mb-0">
                                    <h6></h6>
                                  </div>
                                )}
                                {question.answerType === "textarea" && (
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <textarea
                                        className={`form-control form-control-lg`}
                                        
                                        placeholder=""
                                        value=""
                                        maxLength={question.maxLength || 500}
                                      ></textarea>
                                    </div>
                                    <div
                                      className="helper-text"
                                      style={{ marginTop: "6px" }}
                                    >
                                      {question.maxLength && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Maximum length is {question.maxLength}{" "}
                                          characters.
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "number_only" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only numbers are allowed (e.g.
                                          123456).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "alphabet_only" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only alphabets are allowed (e.g.
                                          JohnDoe).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat ===
                                        "alphanumeric" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          Only alphanumeric characters are
                                          allowed (e.g. John123).
                                          <br />
                                        </small>
                                      )}
                                      {question.inputFormat === "open" && (
                                        <small
                                          className="text-muted"
                                          style={{ display: "block" }}
                                        >
                                          <span
                                            style={{
                                              marginRight: "5px",
                                            }}
                                          >
                                            •
                                          </span>{" "}
                                          You can enter any characters.
                                          <br />
                                        </small>
                                      )}
                                    </div>
                                    
                                  </div>
                                )}
                                {question.answerType === "datetime" && (
                                  <div className="form-group">
                                    <DateTimePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={null}
                                      
                                    />
                                    
                                  </div>
                                )}
                                {question.answerType === "date" && (
                                  <div className="form-group">
                                    <DatePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={null}
                                      
                                    />
                                    
                                  </div>
                                )}
                                {question.answerType === "time" && (
                                  <div className="form-group">
                                    <TimePicker
                                      variant="inline"
                                      inputVariant="outlined"
                                      fullWidth
                                      value={null}
                                      
                                    />
                                    
                                  </div>
                                )}
                                {question.answerType === "dropdown" && (
                                  <div className="form-group">
                                    <div className="form-control-wrap">
                                      <div className="form-control-select">
                                        <select
                                          className={`form-control form-control-lg`}
                                          
                                          value=""
                                        >
                                          <option value="">
                                            Select an option
                                          </option>
                                          {question.options.map(
                                            (option, index) => (
                                              <option
                                                key={index}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                    
                                  </div>
                                )}
                                {question.answerType === "checkbox" && (
                                  <>
                                    <div className="mt-2 prettyprint">
                                      <div className="row">
                                        {question.options.map(
                                          (option, index) => (
                                            <div
                                              className="col-md-12 mb-1"
                                              key={`options_${index}`}
                                            >
                                              <div className="custom-control custom-control-md custom-checkbox custom-control-pro">
                                                <input
                                                  type="checkbox"
                                                  className="custom-control-input vdrSelected"
                                                  id={`question_checkbox_${question.id}_${index}`}
                                                  checked={
                                                    true
                                                  }
                                                  
                                                />
                                                <label
                                                  className="custom-control-label"
                                                  htmlFor={`question_checkbox_${question.id}_${index}`}
                                                >
                                                  {option}
                                                </label>
                                              </div>
                                              
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}

                                {question.answerType === "radio" && (
                                  <div className="form-pm-group">
                                    <ul className="buysell-pm-list">
                                      {question.options.map((option, index) => (
                                        <li
                                          className="buysell-pm-item"
                                          key={index}
                                        >
                                          <input
                                            className="buysell-pm-control"
                                            type="radio"
                                            name={`radio_${question.id}`}
                                            id={`radio_${question.id}_${index}`}
                                            
                                            value={option}
                                          />
                                          <label
                                            className="buysell-pm-label"
                                            htmlFor={`radio_${question.id}_${index}`}
                                          >
                                            <span className="pm-name">
                                              {option}
                                            </span>
                                            <span className="pm-icon">
                                              {/*<em className="icon ni ni-question"></em>*/}
                                            </span>
                                          </label>
                                        </li>
                                      ))}
                                    </ul>
                                    
                                  </div>
                                )}
                                {question.answerType === "image_input" && (
                                  <div className="form-group">
                                    <div
                                      className="form-control-group"
                                      id="fileContainer"
                                    >
                                      <input
                                        type="file"
                                        id={`add_picture_${question.id}`}
                                        className="file-block"
                                        name="add_picture"
                                        accept=".png, .jpg, .jpeg, .pdf, .doc, .docx, .xlx, .xlxs, .csv"
                                        
                                      />
                                      {/* Display the uploaded file name or image preview if the URL exists in the state */}
                                      {formState[question.id] && (
                                        <div className="uploaded-file-preview">
                                          {formState[question.id].match(
                                            /\.(jpeg|jpg|png)$/i
                                          ) ? (
                                            <img
                                              src={`${APP_LIVE_URL}files_data/form_uploads/${
                                                formState[question.id]
                                              }`}
                                              alt="Uploaded preview"
                                              style={{
                                                maxWidth: "100px",
                                              }}
                                            />
                                          ) : (
                                            <p>
                                              {formState[question.id]
                                                .split("/")
                                                .pop()}
                                            </p> // Display file name for non-images
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ))}
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </MuiPickersUtilsProvider>
  );
};

export default FormRenderer;
