import { React } from "react";
import { Component } from "react";
import { Prompt } from "react-router-dom";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listDefaultServices,
  addNewService,
  addCsvFile,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import Radio from "@material-ui/core/Radio";
import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

class Addratedeck extends Component {
  constructor() {
    super();
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    this.state = {
      serviceData: [],
      select_service: "",
      product_desc: "",
      errorMessage: "",
      start_date: datestring_today,
      serviceId: "",
      title: "",
      service_title: "",
      service_desc: "",
      service_type: "tfn",
      successMessage: "",
      tableLoader: true,
      radio_buttons: false,
      radio_type: "b",
      nrc_rate_type: "flat",
      min_rate_type: "flat",
      defaultNrc: "",
      defaultMrc: "",
      new_nrc: 0.0,
      new_mrc: 0.0,
      agent_mrc_comission: 5,
      agent_nrc_comission: 0.0,
      agent_rate_comission: 5,
      sms_in_comission: 5,
      sms_out_comission: 5,
      picture: "",
      pictureName: "",
      rate_type: "",
      rate: 0.0,
      defaultRate: "",
      product_id: "",
      send_notification: false,
      sms_in: 0.0,
      sms_out: 0.0,
      mrc_rate_type: "flat",
      select_rate_type: "flat_rate",
      mrc_helper: "This value should be greater than MRC Buy rate.",
      mrc_error: false,
      mrc_error_text: "This value should be greater than MRC buy rate.",
      nrc_error: false,
      nrc_error_text: "This value should be greater than NRC buy rate.",
      rate_error: false,
      rate_error_text: "This value should be greater than per minute buy rate.",
      sms_in_error: false,
      sms_in_error_text:
        "This value should be greater than SMS outbound buy rate.",
      sms_out_error: false,
      sms_out_error_text:
        "This value should be greater than SMS outbound buy rate.",
      mrc_comission_error: false,
      mrc_comission_error_text:
        "Agent Commission in percentage (Applied on Gross Revenue)",
      nrc_comission_error: false,
      rate_comission_error: false,
      rate_comission_error_text:
        "Agent Commission in percentage (Applied on Gross Revenue)",
      ratedeck_error: false,
      ratedeck_error_text: "",
      rate_deck_file: [],
      rate_settings_tab: false,
      misc_settings_tab: false,
      porting_settings_tab: false,
      desc_settings_tab: false,
      radio_type: "b",
      misc_rows: [{}],
      isBlocking: true,
      kyc_required: false,
      capture_projection: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listDefaultServices(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Service Data: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        serviceData: servicesResponce.data.data,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  setMarkUp = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    console.log("setMarkup: ", name + " " + value);
    let service_data = this.state.serviceData;
    console.log("serviceData: ", service_data);
    for (let i = 0; i < service_data.length; i++) {
      if (service_data[i][0] == value) {
        this.setState({
          product_id: service_data[i][0],
          defaultNrc: Number.parseFloat(service_data[i][3]).toFixed(4),
          defaultMrc: Number.parseFloat(service_data[i][4]).toFixed(4),
          rate_type: service_data[i][5],
          defaultRate: Number.parseFloat(service_data[i][5]).toFixed(4),
          service_title: service_data[i][1],
          service_desc: service_data[i][2],
          product_desc: service_data[i][2],
          defaultSmsIn: service_data[i][7],
          defaultSmsOut: service_data[i][8],
          service_type: service_data[i][9],
          rate_settings_tab: true,
          isBlocking: false,
        });
      }
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  open_current_tab = (link) => {
    this.setState({
      [link]: true,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
    //console.log("handleChange:", name + " " + value);
    this.handlePercentage(name, value);
  };

  handleChangeRadio = async (event) => {
    const { target } = event;
    const value = target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });
    //console.log("handleChange:", name + " " + value);
  };

  handleChangeMrc = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });
    this.handlePercentage(name, value);
    //console.log("handleChange:", name + " " + value);
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };
  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
      isBlocking: false,
    });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
      isBlocking: false,
    });
    //await this.sendFileData(event.target.files[0]);
  };

  handlePercentage = async (name, value) => {
    //Allowed Percentages for new_mrc, agent_mrc_comission, rate, agent_rate_comission, sms_in, sms_out, sms_in_commission, sms_out_commission
    if (name === "new_mrc") {
      if (value > this.state.defaultMrc) {
        let mrc_diff = value - this.state.defaultMrc;
        let mrc_percentage = (mrc_diff / 100) * this.state.agent_mrc_comission;

        this.setState({
          mrc_comission_error_text:
            "Agent Commission in percentage (Applied on Gross Revenue). (Estimated: $" +
            mrc_percentage.toFixed(2) +
            ")",
        });
      }
    }

    if (name === "agent_mrc_comission") {
      if (this.state.new_mrc > this.state.defaultMrc) {
        let mrc_diff = this.state.new_mrc - this.state.defaultMrc;
        let mrc_percentage = (mrc_diff / 100) * value;

        this.setState({
          mrc_comission_error_text:
            "Agent Commission in percentage (Applied on Gross Revenue). (Estimated: $" +
            mrc_percentage.toFixed(2) +
            ")",
        });
      }
    }
  };

  handleChangeMisc = (idx) => (e) => {
    const { name, value } = e.target;
    const misc_rows = [...this.state.misc_rows];
    misc_rows[idx][name] = value;

    this.setState({
      misc_rows,
      isBlocking: false,
    });
    console.log("handleChangeMisc: ", misc_rows);
    console.log("handleChangeMisc idx: ", idx);
  };
  handleAddRow = () => {
    const item = {
      misc_title: "",
      misc_type: "",
      misc_rate: "",
    };
    this.setState({
      misc_rows: [...this.state.misc_rows, item],
    });
  };
  handleRemoveRow = () => {
    this.setState({
      misc_rows: this.state.misc_rows.slice(0, -1),
    });
  };
  handleRemoveSpecificRow = (idx) => () => {
    const misc_rows = [...this.state.misc_rows];
    misc_rows.splice(idx, 1);
    this.setState({ misc_rows });
  };

  handleChangeCheckboxKyc = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
      isBlocking: false,
    });

    //console.log("handleChangeCheckbox:", name + " " + value);
  };

  sendFileData = async (filedata) => {
    const saveResponces = await addCsvFile(auth.getAccount(), auth.getToken());
    console.log("rate_deck_file: ", saveResponces);
  };

  formSave = async (e) => {
    e.preventDefault();
    let {
      select_service,
      service_type,
      new_nrc,
      new_mrc,
      nrc_rate_type,
      mrc_rate_type,
      min_rate_type,
      defaultNrc,
      defaultMrc,
      rate,
      defaultRate,
      defaultSmsIn,
      defaultSmsOut,
      min_sms_in_type,
      min_sms_out_type,
      sms_in,
      sms_out,
      rate_type,
      title,
      agent_mrc_comission,
      agent_nrc_comission,
      agent_rate_comission,
      sms_in_comission,
      sms_out_comission,
      product_desc,
      start_date,
      misc_rows,
      kyc_required,
      capture_projection,
    } = this.state;

    var is_validated = true;
    this.setState({
      nrc_error: false,
      nrc_error_text: "",
      mrc_error: false,
      mrc_error_text: "",
      rate_error: false,
      rate_error_text: "",
      sms_out_error: false,
      sms_out_error_text: "",
      sms_in_error_text: "",
    });
    if (select_service === "") {
      is_validated = false;
    } else if (title === "") {
      is_validated = false;
    } else if (start_date === "") {
      is_validated = false;
    } else if (
      this.state.service_type === "did" ||
      this.state.service_type === "tfn"
    ) {
      if (new_nrc === "") {
        is_validated = false;
      } else {
        if (new_nrc < this.state.defaultNrc) {
          is_validated = false;
          this.setState({ nrc_error: true });
        }
        if (new_nrc > this.state.defaultNrc) {
          let nrc_diff = new_nrc - this.state.defaultNrc;
          let nrc_percentage = agent_nrc_comission;
          if (nrc_percentage > nrc_diff) {
            is_validated = false;
            this.setState({
              nrc_error: true,
              nrc_error_text:
                "Agent Spiff should be less than reseller margin.",
            });
          }
        }
      }
      if (agent_nrc_comission === "" || agent_nrc_comission === 0) {
        is_validated = false;
      } else {
        let nrc_diff = new_nrc - this.state.defaultNrc;
        if (agent_nrc_comission > nrc_diff) {
          is_validated = false;
          this.setState({ nrc_comission_error: true });
        }
      }
      if (new_mrc === "") {
        is_validated = false;
      } else {
        if (new_mrc <= this.state.defaultMrc) {
          is_validated = false;
          this.setState({ mrc_error: true });
        }
        if (new_mrc > this.state.defaultMrc) {
          let mrc_diff = new_mrc - this.state.defaultMrc;
          let mrc_percentage = (mrc_diff / 100) * agent_mrc_comission;
          if (mrc_percentage > mrc_diff) {
            is_validated = false;
            this.setState({
              mrc_error: true,
              mrc_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }
      /*if (rate === "") {
        is_validated = false;
      } else {
        if (rate <= this.state.defaultRate) {
          is_validated = false;
          this.setState({
            rate_error: true,
          });
        }
        if (rate > this.state.defaultRate) {
          let rate_diff = (rate - this.state.defaultRate).toFixed(3);
          let rate_percentage = ((rate / 100) * agent_rate_comission).toFixed(
            3
          );

          if (rate_percentage > rate_diff) {
            is_validated = false;
            this.setState({
              rate_error: true,
              rate_error_text:
                "Agent commission should be less than reseller margin.",
            });
          }
        }
      }*/
      if (this.state.fileName && this.state.fileName === "") {
        is_validated = false;
        this.setState({
          ratedeck_error: true,
          ratedeck_error_text: "Please upload a rate deck CSV file.",
        });
      }
    } else if (this.state.service_type === "term") {
      if (this.state.fileName && this.state.fileName === "") {
        is_validated = false;
        this.setState({
          ratedeck_error: true,
          ratedeck_error_text: "Please upload a rate deck CSV file.",
        });
      }
    }

    console.log("select_service: ", select_service);
    console.log("service_type: ", this.state.service_type);

    console.log("title: ", title);
    console.log("defaultNrc: ", defaultNrc);
    console.log("defaultMrc: ", defaultMrc);
    console.log("new_nrc: ", new_nrc);
    console.log("new_mrc: ", new_mrc);
    console.log("start_date: ", start_date);
    console.log("mrc_rate_type: ", mrc_rate_type);
    console.log("Service ID: ", this.state.serviceId);
    console.log("rate: ", rate);
    console.log("defaultRate: ", defaultRate);
    console.log("rate_type: ", rate_type);
    console.log("product_id: ", this.state.product_id);
    console.log("sms_in: ", sms_in);
    console.log("sms_out: ", sms_out);

    console.log("agent_mrc_comission: ", this.state.agent_mrc_comission);
    console.log("agent_nrc_comission: ", this.state.agent_nrc_comission);
    console.log("agent_rate_comission: ", this.state.agent_rate_comission);
    console.log("pictureName: ", this.state.pictureName);

    console.log("is_validated: ", is_validated);
    if (is_validated === false) {
      document
        .getElementById("Services_Block_Ratedeck")
        ?.scrollIntoView({ top: 0, behavior: "smooth" });
      return this.setState({
        errorMessage:
          "Please enter the required fields or check the highlighted text fields. ",
      });
    } else {
      this.setState({ errorMessage: "" });

      if (this.state.service_type == "term") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (this.state.service_type == "ivr") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (this.state.service_type == "live_agent") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (this.state.service_type == "did") {
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (this.state.service_type == "tfn") {
        sms_in = "";
        sms_out = "";
        defaultSmsIn = "";
        defaultSmsOut = "";
      } else if (this.state.service_type == "sms") {
        new_nrc = "";
        new_mrc = "";
        defaultNrc = "";
        defaultMrc = "";
        defaultRate = "";
        rate = "";
      }
      console.log("select_service: ", select_service);
      console.log("service_type: ", this.state.service_type);
      console.log("service_title: ", this.state.service_title);
      console.log("title: ", title);
      console.log("defaultNrc: ", defaultNrc);
      console.log("defaultMrc: ", defaultMrc);
      console.log("new_nrc: ", new_nrc);
      console.log("new_mrc: ", new_mrc);
      console.log("nrc_rate_type: ", nrc_rate_type);
      console.log("mrc_rate_type: ", mrc_rate_type);
      console.log("Service ID: ", this.state.serviceId);
      console.log("rate: ", rate);
      console.log("defaultRate: ", defaultRate);
      console.log("rate_type: ", rate_type);
      console.log("product_id: ", this.state.product_id);
      console.log("sms_in: ", sms_in);
      console.log("sms_out: ", sms_out);
      console.log("defaultSmsIn: ", defaultSmsIn);
      console.log("defaultSmsOut: ", defaultSmsOut);
      console.log("min_sms_in_type: ", this.state.min_sms_in_type);
      console.log("min_sms_out_type: ", this.state.min_sms_out_type);
      console.log("agent_mrc_comission: ", this.state.agent_mrc_comission);
      console.log("agent_nrc_comission: ", this.state.agent_nrc_comission);
      console.log("agent_rate_comission: ", this.state.agent_rate_comission);
      console.log("pictureName: ", this.state.pictureName);

      this.setState({
        errorMessage: "",
        disabled: true,
      });

      let send_notification = "0";
      if (this.state.send_notification === true) {
        send_notification = "1";
      }
      console.log(
        "this.state.misc_rows: ",
        JSON.stringify(this.state.misc_rows)
      );
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("accountno", auth.getAccount());
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", this.state.picture);
      formData.append("fileName", this.state.pictureName);
      formData.append("new_nrc", this.state.new_nrc);
      formData.append("new_mrc", this.state.new_mrc);
      //formData.append("rate", rate);
      formData.append("serviceId", this.state.product_id);
      formData.append("select_service", this.state.select_service);
      formData.append("service_type", this.state.service_type);
      formData.append("title", this.state.title);
      formData.append("service_title", this.state.service_title);
      formData.append("product_desc", this.state.product_desc);
      formData.append("nrc_rate_type", nrc_rate_type);
      formData.append("mrc_rate_type", mrc_rate_type);
      formData.append("min_sms_in_type", this.state.min_sms_in_type);
      formData.append("min_sms_out_type", this.state.min_sms_out_type);
      formData.append("sms_in", this.state.sms_in);
      formData.append("sms_out", this.state.sms_out);
      formData.append("agent_mrc_comission", this.state.agent_mrc_comission);
      formData.append("agent_nrc_comission", this.state.agent_nrc_comission);
      formData.append("agent_rate_comission", this.state.agent_rate_comission);
      formData.append("defaultMrc", this.state.defaultMrc);
      formData.append("defaultRate", this.state.defaultRate);
      formData.append("defaultNrc", this.state.defaultNrc);
      formData.append("sms_in_comission", this.state.sms_in_comission);
      formData.append("sms_out_comission", this.state.sms_out_comission);
      formData.append("send_notification", this.state.send_notification);
      formData.append("start_date", this.state.start_date);
      formData.append("kyc_required", this.state.kyc_required);
      formData.append("capture_projection", this.state.capture_projection);
      formData.append("misc_rows", JSON.stringify(this.state.misc_rows));

      axios
        .post(
          process.env.REACT_APP_API_URL + "ratedeck/add_rate_deck",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("RateDeck RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404) {
            this.setState({
              successMessage: "",
              disabled: false,
              errorMessage: "There is some error while adding the service.",
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              successMessage: "Service added successfully.",
              errorMessage: "",
              pictureName: "",
              picture: "",
              disabled: false,
              isBlocking: true,
            });
          } else {
            this.setState({
              successMessage: "",
              disabled: false,
              errorMessage: "There is some error while adding the image.",
            });
          }
          document
            .getElementById("Services_Block_Ratedeck")
            ?.scrollIntoView({ top: 0, behavior: "smooth" });
        });
    }
  };

  calculatePercent = (percent, num) => {
    return (percent * (num / 100)).toFixed(4);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleFile = async (event) => {
    this.setState({
      picture: event.target.files[0],
      pictureName: event.target.files[0].name,
    });
  };

  render() {
    return (
      <div className="nk-content " id="Services_Block_Ratedeck">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          <Prompt
            when={this.state.isBlocking === false}
            message={() =>
              `Are you sure you want to leave this page without saving the changes.`
            }
          />
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/services")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Services
                  </Link>
                  <Typography color="textPrimary" className="">
                    Add New Rate Deck
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="row g-4">
            <div className="col-md-12">
              <div className="card card-bordered">
                <div className="card-inner">
                  {this.state.errorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.errorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.successMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.successMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="modal fade" tabIndex="-1" id="modalAlert">
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHide();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                            <h4 className="nk-modal-title">Add New Service</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text">
                                {this.state.successMessage}
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHide();
                                }}
                              >
                                OK
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <form
                    method="post"
                    className="serviceForm"
                    encType="multipart/form-data"
                    onSubmit={this.formSave}
                    id="serviceForm"
                  >
                    {/* NEW DESIGN HTML */}
                    <div className="nk-block-head nk-block-head-lg ">
                      <div className="nk-block-head-content">
                        <h2 className="nk-block-title fw-normal">
                          Add New Rate Deck
                        </h2>
                        <div className="nk-block-des">
                          <p>
                            Please add all the fields carefully while adding the
                            new rate deck.{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="nk-block">
                      <div className="card card-bordered">
                        <div className="nk-kycfm">
                          <div
                            className="nk-kycfm-head"
                            style={{ background: "#f5f6fa" }}
                          >
                            <div className="nk-kycfm-count">01</div>
                            <div className="nk-kycfm-title">
                              <h5 className="title">Service Details</h5>
                              <p className="sub-title">
                                Select service type and add your service title
                                here
                              </p>
                            </div>
                          </div>

                          {/* .nk-kycfm-head */}
                          <div className="nk-kycfm-content">
                            <div className="row g-4">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="These are the default services with default buy rates and settings."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Select Service{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <MutextField
                                      id="select_service"
                                      name="select_service"
                                      select
                                      label="Select Service"
                                      value={this.state.select_service}
                                      onChange={this.setMarkUp}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText="Please select the service from default services."
                                      variant="outlined"
                                      fullWidth
                                    >
                                      <option key="ser1212" value=""></option>
                                      {this.state.serviceData.map(
                                        (services) => (
                                          <>
                                            {services[9] === "term" ||
                                            services[9] === "tfn" ||
                                            services[9] === "tfn_term" ||
                                            services[9] === "az_term" ? (
                                              <option
                                                value={services[0]}
                                                key={`ser${services[0]}`}
                                              >
                                                {services[1]}
                                              </option>
                                            ) : null}
                                          </>
                                        )
                                      )}
                                    </MutextField>
                                  </div>
                                </div>
                              </div>
                              {/* .col */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="Title of your service."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Service Title{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                  </div>
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="title"
                                      name="title"
                                      type="text"
                                      label="Service Title"
                                      defaultValue={this.state.title}
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>

                              {/* .col */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <ul className="nk-kycfm-control-list g-3">
                                    <li className="nk-kycfm-control-item">
                                      <input
                                        checked={this.state.kyc_required}
                                        onChange={this.handleChangeCheckboxKyc}
                                        className="nk-kycfm-control"
                                        type="checkbox"
                                        name="kyc_required"
                                        id="kyc_required"
                                        data-title="Kyc Required"
                                        value="Yes"
                                      />
                                      <label
                                        className="nk-kycfm-label"
                                        htmlFor="kyc_required"
                                      >
                                        <span className="nk-kycfm-label-icon">
                                          <div className="label-icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 90 90"
                                            >
                                              <rect
                                                x="5"
                                                y="7"
                                                width="60"
                                                height="56"
                                                rx="7"
                                                ry="7"
                                                fill="#e3e7fe"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <rect
                                                x="25"
                                                y="27"
                                                width="60"
                                                height="56"
                                                rx="7"
                                                ry="7"
                                                fill="#e3e7fe"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <rect
                                                x="15"
                                                y="17"
                                                width="60"
                                                height="56"
                                                rx="7"
                                                ry="7"
                                                fill="#fff"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="15"
                                                y1="29"
                                                x2="75"
                                                y2="29"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-miterlimit="10"
                                                stroke-width="2"
                                              />
                                              <circle
                                                cx="53"
                                                cy="23"
                                                r="2"
                                                fill="#c4cefe"
                                              />
                                              <circle
                                                cx="60"
                                                cy="23"
                                                r="2"
                                                fill="#c4cefe"
                                              />
                                              <circle
                                                cx="67"
                                                cy="23"
                                                r="2"
                                                fill="#c4cefe"
                                              />
                                              <rect
                                                x="22"
                                                y="39"
                                                width="20"
                                                height="20"
                                                rx="2"
                                                ry="2"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <circle
                                                cx="32"
                                                cy="45.81"
                                                r="2"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <path
                                                d="M29,54.31a3,3,0,0,1,6,0"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="49"
                                                y1="40"
                                                x2="69"
                                                y2="40"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="49"
                                                y1="51"
                                                x2="69"
                                                y2="51"
                                                fill="none"
                                                stroke="#c4cefe"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="49"
                                                y1="57"
                                                x2="59"
                                                y2="57"
                                                fill="none"
                                                stroke="#c4cefe"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="64"
                                                y1="57"
                                                x2="66"
                                                y2="57"
                                                fill="none"
                                                stroke="#c4cefe"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="49"
                                                y1="46"
                                                x2="59"
                                                y2="46"
                                                fill="none"
                                                stroke="#c4cefe"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="64"
                                                y1="46"
                                                x2="66"
                                                y2="46"
                                                fill="none"
                                                stroke="#c4cefe"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                            </svg>
                                          </div>
                                        </span>
                                        <span className="nk-kycfm-label-text">
                                          Kyc Required
                                        </span>
                                      </label>
                                    </li>
                                    <li className="nk-kycfm-control-item">
                                      <input
                                        checked={this.state.capture_projection}
                                        onChange={this.handleChangeCheckboxKyc}
                                        className="nk-kycfm-control"
                                        type="checkbox"
                                        name="capture_projection"
                                        id="capture_projection"
                                        data-title="Capture Projection"
                                        value="Yes"
                                      />
                                      <label
                                        className="nk-kycfm-label"
                                        htmlFor="capture_projection"
                                      >
                                        <span className="nk-kycfm-label-icon">
                                          <div className="label-icon">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 90 90"
                                            >
                                              <rect
                                                x="3"
                                                y="10"
                                                width="70"
                                                height="50"
                                                rx="7"
                                                ry="7"
                                                fill="#e3e7fe"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <rect
                                                x="13"
                                                y="24"
                                                width="70"
                                                height="50"
                                                rx="7"
                                                ry="7"
                                                fill="#fff"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="20"
                                                y1="33"
                                                x2="31"
                                                y2="33"
                                                fill="none"
                                                stroke="#9cabff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="75"
                                                y1="33"
                                                x2="77"
                                                y2="33"
                                                fill="none"
                                                stroke="#9cabff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="66"
                                                y1="33"
                                                x2="67"
                                                y2="33"
                                                fill="none"
                                                stroke="#9cabff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <line
                                                x1="70"
                                                y1="33"
                                                x2="72"
                                                y2="33"
                                                fill="none"
                                                stroke="#9cabff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <rect
                                                x="19"
                                                y="40"
                                                width="56"
                                                height="7"
                                                rx="2"
                                                ry="2"
                                                fill="#eff1ff"
                                              />
                                              <rect
                                                x="20"
                                                y="51"
                                                width="24"
                                                height="8"
                                                rx="2"
                                                ry="2"
                                                fill="#eff1ff"
                                              />
                                              <rect
                                                x="48"
                                                y="51"
                                                width="7"
                                                height="8"
                                                rx="2"
                                                ry="2"
                                                fill="#eff1ff"
                                              />
                                              <ellipse
                                                cx="69"
                                                cy="61.98"
                                                rx="18"
                                                ry="18.02"
                                                fill="#fff"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <circle
                                                cx="69"
                                                cy="62"
                                                r="7"
                                                fill="#e3e7fe"
                                              />
                                              <polyline
                                                points="77 56 77 60 73 60"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <path
                                                d="M62.26,59.17a6.81,6.81,0,0,1,11.25-2.55L77,59.92"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <polyline
                                                points="61 67 61 63 65 63"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                              <path
                                                d="M61.43,64l3.51,3.31A6.83,6.83,0,0,0,76.2,64.79"
                                                fill="none"
                                                stroke="#6576ff"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                                stroke-width="2"
                                              />
                                            </svg>
                                          </div>
                                        </span>
                                        <span className="nk-kycfm-label-text">
                                          Capture Projection
                                        </span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>

                              {/* .col */}
                            </div>
                            {/* .row */}
                          </div>
                          {/* .nk-kycfm-content */}
                          {this.state.select_service !== "" ? (
                            <>
                              <div
                                className="nk-kycfm-head"
                                style={{
                                  background: "#f5f6fa",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.open_current_tab("desc_settings_tab");
                                }}
                                title="Click to open the Rate Settings"
                              >
                                <div className="nk-kycfm-count">02</div>
                                <div className="nk-kycfm-title">
                                  <h5 className="title">Rates Setting</h5>
                                  <p className="sub-title">
                                    NRC, MRC, Per minute and rate deck will be
                                    added in this step.
                                  </p>
                                </div>
                              </div>
                              {this.state.rate_settings_tab === true ? (
                                <div className="nk-kycfm-content">
                                  <div className="row g-4">
                                    {this.state.service_type !== "term" &&
                                    this.state.service_type !== "sms" &&
                                    this.state.service_type !== "ivr" &&
                                    this.state.service_type !== "tfn_term" &&
                                    this.state.service_type !== "az_term" &&
                                    this.state.service_type !== "live_agent" ? (
                                      <>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Cost for recurring monthly charges associated with phone number subscriptions."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                MRC Buy Rate{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="defaultMrc"
                                                name="defaultMrc"
                                                label="MRC Buy Rate"
                                                value={this.state.defaultMrc}
                                                variant="outlined"
                                                disabled
                                                fullWidth
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Rate for recurring monthly charges associated with phone number subscriptions."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                MRC Sell Rate{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="new_mrc"
                                                name="new_mrc"
                                                type="text"
                                                label="MRC Sell Rate"
                                                defaultValue={
                                                  this.state.new_mrc
                                                }
                                                onChange={this.handleChangeMrc}
                                                variant="outlined"
                                                helperText={
                                                  this.state.mrc_error_text
                                                }
                                                onKeyPress={() => {
                                                  this.checkNumber();
                                                }}
                                                fullWidth
                                                error={this.state.mrc_error}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Commission amount for agent, taken out of profit."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                Agent Commission{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="agent_mrc_comission"
                                                name="agent_mrc_comission"
                                                select
                                                label="Agent Commission"
                                                value={
                                                  this.state.agent_mrc_comission
                                                }
                                                onChange={this.handleChange}
                                                SelectProps={{
                                                  native: true,
                                                }}
                                                helperText={
                                                  this.state
                                                    .mrc_comission_error_text
                                                }
                                                variant="outlined"
                                                fullWidth
                                              >
                                                <option key="5" value="5">
                                                  5%
                                                </option>
                                                <option key="10" value="10">
                                                  10%
                                                </option>
                                                <option key="15" value="15">
                                                  15%
                                                </option>
                                                <option key="20" value="20">
                                                  20%
                                                </option>
                                                <option key="25" value="25">
                                                  25%
                                                </option>
                                                <option key="30" value="30">
                                                  30%
                                                </option>
                                                <option key="35" value="35">
                                                  35%
                                                </option>
                                                <option key="40" value="40">
                                                  40%
                                                </option>
                                                <option key="45" value="45">
                                                  45%
                                                </option>
                                                <option key="50" value="50">
                                                  50%
                                                </option>
                                              </MutextField>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Cost for fixed phone number charges include the non-recurring setup charges."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                NRC Buy Rate{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="defaultNrc"
                                                label="NRC Buy Rate"
                                                value={this.state.defaultNrc}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Rate for fixed phone number charges include the non-recurring setup charges."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                NRC Sell Rate{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="new_nrc"
                                                name="new_nrc"
                                                type="text"
                                                label="NRC Sell Rate"
                                                defaultValue={
                                                  this.state.new_nrc
                                                }
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                helperText={
                                                  this.state.nrc_error_text
                                                }
                                                onKeyPress={() => {
                                                  this.checkNumber();
                                                }}
                                                fullWidth
                                                error={this.state.nrc_error}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="form-group">
                                            <div className="form-label-group">
                                              <label className="form-label">
                                                <Tooltip
                                                  title="Sales Performance Incentive Fund is an incentive amount earned against a sale / subscription."
                                                  placement="right"
                                                >
                                                  <em
                                                    className="icon ni ni-info"
                                                    style={{
                                                      color: "#6576ff",
                                                    }}
                                                  ></em>
                                                </Tooltip>{" "}
                                                Agent Spiff{" "}
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </label>
                                            </div>
                                            <div className="form-control-group">
                                              <MutextField
                                                id="agent_nrc_comission"
                                                name="agent_nrc_comission"
                                                type="text"
                                                label="Agent Spiff "
                                                defaultValue={
                                                  this.state.agent_nrc_comission
                                                }
                                                onChange={this.handleChange}
                                                variant="outlined"
                                                helperText="Agent Spiff will be less than the difference between buy and sell NRC rate."
                                                onKeyPress={() => {
                                                  this.checkNumber();
                                                }}
                                                fullWidth
                                                error={
                                                  this.state.nrc_comission_error
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                    {this.state.service_type !== "sms" ? (
                                      <>
                                        {this.state.service_type == "tfn" ||
                                        this.state.service_type == "term" ||
                                        this.state.service_type == "tfn_term" ||
                                        this.state.service_type == "az_term" ? (
                                          <>
                                            <div className="col-md-12">
                                              <ul
                                                className="nk-kycfm-control-list g-3"
                                                style={{ width: "33%" }}
                                              >
                                                <li className="nk-kycfm-control-item">
                                                  <input
                                                    checked={
                                                      this.state.radio_type ===
                                                      "b"
                                                    }
                                                    onChange={
                                                      this.handleChangeRadio
                                                    }
                                                    className="nk-kycfm-control"
                                                    type="radio"
                                                    name="radio_type"
                                                    id="metered"
                                                    data-title="Rate Deck"
                                                    value="b"
                                                  />
                                                  <label
                                                    className="nk-kycfm-label"
                                                    htmlFor="metered"
                                                  >
                                                    <span className="nk-kycfm-label-icon">
                                                      <div className="label-icon">
                                                        <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          viewBox="0 0 71.9904 75.9285"
                                                        >
                                                          <path
                                                            d="M27.14,23.73A15.55,15.55,0,1,0,42.57,39.4v-.12A15.5,15.5,0,0,0,27.14,23.73Zm11.42,9.72H33a25.55,25.55,0,0,0-2.21-6.53A12.89,12.89,0,0,1,38.56,33.45ZM31,39.28a26.9929,26.9929,0,0,1-.2,3.24H23.49a26.0021,26.0021,0,0,1,0-6.48H30.8A29.3354,29.3354,0,0,1,31,39.28ZM26.77,26.36h.75a21.7394,21.7394,0,0,1,2.85,7.09H23.91A21.7583,21.7583,0,0,1,26.77,26.36Zm-3.28.56a25.1381,25.1381,0,0,0-2.2,6.53H15.72a12.88,12.88,0,0,1,7.78-6.53ZM14.28,39.28A13.2013,13.2013,0,0,1,14.74,36H20.9a29.25,29.25,0,0,0,0,6.48H14.74A13.1271,13.1271,0,0,1,14.28,39.28Zm1.44,5.83h5.57a25.9082,25.9082,0,0,0,2.2,6.53A12.89,12.89,0,0,1,15.72,45.11ZM27.51,52.2h-.74a21.7372,21.7372,0,0,1-2.85-7.09h6.44A21.52,21.52,0,0,1,27.51,52.2Zm3.28-.56A25.1413,25.1413,0,0,0,33,45.11h5.57a12.84,12.84,0,0,1-7.77,6.53Zm2.59-9.12a28.4606,28.4606,0,0,0,0-6.48h6.15a11.7,11.7,0,0,1,0,6.48ZM14.29,62.6H40v2.6H14.28V62.61ZM56.57,49l1.33-5,2.48.67-1.33,5Zm-6,22.52L55.24,54l2.48.67L53.06,72.14Zm21.6-61.24-29.8-8a5.13,5.13,0,0,0-6.29,3.61h0L33.39,16H6.57A2.58,2.58,0,0,0,4,18.55V70.38A2.57,2.57,0,0,0,6.52,73L6.57,73h29.7l17.95,4.85a5.12,5.12,0,0,0,6.28-3.6v-.06L75.8,16.61a5.18,5.18,0,0,0-3.6066-6.3763L72.18,10.23ZM6.57,70.38V18.55H45.14a2.57,2.57,0,0,1,2.57,2.57V67.79a2.57,2.57,0,0,1-2.55,2.59H6.57ZM73.34,15.91,58,73.48a2.59,2.59,0,0,1-2.48,1.93,2.5192,2.5192,0,0,1-.67-.09l-9-2.42a5.15,5.15,0,0,0,4.37-5.11V47.24l1.32.36,1.33-5-2.49-.67-.16.62V21.94l2.62.71,3.05,10,2.13.57L57.88,24l3.76,1,1.65,3,1.42.39-.25-4.09,2.24-3.42-1.41-.39L62.4,22.15l-3.76-1,4.76-7.92-2.13-.57-7.6,7.14-4-1.08A5.1,5.1,0,0,0,45.14,16H36.05l2.51-9.45a2.57,2.57,0,0,1,3.12-1.84h0l29.81,8.05a2.56,2.56,0,0,1,1.56,1.21A2.65,2.65,0,0,1,73.34,15.91ZM56.57,39.59l.66-2.5,2.44.65L59,40.24Zm4.88,1.31.66-2.51,2.44.66-.65,2.5Zm-9.76-2.61.66-2.51,2.45.66-.66,2.5Z"
                                                            transform="translate(-3.9995 -2.101)"
                                                            fill="#6476ff"
                                                          />
                                                        </svg>
                                                      </div>
                                                    </span>
                                                    <span className="nk-kycfm-label-text">
                                                      Rate Deck
                                                    </span>
                                                  </label>
                                                </li>
                                              </ul>
                                            </div>

                                            {this.state.radio_type === "b" ? (
                                              <>
                                                <div className="col-lg-4">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="A rate deck is a simple table that shows a call destination and cost to complete. Commonly, the rate deck is done at the 6 digit and 7 digit level of the destination phone number (NPA-NXX or NPA-NXX-X)"
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Rate Deck CSV File{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div className="form-label-group">
                                                      <div
                                                        className="upload-btn-wrapper"
                                                        style={{
                                                          position: "relative",
                                                          overflow: "hidden",
                                                          display:
                                                            "inline-block",
                                                          width: "100%",
                                                        }}
                                                      >
                                                        <button
                                                          style={{
                                                            width: "100%",
                                                            border: "1px solid",
                                                            borderColor:
                                                              "#aef4e1",
                                                            cursor: "pointer",
                                                            color: "#19bc90",
                                                            backgroundColor:
                                                              "#e4fbf5",
                                                            padding:
                                                              "15px 20px",
                                                            borderRadius: "4px",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          <em className="icon ni ni-upload"></em>{" "}
                                                          Choose a file to
                                                          upload{" "}
                                                          <small>
                                                            (.csv only)
                                                          </small>
                                                        </button>
                                                        <input
                                                          type="file"
                                                          name="picture"
                                                          accept=".csv"
                                                          onChange={
                                                            this.handleFile
                                                          }
                                                          defaultValue={
                                                            this.state.picture
                                                          }
                                                          style={{
                                                            border: "1px solid",
                                                            borderColor:
                                                              "rgba(0, 0, 0, 0.125)",
                                                            position:
                                                              "absolute",
                                                            left: "0",
                                                            cursor: "pointer",
                                                            top: "0",
                                                            opacity: "0",
                                                            fontSize: "100px",
                                                          }}
                                                          required
                                                        />
                                                      </div>
                                                    </div>
                                                    {this.state
                                                      .ratedeck_error !==
                                                    true ? (
                                                      <div
                                                        style={{
                                                          clear: "both",
                                                          marginBottom: "15px",
                                                        }}
                                                      >
                                                        <span className="badge badge-outline-danger">
                                                          {
                                                            this.state
                                                              .ratedeck_error_text
                                                          }
                                                        </span>
                                                      </div>
                                                    ) : null}
                                                    {this.state.pictureName !==
                                                    "" ? (
                                                      <div
                                                        style={{
                                                          clear: "both",
                                                          marginBottom: "15px",
                                                        }}
                                                      >
                                                        <span className="badge badge-outline-primary">
                                                          {
                                                            this.state
                                                              .pictureName
                                                          }
                                                        </span>
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="Effective Date for the rate deck means the date from which these rates will be effective."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Select Effective Date{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div className="form-control-group">
                                                      <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                      >
                                                        <DatePicker
                                                          id="start_date"
                                                          name="start_date"
                                                          format="yyyy-MM-dd"
                                                          margin="normal"
                                                          label="Select Effective Date"
                                                          value={
                                                            this.state
                                                              .start_date
                                                          }
                                                          onChange={
                                                            this
                                                              .handleDateChange
                                                          }
                                                          keyboardbuttonprops={{
                                                            "aria-label":
                                                              "change date",
                                                            variant: "outlined",
                                                          }}
                                                          fullWidth
                                                        />
                                                      </MuiPickersUtilsProvider>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="Agent Commission in percentage (Applied on Gross Revenue)"
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Agent Commission{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div className="form-control-group">
                                                      <MutextField
                                                        id="agent_rate_comission"
                                                        name="agent_rate_comission"
                                                        select
                                                        label="Agent Commission"
                                                        value={
                                                          this.state
                                                            .agent_rate_comission
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        SelectProps={{
                                                          native: true,
                                                        }}
                                                        helperText="Agent Commission in percentage (Applied on Gross Revenue)"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={
                                                          this.state
                                                            .rate_comission_error
                                                        }
                                                      >
                                                        <option
                                                          key="5"
                                                          value="5"
                                                        >
                                                          5%
                                                        </option>
                                                        <option
                                                          key="10"
                                                          value="10"
                                                        >
                                                          10%
                                                        </option>
                                                        <option
                                                          key="15"
                                                          value="15"
                                                        >
                                                          15%
                                                        </option>
                                                        <option
                                                          key="20"
                                                          value="20"
                                                        >
                                                          20%
                                                        </option>
                                                        <option
                                                          key="25"
                                                          value="25"
                                                        >
                                                          25%
                                                        </option>
                                                        <option
                                                          key="30"
                                                          value="30"
                                                        >
                                                          30%
                                                        </option>
                                                        <option
                                                          key="35"
                                                          value="35"
                                                        >
                                                          35%
                                                        </option>
                                                        <option
                                                          key="40"
                                                          value="40"
                                                        >
                                                          40%
                                                        </option>
                                                        <option
                                                          key="45"
                                                          value="45"
                                                        >
                                                          45%
                                                        </option>
                                                        <option
                                                          key="50"
                                                          value="50"
                                                        >
                                                          50%
                                                        </option>
                                                      </MutextField>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-4">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="Send Rate Deck Notification."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Send Rate Deck
                                                        Notification{" "}
                                                        <span className="text-danger">
                                                          *
                                                        </span>
                                                      </label>
                                                    </div>
                                                    <div className="form-control-group">
                                                      <FormControlLabel
                                                        control={
                                                          <Checkbox
                                                            checked={
                                                              this.state
                                                                .send_notification
                                                            }
                                                            onChange={
                                                              this.handleChange
                                                            }
                                                            name="send_notification"
                                                            id="send_notification"
                                                            color="primary"
                                                          />
                                                        }
                                                        style={{
                                                          marginBottom: "0px",
                                                        }}
                                                        label="Send Rate Deck Notification"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </div>
                                </div>
                              ) : null}
                              {/* .nk-kycfm-content */}
                              <div
                                className="nk-kycfm-head"
                                style={{
                                  background: "#f5f6fa",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.open_current_tab("misc_settings_tab");
                                }}
                                title="Click to open the Miscellaneous Rates"
                              >
                                <div className="nk-kycfm-count">03</div>
                                <div className="nk-kycfm-title">
                                  <h5 className="title">Miscellaneous Rates</h5>
                                  <p className="sub-title">
                                    Miscellaneous rates, fees and charges may
                                    apply and are in addition to the applicable
                                    Service rates and charges. You can add up to
                                    5 miscellaneous rates here.
                                  </p>
                                </div>
                              </div>
                              {/* .nk-kycfm-head */}
                              {this.state.misc_settings_tab === true ? (
                                <div className="nk-kycfm-content">
                                  <div className="row g-4">
                                    <div className="col-lg-12">
                                      <div className="form-group">
                                        <table
                                          className="table table-bordered"
                                          id="tab_logic"
                                        >
                                          <thead className="thead-light">
                                            <tr>
                                              <th
                                                className="text-center"
                                                style={{ lineHeight: "28px" }}
                                              >
                                                {" "}
                                                Misc. Rate Title{" "}
                                              </th>
                                              <th
                                                className="text-center"
                                                style={{ lineHeight: "28px" }}
                                              >
                                                {" "}
                                                Misc. Rate Type{" "}
                                              </th>
                                              <th
                                                className="text-center"
                                                style={{ lineHeight: "28px" }}
                                              >
                                                {" "}
                                                Misc. Rate{" "}
                                              </th>
                                              <th className="text-center">
                                                {this.state.misc_rows.length <
                                                5 ? (
                                                  <span
                                                    className="badge badge-outline-success"
                                                    onClick={this.handleAddRow}
                                                    style={{
                                                      cursor: "pointer",
                                                      paddingTop: "3px",
                                                      paddingBottom: "3px",
                                                      paddingLeft: "7px",
                                                      fontSize: "18px",
                                                    }}
                                                  >
                                                    <em className="icon ni ni-plus"></em>
                                                  </span>
                                                ) : null}
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.misc_rows.map(
                                              (item, idx) => (
                                                <tr
                                                  id={`addr${idx}`}
                                                  key={`idx${idx}`}
                                                >
                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    <MutextField
                                                      id="misc_title"
                                                      name="misc_title"
                                                      label="Rate Title"
                                                      defaultValue={
                                                        this.state.misc_rows[
                                                          idx
                                                        ].misc_title
                                                      }
                                                      variant="outlined"
                                                      onChange={this.handleChangeMisc(
                                                        idx
                                                      )}
                                                      fullWidth
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    <MutextField
                                                      id="misc_type"
                                                      name="misc_type"
                                                      select
                                                      label="Rate Type"
                                                      defaultValue={
                                                        this.state.misc_rows[
                                                          idx
                                                        ].misc_type
                                                      }
                                                      onChange={this.handleChangeMisc(
                                                        idx
                                                      )}
                                                      SelectProps={{
                                                        native: true,
                                                      }}
                                                      helperText="Miscellaneous rate type"
                                                      variant="outlined"
                                                      fullWidth
                                                    >
                                                      <option
                                                        key="per_minute1"
                                                        value="per_minute"
                                                      >
                                                        Select Rate Type
                                                      </option>
                                                      <option
                                                        key="per_minute"
                                                        value="per_minute"
                                                      >
                                                        Per Minute
                                                      </option>
                                                      <option
                                                        key="per_hour"
                                                        value="per_hour"
                                                      >
                                                        Per Hour
                                                      </option>
                                                      <option
                                                        key="one_time"
                                                        value="one_time"
                                                      >
                                                        One Time
                                                      </option>
                                                    </MutextField>
                                                  </td>
                                                  <td
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    <MutextField
                                                      id="misc_rate"
                                                      name="misc_rate"
                                                      label="Rate"
                                                      defaultValue={
                                                        this.state.misc_rows[
                                                          idx
                                                        ].misc_rate
                                                      }
                                                      variant="outlined"
                                                      onChange={this.handleChangeMisc(
                                                        idx
                                                      )}
                                                      onKeyPress={() => {
                                                        this.checkNumber();
                                                      }}
                                                      fullWidth
                                                    />
                                                  </td>
                                                  <td
                                                    className="text-center"
                                                    style={{
                                                      paddingTop: "20px",
                                                    }}
                                                  >
                                                    <span
                                                      className="badge badge-outline-danger"
                                                      onClick={this.handleRemoveSpecificRow(
                                                        idx
                                                      )}
                                                      style={{
                                                        cursor: "pointer",
                                                        paddingTop: "3px",
                                                        paddingBottom: "3px",
                                                        paddingLeft: "7px",
                                                        fontSize: "18px",
                                                      }}
                                                    >
                                                      <em className="icon ni ni-trash"></em>
                                                    </span>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>

                                        {/*<button
                            onClick={this.handleRemoveRow}
                            className="btn btn-danger float-right"
                          >
                            Delete Last Row
                                      </button>*/}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div
                                className="nk-kycfm-head"
                                style={{
                                  background: "#f5f6fa",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  this.open_current_tab("desc_settings_tab");
                                }}
                                title="Click to open the short description section"
                              >
                                <div className="nk-kycfm-count">04</div>
                                <div className="nk-kycfm-title">
                                  <h5 className="title">Short Description</h5>
                                  <p className="sub-title">
                                    Short description of the service
                                  </p>
                                </div>
                              </div>
                              {/* .nk-kycfm-head */}
                              {this.state.desc_settings_tab === true ? (
                                <div className="nk-kycfm-content">
                                  <div className="row g-4">
                                    <div className="col-md-12 col-lg-12 col-xxl-12">
                                      <TextareaAutosize
                                        maxrows={4}
                                        name="product_desc"
                                        id="product_desc"
                                        aria-label=""
                                        placeholder=""
                                        defaultValue={this.state.product_desc}
                                        onChange={this.handleChange}
                                        style={{
                                          width: "100%",
                                          height: "100px",
                                          borderColor: "rgba(0, 0, 0, 0.125)",
                                          borderRadius: "4px",
                                        }}
                                        variant="outlined"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="nk-kycfm-footer">
                                <div className="nk-kycfm-action pt-2 text-right">
                                  <a
                                    onClick={() =>
                                      this.addMenuHistory("/services")
                                    }
                                    className="btn btn-lg btn-light"
                                    disabled={this.state.disabled}
                                    style={{ marginRight: "15px" }}
                                  >
                                    <em className="icon ni ni-step-back"></em>
                                    Back
                                  </a>
                                  <button
                                    type="submit"
                                    className="btn btn-lg btn-primary"
                                    disabled={this.state.disabled}
                                  >
                                    <em className="icon ni ni-plus-round"></em>
                                    Save Service
                                  </button>
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {/* NEW DESIGN HTML */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addratedeck);
