import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import { singleSignOnOAuth } from "../../../config/reseller_api_calls";

export default class SsoAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
      allowedDomain: "",
    };
  }

  pythonDecrypt = (encryptedData, passphrase) => {
    // Decode the URL-safe Base64 back to regular Base64
    const base64Encoded = encryptedData.replace(/-/g, "+").replace(/_/g, "/");

    const jsonString = CryptoJS.enc.Utf8.stringify(
      CryptoJS.enc.Base64.parse(base64Encoded)
    );
    const json = JSON.parse(jsonString);

    const iv = CryptoJS.enc.Base64.parse(json.iv);
    const encrypted = json.data;

    const secretKey = CryptoJS.enc.Hex.parse(passphrase);

    const decrypted = CryptoJS.AES.decrypt(encrypted, secretKey, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  };

  async componentDidMount() {
    console.log("In OAuth");

    const { token } = this.props.match.params;

    let allowedDomain = document.referrer;
    console.log("Hit received from domain: ", allowedDomain);

    this.setState({
      allowedDomain: allowedDomain
    });

    const passphrase = process.env.REACT_APP_CARRIER_VOICE_ALERTS_SECRET_KEY;

    let decryptedData;
    try {
      decryptedData = this.pythonDecrypt(token, passphrase);
      console.log("Decrypted data:", decryptedData);
    } catch (error) {
      console.error("Error decrypting token:", error);
      this.setState({
        errorMessage: "Failed to decrypt the token.",
      });
      return;
    }

    let parsedData;
    try {
      parsedData = JSON.parse(decryptedData);
      console.log("Parsed Data:", parsedData);
    } catch (error) {
      console.error("Error parsing decrypted data:", error);
      this.setState({
        errorMessage: "Failed to parse the decrypted token data.",
      });
      return;
    }

    const {
      email,
      redirect_url,
      return_url,
      name,
      comp_name,
      address,
      city,
      state,
      zip,
      country,
      phone,
      title,
    } = parsedData;

    let apiHit = await singleSignOnOAuth(
      email,
      name,
      comp_name,
      address,
      city,
      state,
      zip,
      country,
      phone,
      title,
      this.state.allowedDomain
    );

    console.log("apiHit.data: ", apiHit.data);

    if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "Invalid or Expired Token."
    ) {
      this.setState({
        errorMessage: "Invalid credentials or expired token.",
        successMessage: "",
      });
    } else if (
      apiHit.data.status === 404 &&
      apiHit.data.message === "Invalid or unauthorized domain."
    ) {
      this.setState({
        errorMessage: "Invalid or unauthorized domain.",
        successMessage: "",
      });
    } else if (
      apiHit.data.status === 200 &&
      apiHit.data.message === "success"
    ) {
      if (return_url && return_url !== "") {
        Cookies.set(
          "return_url__",
          CryptoJS.AES.encrypt(return_url, process.env.REACT_APP_SIGNATURE).toString()
        );
      }

      // Set cookies using async/await to ensure all are set before redirecting
      await Promise.all([
        Cookies.set(
          "tkn__",
          CryptoJS.AES.encrypt(apiHit.data.token, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "acn__",
          CryptoJS.AES.encrypt(apiHit.data.accountno, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "eml__",
          CryptoJS.AES.encrypt(apiHit.data.email, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "comp_nme__",
          CryptoJS.AES.encrypt(apiHit.data.comp_name, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "name__",
          CryptoJS.AES.encrypt(apiHit.data.name, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "phone__",
          CryptoJS.AES.encrypt(apiHit.data.phone, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "title__",
          CryptoJS.AES.encrypt(apiHit.data.title, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "usertype__",
          CryptoJS.AES.encrypt(apiHit.data.user_type, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "useracct__",
          CryptoJS.AES.encrypt(apiHit.data.user_accountno, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "usertkn__",
          CryptoJS.AES.encrypt(apiHit.data.sub_user_token, process.env.REACT_APP_SIGNATURE).toString()
        ),
        Cookies.set(
          "decimalPoint__",
          CryptoJS.AES.encrypt(apiHit.data.decimalPoint.toString(), process.env.REACT_APP_SIGNATURE).toString()
        )
      ]);

      this.setState({
        errorMessage: "",
        successMessage: "Account authenticated successfully.",
      });

      // Ensure the redirection happens only after all cookies are set
      setTimeout(() => {
        if (redirect_url && redirect_url !== "") {
          window.location.replace(`${redirect_url}`);
        } else {
          window.location.replace(`/dashboard`);
        }
      }, 3000);
    } else {
      this.setState({
        errorMessage:
          "Your account cannot be authenticated this time. Please check the URL or try again later.",
        successMessage: "",
      });
    }
  }


  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              {this.state.errorMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-danger">
                    <div className="alert-text">
                      <h4>Error</h4>
                      <p>{this.state.errorMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}

              {this.state.successMessage !== "" ? (
                <div className="example-alert">
                  <div className="alert alert-pro alert-success">
                    <div className="alert-text">
                      <h4>Success</h4>
                      <p>{this.state.successMessage}</p>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="text-center" style={{ marginTop: "20%" }}>
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
