import { React } from "react";
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listClientPermissions,
  saveClientPermissions,
} from "./../../../config/api_calls";

import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class ClientPermissons extends Component {
  constructor() {
    super();
    this.state = {
      fillerID: false,
      permissions: {
        fillerID: false,
        require: false,
        //notRequire: false,
      },
      reseller_accountno: "",
      dateUpdated: null,
      selectedNames: [],
      successMessagePermission: "",
      errorMessagePermission: "",
      showRequiredButton: false,
      showNotRequiredButton: false,
    };
  }

  async componentDidMount() {
    try {
      const listPermissionsResponse = await listClientPermissions(
        auth.getAccount(),
        auth.getToken()
      );

      console.log("Permissions Res: ", listPermissionsResponse.data);

      if (
        listPermissionsResponse.data.status === 403 ||
        listPermissionsResponse.data.errors === "authentication missing" ||
        listPermissionsResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (listPermissionsResponse.data.status === 404) {
        //window.location.replace("/error");
      } else if (
        listPermissionsResponse.data.status === 200 &&
        listPermissionsResponse.data.message === "success"
      ) {
        const data = listPermissionsResponse.data.data;
        const { fillerID, isRequired } = data[0];

        this.setState({
          tableLoader: false,
          permissions: {
            fillerID: fillerID === 1 ? true : null,
            require: isRequired === 1 ? true : null,
            //notRequire: isRequired === 0 ? true : null,
          },
        });
      } else {
        // Handle other cases or errors
      }
    } catch (error) {
      console.error("Error fetching agent permissions:", error);
    }
  }

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    this.setState(
      (prevState) => ({
        permissions: {
          ...prevState.permissions,
          [name]: checked,
          // Update require based on fillerID checkbox state
          require:
            name === "fillerID" && !checked
              ? false
              : prevState.permissions.require,
        },
        showRequiredButton: name === "fillerID" && checked,
      }),
      () => {
        console.log("Updated Permissions State:", this.state.permissions);
      }
    );
  };

  handleRequireChange = () => {
    this.setState((prevState) => ({
      permissions: {
        ...prevState.permissions,
        require: !prevState.permissions.require,
      },
    }));
  };

  handleNotRequireChange = () => {
    this.setState((prevState) => ({
      permissions: {
        ...prevState.permissions,
        require: false,
        notRequire: true,
      },
    }));
  };

  saveAgentPermissionsFun = async () => {
    const servicesResponse = await saveClientPermissions(
      auth.getAccount(),
      auth.getToken(),
      this.state.permissions
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessagePermission: "Permissions Updated Successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlert").modal("hide");
            this.setState({ successMessagePermission: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessagePermission: "Error updating permissions.",
      });

      // Clear the error message after 3 seconds
      setTimeout(() => {
        this.setState({ errorMessagePermission: "" });
      }, 3000);
    } else {
      this.setState({
        errorMessagePermission: "Error updating permissions.",
      });

      // Clear the error message after 3 seconds
      setTimeout(() => {
        this.setState({ errorMessagePermission: "" });
      }, 3000);
    }
  };

  render() {
    const { fillerID, require, notRequire } = this.state.permissions;
    const { showRequiredButton, showNotRequiredButton, permissions } =
      this.state;

    console.log("Require", require);
    console.log("Not Require", notRequire);
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Client Permissions"
                    icon="icon ni ni-setting"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          {this.state.errorMessagePermission !== "" ? (
            <div className="example-alert" style={{ marginBottom: "15px" }}>
              <div className="alert alert-pro alert-danger">
                <div className="alert-text">
                  <h4>Error</h4>
                  <p>{this.state.errorMessagePermission}</p>
                </div>
              </div>
            </div>
          ) : null}
          {this.state.successMessagePermission !== "" ? (
            <div className="example-alert" style={{ marginBottom: "15px" }}>
              <div className="alert alert-pro alert-success">
                <div className="alert-text">
                  <h4>Success</h4>
                  <p>{this.state.successMessagePermission}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="">
              <div className="card card-bordered">
                <div className="card-inner">
                  <div className="row ">
                    <div className="col-md-6">
                      <label className="form-label">
                        FCC 499 Filer ID{"  "}{" "}
                        <Tooltip
                          title="This will enable 499 Filer ID on client registration forms."
                          placement="top"
                        >
                          <em
                            className="icon ni ni-info"
                            style={{
                              color: "#6576ff",
                            }}
                          ></em>
                        </Tooltip>
                      </label>
                    </div>
                    <div className="col-md-5 ml-3">
                      <label className="form-label">
                        FCC 499 Filer ID Required {"  "}
                        <Tooltip
                          title="This will make 499 Filer ID mandatory on client registration forms."
                          placement="top"
                        >
                          <em
                            className="icon ni ni-info"
                            style={{
                              color: "#6576ff",
                            }}
                          ></em>
                        </Tooltip>
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <ul className="nk-kycfm-control-list g-3">
                      {fillerID !== undefined && (
                        <li className="nk-kycfm-control-item">
                          <input
                            checked={this.state.permissions.fillerID}
                            onChange={this.handleCheckboxChange}
                            className="nk-kycfm-control"
                            type="checkbox"
                            name="fillerID"
                            id="fillerID"
                            data-title="FCC 499 Filer ID"
                            value="Yes"
                          />
                          <label className="nk-kycfm-label" htmlFor="fillerID">
                            <span className="nk-kycfm-label-icon">
                              <div className="label-icon">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 90 90"
                                >
                                  <rect
                                    x="5"
                                    y="7"
                                    width="60"
                                    height="56"
                                    rx="7"
                                    ry="7"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <rect
                                    x="25"
                                    y="27"
                                    width="60"
                                    height="56"
                                    rx="7"
                                    ry="7"
                                    fill="#e3e7fe"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <rect
                                    x="15"
                                    y="17"
                                    width="60"
                                    height="56"
                                    rx="7"
                                    ry="7"
                                    fill="#fff"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="15"
                                    y1="29"
                                    x2="75"
                                    y2="29"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                  />
                                  <circle
                                    cx="53"
                                    cy="23"
                                    r="2"
                                    fill="#c4cefe"
                                  />
                                  <circle
                                    cx="60"
                                    cy="23"
                                    r="2"
                                    fill="#c4cefe"
                                  />
                                  <circle
                                    cx="67"
                                    cy="23"
                                    r="2"
                                    fill="#c4cefe"
                                  />
                                  <rect
                                    x="22"
                                    y="39"
                                    width="20"
                                    height="20"
                                    rx="2"
                                    ry="2"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <circle
                                    cx="32"
                                    cy="45.81"
                                    r="2"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    d="M29,54.31a3,3,0,0,1,6,0"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="49"
                                    y1="40"
                                    x2="69"
                                    y2="40"
                                    fill="none"
                                    stroke="#6576ff"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="49"
                                    y1="51"
                                    x2="69"
                                    y2="51"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="49"
                                    y1="57"
                                    x2="59"
                                    y2="57"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="64"
                                    y1="57"
                                    x2="66"
                                    y2="57"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="49"
                                    y1="46"
                                    x2="59"
                                    y2="46"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                  <line
                                    x1="64"
                                    y1="46"
                                    x2="66"
                                    y2="46"
                                    fill="none"
                                    stroke="#c4cefe"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </svg>
                              </div>
                            </span>
                            <span className="nk-kycfm-label-text">
                              FCC 499 Filer ID
                            </span>
                          </label>
                        </li>
                      )}

                      <li className="nk-kycfm-control-item">
                        <input
                          checked={this.state.permissions.require}
                          onChange={this.handleRequireChange}
                          className="nk-kycfm-control"
                          type="checkbox"
                          name="require"
                          id="require"
                          data-title="Require"
                          value="Yes"
                        />
                        <label className="nk-kycfm-label" htmlFor="require">
                          <span className="nk-kycfm-label-icon">
                            <div className="label-icon">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 90 90"
                              >
                                <rect
                                  x="5"
                                  y="7"
                                  width="60"
                                  height="56"
                                  rx="7"
                                  ry="7"
                                  fill="#e3e7fe"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <rect
                                  x="25"
                                  y="27"
                                  width="60"
                                  height="56"
                                  rx="7"
                                  ry="7"
                                  fill="#e3e7fe"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <rect
                                  x="15"
                                  y="17"
                                  width="60"
                                  height="56"
                                  rx="7"
                                  ry="7"
                                  fill="#fff"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="15"
                                  y1="29"
                                  x2="75"
                                  y2="29"
                                  fill="none"
                                  stroke="#6576ff"
                                  strokeMiterlimit="10"
                                  strokeWidth="2"
                                />
                                <circle cx="53" cy="23" r="2" fill="#c4cefe" />
                                <circle cx="60" cy="23" r="2" fill="#c4cefe" />
                                <circle cx="67" cy="23" r="2" fill="#c4cefe" />
                                <rect
                                  x="22"
                                  y="39"
                                  width="20"
                                  height="20"
                                  rx="2"
                                  ry="2"
                                  fill="none"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <circle
                                  cx="32"
                                  cy="45.81"
                                  r="2"
                                  fill="none"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <path
                                  d="M29,54.31a3,3,0,0,1,6,0"
                                  fill="none"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="49"
                                  y1="40"
                                  x2="69"
                                  y2="40"
                                  fill="none"
                                  stroke="#6576ff"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="49"
                                  y1="51"
                                  x2="69"
                                  y2="51"
                                  fill="none"
                                  stroke="#c4cefe"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="49"
                                  y1="57"
                                  x2="59"
                                  y2="57"
                                  fill="none"
                                  stroke="#c4cefe"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="64"
                                  y1="57"
                                  x2="66"
                                  y2="57"
                                  fill="none"
                                  stroke="#c4cefe"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="49"
                                  y1="46"
                                  x2="59"
                                  y2="46"
                                  fill="none"
                                  stroke="#c4cefe"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                                <line
                                  x1="64"
                                  y1="46"
                                  x2="66"
                                  y2="46"
                                  fill="none"
                                  stroke="#c4cefe"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                />
                              </svg>
                            </div>
                          </span>
                          <span className="nk-kycfm-label-text">Required</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-12 text-right mt-4 mb-4 mr-4">
                  <button
                    className="btn btn-lg btn-primary"
                    type="submit"
                    onClick={this.saveAgentPermissionsFun}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div style={{ clear: "both" }}></div>

            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ClientPermissons);
