import ContentLoader from "react-content-loader";
export const tableLoaderListingBlocks = () => {
  return (
    <ContentLoader
      width="100%"
      speed={5}
      primarycolor="#f3f3f3"
      secondarycolor="#ecebeb"
    >
      <rect x="0" y="20" rx="3" width="32%" height="10" />
      <rect x="33%" y="20" rx="3" width="32%" height="10" />
      <rect x="67%" y="20" rx="3" width="32%" height="10" />

      <rect x="0" y="20" rx="4" width="32%" height="10" />
      <rect x="33%" y="20" rx="4" width="32%" height="10" />
      <rect x="67%" y="20" rx="4" width="32%" height="10" />

      <rect x="0" y="40" rx="4" width="32%" height="10" />
      <rect x="33%" y="40" rx="4" width="32%" height="10" />
      <rect x="67%" y="40" rx="4" width="32%" height="10" />

      <rect x="0" y="60" rx="4" width="32%" height="10" />
      <rect x="33%" y="60" rx="4" width="32%" height="10" />
      <rect x="67%" y="60" rx="4" width="32%" height="10" />

      <rect x="0" y="80" rx="4" width="32%" height="10" />
      <rect x="33%" y="80" rx="4" width="32%" height="10" />
      <rect x="67%" y="80" rx="4" width="32%" height="10" />
    </ContentLoader>
  );
};
