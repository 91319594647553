import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listPackageAgreements,
  deleteClientAgreement,
  listPackagesClients,
  assignAgreementToPackages,
  unSignAgreement,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";

import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import dateFormat, { masks } from "dateformat";
import Tooltip from "@material-ui/core/Tooltip";

const auth = new HelperClass();

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

class PackageAgreements extends Component {
  constructor() {
    super();
    this.state = {
      agreementsData: [],
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      title: "",
      name: "",
      logo: "",
      agent_email: auth.getEmail(),
      delete_id: "",
      agent_password: "",
      c_password: "",
      description: "",
      successMessage: "",
      tableLoader: true,
      domain: "",
      step: 1,
      dnsList: [],
      a_ip: "",
      is_verified: 0,
      list_services: [],
      assigned_services: [],
      did_service: [],
      emptyService: 0,
      assignSuccessMessage: "",
      assignErrorMessage: "",
      isLoading: "1",
      columns: [
        { name: "Title" },
        {
          name: "Status",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.statusText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.signupText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Type",
          options: {
            customBodyRender: (value, tableMeta) => {
              return <div>{this.typeText(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Date Added" },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.addMenuHistory(
                                      "/package_agreements/edit_package_agreement/" +
                                        tableMeta.rowData[5]
                                    )
                                  }
                                  title="Edit Agreement"
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Edit Agreement</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.serviceAlert(tableMeta.rowData[5])
                                  }
                                  title="Assign agreement to the package"
                                  style={{ cursor: "pointer" }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-link-group"></em>
                                  <span>Assign Package</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() =>
                                    this.deleteAlert(tableMeta.rowData[5])
                                  }
                                  style={{ cursor: "pointer" }}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete Agreement"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Agreement</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      tableData: [],
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, TT");
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    const servicesResponce = await listPackageAgreements(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        agreementsData: [],
        tableLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agreementsData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        agreementsData: [],
        tableLoader: false,
      });
    }
  }

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[1];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  statusText = (value, tableMeta) => {
    let is_status = tableMeta.rowData[1];
    if (is_status === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> Inactive
        </span>
      );
    }
  };

  signupText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[2];
    if (is_signup === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  typeText = (value, tableMeta) => {
    let is_signup = tableMeta.rowData[3];
    if (is_signup === "esign") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-edit-fill"></em> E-Signature
        </span>
      );
    } else if (is_signup === "accept") {
      return (
        <span className="badge badge-outline-primary">
          <em className="icon ni ni-done"></em> Acceptance only
        </span>
      );
    }
  };

  defaultText = (value, tableMeta) => {
    let is_default = tableMeta.rowData[2];
    if (is_default === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  serviceAlert = async (value) => {
    let id = value;
    this.setState({
      delete_id: id,
      assignSuccessMessage: "",
      assignErrorMessage: "",
    });
    const agreementResponce = await listPackagesClients(
      auth.getAccount(),
      auth.getToken(),
      id
    );

    console.log("serviceAlert: ", agreementResponce.data);

    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        list_services: [],
        assignErrorMessage: "There are no services.",
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        emptyService: true,
        disabled: false,
        list_services: agreementResponce.data.data,
        assigned_services: agreementResponce.data.assigned,
      });
    } else {
      this.setState({
        list_services: [],
        assignErrorMessage: "There are no services.",
      });
    }
    window.$("#modalAssignService").modal("show");
  };

  assignServices = async () => {
    const { did_service, delete_id } = this.state;
    console.log("did_service: ", did_service);
    console.log("delete_id: ", delete_id);
    var checkedValues = $("input:checkbox.vdrSelected:checked")
      .map(function () {
        return this.value;
      })
      .get();
    console.log("checkedValues: ", checkedValues);
    if (checkedValues.length === 0) {
      this.setState({
        assignErrorMessage: "Please choose the service.",
        assignSuccessMessage: "",
        disabled: false,
      });
    } else {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
      const agreementResponce = await assignAgreementToPackages(
        auth.getAccount(),
        auth.getToken(),
        JSON.stringify(checkedValues),
        delete_id
      );

      console.log("status: ", agreementResponce.data);

      if (
        agreementResponce.data.status === 403 ||
        agreementResponce.data.errors === "authentication missing" ||
        agreementResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        agreementResponce.data.status === 404 &&
        agreementResponce.data.note === "empty"
      ) {
        this.setState({
          assignErrorMessage: agreementResponce.data.message,
          assignSuccessMessage: "",
          disabled: false,
        });
      } else if (
        agreementResponce.data.status === 200 &&
        agreementResponce.data.message === "success"
      ) {
        this.setState({
          assignErrorMessage: "",
          assignSuccessMessage: "Agreement assigned successfully.",
          disabled: false,
        });
        this.componentDidMount();
        setTimeout(function () {
          window.$("#modalAssignService").modal("hide");
        }, 3000);
      } else {
        this.setState({
          assignErrorMessage:
            "There is some error while assigning the agreement.",
          assignSuccessMessage: "",
          disabled: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
      });
    }, 4000);
  };

  modalHideAssign = () => {
    window.$("#modalAssignService").modal("hide");
  };

  deleteAlert = async (value) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let id = value;
    this.setState({
      delete_id: id,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteAgreement = async () => {
    let agreement_id = this.state.delete_id;
    const agreementResponce = await deleteClientAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id
    );

    console.log("status: ", agreementResponce.data.data);
    //$("#btnloader").show();
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      // window.location.replace("/error");
      this.setState({
        errorMessaged: agreementResponce.data.note,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        errorMessaged: "",
        successMessaged: "Agreement deleted successfully.",
        disabled: false,
      });
      this.componentDidMount();
      setTimeout(function () {
        window.$("#modalDeleteAlert").modal("hide");
      }, 3000);
      //$("#btnloader").hide();
    } else {
      this.setState({
        errorMessaged: agreementResponce.data.message,
        successMessaged: "",
        disabled: false,
      });
      //$("#btnloader").hide();
      //window.location.replace("/error");
    }
  };

  unassign_agreement = async (id) => {
    let agreement_id = this.state.delete_id;

    const agreementResponce = await unSignAgreement(
      auth.getAccount(),
      auth.getToken(),
      agreement_id,
      id
    );
    this.setState({
      isLoading: "2",
      disabled: true,
    });
    console.log("unassign_agreement: ", agreementResponce.data);
    if (
      agreementResponce.data.status === 403 ||
      agreementResponce.data.errors === "authentication missing" ||
      agreementResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      agreementResponce.data.status === 404 &&
      agreementResponce.data.message === "fail" &&
      agreementResponce.data.note !== ""
    ) {
      this.setState({
        assignErrorMessage: agreementResponce.data.note,
        assignSuccessMessage: "",
        disabled: false,
        isLoading: "4",
      });
    } else if (
      agreementResponce.data.status === 200 &&
      agreementResponce.data.message === "success"
    ) {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "Agreement unassigned successfully.",
        disabled: false,
        isLoading: "3",
        assigned_services: agreementResponce.data.assignedArray,
      });
    } else {
      this.setState({
        assignErrorMessage: agreementResponce.data.message,
        assignSuccessMessage: "",
        disabled: false,
        isLoading: "4",
      });
    }
    setTimeout(() => {
      this.setState({
        assignErrorMessage: "",
        assignSuccessMessage: "",
        disabled: false,
        isLoading: "1",
      });
    }, 4000);
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}

          {/*### Assign Service Modal ###*/}
          <div className="modal fade" tabIndex="-1" id="modalAssignService">
            <div
              className="modal-dialog modal-lg modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideAssign();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">
                    <em className="icon ni ni-link-group"></em> Assign agreement
                    to the package
                  </h5>
                </div>
                <div className="modal-body">
                  {this.state.assignErrorMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-danger">
                        <div className="alert-text">
                          <h4>Error</h4>
                          <p>{this.state.assignErrorMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {this.state.assignSuccessMessage !== "" ? (
                    <div
                      className="example-alert"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="alert alert-pro alert-success">
                        <div className="alert-text">
                          <h4>Success</h4>
                          <p>{this.state.assignSuccessMessage}</p>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div
                    className="col-lg-12 col-md-12 col-xxl-12"
                    style={{ marginBottom: "30px" }}
                  >
                    <div id="accordion-2" className="accordion accordion-s3">
                      <div className="accordion-item">
                        <a
                          href="#"
                          className="accordion-head"
                          data-toggle="collapse"
                          data-target="#accordion-item-2-1"
                          style={{ borderBottom: "1px solid #dbdfea" }}
                        >
                          <h6 className="title">
                            Select packages to assign to the agreement
                          </h6>
                          <span className="accordion-icon"></span>
                        </a>
                        <div
                          className="accordion-body collapse show"
                          id="accordion-item-2-1"
                          data-parent="#accordion-2"
                        >
                          <div className="accordion-inner">
                            {this.state.list_services ? (
                              <>
                                <div className="col-lg-12 col-md-12">
                                  <div className="form-group select_services">
                                    <label className="form-label">
                                      Select Packages
                                    </label>
                                    <ul className="custom-control-group custom-control-vertical custom-control-stacked w-100">
                                      {this.state.list_services.map(
                                        (splits, index) => (
                                          <li key={`addser${index}`}>
                                            <div className="custom-control custom-control-sm custom-radio custom-control-pro">
                                              <input
                                                type="checkbox"
                                                className="custom-control-input vdrSelected"
                                                id={`cp1-package-${index}`}
                                                name="did_service"
                                                defaultChecked={false}
                                                value={splits[0]}
                                                onChange={this.handleChange}
                                                ref="did_service"
                                                required=""
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`cp1-package-${index}`}
                                              >
                                                <span className="d-flex align-center">
                                                  <span className="user-avatar sq bg-primary-dim">
                                                    <span className="icon ni ni-briefcase"></span>
                                                  </span>
                                                  <span className="ms-2">
                                                    <span className="lead-text">
                                                      {splits[1]}
                                                    </span>
                                                    <span className="sub-text">
                                                      Per Month Rate (Charge
                                                      Monthly): $
                                                      {splits[2]
                                                        ? splits[2].toFixed(4)
                                                        : "0.00"}
                                                      <br />
                                                      Per Month Rate (Charge
                                                      Yearly): $
                                                      {splits[3]
                                                        ? splits[3].toFixed(4)
                                                        : "0.00"}
                                                    </span>
                                                  </span>
                                                </span>
                                              </label>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </div>
                                <div
                                  className="form-group text-right col-md-12"
                                  style={{ marginTop: "20px" }}
                                >
                                  <button
                                    className="btn btn-lg btn-primary"
                                    type="button"
                                    disabled={this.state.disabled}
                                    onClick={() => {
                                      this.assignServices();
                                    }}
                                  >
                                    Submit
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <a
                          href="#"
                          className="accordion-head collapsed"
                          data-toggle="collapse"
                          data-target="#accordion-item-2-2"
                          style={{ borderBottom: "1px solid #dbdfea" }}
                        >
                          <h6 className="title">Already Assigned Packages</h6>
                          <span className="accordion-icon"></span>
                        </a>
                        <div
                          className="accordion-body collapse"
                          id="accordion-item-2-2"
                          data-parent="#accordion-2"
                        >
                          <div className="accordion-inner">
                            {this.state.assigned_services.length > 0 ? (
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th scope="col">Service</th>
                                    <th scope="col">Date Assigned</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.assigned_services.map(
                                    (sel_ser, inde) => (
                                      <tr key={`sel_ser${inde}`}>
                                        <td>{sel_ser[0]}</td>
                                        <td>
                                          {sel_ser[1] !== "" ? (
                                            <>
                                              {dateFormat(
                                                sel_ser[1],
                                                "mmmm dS, yyyy, h:MM:ss TT"
                                              )}
                                            </>
                                          ) : null}
                                        </td>
                                        <td>
                                          <Tooltip
                                            title="Unassign Package"
                                            placement="top"
                                          >
                                            <a
                                              onClick={() =>
                                                this.unassign_agreement(
                                                  sel_ser[3]
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              disabled={this.state.disabled}
                                            >
                                              <span className="badge badge-outline-danger">
                                                <em className="icon ni ni-unlink-alt"></em>
                                              </span>
                                            </a>
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div
                                className="example-alert"
                                style={{ marginBottom: "20px" }}
                              >
                                <div className="alert alert-pro alert-danger">
                                  <div className="alert-text">
                                    <h4>Note</h4>
                                    <p>
                                      Packages not assigned to this agreement.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*### Assign Service Modal ###*/}

          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <h3 className="nk-block-title page-title">
                  <PageTitle
                    name="Package Agreements"
                    icon="icon ni ni-file-docs"
                  />
                </h3>
              </div>
              <div className="nk-block-head-content" id="HeadContent">
                <div className="toggle-wrap nk-block-tools-toggle">
                  <a
                    href="#"
                    className="btn btn-icon btn-trigger toggle-expand mr-n1"
                    data-target="pageMenu"
                  >
                    <em className="icon ni ni-more-v"></em>
                  </a>
                  <div
                    className="toggle-expand-content"
                    data-content="pageMenu"
                  >
                    <ul className="nk-block-tools g-3">
                      <li className="nk-block-tools-opt">
                        <Link
                          color="inherit"
                          onClick={() =>
                            this.addMenuHistory(
                              "/package_agreements/add_package_agreement"
                            )
                          }
                          className="btn btn-primary"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                            color: "#fff",
                          }}
                        >
                          <em className="icon ni ni-plus-round"></em>
                          &nbsp;Add New Agreement
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                {this.state.errorMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-danger">
                      <div className="alert-text">
                        <h4>Error</h4>
                        <p>{this.state.errorMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                {this.state.successMessage !== "" ? (
                  <div className="example-alert">
                    <div className="alert alert-pro alert-success">
                      <div className="alert-text">
                        <h4>Success</h4>
                        <p>{this.state.successMessage}</p>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="nk-block nk-block-lg">
                  {/* START DATATABLE */}
                  {this.state.tableLoader === true ? (
                    tableLoader()
                  ) : (
                    <DataTable
                      columns={this.state.columns}
                      tableData={this.state.agreementsData}
                      title="Package Agreements"
                    />
                  )}

                  {/* END TABLE */}
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PackageAgreements);
