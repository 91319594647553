import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listCreditApplications,
  listAllClients,
  approveCreditApplication,
  rejectCreditApplication
} from "./../../../config/signed_agreements_api_calls";

import DataTable from "../../../components/Tables/DataTable";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { APP_LIVE_URL, AGENT_PORTAL_URL } from "./../../../config/config";
import dateFormat, { masks } from "dateformat";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ContactsOutlined } from "@material-ui/icons";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class CreditApp extends Component {
  constructor() {
    super();
    
    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      all_active_clients: [],
      selected_accountno: null,
      cap_accountno: "",
      client_accountno: "",
      approveErrorMessage: "",
      approveSuccessMessage: "",
      approve_button: false,
      revoke_reason: "",
      disabled: false,
      revokeErrorMessage: "",
      revokeSuccessMessage: "",
      revoke_button: false,
      columnServices: [
        { name: "application_no", label: "ID" },
        {
          name: "agentTitle", // Add this new column
          label: "Client Email",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.getClientEmail(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "createdAt",
          label: "Created Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateOnly(value)}</div>;
            },
          },
        },
        {
          name: "pdf_file",
          label: "View",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewPdf(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "status",
          label: "Status",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setStatus(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "complete_status",
          label: "Complete",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.setComplete(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "approvedAt",
          label: "Approved Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateOnly(value)}</div>;
            },
          },
        },
        {
          name: "rejectedAt",
          label: "Rejected Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{auth.formatDateOnly(value)}</div>;
            },
          },
        },
        {
          label: "Actions",
          name: "client_accountno",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.openApprove(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Reason / Step Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Approve</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.openRevoke(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Reason / Step Details"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em className="icon ni ni-list"></em>
                                  <span>Reject</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    const servicesResponce = await listCreditApplications(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Available Credit Applications: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else if (servicesResponce.data.status === 200) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
      });
      //window.location.replace("/error");
    }
    this.listAllActiveClients();
  }

  listAllActiveClients = async () => {
    const servicesResponce = await listAllClients(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listAllActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][1] +
              " (" +
              servicesResponce.data.data[i][0] +
              ")",
            value: servicesResponce.data.data[i][2],
          };
          all_clients.push(newArr);
        }
      }

      this.setState({
        all_active_clients: all_clients,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          client_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.client_accountno);
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          client_accountno: null,
          selected_accountno: null,
        },
        () => {
          console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    console.log("Client Account Number: ", this.state.client_accountno);
    const servicesResponce = await listCreditApplications(
      auth.getAccount(),
      auth.getToken(),
      this.state.client_accountno
    );
    console.log("getAgentAgreement: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getClientEmail = (value, tableMeta) => {
    if (value && value !== "") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    }
    return "---";
    // const rowData = tableMeta.rowData;
    // const clientInfo = rowData[5];
    // const clientEmail =
    //   clientInfo && clientInfo.length > 0 ? clientInfo[0].email : "N/A";
    // return <span>{clientEmail}</span>;
  };

  setStatus = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "pending") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> Pending
          </span>
        </div>
      );
    } else if (domain_str === "approved") {
      return (
        <div>
          <span className="badge badge-outline-success">
            {" "}
            <em className="icon ni ni-done "></em> Approved
          </span>
        </div>
      );
    } else if (domain_str === "rejected") {
      return (
        <div>
          <span className="badge badge-outline-danger">
            {" "}
            <em className="icon ni ni-alert-c"></em> Rejected
          </span>
        </div>
      );
    }
  };

  setComplete = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str === "incomplete") {
      return (
        <div>
          <span className="badge badge-outline-warning">
            {" "}
            <em className="icon ni ni-alert-c" title="Active"></em> In Progress
          </span>
        </div>
      );
    } else if (domain_str === "complete") {
      return (
        <div>
          <span className="badge badge-outline-secondary">
            {" "}
            <em className="icon ni ni-done "></em> Submitted
          </span>
        </div>
      );
    }
  };

  viewPdf = (value, tableMeta) => {
    let domain_str = value;
    //console.log("domain_str: ", domain_str);
    if (domain_str && domain_str !== "") {
      return (
        <div>
          <a
            onClick={() => this.downloadPdf(value)}
            style={{ cursor: "pointer" }}
            title="Download PDF"
          >
            <span className="badge badge-outline-secondary">
              {" "}
              <em class="icon ni ni-file-pdf"></em> View PDF
            </span>
          </a>
        </div>
      );
    } else {
      return (
        <div>
          <span className="badge badge-outline-light">
            {" "}
            <em class="icon ni ni-file-pdf"></em> View PDF
          </span>
        </div>
      );
    }
  };

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  serviceSigning = async (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  defaultText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = tableMeta.rowData[5];
    if (is_default === "No") {
      return (
        <a
          style={{ cursor: "pointer" }}
          onClick={() => this.setDefaultService(value, tableMeta)}
          disabled={
            this.props.is_msa_signed === "No" ||
            this.props.defaultSettings === "missing"
              ? true
              : false
          }
        >
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Make Default
          </span>
        </a>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  defaultMarketing = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value.split("###");
    let marketing_content = is_default[0];
    let marketing_link = is_default[1];
    if (marketing_content === "link") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-signin"></em> Product Info
          </span>
        </a>
      );
    } else if (marketing_content === "pdf") {
      return (
        <a href={marketing_link} terget="_blank">
          <span className="badge badge-outline-primary">
            <em className="icon ni ni-download"></em> View PDF
          </span>
        </a>
      );
    } else {
      return "";
    }
  };

  setMarkUp = async (value, tableMeta) => {
    let service_id = tableMeta.rowData[0];

    window.$("#modalForm").modal("show");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  downloadPdf = async (d_f_name) => {
    var url = AGENT_PORTAL_URL + "files_data/credit_application/" + d_f_name;
    let file_name = d_f_name;
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = file_name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  getNewFileName = (originalFileName) => {
    console.log("originalFileName: ",originalFileName);
    // Extract the file extension
    const fileExtension = originalFileName.split('.').pop();

    // Remove spaces and special characters and replace with hyphens
    const cleanedFileName = originalFileName
      .replace(/[^a-zA-Z0-9.]/g, '') // Remove special characters except for dot
      .replace(/\s+/g, '-')         // Replace spaces with hyphens
      .toLowerCase();               // Convert to lowercase

    // Combine the cleaned file name with the original file extension
    const newFileName = `${cleanedFileName}${fileExtension ? `.${fileExtension}` : ''}`;

    return newFileName;
  };

  handleChanges = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeCheckbox = (input) => (e) => {
    this.setState({
      [input]:
        e.target.type === "checkbox" ? e.target.checked : e.target.checked,
    });
    //console.log("handleChangeCheckbox: ", input);
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  generateRandomNumber = (link) => {
    const min = 1000000000; // Minimum 10-digit number (10^9)
    const max = 9999999999; // Maximum 10-digit number (10^10 - 1)
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    this.props.props.history.push(link + "/" + randomNumber);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  nextStep = () => {
    const { step } = this.state;

    this.setState({
      step: step + 1,
    });
  };

  // Go to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };
  firstStep = () => {
    this.setState({
      step: 1,
    });
  };

  openApprove = async (value, tableMeta) => {
    console.log("openApprove: ", tableMeta.rowData[0]);
    let cap_accountno = tableMeta.rowData[0];
    let client_accountno = tableMeta.rowData[8];
    console.log("openApprove client_accountno: ", client_accountno);
    this.setState({
      cap_accountno: cap_accountno,
      client_accountno: client_accountno,
      approveErrorMessage: "",
      approveSuccessMessage: "",
      disabled: false,
    });
    window.$("#modalOpenApprove").modal("show");
  };

  approveCreditApplication= async () => {
    const { cap_accountno, client_accountno } = this.state;
    this.setState({
      approveErrorMessage: "",
      approveSuccessMessage: "",
      disabled: true,
    });
    console.log("approveCreditApplication client_accountno: ", client_accountno);
    const servicesResponce = await approveCreditApplication(
      auth.getAccount(),
      auth.getToken(),
      client_accountno,
      cap_accountno
    );
    console.log("approveAgreement.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        approveErrorMessage:
          "There is an error while approving the application. Please try again.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        approveErrorMessage:
          "There is an error while approving the application. Please try again.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.reloadClients();
      this.setState({
        approveSuccessMessage: "Credit application approved successfully.",
        disabled: false,
      });
      setTimeout(function () {
        window.$("#modalOpenApprove").modal("hide");
      }, 4000);
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while approving the application. Please try again.",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ approveSuccessMessage: "", approveErrorMessage: "" });
    }, 4000);
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  openRevoke = async (value, tableMeta) => {
    console.log("openApprove: ", tableMeta.rowData[0]);
    let cap_accountno = tableMeta.rowData[0];
    let client_accountno = tableMeta.rowData[8];
    console.log("openApprove client_accountno: ", client_accountno);
    this.setState({
      cap_accountno: cap_accountno,
      client_accountno: client_accountno,
      revoke_reason: "",
      revokeErrorMessage: "",
      revokeSuccessMessage: "",
      disabled: false,
    });
    window.$("#modalOpenRevoke").modal("show");
  };

  submitCreditAppRejection = async () => {
    const { cap_accountno, client_accountno, revoke_reason } =
      this.state;
    //console.log('submitCreditAppRejection',agent_id, agreement_id, revoke_reason, agreement_accountno);
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        revokeErrorMessage: "Rejection reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        revokeSuccessMessage: "",
        revokeErrorMessage: "",
        disabled: true,
      });

      const servicesResponce = await rejectCreditApplication(
        auth.getAccount(),
        auth.getToken(),
        client_accountno,
        cap_accountno,
        revoke_reason
      );
      console.log(
        "submitCreditAppRejection.data: ",
        servicesResponce.data
      );
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          revokeErrorMessage:
            "There is an error while rejecting the application. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          revokeErrorMessage:
            "There is an error while rejecting the application. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          revokeSuccessMessage: "Credit application rejected successfully.",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        this.reloadClients();
        setTimeout(function () {
          window.$("#modalOpenRevoke").modal("hide");
        }, 4000);
      } else {
        this.setState({
          revokeErrorMessage:
            "There is an error while rejecting the application. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ revokeSuccessMessage: "", revokeErrorMessage: "" });
    }, 4000);
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    const searchParams = new URLSearchParams(window.location.search);
const credit_app_id = searchParams.get("credit_app_id");
const log_type = searchParams.get("log_type");
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}
              <div className="modal fade" tabIndex="-1" id="modalFormDetails">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">
                        {" "}
                        <em className="icon ni ni-unlock"></em> Other Details
                      </h5>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body">
                      {/* Error Start */}

                      <div className=" g-4">
                        {this.state.tableLoaderListing === true ? (
                          tableLoaderListing()
                        ) : (
                          <>
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Title</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Value</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tableDataSingle &&
                                  this.state.tableDataSingle.length > 0 ? (
                                    <>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #1:</strong> Business
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "business_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #2:</strong> Financial
                                          Information
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "financial_information_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #3:</strong> Trade
                                          References
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "trade_ref_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="tb-col-os">
                                          <strong>Step #4:</strong> Signatory
                                          Details
                                        </td>
                                        <td class="tb-col-ip">
                                          {this.state.tableDataSingle[0][
                                            "signatory_status"
                                          ] === 1 ? (
                                            <div className="text-success">
                                              <em className="icon ni ni-list-check"></em>{" "}
                                              Completed
                                            </div>
                                          ) : (
                                            <div className="text-danger">
                                              <em className="icon ni ni-na"></em>{" "}
                                              Remaining
                                            </div>
                                          )}
                                        </td>
                                      </tr>
                                      {this.state.tableDataSingle[0][
                                        "reason"
                                      ] &&
                                      this.state.tableDataSingle[0][
                                        "reason"
                                      ] !== "" ? (
                                        <>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              <strong>
                                                Reason of rejection
                                              </strong>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td class="tb-col-os" colSpan={2}>
                                              {
                                                this.state.tableDataSingle[0][
                                                  "reason"
                                                ]
                                              }
                                            </td>
                                          </tr>
                                        </>
                                      ) : null}
                                    </>
                                  ) : null}
                                </tbody>
                              </table>
                            </div>
                          </>
                        )}
                      </div>

                      {/* </form> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Credit Applications"
                        icon="icon ni ni-coin"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          <div class="card" style={{ borderRadius: "0px" }}>
                            <div class="card-inner border-bottom">
                              <div className="row g-gs mb-3">
                                <div className="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label">
                                        Choose Client{" "}
                                        <span class="text-danger"></span>
                                      </label>
                                    </div>
                                    <div class="form-control-group">
                                      <Select
                                        options={this.state.all_active_clients}
                                        name="selected_accountno"
                                        placeholder="Select a client to view their orders."
                                        value={this.state.selected_accountno}
                                        autoComplete="off"
                                        emptyMessage="Clients not found"
                                        onChange={this.handleChangeSearch}
                                        isClearable
                                        isSearchable
                                        components={animatedComponents}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            lineHeight: "40px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <DataTable
                                columns={this.state.columnServices}
                                tableData={this.state.tableData}
                                accountnoFromParams={credit_app_id}
                                log_type={log_type}
                                title=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/**** Open Approve Modal ***/}
                  <div class="modal fade" tabindex="-1" id="modalOpenApprove">
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Approve Credit Application</h5>
                        </div>
                        <div class="modal-body">
                          <div className="row g-gs">
                            <div className="col-md-12 mb-3">
                              {this.state.approveErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger mb-2">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.approveErrorMessage} </p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.approveSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success mb-2">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.approveSuccessMessage} </p>
                                  </div>
                                </div>
                              ) : null}
                              <div class="card card-bordered">
                                <div class="card-inner">
                                  <h5 class="card-title">Approve Credit Application</h5>

                                  <p class="card-text">
                                  Are you certain you wish to grant approval for this credit application?
                                  </p>

                                  <div className="col-md-12">
                                    <div className="form-group text-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          window
                                            .$("#modalOpenApprove")
                                            .modal("hide")
                                        }
                                        className="btn btn-light"
                                        disabled={this.state.disabled}
                                        style={{ marginRight: "15px" }}
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.approveCreditApplication()
                                        }
                                        className="btn btn-success"
                                        disabled={this.state.disabled}
                                        title=""
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Approve Modal ***/}
                  {/**** Open Revoke Modal ***/}
                  <div class="modal fade" tabindex="-1" id="modalOpenRevoke">
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Reject Credit Application</h5>
                        </div>
                        <div class="modal-body">
                          {this.state.revokeErrorMessage !== "" ? (
                            <div class="alert alert-pro alert-danger mb-2">
                              <div class="alert-text">
                                <h6>Error</h6>
                                <p>{this.state.revokeErrorMessage} </p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.revokeSuccessMessage !== "" ? (
                            <div class="alert alert-pro alert-success mb-2">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.revokeSuccessMessage} </p>
                              </div>
                            </div>
                          ) : null}
                          <div id="revokeReasonItems">
                            <div className="tb-odr-id">Reason to reject</div>
                            <div className="card card-bordered card-preview mt-1">
                              <TextareaAutosize
                                maxrows={4}
                                name="revoke_reason"
                                id="revoke_reason"
                                aria-label=""
                                placeholder=""
                                value={this.state.revoke_reason}
                                helperText="Reason to revoke this agreement"
                                onChange={this.handleRevokeValueChange}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  borderColor: "rgba(0, 0, 0, 0.125)",
                                  borderRadius: "4px",
                                }}
                                variant="outlined"
                              />
                            </div>
                            <div className="form-group text-right mt-2 mb-2">
                              {this.state.revoke_button === true ? (
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Loading... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    this.submitCreditAppRejection();
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Revoke Modal ***/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreditApp);
