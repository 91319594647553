import styles from "./styles.module.css";

import SignatureCanvas from "react-signature-canvas";
import { Row, Col, Container } from "react-bootstrap";
import $ from "jquery";

import { saveAgreement } from "../../config/api_calls";
import { REPLACECONTENT } from "../../config/utility";
import { React, Component } from "react";
import HelperClass from "./../../config/helperClass";

import { THEME_COLOR, THEME_TEXT_COLOR } from "../../config/config";

import "./agreement.css";

const auth = new HelperClass();
export default class Agreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      successMessage: "",
    };
  }

  clear = () => {
    this.signaturePad.clear();
    $("#divImgSig").hide();
    $(".clsimgSig").attr("src", "");
    $(".clsimgSig").hide();
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    this.props.agreementSigned();
  };

  save = async () => {
    if (this.signaturePad.isEmpty()) {
      window.$("#modalAlertError").modal("show");
      return this.setState({
        errorMessage:
          "For account activation and further processing. You must sign Master Service Agreement.",
      });
    } else {
      $("#loading_btn").show();
      $("#save_btn").hide();

      this.setState({
        errorMessage: "",
      });
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");

      const apiData = await saveAgreement(
        auth.getAccount(),
        auth.getToken(),
        data,
        this.props.content.replace(
          "VENDOR SIGNATURE DATE SHOWN HERE",
          this.props.published_date
        ),
        this.props.vendor_signor_signature_file
      );
      console.log("MSA: ", this.props.vendor_signor_signature_file);
      if (
        apiData.data.status === 403 ||
        apiData.data.errors === "authentication missing" ||
        apiData.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (apiData.data.status === 404) {
        window.$("#modalAlertError").modal("show");
        return this.setState({
          errorMessage: "We are sorry, something went wrong try later.",
        });
        // this.props.mainHistory.history.push("/error");
        // console.log("errors: ", apiData.data);
      } else if (
        apiData.data.status === 200 &&
        apiData.data.data === "success"
      ) {
        $("#loading_btn").hide();
        $("#save_btn").show();
        window.$("#modalAlert").modal("show");

        this.setState({
          successMessage: "Congratulations!",
        });

        setTimeout(() => {
          window.$("#modalAlert").modal("hide");
          this.props.agreementSigned();
        }, 3000);
      }

      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  preview = () => {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad
        .getTrimmedCanvas()
        .toDataURL("image/svg+xml");
      $("#divSig").show();
      $("#divImgSig").show();
      $(".clsimgSig").show();
      $(".clsimgSig").attr("src", data);
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div
                className="content-page wide-md m-auto"
                style={{ minWidth: "100%" }}
              >
                <div className="nk-block-head nk-block-head-lg wide-sm mx-auto">
                  <div className="nk-block-head-content text-center">
                    <h2 className="nk-block-title fw-normal">
                      {this.props.title}
                    </h2>
                  </div>
                </div>
                <div className="nk-block">
                  <div
                    className="card card-bordered"
                    style={{
                      backgroundColor: this.props.backgroundColor,
                      border: "none",
                    }}
                  >
                    <div className="card-inner card-inner-xl">
                      <div className="entry">
                        <div
                          // className={{
                          //   textAlign: "justify",
                          //   textJustify: "inter-word",
                          // }}
                          dangerouslySetInnerHTML={{
                            __html: this.props.content,
                          }}
                        />
                        {this.props.title !== "" ? (
                          <div className="container-fluid">
                            {/* SUCCESS MODAL START */}
                            <div
                              className="modal fade"
                              tabIndex="-1"
                              id="modalAlert"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <a
                                    href="#"
                                    className="close"
                                    onClick={() => {
                                      this.modalHide();
                                    }}
                                  >
                                    <em className="icon ni ni-cross"></em>
                                  </a>
                                  <div className="modal-body modal-body-lg text-center">
                                    <div className="nk-modal">
                                      <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>
                                      <h4 className="nk-modal-title">
                                        {this.state.successMessage}
                                      </h4>
                                      <div className="nk-modal-text">
                                        <div className="caption-text">
                                          You’ve successfully signed{" "}
                                          <strong>Agreement</strong>
                                        </div>
                                      </div>
                                      <div className="nk-modal-action">
                                        <a
                                          href="#"
                                          className="btn btn-lg btn-mw btn-primary"
                                          onClick={() => {
                                            this.modalHide();
                                          }}
                                        >
                                          OK
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* SUCCESS MODAL End */}
                            {/* ERROR MODAL START */}
                            <div
                              className="modal fade"
                              tabIndex="-1"
                              id="modalAlertError"
                            >
                              <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                  <div className="modal-body modal-body-lg text-center">
                                    <div className="nk-modal">
                                      <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-danger"></em>
                                      <h4 className="nk-modal-title">
                                        Unable to Process!
                                      </h4>
                                      <div className="nk-modal-text">
                                        <p className="lead">
                                          {this.state.errorMessage}
                                        </p>
                                      </div>
                                      <div className="nk-modal-action mt-5">
                                        <a
                                          href="#"
                                          className="btn btn-lg btn-mw btn-light"
                                          data-dismiss="modal"
                                        >
                                          Cancel
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* ERROR MODAL END */}

                            {/* ACTION BUTTONS DIV  START*/}
                            <div id="signature_div">
                              <Row>
                                <Col sm={12}>
                                  <div>
                                    <strong>Sign Here:</strong>
                                  </div>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: "1%" }}>
                                <Col sm={12}>
                                  <SignatureCanvas
                                    clearOnResize={true}
                                    canvasProps={{
                                      height: "400px",
                                      className: styles.sigPad,
                                    }}
                                    velocityFilterWeight={0.7}
                                    throttle={50}
                                    ref={(ref) => {
                                      this.signaturePad = ref;
                                    }}
                                  />
                                </Col>
                              </Row>
                              <Row style={{ marginTop: "1%" }}>
                                <Col sm={12}>
                                  <div style={{ float: "right" }}>
                                    <button
                                      className="btn btn-lg btn-warning"
                                      style={{ margin: "13px 12px 12px 10px" }}
                                      onClick={() => this.clear()}
                                    >
                                      Clear
                                    </button>
                                    <button
                                      className="btn btn-lg btn-success"
                                      style={{ margin: "13px 12px 12px 10px" }}
                                      onClick={() => this.preview()}
                                    >
                                      Preview Signature
                                    </button>
                                    <button
                                      className="btn btn-lg"
                                      type="button"
                                      id="loading_btn"
                                      style={{
                                        display: "none",
                                        backgroundColor: THEME_COLOR,
                                        color: THEME_TEXT_COLOR,
                                      }}
                                      disabled
                                    >
                                      <span
                                        className="spinner-grow spinner-grow-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                      <span> Wait... </span>
                                    </button>
                                    <button
                                      className="btn btn-lg"
                                      style={{
                                        backgroundColor: THEME_COLOR,
                                        color: THEME_TEXT_COLOR,
                                        margin: "13px 12px 12px 10px",
                                      }}
                                      onClick={() => this.save()}
                                      id="save_btn"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            {/* ACTIONS BUTTONS DIV END */}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
