import { React } from "react";
import { Component } from "react";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { listQuestionGroup_single, editQuestionGroup } from "./../../../config/reseller_api_calls";
import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import MutextField from "@material-ui/core/TextField";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
//import { Editor } from "@tinymce/tinymce-react";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";

const auth = new HelperClass();

class RfqQuestionsEditGroup extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      tableLoader: true,
      disabled: false,
      group_id: "",
      group_title: "",
      group_desc: "",
      group_sequence: "",
      group_sequence_error: "",
      status: "",
    };
  }

  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  async componentDidMount() {
    let group_id = this.props.props.match.params.id;
    this.setState({
      errorMessage: "",
      successMessage: "",
      group_id: group_id,
      tableLoader: true,
    });
    const servicesResponce = await listQuestionGroup_single(
      auth.getAccount(),
      auth.getToken(),
      group_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableLoader: false,
        group_id: servicesResponce.data.data.product_id,
        group_title: servicesResponce.data.data.group_title,
        group_desc: servicesResponce.data.data.group_desc,
        status: servicesResponce.data.data.status,
        group_sequence: servicesResponce.data.data.group_sequence,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
    });
  };

  handleEditorProductDesc = (content, editor) => {
    this.setState({
      group_desc: content,
    });
  };

  handleSequenceChange = async (event) => {
    const { target } = event;
    let value = target.value;
    let parsedValue = parseInt(target.value);
    if (!parsedValue || parsedValue < 1 || parsedValue > 9) {
      this.setState({
          group_sequence_error: "The sequence can only be between 1-9",
          group_sequence: value,
      });
    } else {
      this.setState({
          group_sequence_error: "",
          group_sequence: value,
      });
    }
  };

  formSave = async () => {
    window.scrollTo(0, 0);
    let group_id = this.props.props.match.params.id;
    const { group_title, group_desc, status, group_sequence, group_sequence_error } = this.state;
    var validated = true;

    if (group_title === "") {
      validated = false;
    } else if (status === "") {
      validated = false;
    } else if (group_sequence_error !== "") {
      validated = false;
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields correctly.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await editQuestionGroup(
        auth.getAccount(),
        auth.getToken(),
        group_title,
        group_desc,
        status,
        group_id,
        group_sequence
      );

      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (saveResponce.data.status === 404) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while updating the Question Group.",
          successMessage: "",
          disabled: false,
        });
      } else if (saveResponce.data.status === 400 && saveResponce.data.message === "sequence_already_exists") {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "You have already assigned this sequence # to a group.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Question Group updated successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/QuestionsLibrary/groups/");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() =>
                      this.addMenuHistory("/QuestionsLibrary/groups")
                    }
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Question Group
                  </Link>
                  <Typography color="textPrimary" className="">
                    Edit Question Group
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Update Question Group
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="group_title"
                                name="group_title"
                                type="text"
                                label="Group Title"
                                value={this.state.group_title}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          {/* <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="group_desc"
                                name="group_desc"
                                type="text"
                                label="Product  Desc"
                                value={this.state.group_desc}
                                onChange={this.handleChange}
                                inputProps={{ maxLength: 100 }}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div> */}
                          <div className="col-lg-3">
                            <div className="form-group">
                              <MutextField
                                id="status"
                                name="status"
                                select
                                label="Select Status"
                                value={this.state.status}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Group Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="0" value="0">
                                  Inactive
                                </option>
                                <option key="1" value="1">
                                  Active
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-md-3 customStyle-sqnc-addGroup">
                            <div className="form-group">
                              <div className="form-label-group">
                                <label className="form-label">
                                  <Tooltip
                                    title="Set the sequence of this group(e.g 1)."
                                    placement="right"
                                  >
                                    <em
                                      className="icon ni ni-info"
                                      style={{
                                        color: "#6576ff",
                                      }}
                                    ></em>
                                  </Tooltip>{" "}
                                  Sequence #{" "}
                                  <span className="text-danger">*</span>
                                </label>
                              </div>
                              <div className="form-control-group">
                                <MutextField
                                  required
                                  id="group_sequence"
                                  name="group_sequence"
                                  type="number"
                                  value={this.state.group_sequence}
                                  inputProps={{ min: 1, max: 9 }}
                                  onChange={this.handleSequenceChange}
                                  variant="outlined"
                                  fullWidth
                                />
                                {this.state.group_sequence_error !== "" && (
                                    <p style={{ color: 'red' }}>{this.state.group_sequence_error}</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                                <MutextField
                                    id="group_desc"
                                    name="group_desc"
                                    type="text"
                                    label="Short Description"
                                    value={this.state.group_desc}
                                    onChange={this.handleChange}
                                    inputProps={{ maxLength: 100 }}
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                          </div>
                          <div className="col-lg-4"></div>
                          <div style={{ clear: "both" }}></div>

                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RfqQuestionsEditGroup);
