import { React } from "react";
import { Component, Fragment } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import { getAgentCannedEmailPreview } from "./../../../config/api_calls";

import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";

const auth = new HelperClass();

class PreViewEmail extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      email_id: "",
      email_title: "",
      email_subject: "",
      email_template: "",
      tableLoader: false,
      isEditorReady: false,
    };
  }

  async componentDidMount() {
    let email_id = this.props.props.match.params.id;
    console.log(email_id);
    const getEmailResponse = await getAgentCannedEmailPreview(
      auth.getAccount(),
      auth.getToken(),
      email_id
    );

    this.setState({
      email_id: email_id,
    });

    console.log("getEmailResponse agent: ", getEmailResponse.data);
    if (
      getEmailResponse.data.status === 403 ||
      getEmailResponse.data.errors === "authentication missing" ||
      getEmailResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (getEmailResponse.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      getEmailResponse.data.status === 200 &&
      getEmailResponse.data.message === "success"
    ) {
      let emailTemplate = getEmailResponse.data.data[0][3];
      let logoUrl = getEmailResponse.data.data[0].reseller_logo;

      if (emailTemplate && emailTemplate !== "") {
        emailTemplate = emailTemplate.replace(/{{comp_logo}}/g, logoUrl);
        emailTemplate = emailTemplate.replace(/<p>&nbsp;<\/p>/g, "");
      }

      this.setState({
        email_id: getEmailResponse.data.data[0][0],
        email_title: getEmailResponse.data.data[0][1],
        email_subject: getEmailResponse.data.data[0][2],
        email_template: emailTemplate,
        tableLoader: false,
        isEditorReady: true,
      });
    } else {
      //window.location.replace("/error");
    }
  }

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}

          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/CannedEmails")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Canned Emails
                  </Link>
                  <Typography color="textPrimary" className="">
                    Email View
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
          </div>
          <div className="nk-block">
            <div className="card card-bordered">
              <div className="card-inner">
                <div className="email-preview">
                  {this.state.isEditorReady ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.state.email_template,
                      }}
                    />
                  ) : (
                    tableLoader()
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PreViewEmail);
