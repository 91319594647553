const localization = {
  dashboardPage: {
    executiveHeading: "Executive Summary",
    executiveText: "Overall User / System and Revenue Analysis",
    totalRegistered: "Total registered companies",
    totalRegisteredHead: "Total Registered",
    totalActive: "Total active companies",
    totalActiveHead: "Total Active",
    totalPaid: "Total Paid (Inc. Credit)",
    totalPaidHead: "Paid Bills",
    salesOrderTab: "Sales Order",
    quotationsTab: "Quotations",
    rfqTab: "Manage RFQ\'s"
  },
  clientsListing: {
    heading: "Clients",
    allClientsTab: "All Clients",
    allClientsTabSub: "All Clients",
    clientsListingLink1: "View Client",
    clientsListingLink2: "Dashboard",
    pendingInvitations: "Pending Invitations",
    pendingInvitationsSub: "Pending Invitations",
    signupButton: "Signup Link",
    inviteButton: "Invite New Client",
    addNewButton: "Add New Client",
  },
  clientsAgreementsListing: {
    heading: "Client Agreements",
    allAgreementsTab: "Client Agreements",
    allAgreementsTabSub: "Client Agreements",
    agreementsListingLink1: "Edit Agreement",
    agreementsListingLink2: "Delete Agreement",
    archivedTab: "Archived",
    archivedTabSub: "Archived",
    addNewButton: "Add New Agreement",
  },
  clientsRoles: {
    heading: "Client Control Panel",
    mainRolesTab: "Roles",
    mainRolesSub: "Client Roles",
    mainRoleLink: "Edit Role",
    subRoleTab: "Sub Roles",
    subRoleTabSub: "Client Sub Roles",
    subRoleLink: "Edit Sub Role",
    addNewButton: "Add New Role",
    addNewButtonLink1: "New Role",
    addNewButtonLink2: "New Sub Role",
  },
  productListing: {
    heading: "Products & Services",
    addNewButton: "Add New",
    selectServiceType: "Select Service Type",
    selectServiceTypePlaceHolder: "Select a service type to view its services.",
    selectServiceTypeEmpty: "Service type not found",
    listingLink1: "Edit Product",
    listingLink2: "View Product",
    detailsPopupHead: "Product Details",
    detailsPopupOtherServicesHead: "Other Service Rates",
    detailsPopupCancellationHead: "Cancellation Fee",
    detailsPopupCancellationText: "Cancellation Fee",
    detailsPopupDatesHead: "Add / Update Dates",
    detailsPopupDatesAdd: "Date Added",
    detailsPopupDatesUpdate: "Date Updated",
    detailsPopupDescriptionHead: "Description",
    detailsPopupSummary: "Summary",
    detailsPopupMiscHead: "Miscellaneous Rates",
    detailsPopupMarketingHead: "Marketing Link",
    detailsPopupMarketingText: "Marketing Link",
    detailsPopupMarketingLinkOpen: "Click to open link",
    detailsPopupMarketingLinkView: "View PDF",
    detailsPopupIntegration: "3rd Party Integration"
  },
  productTypeListing: {
    heading: "Products & Services Types",
    addNewButton: "Add New",
    listingLink1: "Edit",
    listingLink2: "Default Value",
    listingLink3: "Unarchive",
  },
  //Channel Partner Listing
  agentsListing: {
    heading: "Channel Partners",
    signupButton: "Channel Partner Signup Link",
    inviteButton: "Invite New Channel Partner",
    addNewButton: "Add New Channel Partner",
    allCPTab: "All Channel Partners",
    listingLink1: "Edit Channel Partner",
    listingLink2: "Agreements",
    listingLink3: "Commissions",
    listingLink4: "Sub Channel Partners",
    listingLink5: "Assign Menu",
    listingLink6: "Dashboard",
    invitationTab: "Pending Invitations",
    signupLinkPopupHeading: "Channel Partner Signup Link",
    signupLinkPopupSubHeading1: "Channel Partner Signup Link",
    signupLinkPopupSub1Note: "NOTE: Use this link to invite Channel Partners to join.",
    signupLinkPopupSubHeading2: "Channel Partner Login URL",
    subSignupLinkPopupHeading: "Sub Channel Partner Signup Link",
    subSignupLinkPopupNote: "NOTE: Anyone with this link will be able to create a sub channel partner for this agent. Be very careful where and how you send this! It is also a good idea to make sure you have any supporting documents and contracts in place before you allow the channel partner to have its first sub agent.",
  },
  agentAgreementsListing: {
    heading: "Channel Partner Agreements",
    allAgreementsTab: "Channel Partner Agreements",
    allAgreementsTabSub: "Channel Partner Agreements",
    agreementsListingLink1: "Edit Agreement",
    agreementsListingLink2: "Delete Agreement",
    archivedTab: "Archived",
    archivedTabSub: "Archived",
    addNewButton: "Add New Agreement",
  },
  //Channel Partner Roles
  agentRoles: {
    heading: "Control Panel",
    mainRolesTab: "Roles",
    mainRolesSub: "Channel Partner Roles",
    mainRoleLink: "Edit Role",
    subRoleTab: "Sub Roles",
    subRoleTabSub: "Channel Partner Sub Roles",
    subRoleLink: "Edit Sub Role",
    addNewButton: "Add New Role",
    addNewButtonLink1: "New Role",
    addNewButtonLink2: "New Sub Role",
  },
  quotationListing: {
    heading: "Quotations",
  },
  ordersListing: {
    heading: "Sale Orders",
    addNewButton: "Add New",
    chooseClient: "Choose Client",
    chooseClientPlaceholder: "Select a client to view his order.",
    chooseClientEmpty: "Clients not available"
  },
  orderAddUpdate: {
    heading: "Sale Orders",
    subHeading: "Add New Sale Order",
    clientDetailHead: "ORDER FOR",
    orderDetailHead: "ORDER",
    chooseClientPlaceholder: "Select a client to view his order.",
    chooseClientEmpty: "Clients not available"
  },
  // Add more pages as needed
};

export default localization;