import { React } from "react";
import { Component } from "react";
import { COMPANY_NAME } from "../../../config/config";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import { getCSVData } from "./../../../config/admin_apis";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTable";
import { Link } from "react-router-dom";

import TextField from "../../../components/InputFields/TextField";

import HelperClass from "./../../../config/helperClass";
import { THEME_TEXT_COLOR, THEME_COLOR } from "./../../../config/config";
import SettingIcon from "@material-ui/icons/Settings";
import Tooltip from "@material-ui/core/Tooltip";

import MutextField from "@material-ui/core/TextField";

import Typography from "@material-ui/core/Typography";

import HomeIcon from "@material-ui/icons/Home";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { saveAs } from "file-saver";

const auth = new HelperClass();

const styles = {
  sectionss: {
    marginTop: "0px",
  },
};

export default class AdminSubpoena extends Component {
  constructor() {
    super();
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();
    var datestring_today = new Date().toISOString().slice(0, 10);
    var datestring = new Date().toISOString().slice(0, 10);
    this.state = {
      apiUrl: "",
      apiKey: "",
      authKey: "",
      useraccountno: "",
      reseller_accountno: "",
      clientData: [],
      list_users: [],
      disabled: false,
      disabled_final: false,
      errorMessage: "",
      successMessage: "",
      errorMessaged: "",
      successMessaged: "",
      request_did: "",
      service_type: "did",
      start_date: datestring_today,
      end_date: datestring,
      subscriber_settings: false,
      billing_settings: false,
      other_did_settings: false,
      contract_settings: false,
      client_comm_settings: false,
      payment_settings: false,
      send_email: false,
      email_address: "",
      first_result: "",
      did_data: [],
      ref_number: "",
      did_companies: "",
      subscriber_popup: "",
      billing_popup: "",
      payment_popup: "",
      contracts_popup: "",
      contractName: "",
      e_rate: "",
      sms_in: "0.00",
      sms_out: "0.00",
      e_sms_in: "0.00",
      e_sms_out: "0.00",
      agreement_view: "",
      delete_id: "",
    };
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  async componentDidMount() {
    let page_id = atob(this.props.props.match.params.filename);
    console.log("PageID:", page_id);

    const reportsData = await getCSVData(
      auth.getAdminAccount(),
      auth.getAdminToken(),
      page_id
    );
    console.log("reportsData.data.data: ", reportsData.data.data);

    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.adminlogout();
    } else if (reportsData.data.status === 404) {
      this.setState({
        errorMessage: "There is some error.",
      });
      //window.location.replace("/error");
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      var url = reportsData.data.data;
      const response = await fetch(url);
      const data = await response.text();
      const blob = new Blob([data], { type: "data:text/csv;charset=utf-8," });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement("a");
      anchor.download = page_id;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        "text/csv",
        anchor.download,
        anchor.href,
      ].join(":");
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);

      setTimeout(() => {
        window.location.replace("/admin_subpoena");
      }, 2000);
    } else {
      this.setState({
        errorMessage: "There is some error.",
      });
    }
  }

  render() {
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.state.errorMessage !== "" ? (
            <div className="example-alert">
              <div className="alert alert-pro alert-danger">
                <div className="alert-text">
                  <h4>Error</h4>
                  <p>{this.state.errorMessage}</p>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}
