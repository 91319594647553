import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { COMPANY_NAME, LOGO, THEME_COLOR } from "../../../config/config";

import { setNewPassword } from "../../../config/api_calls";

import { ValidateEmail, axiosConfig } from "../../../config/utility";

import { useJwt, isExpired, decodeToken } from "react-jwt";

export default class PasswordSetting extends Component {
  constructor() {
    super();
    this.state = {
      password: "",
      confirm_password: "",
      errorPass: "",
      errorConfirmPass: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
      tokenExpired: false,
    };
  }

  componentDidMount = async () => {
    let isMyTokenExpired = isExpired(this.props.match.params.token_code);
    console.log("isMyTokenExpired: ", isMyTokenExpired);
    if (isMyTokenExpired === true) {
      this.setState({
        tokenExpired: isMyTokenExpired,
        errorMessage: "Your forgot password activation link is expired now.",
      });
    }
    let myDecodedToken = decodeToken(this.props.match.params.token_code);
    console.log("email: ", myDecodedToken.email);
  };

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  formSubmit = async () => {
    const { password, confirm_password } = this.state;
    if (password === "") {
      return this.setState({
        errorPass: "error",
        errorMessage: "Password is required.",
      });
    } else if (password.length < 8) {
      return this.setState({
        errorPass: "error",
        errorMessage: "Minimum 8 digits required.",
      });
    } else if (confirm_password === "") {
      return this.setState({
        errorPass: "",
        errorConfirmPass: "error",
        errorMessage: "Confirm password is required.",
      });
    } else if (password !== confirm_password) {
      return this.setState({
        errorPass: "",
        errorConfirmPass: "error",
        errorMessage: "Password & confirm password not match",
      });
    } else {
      this.setState({
        errorPass: "",
        errorConfirmPass: "",
        errorMessage: "",
        disabled: true,
      });
      let myDecodedToken = decodeToken(this.props.match.params.token_code);

      console.log("password: ", password);
      let postSetPass = await setNewPassword(
        myDecodedToken.email,
        password,
        this.props.match.params.token_code
      );
      console.log("postSetPass: ", postSetPass.data);
      if (
        postSetPass.data.status === 200 &&
        postSetPass.data.message === "Success"
      ) {
        this.setState({
          successMessage: "Your new password is successfully set",
          errorMessage: "",
        });
        setTimeout(() => {
          window.location.replace("/");
        }, 2000);
      } else if (
        postSetPass.data.status === 404 ||
        postSetPass.data.errors === "Invalid User"
      ) {
        this.setState({
          successMessage: "",
          errorMessage: "Error",
          disabled: false,
        });
      } else if (
        (postSetPass.data.status === 403 &&
          postSetPass.data.errors === "authentication missing") ||
        postSetPass.data.errors === "jwt expired"
      ) {
        this.setState({
          successMessage: "",
          errorMessage: "Your forgot password activation link is expired now.",
          disabled: false,
        });
      }
    }
  };

  render() {
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn-white btn btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
                <div className="brand-logo pb-5">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">
                      {this.state.tokenExpired === false
                        ? "Set your password"
                        : "Link Expired"}
                    </h5>
                    <br />

                    <div className="nk-block-des">
                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                    </div>
                  </div>
                </div>

                {this.state.tokenExpired === false ? (
                  <div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          tabIndex="-1"
                          href="#"
                          className="form-icon form-icon-right passcode-switch"
                          data-target="password"
                        >
                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                        </a>
                        <input
                          type="password"
                          className={
                            "form-control form-control-lg " +
                            this.state.errorPass
                          }
                          id="password"
                          name="password"
                          onChange={this.handleChange}
                          value={this.state.password}
                          placeholder="Enter your password"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          Confirm Password
                        </label>
                      </div>
                      <div className="form-control-wrap">
                        <a
                          tabIndex="-1"
                          href="#"
                          className="form-icon form-icon-right passcode-switch"
                          data-target="confirm_password"
                        >
                          <em className="passcode-icon icon-show icon ni ni-eye"></em>
                          <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                        </a>
                        <input
                          type="password"
                          className={
                            "form-control form-control-lg " +
                            this.state.errorConfirmPass
                          }
                          id="confirm_password"
                          name="confirm_password"
                          onChange={this.handleChange}
                          value={this.state.confirm_password}
                          placeholder="Confirm your password"
                        />
                      </div>
                    </div>{" "}
                    <div className="form-group">
                      <button
                        className="btn btn-lg btn-block"
                        onClick={() => this.formSubmit()}
                        disabled={this.state.disabled}
                        style={{ backgroundColor: THEME_COLOR, color: "white" }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="nk-block nk-auth-footer">
                <div className="mt-3">
                <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":false, "arrows":false}'
                >
                  <div className="nk-feature nk-feature-center">
                    <div className="nk-feature-img">
                      <img className="round" src={PROMO_A} alt="" />
                    </div>
                    <div className="nk-feature-content py-4 p-sm-5">
                      <h4>{COMPANY_NAME}</h4>
                      <p>
                        You can start to create your products easily with its
                        user-friendly design & most completed responsive layout.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
