import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { addSettings } from "../../../actions";
import {
  listAllAgentAgreements,
  listAllAgents,
  revokeAgentSignedAgreements,
  approveAgentSignedAgreement,
  getAgentAgreementSigneeDetails,
} from "../../../config/signed_agreements_api_calls";
import {
  THEME_TEXT_COLOR,
  AGENT_PORTAL_URL,
  AGENT_SERVER_URL,
} from "./../../../config/config";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "../../../config/helperClass";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Agreements extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      errorMessage: "",
      successMessage: "",
      revokeErrorMessage: "",
      revokeSuccessMessage: "",
      revoke_button: false,
      approveErrorMessage: "",
      approveSuccessMessage: "",
      approve_button: false,
      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      tableLoader: true,
      pdfurl: "",
      unsigned_agreements: "",
      selected_accountno: null,
      all_active_agents: [],
      choose_accountno: "",
      agent_accountno: "",
      selected_agent_accountno: "",
      selected_agreement_id: "",
      revoke_reason: "",
      signee_details: [],
      add_picture: "",
      add_picture_name: "",

      columnServices: [
        { name: "agreement_title", label: "Agreement" },
        {
          name: "agent_title", // Add this new column
          label: "Channel Partner",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.getClientEmail(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "signed_date",
          label: "Date Signed",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "is_signed",
          label: "Signed",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.signedText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "approved",
          label: "Approved",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.approvedText(value, tableMeta)}</div>;
            },
          },
        },

        {
          name: "is_mandatory",
          label: "Mandatory",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.mandatoryText(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "msa_pdf_path",
          label: "Download PDF",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(tableMeta)}</div>;
            },
          },
        },
        {
          name: "accountno",
          label: "Acccount#",
          options: {
            display: "false",
          },
        },

        {
          name: "agreements_id",
          label: "ID",
          options: {
            display: false,
          },
        },
        {
          name: "agreement_id",
          label: "Action",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              {tableMeta.rowData[3] === "Yes" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    data-toggle="tooltip"
                                    data-placement="left"
                                    onClick={() =>
                                      this.openRevoke(value, tableMeta)
                                    }
                                    title="Revoke channel partner agreement"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em class="icon ni ni-na"></em>
                                    <span>Revoke</span>
                                  </a>
                                </li>
                              ) : null}
                              {tableMeta.rowData[4] === "No" &&
                              tableMeta.rowData[3] === "Yes" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.openApprove(value, tableMeta)
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Approve phannel partner agreement"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em class="icon ni ni-check-round-cut"></em>
                                    <span>Approve</span>
                                  </a>
                                </li>
                              ) : null}
                              {tableMeta.rowData[3] === "Yes" ? (
                                <li>
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.openSigneeDetails(value, tableMeta)
                                    }
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Show signee details"
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em class="icon ni ni-table-view"></em>
                                    <span>Signee Details</span>
                                  </a>
                                </li>
                              ) : null}
                              <li>
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.openUploadContract(value, tableMeta)
                                  }
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Upload new contract"
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                >
                                  <em class="icon ni ni-upload"></em>
                                  <span>Upload Agreement</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  signedText = (value, tableMeta) => {
    //console.log("tableMeta.rowData: ", tableMeta.rowData[9]);
    let is_default = value;
    if (is_default === "Yes") {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    }
  };

  mandatoryText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-warning">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  approvedText = (value, tableMeta) => {
    let is_default = value;
    if (is_default === "No") {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-na"></em> No
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Yes
        </span>
      );
    }
  };

  getClientEmail = (value, tableMeta) => {
    if (value && value !== "") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: value,
          }}
        />
      );
    }
    return "---";
  };

  viewDownloadBtns = (tableMeta) => {
    const rowIndex = tableMeta.rowIndex;
    const isSigned = tableMeta.rowData[3];
    
    if (isSigned === "Yes") {
      const isLoading = this.state.downloadingIndex === rowIndex;
  
      return (
        <div>
          <a
            onClick={() => {
              this.setState({ downloadingIndex: rowIndex });
  
              // Start loader and stop after 1 second
              setTimeout(() => {
                this.setState({ downloadingIndex: null });
                // Start download after loader stops
                this.downloadPdf(tableMeta.rowData[6], tableMeta.rowData[0]);
              }, 2000);
            }}
            disabled={isSigned === "No"}
            style={{ cursor: "pointer", color: "#006393" }}
          >
            {isLoading ? (
              <div className="text-left">
                <div className="spinner-border spinner-border-sm text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <span className="badge badge-outline-primary">
                <em className="icon ni ni-download" style={{ fontSize: "18px" }}></em>
              </span>
            )}
          </a>{" "}
        </div>
      );
    } else {
      return "---";
    }
  };

  async componentDidMount() {
    const servicesResponce = await listAllAgentAgreements(
      auth.getAccount(),
      auth.getToken()
    );
    /*console.log(
      "servicesResponce.data: ",
      process.env.REACT_APP_API_URL + "public/msa_agreements/"
    );*/
    console.log("signedAgreements: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
    this.listAllActiveAgents();
  }

  listAllActiveAgents = async () => {
    const servicesResponce = await listAllAgents(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listAllActiveAgents: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The question not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_agents = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][1] +
              " (" +
              servicesResponce.data.data[i][0] +
              ")",
            value: servicesResponce.data.data[i][2],
          };
          all_agents.push(newArr);
        }
      }

      this.setState({
        all_active_agents: all_agents,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while deleting the question.",
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          agent_accountno: changed_value.value,
          selected_accountno: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.agent_accountno);
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          agent_accountno: null,
          selected_accountno: null,
        },
        () => {
          console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    console.log("Client Account Number: ", this.state.agent_accountno);
    const servicesResponce = await listAllAgentAgreements(
      auth.getAccount(),
      auth.getToken(),
      this.state.agent_accountno
    );
    console.log("getAgentAgreement: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  setMarkUp = (value, tableMeta) => {
    let pdf_path = tableMeta.rowData[2];
    console.log("pdf_path: ", pdf_path);
    if (tableMeta.rowData[1] === "Yes") {
      this.setState({
        pdfurl:
          process.env.REACT_APP_API_URL + "public/msa_agreements/" + pdf_path,
      });
    } else {
      this.setState({
        pdfurl: "",
      });
    }
    console.log(this.state.pdfurl);
    return this.state.pdfurl;
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".zip",
      ".txt",
      ".rtf",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  modalHide = () => {
    window.$("#modalForm").modal("hide");
  };

  format_date = (value, tableMeta) => {
    let date = value;
    if(date && date !== ""){
      return dateFormat(date, "mmmm dS, yyyy");
    }
    return "---";
  };

  showModal = () => {
    window.$("#modalForm").modal("show");
  };

  getNewFileName = (originalFileName, file_title) => {
    // Extract the file extension
    const fileExtension = originalFileName.split(".").pop();

    // Remove spaces and special characters and replace with hyphens
    const cleanedFileName = file_title
      .replace(/[^\w\s.-]/gi, "") // Remove special characters except for hyphen and dot
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .toLowerCase(); // Convert to lowercase

    // Combine the cleaned file name with the original file extension
    const newFileName = `${cleanedFileName}.${fileExtension}`;

    return newFileName;
  };

  downloadPdf = async (d_f_name, file_title) => {
    var file_name = this.getNewFileName(d_f_name, file_title);
    if (d_f_name !== "") {
      let url = AGENT_PORTAL_URL + "files_data/agreements/" + d_f_name;

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = file_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage:
              "There is some error while downloading the attachment.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    } else {
      this.setState({
        errorMessage: "File not found.",
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  handleRevokeValueChange = (event) => {
    const { target } = event;
    const value = target.value;

    this.setState({
      revoke_reason: value,
    });
  };

  openRevoke = async (value, tableMeta) => {
    console.log("openRevoke: ", tableMeta.rowData[9]);
    let value_array = tableMeta.rowData[9].split("###");
    let selected_agent_accountno = value_array[1];
    let selected_agreement_id = value_array[0];
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      revokeSuccessMessage: "",
      revokeErrorMessage: "",
      revoke_reason: "",
      disabled: false,
    });
    window.$("#modalOpenRevoke").modal("show");
  };

  handleAgentAgreementRevokeSubmit = async () => {
    const { selected_agent_accountno, selected_agreement_id, revoke_reason } =
      this.state;
    //console.log('handleAgentAgreementRevokeSubmit',agent_id, agreement_id, revoke_reason, agreement_accountno);
    let is_validated = true;
    if (revoke_reason === "") {
      is_validated = false;
      this.setState({
        errorCompName: "error",
        revokeErrorMessage: "Revoke Reason is required.",
      });
    }
    if (is_validated === true) {
      this.setState({
        revokeSuccessMessage: "",
        revokeErrorMessage: "",
        disabled: true,
      });

      const servicesResponce = await revokeAgentSignedAgreements(
        auth.getAccount(),
        auth.getToken(),
        selected_agent_accountno,
        selected_agreement_id,
        revoke_reason
      );
      console.log(
        "handleAgentAgreementRevokeSubmit.data: ",
        servicesResponce.data
      );
      this.setState({
        disabled: false,
      });
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
        return;
      } else if (servicesResponce.data.status === 406) {
        this.setState({
          revokeErrorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          revokeErrorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          revokeSuccessMessage: "Agreement is revoked successfully",
          revoke_reason: "",
          isRevokeReasonActive: false,
        });
        this.reloadClients();
        setTimeout(function () {
          window.$("#modalOpenRevoke").modal("hide");
        }, 4000);
      } else {
        this.setState({
          revokeErrorMessage:
            "There is an error while revoking an agreement. Please try again.",
        });
      }
    }
    setTimeout(() => {
      this.setState({ revokeSuccessMessage: "", revokeErrorMessage: "" });
    }, 4000);
  };

  openApprove = async (value, tableMeta) => {
    console.log("openRevoke: ", tableMeta.rowData[9]);
    let value_array = tableMeta.rowData[9].split("###");
    let selected_agent_accountno = value_array[1];
    let selected_agreement_id = value_array[0];
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      approveErrorMessage: "",
      approveSuccessMessage: "",
      disabled: false,
    });
    window.$("#modalOpenApprove").modal("show");
  };

  approveAgreement = async (agent_id) => {
    const { selected_agreement_id, selected_agent_accountno } = this.state;
    this.setState({
      approveErrorMessage: "",
      approveSuccessMessage: "",
      disabled: true,
    });

    const servicesResponce = await approveAgentSignedAgreement(
      auth.getAccount(),
      auth.getToken(),
      selected_agent_accountno,
      selected_agreement_id
    );
    console.log("approveAgreement.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        approveErrorMessage:
          "There is an error while approving an agreement. Please try again.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        approveErrorMessage:
          "There is an error while approving an agreement. Please try again.",
        disabled: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.reloadClients();
      this.setState({
        approveSuccessMessage: "Agreement approved successfully",
        disabled: false,
      });
      setTimeout(function () {
        window.$("#modalOpenApprove").modal("hide");
      }, 4000);
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while approving an agreement. Please try again.",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ approveSuccessMessage: "", approveErrorMessage: "" });
    }, 4000);
  };

  openSigneeDetails = async (value, tableMeta) => {
    console.log("openRevoke: ", tableMeta.rowData[9]);
    let value_array = tableMeta.rowData[9].split("###");
    let selected_agent_accountno = value_array[1];
    let selected_agreement_id = value_array[0];
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      signee_details: [],
    });
    const servicesResponce = await getAgentAgreementSigneeDetails(
      auth.getAccount(),
      auth.getToken(),
      selected_agent_accountno,
      selected_agreement_id
    );
    console.log("openSigneeDetails.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
      return;
    } else if (servicesResponce.data.status === 406) {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "signee_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Signee details not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "agent_not_found"
    ) {
      this.setState({
        approveErrorMessage: "Channel partner not found.",
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        signee_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the signee details. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalOpenSignee").modal("show");
  };

  openUploadContract = async (value, tableMeta) => {
    console.log("openUploadContract: ", tableMeta.rowData[9]);
    let value_array = tableMeta.rowData[9].split("###");
    let selected_agent_accountno = value_array[1];
    let selected_agreement_id = value_array[0];
    this.setState({
      selected_agent_accountno: selected_agent_accountno,
      selected_agreement_id: selected_agreement_id,
      uploadErrorTitle: "",
      uploadErrorMessage: "",
      uploadSuccessMessage: "",
      upload_agreement_button: false,
      add_picture: "",
      add_picture_name: "",
    });
    window.$("#modalOpenUploadContract").modal("show");
  };

  submitAgentContract = async () => {
    let {
      selected_agent_accountno,
      selected_agreement_id,
      add_picture,
      add_picture_name,
    } = this.state;
    this.setState({
      upload_agreement_button: true,
    });
    if (add_picture_name === "") {
      this.setState({
        uploadErrorMessage: "Agreement file is required.",
        uploadErrorTitle: "Missing Fields",
        upload_agreement_button: false,
      });
      setTimeout(() => {
        this.setState({
          uploadErrorTitle: "",
          uploadErrorMessage: "",
        });
      }, 4000);
      return false;
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("agent_id", Number(selected_agent_accountno));
      formData.append("agreement_id", Number(selected_agreement_id));

      try {
        axios
          .post(
            AGENT_SERVER_URL +
              "signed_agreements_external_api_calls/upload_agent_agreement",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (
              response.data.status === 404 &&
              response.data.message === "no_record"
            ) {
              this.setState({
                uploadErrorMessage: "Record not found.",
                uploadErrorTitle: "System Error",
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (response.data.status === 403) {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage: response.data.message,
                upload_agreement_button: false,
                uploadSuccessMessage: "",
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                uploadSuccessMessage: "Agreement added successfully.",
                uploadErrorMessage: "",
                uploadErrorTitle: "",
                upload_agreement_button: true,
              });
              this.reloadClients();
              setTimeout(() => {
                window.$("#modalOpenUploadContract").modal("hide");
              }, 4000);
            } else {
              this.setState({
                uploadErrorTitle: "System Error",
                uploadErrorMessage:
                  "There is some error while adding the agreement.",
                uploadSuccessMessage: "",
                upload_agreement_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          uploadErrorTitle: "System Error",
          uploadErrorMessage: "There is some error while adding the agreement.",
          disabled: false,
          uploadSuccessMessage: "",
          upload_agreement_button: false,
        });

        console.log("catch : ", error);
      }
      setTimeout(() => {
        this.setState({
          uploadErrorMessage: "",
          uploadErrorTitle: "",
        });
      }, 4000);
    }
  };

  render() {
    const searchParams = new URLSearchParams(window.location.search);
    const accountno = searchParams.get("accountno");
    const agreement_accountno = searchParams.get("agreement_accountno");
    const log_type = searchParams.get("log_type");
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Channel Partner Agreements"
                        icon="icon ni ni-file-docs"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content">
                    <Link
                      to="/agreements/unsignedAgreements"
                      className="unSignedAgreement-link"
                    >
                      {this.state.unsigned_agreements === 0 ? (
                        <>
                          <span className="badge badge-outline-dark">
                            <em className="icon ni ni-done"></em> No Unsigned
                            Agreements
                          </span>
                        </>
                      ) : this.state.unsigned_agreements > 0 ? (
                        <>
                          <span className="badge badge-outline-danger">
                            <em className="icon ni ni-na"></em>{" "}
                            {this.state.unsigned_agreements} Unsigned Agreements
                          </span>
                        </>
                      ) : null}
                    </Link>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      <div class="card" style={{ borderRadius: "0px" }}>
                        <div class="card-inner border-bottom">
                          <div className="row g-gs mb-3">
                            <div className="col-md-6">
                              <div class="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Choose Channel Partner{" "}
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div class="form-control-group">
                                  <Select
                                    options={this.state.all_active_agents}
                                    name="selected_accountno"
                                    placeholder="Select a channel partner to view their orders."
                                    value={this.state.selected_accountno}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "40px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <DataTable
                          columns={this.state.columnServices}
                          log_type={log_type}
                          accountnoFromParams={accountno}
                          agreement_accountno={agreement_accountno}
                          tableData={this.state.tableData}
                          title="Signed Agreements"
                        />
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* DETAIL MODAL START */}
                  <div className="modal fade" tabIndex="-1" id="modalForm">
                    <div className="modal-dialog modal-lg" role="document">
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <h4 className="nk-modal-title">Download PDF</h4>
                            <div className="nk-modal-text">
                              <div className="caption-text"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* DETAIL MODAL End */}
                  {/**** Open Revoke Modal ***/}
                  <div class="modal fade" tabindex="-1" id="modalOpenRevoke">
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Revoke Agreement</h5>
                        </div>
                        <div class="modal-body">
                          {this.state.revokeErrorMessage !== "" ? (
                            <div class="alert alert-pro alert-danger mb-2">
                              <div class="alert-text">
                                <h6>Error</h6>
                                <p>{this.state.revokeErrorMessage} </p>
                              </div>
                            </div>
                          ) : null}
                          {this.state.revokeSuccessMessage !== "" ? (
                            <div class="alert alert-pro alert-success mb-2">
                              <div class="alert-text">
                                <h6>Success</h6>
                                <p>{this.state.revokeSuccessMessage} </p>
                              </div>
                            </div>
                          ) : null}
                          <div id="revokeReasonItems">
                            <div className="tb-odr-id">Reason to revoke</div>
                            <div className="card card-bordered card-preview mt-1">
                              <TextareaAutosize
                                maxrows={4}
                                name="revoke_reason"
                                id="revoke_reason"
                                aria-label=""
                                placeholder=""
                                value={this.state.revoke_reason}
                                helperText="Reason to revoke this agreement"
                                onChange={this.handleRevokeValueChange}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  borderColor: "rgba(0, 0, 0, 0.125)",
                                  borderRadius: "4px",
                                }}
                                variant="outlined"
                              />
                            </div>
                            <div className="form-group text-right mt-2 mb-2">
                              {this.state.revoke_button === true ? (
                                <button
                                  class="btn btn-primary"
                                  type="button"
                                  disabled
                                >
                                  <span
                                    class="spinner-grow spinner-grow-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  <span> Loading... </span>
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={() => {
                                    this.handleAgentAgreementRevokeSubmit();
                                  }}
                                  disabled={this.state.disabled}
                                >
                                  Submit
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Revoke Modal ***/}
                  {/**** Open Approve Modal ***/}
                  <div class="modal fade" tabindex="-1" id="modalOpenApprove">
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Approve Agreement</h5>
                        </div>
                        <div class="modal-body">
                          <div className="row g-gs">
                            <div className="col-md-12 mb-3">
                              {this.state.approveErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger mb-2">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.approveErrorMessage} </p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.approveSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success mb-2">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.approveSuccessMessage} </p>
                                  </div>
                                </div>
                              ) : null}
                              <div class="card card-bordered">
                                <div class="card-inner">
                                  <h5 class="card-title">Approve Agreement</h5>

                                  <p class="card-text">
                                    Are you sure you want to approve this
                                    agreement?
                                  </p>

                                  <div className="col-md-12">
                                    <div className="form-group text-right">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          window
                                            .$("#modalOpenApprove")
                                            .modal("hide")
                                        }
                                        className="btn btn-light"
                                        disabled={this.state.disabled}
                                        style={{ marginRight: "15px" }}
                                      >
                                        No
                                      </button>
                                      <button
                                        type="button"
                                        onClick={() =>
                                          this.approveAgreement(
                                            this.state.agent_id
                                          )
                                        }
                                        className="btn btn-success"
                                        disabled={this.state.disabled}
                                        title=""
                                      >
                                        Yes
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Approve Modal ***/}
                  {/**** Open Signee Details Modal ***/}
                  <div class="modal fade" tabindex="-1" id="modalOpenSignee">
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Signee Details</h5>
                        </div>
                        <div class="modal-body">
                          <div class="g-4">
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Name</span>
                                    </th>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Email</span>
                                    </th>
                                    <th class="tb-col-os">
                                      <span class="overline-title">Title</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Signed Date
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.signee_details &&
                                  this.state.signee_details.length > 0 ? (
                                    <>
                                      {this.state.signee_details.map(
                                        (details, index) => (
                                          <tr key={`idx${index}`}>
                                            <td class="tb-col-os">
                                              {details.signee_name}
                                            </td>
                                            <td class="tb-col-os">
                                              {details.signee_email}
                                            </td>
                                            <td class="tb-col-os">
                                              {details.signee_title &&
                                              details.signee_title !== ""
                                                ? details.signee_title
                                                : "---"}
                                            </td>
                                            <td class="tb-col-ip">
                                              {details.signed_date &&
                                              details.signed_date !== ""
                                                ? this.format_date(
                                                    details.signed_date
                                                  )
                                                : "---"}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td class="tb-col-os" colSpan={4}>
                                        <strong className="text-danger">
                                          Record not found
                                        </strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Signee Details Modal ***/}
                  {/**** Open Contract Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalOpenUploadContract"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Upload Agreement</h5>
                        </div>
                        <div class="modal-body">
                          <div className="row g-gs">
                            <div className="col-md-12 mb-3">
                              {this.state.uploadErrorMessage !== "" ? (
                                <div class="alert alert-pro alert-danger mb-2">
                                  <div class="alert-text">
                                    <h6>Error</h6>
                                    <p>{this.state.uploadErrorMessage} </p>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.uploadSuccessMessage !== "" ? (
                                <div class="alert alert-pro alert-success mb-2">
                                  <div class="alert-text">
                                    <h6>Success</h6>
                                    <p>{this.state.uploadSuccessMessage} </p>
                                  </div>
                                </div>
                              ) : null}

                              <div
                                className="col-md-12 mb-2"
                                style={{ marginBottom: "20px", clear: "both" }}
                              >
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      <Tooltip
                                        title="Upload agreement directly."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Upload Agreement <sup>*</sup>
                                      <span className="text-danger"></span>
                                    </label>
                                  </div>
                                  <div className="form-control-group signatory_upload mb-4">
                                    <div class="custom-file">
                                      <input
                                        type="file"
                                        class="custom-file-input"
                                        id="add_picture"
                                        name="add_picture"
                                        defaultValue={this.state.add_picture}
                                        accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .txt, .rtf, .zip"
                                        onChange={this.handleFileChange}
                                      />
                                      <label
                                        class="custom-file-label"
                                        for="add_picture"
                                      >
                                        Choose file
                                      </label>
                                    </div>
                                  </div>
                                  <div class="form-note">
                                    {this.state.add_picture_name}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 mt-5">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        Accepted Formats: PDF, DOC, DOCX, ZIP,
                                        TXT, RTF, PNG, JPEG, JPG
                                      </li>
                                      <li>Recommended Size: 10MB</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          class="modal-footer"
                          style={{ justifyContent: "right" }}
                        >
                          <div class="d-flex justify-content-end">
                            {this.state.upload_agreement_button === true ? (
                              <button
                                className="btn btn-lg btn-primary"
                                disabled
                              >
                                <em className="icon ni ni-swap"></em> Saving
                                <div
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </button>
                            ) : (
                              <button
                                type="button"
                                class="btn btn-lg btn-primary"
                                onClick={() => this.submitAgentContract()}
                              >
                                Save Agreement
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Contract Modal ***/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agreements);
