import { React } from "react";
import { Component, Fragment } from "react";
import axios from "axios";
import { axiosConfig } from "../../../config/utility";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import CreateIcon from "@material-ui/icons/Create";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  addClientAgreement,
  getSignatorySettings,
  listClientAgreements,
  getClientAgreement,
  getAgreementSequence,
  listClients,
} from "./../../../config/api_calls";

import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  AGENT_SERVER_URL,
} from "./../../../config/config";

import Step1 from "../../../components/Agents/Step1";
import Step2 from "../../../components/Agents/Step2";
import Step3 from "../../../components/Agents/Step3";

import TextField from "../../../components/InputFields/TextField";
import SelectField from "../../../components/InputFields/SelectField";
import MutextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FormControlLabel from "@material-ui/core/FormControlLabel";

//import { CKEditor } from "@ckeditor/ckeditor5-react";
//import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import RichTextEditorComponent from "../../../components/Reseller/RichTextEditorComponent";

import Checkbox from "@material-ui/core/Checkbox";
//import { Editor } from "@tinymce/tinymce-react";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Addagreement extends Component {
  constructor() {
    super();
    this.state = {
      errorMessaged: "",
      errorMessage: "",
      successMessaged: "",
      successMessage: "",
      agent_email: auth.getEmail(),
      agreement_title: "",
      agreement_content: "",
      agreement_instruction: "",
      max_seqno: 1,
      all_seqno: [],
      //agreement_type: "msa",
      agreement_type: "esign",
      service_type: "main",
      is_default: 0,
      isactive: 1,
      issignup: 1,
      ismandatory: 1,
      ispublished: 0,
      tableData: [],
      signatoryDetails: [],
      signatory_id: "",
      signatory_details: null,
      copiedBadge: "",
      showTooltip: false,
      add_agreement_type: "new",
      all_active_agreements: [],
      selected_agreement: "",
      selected_agreement_array: null,
      clientsList: [],
      selectedClients: [],
      selectAll: false,
      isEditorReady: false,
      isLocked: false,
    };
  }
  viewBtns = () => {
    if (
      this.props.is_msa_signed === "No" ||
      this.props.defaultSettings === "missing"
    ) {
      return <VisibilityOffIcon />;
    } else {
      return <VisibilityIcon style={{ cursor: "pointer" }} />;
    }
  };
  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    this.listClients();
    this.get_signatory_settings();
    this.getAllAgreements();
    this.getAgreementSequence();
    this.setState({ isEditorReady: true });
  }

  get_signatory_settings = async () => {
    const notesResponce = await getSignatorySettings(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("get_signatory_settings: ", notesResponce.data.data);

    if (
      notesResponce.data.status === 403 ||
      notesResponce.data.errors === "authentication missing" ||
      notesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (notesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      notesResponce.data.status === 200 &&
      notesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (notesResponce.data.data && notesResponce.data.data.length > 0) {
        for (let i = 0; i < notesResponce.data.data.length; i++) {
          let newArr = {
            label:
              notesResponce.data.data[i]["name"] +
              " (" +
              notesResponce.data.data[i]["company"] +
              ")",
            value: notesResponce.data.data[i]["id"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        signatoryDetails: all_clients,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  getAllAgreements = async () => {
    const servicesResponce = await listClientAgreements(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("Agreement Res: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_agreements = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][1],
            value: servicesResponce.data.data[i][7],
          };
          all_agreements.push(newArr);
        }
      }
      this.setState({
        all_active_agreements: all_agreements,
        tableLoader: false,
      });
    } else {
      //window.location.replace("/error");
    }
  };

  getClientAgreement = async (agreement_id) => {
    if (agreement_id && agreement_id !== "") {
      const servicesResponce = await getClientAgreement(
        auth.getAccount(),
        auth.getToken(),
        agreement_id
      );

      console.log("getClientAgreement: ", servicesResponce.data);
      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        //window.location.replace("/error");
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          agreement_title: servicesResponce.data.data[0][0],
          agreement_instruction: servicesResponce.data.data[0][3],
          isactive: servicesResponce.data.data[0][1],
          ismandatory: servicesResponce.data.data[0][2],
          //agreement_content: servicesResponce.data.data[0][4],
          agreement_type: servicesResponce.data.data[0][6],
          signatory_id: servicesResponce.data.data[0][7],
          initial_agreement_content: servicesResponce.data.data[0][4],
          tableLoader: false,
        });
        this.setState(
          {
            agreement_content: servicesResponce.data.data[0][4],
            isEditorReady: false,
          },
          () => {  
            setTimeout(() => {
              this.setState({ isEditorReady: true });
            }, 500);
          }
        );
      } else {
        //window.location.replace("/error");
      }
    } else {
      this.setState({
        isactive: 1,

        ismandatory: 1,
        agreement_title: "",
        agreement_instruction: "",
        agreement_content: "",
        agreement_type: "esign",
        signatory_id: "",
        initial_agreement_content: "",
        tableLoader: false,
      });
    }
  };

  getAgreementSequence = async () => {
    const servicesResponce = await getAgreementSequence(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getAgreementSequence: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        max_seqno: 1,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        max_seqno: servicesResponce.data.max_seqno,
        all_seqno: servicesResponce.data.all_seqno,
        tableLoader: false,
      });
    } else {
      this.setState({
        max_seqno: 1,
      });
      //window.location.replace("/error");
    }
  };

  listClients = async () => {
    const listAgentsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("listClientsResponce: ", listAgentsResponce.data.data);
    if (
      listAgentsResponce.data.status === 403 ||
      listAgentsResponce.data.errors === "authentication missing" ||
      listAgentsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentsResponce.data.status === 200 &&
      listAgentsResponce.data.message === "success"
    ) {
      let agents = listAgentsResponce.data.data.map((agent) => ({
        value: agent[0],
        label: agent[1] + "(" + agent[2] + ")",
      }));
      console.log("agentsList agents: ", agents);
      /*this.setState({
        clientsList: agents,
        selectedClients: agents,
      });*/
      this.setState({
        clientsList: agents,
        selectedClients: [],
      });
    } else {
      // window.location.replace("/error");
    }
  };

  /*handleSelectChange = (selectedOptions) => {
    this.setState({ selectedClients: selectedOptions }, () => {
      let temp = false;
      if (this.state.clientsList.length === this.state.selectedClients.length) {
        temp = true;
      }
      this.setState({ selectAll: temp });
    });
  };*/

  handleSelectChange = (selectedOptions) => {
    if (!this.state.isLocked) {
      this.setState({ selectedClients: selectedOptions }, () => {
        let temp = false;
        if (this.state.clientsList.length === this.state.selectedClients.length) {
          temp = true;
        }
        this.setState({ selectAll: temp });
      });
    }
  };

  /*handleSelectAllChange = () => {
    const { clientsList, selectAll } = this.state;
    if (!selectAll) {
      this.setState({
        selectedClients: clientsList,
        selectAll: true,
      });
    } else {
      // Unselect all agents
      this.setState({
        selectedClients: [],
        selectAll: false,
      });
    }
  };*/

  toggleLock = () => {
    this.setState((prevState) => ({ isLocked: !prevState.isLocked }));
  };

  handleSelectAllChange = () => {
    if (!this.state.isLocked) {
      const { clientsList, selectAll } = this.state;
      if (!selectAll) {
        this.setState({
          selectedClients: clientsList,
          selectAll: true,
        });
      } else {
        this.setState({
          selectedClients: [],
          selectAll: false,
        });
      }
    }
  };

  publishText = (value, tableMeta) => {
    let is_published = tableMeta.rowData[2];
    if (is_published === 1) {
      return "Yes";
    } else {
      return "No";
    }
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  handleCheckboxChange = (agentEmail) => {
    const { selectedAgents, agentsList } = this.state;
    const selectedIndex = selectedAgents.indexOf(agentEmail);
    const newSelectedAgents = [...selectedAgents];

    if (selectedIndex === -1) {
      newSelectedAgents.push(agentEmail);
    } else {
      newSelectedAgents.splice(selectedIndex, 1);
    }

    // Update selectAll based on whether all agents are selected
    const allAgentsSelected = agentsList.every((agent) =>
      newSelectedAgents.includes(agent[3])
    );

    this.setState({
      selectedAgents: newSelectedAgents,
      selectAll: allAgentsSelected,
    });
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          signatory_id: changed_value.value,
          signatory_details: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.signatory_id);
        }
      );
    } else {
      this.setState(
        {
          signatory_id: null,
          signatory_details: null,
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearchAgreement = async (changed_value) => {
    console.log("handleChangeSearchAgreement: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          selected_agreement: changed_value.value,
          selected_agreement_array: changed_value,
        }),
        () => {
          // This callback function is called right after the state is updated
          this.getClientAgreement(this.state.selected_agreement);
          console.log("Updated state:", this.state.selected_agreement);
        }
      );
    } else {
      this.setState(
        {
          selected_agreement: null,
          selected_agreement_array: null,
        },
        () => {
          let agreement = "";
          this.getClientAgreement(agreement);
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeStep = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  handleChangeFile = async (event) => {
    this.setState({
      rate_deck_file: event.target.files[0],
    });
    //await this.sendFileData(event.target.files[0]);
  };

  modalHide = () => {
    window.$("#modalAlert").modal("hide");
    // this.props.agreementSigned();
  };

  /*handleEditorChange = (event, editor) => {
    const content = editor.getData();
    const editEditorHtml = document.getElementById("editEditorHtml");
    editEditorHtml.value = content;
    this.setState({
      agreement_content: content,
    });
  };*/
  handleEditorChange = (updatedContent) => {
    console.log("updatedContent: ", updatedContent);
    this.setState({ agreement_content: updatedContent });
  };

  openEditorHtmlModal = () => {
    window.$("#modalEditorHtml").modal("show");
  };

  saveEditorHtml = () => {
    const pageContent = document.getElementById("editEditorHtml").value;
    this.setState(
      {
        agreement_content: pageContent,
        isEditorReady: false,
      },
      () => {
        if (this.editor && this.editor.ui.view.toolbar) {
          this.editor.ui.view.toolbar.element.remove();
        }
        this.editor = null;

        setTimeout(() => {
          this.setState({ isEditorReady: true });
          window.$("#modalEditorHtml").modal("hide");
        }, 500);
      }
    );
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  onFileChange = (event) => {
    // Update the state
    this.setState({ logo: event.target.files[0] });
  };

  formSave = async () => {
    this.setState({
      errorMessage: "",
    });
    window.scrollTo(0, 0);
    const {
      agreement_title,
      agreement_instruction,
      isactive,
      ismandatory,
      agreement_content,
      agreement_type,
      service_type,
      signatory_id,
      selectedClients,
      add_agreement_type,
      selected_agreement,
      max_seqno,
      all_seqno,
      isLocked,
    } = this.state;

    var validated = true;

    if (agreement_title === "") {
      validated = false;
    } else if (agreement_instruction === "") {
      validated = false;
    } else if (agreement_content === "") {
      validated = false;
    } else if (service_type === "") {
      validated = false;
    }
    if (add_agreement_type === "duplicate") {
      if (!selected_agreement || selected_agreement === "") {
        validated = false;
      }
    }
    console.log("max_seqno: ", parseInt(max_seqno));

    const exists = all_seqno.includes(parseInt(max_seqno));
    if (exists) {
      console.log("all_seqno: ", all_seqno);
      return this.setState({
        errorMessage:
          "Sequence no already exists. Please add different sequence.",
      });
    }
    if (validated === false) {
      return this.setState({
        errorMessage: "Please enter the required fields.",
      });
    } else {
      this.setState({
        errorMessage: "",
        disabled: true,
      });

      const saveResponce = await addClientAgreement(
        auth.getAccount(),
        auth.getToken(),
        agreement_title,
        agreement_instruction,
        this.state.isactive.toString(),
        this.state.ismandatory.toString(),
        agreement_content,
        agreement_type,
        service_type,
        signatory_id,
        selectedClients.map((clients) => clients.value),
        add_agreement_type,
        selected_agreement,
        max_seqno,
        isLocked,
      );
      console.log("ADD RES: ", saveResponce.data);
      if (
        saveResponce.data.status === 403 ||
        saveResponce.data.errors === "authentication missing" ||
        saveResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "already_exists"
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "Agreement already exists for selected service type.",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 404 &&
        saveResponce.data.message === "cannot_create"
      ) {
        // window.location.replace("/error");
        this.setState({
          errorMessage: "There is some error while adding the agreement",
          successMessage: "",
          disabled: false,
        });
      } else if (
        saveResponce.data.status === 200 &&
        saveResponce.data.message === "success"
      ) {
        this.setState({
          errorMessage: "",
          successMessage: "Agreement added successfully.",
          disabled: false,
        });
        setTimeout(() => {
          this.props.props.history.push("/clients/agreements");
        }, 3000);
      } else {
        this.setState({
          errorMessage: saveResponce.data.message,
          successMessage: "",
          disabled: false,
        });
        //window.location.replace("/error");
      }
    }
  };

  handleBadgeClick = (content) => {
    navigator.clipboard
      .writeText(content)
      .then(() => {
        this.setState({ copiedBadge: content, showTooltip: true });
        setTimeout(() => {
          this.setState({ copiedBadge: "", showTooltip: false });
        }, 700);
      })
      .catch((error) => {
        console.error("Unable to copy text to clipboard:", error);
      });
  };

  handleOptionChange = (event) => {
    this.setState({
      add_agreement_type: event.target.value,
    });
  };

  render() {
    const { clientsList, selectAll, selectedClients, isLocked } = this.state;
    const fetchToken = async () => {
      try {
        const response = await fetch(
          AGENT_SERVER_URL + "agents/editor_token_url",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              request_type: "ckeditor-tokenUrl",
              user: auth.getAccount(),
            }),
          }
        );
        const data = await response.json();
        return data.token;
      } catch (error) {
        console.error("Failed to fetch token:", error);
        return null;
      }
    };
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}
          {/* MODAL EDIT PROFILE START */}
          <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <a
                  style={{ cursor: "pointer" }}
                  className="close"
                  onClick={() => {
                    this.modalHideDel();
                  }}
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-body modal-body-lg text-center">
                  <div className="nk-modal">
                    <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>
                    <h4 className="nk-modal-title">Delete Agreement?</h4>
                    <div className="nk-modal-text">
                      {this.state.errorMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessaged !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessaged}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="caption-text">
                        Are you sure you want to delete this Agreement?
                      </div>
                    </div>
                    <div className="nk-modal-action">
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-primary"
                        onClick={() => {
                          this.modalHideDel();
                        }}
                      >
                        CANCEL
                      </a>
                      {"  "}
                      <a
                        style={{ cursor: "pointer" }}
                        className="btn btn-lg btn-mw btn-danger"
                        onClick={() => {
                          this.deleteAgreement();
                        }}
                      >
                        DELETE
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" tabIndex="-1" id="modalEditorHtml">
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <a
                  href="#"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <em className="icon ni ni-cross"></em>
                </a>
                <div className="modal-header">
                  <h5 className="modal-title">Editor HTML</h5>
                </div>
                <div className="modal-body">
                  <textarea
                    className="prettyprint lang-html"
                    id="editEditorHtml"
                    defaultValue={this.state.agreement_content}
                  />
                </div>
                <div
                  className="modal-footer"
                  style={{ justifyContent: "right" }}
                >
                  <div className="d-flex justify-content-end">
                    <button
                      type="button"
                      className="btn btn-lg btn-primary"
                      onClick={() => this.saveEditorHtml()}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* MSA WARNING END */}
          <div className="nk-block-head nk-block-head-sm">
            <div className="nk-block-between">
              <div className="nk-block-head-content">
                <Breadcrumbs aria-label="breadcrumb">
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <HomeIcon fontSize="inherit" className="" />
                    Home
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/clients")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Clients
                  </Link>
                  <Link
                    color="inherit"
                    onClick={() => this.addMenuHistory("/clients/agreements")}
                    className=""
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Client Agreements
                  </Link>
                  <Typography color="textPrimary" className="">
                    New Client Agreement
                  </Typography>
                </Breadcrumbs>
                {/*<h3 className="nk-block-title page-title">
                  <PageTitle name="Add New Services" />
                </h3>*/}
              </div>
            </div>
          </div>
          <div className="nk-block">
            {/* Table 5th Col Start */}
            <div className="row g-gs">
              <div className="col-xxl-12">
                <div className="nk-block nk-block-lg">
                  <div className="card card-bordered">
                    <div className="card-inner">
                      {this.state.errorMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      {this.state.successMessage !== "" ? (
                        <div
                          className="example-alert"
                          style={{ marginBottom: "15px" }}
                        >
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <h5
                        className="card-title"
                        style={{ marginBottom: "30px" }}
                      >
                        Add New Agreement
                      </h5>
                      <form method="post" className="pageForm" id="pageForm">
                        <div className="row g-4">
                          <div className="col-lg-12">
                            <ul class="nk-kycfm-control-list g-3">
                              <li class="nk-kycfm-control-item">
                                <input
                                  class="nk-kycfm-control"
                                  type="radio"
                                  name="add_agreement_type"
                                  id="add_agreement_type"
                                  value="new"
                                  data-title="New Agreement"
                                  checked={
                                    this.state.add_agreement_type === "new"
                                  }
                                  onChange={this.handleOptionChange}
                                />
                                <label
                                  class="nk-kycfm-label"
                                  for="add_agreement_type"
                                >
                                  <span class="nk-kycfm-label-icon">
                                    <div class="label-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 90 90"
                                      >
                                        <rect
                                          x="15"
                                          y="5"
                                          width="56"
                                          height="70"
                                          rx="6"
                                          ry="6"
                                          fill="#e3e7fe"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <path
                                          d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                                          fill="#fff"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <polyline
                                          points="60 16 60 31 75 31.07"
                                          fill="none"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="50"
                                          x2="32"
                                          y2="50"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="46"
                                          y1="38"
                                          x2="32"
                                          y2="38"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="44"
                                          x2="32"
                                          y2="44"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="56"
                                          x2="32"
                                          y2="56"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="62"
                                          x2="32"
                                          y2="62"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="68"
                                          x2="32"
                                          y2="68"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="75"
                                          x2="32"
                                          y2="75"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                      </svg>
                                    </div>
                                  </span>
                                  <span class="nk-kycfm-label-text">
                                    New Agreement
                                  </span>
                                </label>
                              </li>
                              <li class="nk-kycfm-control-item">
                                <input
                                  class="nk-kycfm-control"
                                  type="radio"
                                  name="add_agreement_type"
                                  id="add_agreement_type2"
                                  value="duplicate"
                                  data-title="Duplicate Agreement"
                                  checked={
                                    this.state.add_agreement_type ===
                                    "duplicate"
                                  }
                                  onChange={this.handleOptionChange}
                                />
                                <label
                                  class="nk-kycfm-label"
                                  for="add_agreement_type2"
                                >
                                  <span class="nk-kycfm-label-icon">
                                    <div class="label-icon">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 90 90"
                                      >
                                        <rect
                                          x="15"
                                          y="5"
                                          width="56"
                                          height="70"
                                          rx="6"
                                          ry="6"
                                          fill="#e3e7fe"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <path
                                          d="M69.88,85H30.12A6.06,6.06,0,0,1,24,79V21a6.06,6.06,0,0,1,6.12-6H59.66L76,30.47V79A6.06,6.06,0,0,1,69.88,85Z"
                                          fill="#fff"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <polyline
                                          points="60 16 60 31 75 31.07"
                                          fill="none"
                                          stroke="#6576ff"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="50"
                                          x2="32"
                                          y2="50"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="46"
                                          y1="38"
                                          x2="32"
                                          y2="38"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="44"
                                          x2="32"
                                          y2="44"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="56"
                                          x2="32"
                                          y2="56"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="62"
                                          x2="32"
                                          y2="62"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="68"
                                          y1="68"
                                          x2="32"
                                          y2="68"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                        <line
                                          x1="58"
                                          y1="75"
                                          x2="32"
                                          y2="75"
                                          fill="none"
                                          stroke="#c4cefe"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                        />
                                      </svg>
                                    </div>
                                  </span>
                                  <span class="nk-kycfm-label-text">
                                    Duplicate Agreement
                                  </span>
                                </label>
                              </li>
                            </ul>
                          </div>
                          {this.state.add_agreement_type === "duplicate" ? (
                            <>
                              <div className="col-lg-6">
                                <div class="form-group">
                                  <div class="form-label-group">
                                    <label class="form-label">
                                      Choose Agreement{" "}
                                      <span class="text-danger"></span>
                                    </label>
                                  </div>
                                  <div class="form-control-group">
                                    <Select2
                                      options={this.state.all_active_agreements}
                                      name="selected_accountno"
                                      placeholder="Select an agreement to make a copy."
                                      value={
                                        this.state.selected_agreement_array
                                      }
                                      autoComplete="off"
                                      emptyMessage="Clients not found"
                                      onChange={
                                        this.handleChangeSearchAgreement
                                      }
                                      isClearable
                                      isSearchable
                                      components={animatedComponents}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          lineHeight: "40px",
                                        }),
                                        menu: (provided, state) => ({
                                          ...provided,
                                          zIndex: 9,
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">&nbsp;</div>
                            </>
                          ) : null}
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_title"
                                name="agreement_title"
                                type="text"
                                label="Agreement Title"
                                value={this.state.agreement_title}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="agreement_instruction"
                                name="agreement_instruction"
                                type="text"
                                label="Agreement Instructions"
                                value={this.state.agreement_instruction}
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="isactive"
                                name="isactive"
                                select
                                label="Select Status"
                                value={this.state.isactive}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement Status"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Active
                                </option>
                                <option key="0" value="0">
                                  Inactive
                                </option>
                              </MutextField>
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="ismandatory"
                                name="ismandatory"
                                select
                                label="Mandatory"
                                value={this.state.ismandatory}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Agreement is mandatory"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="1" value="1">
                                  Yes
                                </option>
                                <option key="0" value="0">
                                  No
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div class="form-label-group">
                              <label class="form-label">
                                &nbsp; <span class="text-danger"></span>
                              </label>
                            </div>
                            <div className="form-group">
                              <MutextField
                                id="agreement_type"
                                name="agreement_type"
                                select
                                label="Agreement Type"
                                value={this.state.agreement_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="E-Sign or Acceptance"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="esign" value="esign">
                                  E-Signature
                                </option>
                                <option key="accept" value="accept">
                                  Acceptance Only
                                </option>
                              </MutextField>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div class="form-group">
                              <div class="form-label-group">
                                <label class="form-label">
                                Choose Company Profile{" "}
                                  <span class="text-danger"></span>
                                </label>
                              </div>
                              <div class="form-control-group">
                                <Select2
                                  options={this.state.signatoryDetails}
                                  name="signatory_id"
                                  placeholder="Select a company profile of this agreement."
                                  value={this.state.signatory_details}
                                  autoComplete="off"
                                  emptyMessage="Signatory not found"
                                  onChange={this.handleChangeSearch}
                                  isClearable
                                  isSearchable
                                  components={animatedComponents}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      lineHeight: "40px",
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          {/*<div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="service_type"
                                name="service_type"
                                select
                                label="Agreement For"
                                value={this.state.service_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Please select the type of agreement: Client Agreement or Service Agreement."
                                variant="outlined"
                                fullWidth
                              >
                                <option key="main" value="main">
                                  Client Main Agreement
                                </option>
                                <option key="generic" value="generic">
                                  Service Agreement
                                </option>
                                
                              </MutextField>
                            </div>
                          </div>*/}
                          
                          <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                required
                                id="max_seqno"
                                name="max_seqno"
                                type="text"
                                label="Sequence #"
                                value={this.state.max_seqno}
                                onChange={this.handleChange}
                                onKeyPress={() => {
                                  this.checkNumber();
                                }}
                                variant="outlined"
                                fullWidth
                              />
                              {this.state.all_seqno &&
                              this.state.all_seqno.length > 0 ? (
                                <div class="form-note">
                                  <strong>
                                    Already Added Seqno's:{" "}
                                    {this.state.all_seqno.join(", ")}
                                  </strong>
                                </div>
                              ) : null}
                            </div>
                          </div>
                          {clientsList && clientsList.length > 0 ? (
                            <div className="col-lg-6">
                              <div
                                className="form-group"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={selectAll}
                                        onChange={this.handleSelectAllChange}
                                        name="selectAll"
                                        disabled={isLocked}
                                      />
                                    }
                                    label="Select all Clients"
                                    style={{
                                      width: "max-content",
                                      marginBottom: "10px",
                                      float: "right",
                                    }}
                                  />
                                  <Tooltip
                                    title={isLocked ? "Click to enable selection" : "Click to disable selection"}
                                    placement="top"
                                  >
                                    <em
                                      className={`icon ni ${isLocked ? "ni-lock-fill" : "ni-unlock-fill"}`}
                                      style={{ cursor: "pointer", marginBottom: "12px", marginLeft: "-4px", fontSize: "17px", color: isLocked ? "red" : "green" }}
                                      onClick={this.toggleLock}
                                    ></em>
                                  </Tooltip>

                                </div>
                                <Select2
                                  id="selectedClients"
                                  value={selectedClients}
                                  isMulti
                                  name="clients"
                                  options={clientsList}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                  isDisabled={isLocked}
                                  onChange={this.handleSelectChange}
                                  styles={{
                                    menu: (base) => ({
                                      ...base,
                                      zIndex: 9999,
                                    }),
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                          {/* <div className="col-lg-6">
                            <div className="form-group">
                              <MutextField
                                id="service_type"
                                name="service_type"
                                select
                                label="Service Type"
                                value={this.state.service_type}
                                onChange={this.handleChange}
                                SelectProps={{
                                  native: true,
                                }}
                                helperText="Select Service Type"
                                variant="outlined"
                                fullWidth
                              >
                                <option key="main" value="main">
                                  Select Service Type
                                </option>
                                <option key="did" value="did">
                                  DID Origination
                                </option>
                                <option key="tfn" value="tfn">
                                  Toll Free Origination
                                </option>
                                <option key="term" value="term">
                                  Voice Termination
                                </option>
                                <option key="teams" value="teams">
                                  Microsoft Teams UCAAS
                                </option>
                                <option key="tfn_term" value="tfn_term">
                                  Toll Free Termination
                                </option>
                                <option key="az_term" value="az_term">
                                  International A-Z Termination
                                </option>
                                <option key="live_agent" value="live_agent">
                                  Live Agent
                                </option>
                                <option key="cnam" value="cnam">
                                  CNAM
                                </option>
                                <option key="sms" value="sms">
                                  SMS Inbound / Outbound
                                </option>
                              </MutextField>
                            </div>
                          </div> */}
                          <div style={{ clear: "both" }}></div>

                          <div
                            className="col-lg-12"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <h6 style={{ margin: 0, marginRight: "10px" }}>
                              Agreement Content
                            </h6>
                            {this.state.isEditorReady ? (
                              <>
                                {/*<span
                                  className="badge badge-dim bg-outline-gray"
                                  style={{
                                    fontSize: "10px",
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={this.openEditorHtmlModal}
                                >
                                  Source Code
                                  <em
                                    className="icon ni ni-code"
                                    style={{
                                      marginLeft: "5px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  ></em>
                                </span>*/}
                              </>
                            ) : null}
                          </div>

                          <div className="col-lg-12">
                            <div className="form-group">
                              {this.state.isEditorReady ? (
                                <>
                                  <RichTextEditorComponent
                                    agreementContent={
                                      this.state.agreement_content
                                    }
                                    onContentChange={this.handleEditorChange}
                                  />
                                </>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">Client Place Holders</th>
                                  <td>
                                    <ul className="preview-list customStyle-badgeTransfrom">
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGNOR_TITLE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGNOR_TITLE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGNOR_NAME]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGNOR_NAME]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_COMPANY_NAME]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_EMAIL]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_EMAIL]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_PHONE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_PHONE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_ADDRESS]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_CITY]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_CITY]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_STATE]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_STATE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_ZIP]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_ZIP]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_COUNTRY]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_COUNTRY]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[CONTRACT_DATE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_SIGN]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_SIGN]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_499_FILER_ID]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_499_FILER_ID]]
                                        </a>
                                      </li>
                                      <li class="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[CLIENT_FULL_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[CLIENT_FULL_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[CLIENT_FULL_ADDRESS]]" && (
                                            <div
                                              className="copyToClipboardTooltip active"
                                              style={{ marginLeft: "-5px" }}
                                            >
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                {/*<tr>
                                  <th scope="row">User Place Holders</th>
                                  <td>
                                    <ul className="preview-list">
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_TITLE]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_TITLE]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_NAME]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_NAME]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_ADDRESS]]
                                        </a>
                                      </li>

                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_SIGN]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_SIGN]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_SIGNOR_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_SIGNOR_COMPANY_NAME]]
                                        </a>
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[VENDOR_CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[VENDOR_CONTRACT_DATE]]
                                        </a>
                                      </li>
                                    </ul>
                                  </td>
                                </tr>*/}
                                <tr>
                                  <th scope="row">
                                    Company Profile Place Holders
                                  </th>
                                  <td>
                                    <ul className="preview-list customStyle-badgeTransfrom">
                                      
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_SIGN]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_SIGN]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_SIGN]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_TITLE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_TITLE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_TITLE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_NAME]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_EMAIL]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_EMAIL]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_EMAIL]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_PHONENO]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_PHONENO]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_PHONENO]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_ADDRESS]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_FULL_ADDRESS]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_FULL_ADDRESS]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_FULL_ADDRESS]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_CITY]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_CITY]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_CITY]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_STATE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_STATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_STATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_ZIP]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_ZIP]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_ZIP]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_COUNTRY]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_COUNTRY]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_COUNTRY]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_PROFILE_COMPANY_NAME]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_PROFILE_COMPANY_NAME]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_PROFILE_COMPANY_NAME]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[COMPANY_CONTRACT_DATE]]"
                                            )
                                          }
                                        >
                                          [[COMPANY_CONTRACT_DATE]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[COMPANY_CONTRACT_DATE]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Input Fields</th>
                                  <td>
                                    <ul class="preview-list customStyle-badgeTransfrom">
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_LABEL_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_LABEL_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_LABEL_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTFIELD]]"
                                            )
                                          }
                                        >
                                          [[INPUT_TEXTFIELD]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTFIELD]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTFIELD_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_TEXTFIELD_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTFIELD_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTAREA]]"
                                            )
                                          }
                                        >
                                          [[INPUT_TEXTAREA]]
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTAREA]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_TEXTAREA_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_TEXTAREA_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_TEXTAREA_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      {/* <li className="preview-item">
                                        <a className="badge badge-outline-gray" onClick={() => this.handleBadgeClick("[[INPUT_CHECKBOX]]")}>
                                          [[INPUT_CHECKBOX]]
                                        </a>
                                        {this.state.showTooltip && this.state.copiedBadge === "[[INPUT_CHECKBOX]]" && (
                                          <div className="copyToClipboardTooltip active">
                                            <span>Copied to clipboard!</span>
                                          </div>
                                        )}
                                      </li> */}
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_CHECKBOX_OPTION_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_CHECKBOX_OPTION_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_CHECKBOX_OPTION_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_CHECKBOX_CHECKED_OPTION_{text}]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_DROPDOWN_option 1+option 2+option 3(selected)]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      <li className="preview-item">
                                        <a
                                          className="badge badge-outline-gray"
                                          onClick={() =>
                                            this.handleBadgeClick(
                                              "[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]"
                                            )
                                          }
                                        >
                                          {`[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]`}
                                        </a>
                                        {this.state.showTooltip &&
                                          this.state.copiedBadge ===
                                            "[[INPUT_RADIO_option 1+option 2+option 3+option 4(selected)]]" && (
                                            <div className="copyToClipboardTooltip active">
                                              <span>Copied to clipboard!</span>
                                            </div>
                                          )}
                                      </li>
                                      {/* <li className="preview-item">
                                        <a className="badge badge-outline-gray" onClick={() => this.handleBadgeClick("[[INPUT_RADIO]]")}>
                                          [[INPUT_RADIO]]
                                        </a>
                                        {this.state.showTooltip && this.state.copiedBadge === "[[INPUT_RADIO]]" && (
                                          <div className="copyToClipboardTooltip active">
                                            <span>Copied to clipboard!</span>
                                          </div>
                                        )}
                                      </li> */}
                                    </ul>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group text-right">
                              <button
                                type="button"
                                onClick={() => this.formSave()}
                                className="btn btn-lg btn-primary"
                                disabled={this.state.disabled}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Table 5th Col End */}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Addagreement);
