import { React, Fragment } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  listForms,
  deleteForm,
  previewForm,
  listChannelPartners,
  listClients,
  assignFormClients,
  assignFormAgents,
} from "./../../../config/forms_api_calls";
import $ from "jquery";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import FormRenderer from "../../../components/Reseller/formRender";
import Tooltip from "@material-ui/core/Tooltip";
import dateFormat, { masks } from "dateformat";
import MutextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select2 from "react-select";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const auth = new HelperClass();

class Forms extends Component {
  constructor() {
    super();

    this.state = {
      errorMessage: "",
      successMessage: "",
      successMessaged: "",
      errorMessaged: "",
      mainError: "",
      tableLoader: true,
      disabled: false,
      delete_form_acc: "",
      previewContent: [],
      channelPartnersList: [],
      selectedChannelPartners: [],
      selectAllChannelPartners: false,
      assignModalLoader: true,
      errorMessageAssignModal: "",
      successMessageAssignModal: "",
      disableAssignBtn: false,
      clientsList: [],
      selectedClients: [],
      selectAllClients: false,
      active_formAccNo: "",
      columnServices: [
        { name: "form_title", label: "Form Title" },
        { name: "form_sequence", label: "Sequence" },
        {
          name: "show_on_dashboard",
          label: "For Dashboard",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_dashboard(value)}</div>;
            },
          },
        },
        {
          name: "allow_multiple",
          label: "Multiple Submission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_multiple(value)}</div>;
            },
          },
        },
        {
          name: "is_default",
          label: "Default",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_default(value)}</div>;
            },
          },
        },
        {
          name: "date_updated",
          label: "Date Updated",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "form_accountno",
          options: {
            display: false,
          },
        },
        {
          name: "Actions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            style={{ cursor: "pointer" }}
                            title="Other Actions"
                            className="dropdown-toggle btn btn-icon  btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <Tooltip title="Preview Form" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.previewForm(
                                        value,
                                        tableMeta.rowData[6]
                                      )
                                    }
                                  >
                                    <em class="icon ni ni-card-view"></em>
                                    <span>Preview Form</span>
                                  </a>
                                </Tooltip>
                              </li>
                              <li>
                                <Tooltip title="Edit Form" placement="top">
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/forms/edit_form/" +
                                          tableMeta.rowData[6]
                                      )
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em className="icon ni ni-edit"></em>
                                    <span>Edit</span>
                                  </a>
                                </Tooltip>
                              </li>
                              <li>
                                <Tooltip
                                  title="View Form Submissions"
                                  placement="top"
                                >
                                  <a
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.addMenuHistory(
                                        "/forms/view_form/" +
                                          tableMeta.rowData[6]
                                      )
                                    }
                                    disabled={
                                      this.props.is_msa_signed === "No" ||
                                      this.props.defaultSettings === "missing"
                                        ? true
                                        : false
                                    }
                                  >
                                    <em class="icon ni ni-row-view"></em>
                                    <span>View Submissions</span>
                                  </a>
                                </Tooltip>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.openAssignFormModal(
                                      tableMeta.rowData[6]
                                    )
                                  }
                                  title="Assign to channel partners and clients"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-user-check"></em>
                                  <span>Assign Form</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.openDelForm(tableMeta.rowData[6])
                                  }
                                  title="Delete Form"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  format_dashboard = (value, tableMeta) => {
    if (value === 1) {
      return (
        <Tooltip
          title="This form will be displayed on assigned client and channel partner dashboard."
          placement="top"
        >
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_multiple = (value, tableMeta) => {
    if (value === 1) {
      return (
        <Tooltip
          title="This form can be submitted multiple times."
          placement="top"
        >
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  handleClientChange = (event, newSelectedOptions) => {
    const newSelectedClients = newSelectedOptions.map((option) => option.value);

    const selectAllClients =
      this.state.clientsList.length > 0 &&
      this.state.clientsList.length === newSelectedClients.length;

    this.setState({
      selectedClients: newSelectedClients,
      selectAllClients: selectAllClients,
    });
  };

  handleChannelPartnerChange = (event, newSelectedOptions) => {
    const newSelectedChannelPartners = newSelectedOptions.map(
      (option) => option.value
    );

    const selectAllChannelPartners =
      this.state.channelPartnersList.length > 0 &&
      this.state.channelPartnersList.length ===
        newSelectedChannelPartners.length;

    this.setState({
      selectedChannelPartners: newSelectedChannelPartners,
      selectAllChannelPartners: selectAllChannelPartners,
    });
  };

  format_default = (value, tableMeta) => {
    if (value === 1) {
      return (
        <Tooltip
          title="This form will assigned to new clients and channel partners."
          placement="top"
        >
          <span className="badge badge-outline-success">
            <em class="icon ni ni-done"></em> Yes
          </span>
        </Tooltip>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  async componentDidMount() {
    const servicesResponce = await listForms(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services Types",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        mainError: "There is some error while getting the services",
      });
    }
  }

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  openDelForm = async (form_acc) => {
    this.setState({
      delete_form_acc: form_acc,
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  closeDelForm = async () => {
    this.setState({
      delete_form_acc: "",
    });
    window.$("#modalDeleteAlert").modal("hide");
  };

  deleteForm = async () => {
    let form_accountno = this.state.delete_form_acc;
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });
    const servicesResponce = await deleteForm(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );
    console.log("deleteForm: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessaged: "The form was not found.",
        successMessaged: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        errorMessaged: "",
        successMessaged: "Form deleted successfully.",
        disabled: false,
      });
      setTimeout(() => {
        window.location.reload();
      }, 2500);
    } else {
      this.setState({
        errorMessaged: "There is some error while deleting the form.",
        successMessaged: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessaged: "", successMessaged: "" });
    }, 2500);
  };

  previewForm = async (value, form_accountno) => {
    this.setState({
      errorMessage: "",
      successMessage: "",
      disabled: true,
    });
    const servicesResponce = await previewForm(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );
    console.log("previewForm: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "The form was not found.",
        successMessage: "",
        disabled: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const previewContent = (
        <FormRenderer
          formData={JSON.parse(servicesResponce.data.data[0]["form_content"])}
          formTitle={servicesResponce.data.data[0]["form_title"]}
          formDesc={servicesResponce.data.data[0]["form_desc"]}
          formGroups={JSON.parse(servicesResponce.data.data[0]["groups"])}
          
          // id={this.state.forms[0].id}
          // setShowResponse={(value) => this.setState({ showResponse: value })}
        />
      );
      this.setState({ previewContent }, () => {
        window.$("#modalPreviewForm").modal("show");
      });
    } else {
      this.setState({
        errorMessage: "There is some error while previewing the form.",
        successMessage: "",
        disabled: false,
      });
    }

    setTimeout(() => {
      this.setState({ errorMessaged: "", successMessaged: "" });
    }, 2500);
  };

  openAssignFormModal = async (form_accountno) => {
    this.setState({
      active_formAccNo: form_accountno,
    });
    this.listChannelPartners(form_accountno);
    this.listClients(form_accountno);
  };

  listChannelPartners = async (form_accountno) => {
    const servicesResponse = await listChannelPartners(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("agentsResponse.data.data: ", servicesResponse.data);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponse.data.status === 404) {
      this.setState({
        formLoader: false,
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      let channelPartners = servicesResponse.data.data.map((agent) => ({
        value: agent[8],
        label: agent[2] + " (" + agent[1] + ")",
      }));

      const selectedChannelPartners = servicesResponse.data.selected_agents.map(
        (agent) => agent.accountno
      );

      const selectAllChannelPartners =
        channelPartners.length > 0 &&
        channelPartners.every((agent) =>
          selectedChannelPartners.includes(agent.value)
        );

      this.setState({
        channelPartnersList: channelPartners,
        selectedChannelPartners: selectedChannelPartners,
        selectAllChannelPartners: selectAllChannelPartners,
        assignModalLoader: false,
      });

      window.$("#modalAssignForm").modal("show");
    }
  };

  listClients = async (form_accountno) => {
    const listClientsResponse = await listClients(
      auth.getAccount(),
      auth.getToken(),
      form_accountno
    );

    console.log("listClientsResponse: ", listClientsResponse.data);

    if (
      listClientsResponse.data.status === 403 ||
      listClientsResponse.data.errors === "authentication missing" ||
      listClientsResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listClientsResponse.data.status === 404) {
      // Handle 404 error if needed
    } else if (
      listClientsResponse.data.status === 200 &&
      listClientsResponse.data.message === "success"
    ) {
      let clients = listClientsResponse.data.data.map((client) => ({
        value: client[0],
        label: client[1] + " (" + client[2] + ")",
      }));

      const selectedClients = listClientsResponse.data.selected_clients.map(
        (client) => client.accountno
      );

      const selectAllClients =
        clients.length > 0 &&
        clients.every((client) => selectedClients.includes(client.value));

      this.setState({
        clientsList: clients,
        selectedClients: selectedClients,
        selectAllClients: selectAllClients,
      });
    } else {
      // Handle other responses or errors
    }
  };

  handleSelectAllChannelPartnersChange = () => {
    const { selectAllChannelPartners, channelPartnersList } = this.state;
    if (selectAllChannelPartners) {
      this.setState({
        selectedChannelPartners: [],
        selectAllChannelPartners: false,
      });
    } else {
      const allAgentValues = channelPartnersList.map((agent) => agent.value);
      this.setState({
        selectedChannelPartners: allAgentValues,
        selectAllChannelPartners: true,
      });
    }
  };

  handleSelectChannelPartnerChange = (agentValue) => {
    this.setState((prevState) => {
      const selectedIndex =
        prevState.selectedChannelPartners.indexOf(agentValue);
      const newSelectedChannelPartners = [...prevState.selectedChannelPartners];

      if (selectedIndex === -1) {
        newSelectedChannelPartners.push(agentValue);
      } else {
        newSelectedChannelPartners.splice(selectedIndex, 1);
      }

      const allChannelPartnersSelected = prevState.channelPartnersList.every(
        (agent) => newSelectedChannelPartners.includes(agent.value)
      );

      return {
        selectedChannelPartners: newSelectedChannelPartners,
        selectAllChannelPartners: allChannelPartnersSelected,
      };
    });
  };

  handleSelectAllClientsChange = () => {
    const { selectAllClients, clientsList } = this.state;
    if (selectAllClients) {
      this.setState({
        selectedClients: [],
        selectAllClients: false,
      });
    } else {
      const allClientValues = clientsList.map((client) => client.value);
      this.setState({
        selectedClients: allClientValues,
        selectAllClients: true,
      });
    }
  };

  handleSelectClientChange = (clientValue) => {
    this.setState((prevState) => {
      const selectedIndex = prevState.selectedClients.indexOf(clientValue);
      const newSelectedClients = [...prevState.selectedClients];

      if (selectedIndex === -1) {
        newSelectedClients.push(clientValue);
      } else {
        newSelectedClients.splice(selectedIndex, 1);
      }

      const allClientsSelected = prevState.clientsList.every((client) =>
        newSelectedClients.includes(client.value)
      );

      return {
        selectedClients: newSelectedClients,
        selectAllClients: allClientsSelected,
      };
    });
  };

  handleSubmitAssignFormClient = async () => {
    const { selectedClients, active_formAccNo } = this.state;
    const assignFormResponse = await assignFormClients(
      auth.getAccount(),
      auth.getToken(),
      selectedClients,
      active_formAccNo
    );

    console.log("assignFormResponse: ", assignFormResponse.data);

    if (
      assignFormResponse.data.status === 403 ||
      assignFormResponse.data.errors === "authentication missing" ||
      assignFormResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      assignFormResponse.data.status === 404 &&
      assignFormResponse.data.message === "assigning_error"
    ) {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the form to selected Clients.",
      });
    } else if (
      assignFormResponse.data.status === 200 &&
      assignFormResponse.data.message === "success"
    ) {
      this.setState({
        successMessageAssignModal: "Form assigned to selected Clients.",
      });
      /*setTimeout(() => {
        window.location.reload();
      }, 2500);*/
    } else {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the form to selected Clients.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageAssignModal: "",
        errorMessageAssignModal: "",
      });
    }, 2500);
  };

  handleSubmitAssignFormAgents = async () => {
    const { selectedChannelPartners, active_formAccNo } = this.state;
    const assignFormResponse = await assignFormAgents(
      auth.getAccount(),
      auth.getToken(),
      selectedChannelPartners,
      active_formAccNo
    );

    console.log("assignFormResponse: ", assignFormResponse.data);

    if (
      assignFormResponse.data.status === 403 ||
      assignFormResponse.data.errors === "authentication missing" ||
      assignFormResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      assignFormResponse.data.status === 404 &&
      assignFormResponse.data.message === "assigning_error"
    ) {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the form to selected Channel Partners.",
      });
    } else if (
      assignFormResponse.data.status === 200 &&
      assignFormResponse.data.message === "success"
    ) {
      this.setState({
        successMessageAssignModal:
          "Form assigned to selected Channel Partners.",
      });
      /*setTimeout(() => {
        window.location.reload();
      }, 2500);*/
    } else {
      this.setState({
        errorMessageAssignModal:
          "There was some error while assigning the form to selected Channel Partners.",
      });
    }
    setTimeout(() => {
      this.setState({
        successMessageAssignModal: "",
        errorMessageAssignModal: "",
      });
    }, 2500);
  };

  render() {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle name="Forms" icon="icon ni ni-card-view" />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContentAgent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <a
                              style={{ cursor: "pointer", color: "#fff" }}
                              onClick={() =>
                                this.addMenuHistory("/forms/add_form")
                              }
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus"></em> Add New
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.mainError !== "" ? (
                            <div className="example-alert">
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.mainError}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableExtended
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
              {/* START DELETE MODAL  */}
              <div className="modal fade" tabIndex="-1" id="modalDeleteAlert">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() => this.closeDelForm()}
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body modal-body-lg text-center">
                      <div className="nk-modal">
                        <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-danger"></em>

                        <h4 className="nk-modal-title">Delete Form?</h4>
                        <div className="nk-modal-text">
                          {this.state.errorMessaged !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessaged}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.successMessaged !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-success">
                                <div className="alert-text">
                                  <h4>Success</h4>
                                  <p>{this.state.successMessaged}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className="caption-text">
                            Are you sure you want to delete this form?
                          </div>
                        </div>
                        <div className="nk-modal-action">
                          <a
                            style={{ cursor: "pointer" }}
                            className="btn btn-lg btn-mw btn-primary"
                            onClick={() => this.closeDelForm()}
                          >
                            CANCEL
                          </a>
                          {"  "}
                          <a
                            style={{ cursor: "pointer" }}
                            className={"btn btn-lg btn-mw btn-danger"}
                            onClick={() => {
                              this.deleteForm();
                            }}
                            disabled={this.state.disabled ? true : false}
                          >
                            Delete
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade flexibleModal" tabIndex="-1" id="modalPreviewForm">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <a
                      style={{ cursor: "pointer" }}
                      className="close"
                      onClick={() =>
                        window.$("#modalPreviewForm").modal("hide")
                      }
                    >
                      <em className="icon ni ni-cross"></em>
                    </a>
                    <div className="modal-body">
                      <div className="nk-modal">
                        {this.state.previewContent}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade flexibleModal" tabIndex="-1" id="modalAssignForm">
                <div
                  className="modal-dialog modal-xl modal-dialog-top"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div
                        className="modal-title"
                        style={{ textAlign: "center", fontSize: "20px" }}
                      >
                        <strong>
                          <em className="icon ni ni-grid-alt"></em>Assign Form
                          to{" "}
                          <sup>
                            <small className="text-soft">
                              Channel Partners/Clients
                            </small>
                          </sup>
                        </strong>
                      </div>
                      <a
                        href="#"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em className="icon ni ni-cross"></em>
                      </a>
                    </div>

                    <div className="modal-body text-left">
                      <div className="nk-modal">
                        <div className="nk-modal-text">
                          {this.state.assignModalLoader === true ? (
                            tableLoader()
                          ) : (
                            <>
                              {this.state.errorMessageAssignModal !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageAssignModal}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageAssignModal !== "" ? (
                                <div
                                  className="example-alert "
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageAssignModal}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="card card-bordered card-preview">
                                <table className="table table-orders">
                                  <thead className="tb-odr-head">
                                    <tr className="tb-odr-item">
                                      <th
                                        width="50%"
                                        style={{
                                          borderRight: "1px solid #dbdfea",
                                        }}
                                      >
                                        <span className="tb-odr-action">
                                          Channel Partners
                                        </span>
                                      </th>
                                      <th width="50%">
                                        <span className="tb-odr-action">
                                          Clients
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="tb-odr-body">
                                  {this.state.channelPartnersList &&
                                    this.state.channelPartnersList.length >
                                      0 ? (
                                      <tr className="tb-odr-item customStyle_tb-odr-item_forms">
                                        <td
                                          style={{
                                            borderRight: "1px solid #dbdfea",
                                          }}
                                        >
                                          <div
                                            className="form-group"
                                            style={{
                                              display: "flex",
                                              flexDirection: "column",
                                            }}
                                          >
                                            <FormControlLabel
                                              control={
                                                <Checkbox
                                                  checked={
                                                    this.state
                                                      .selectAllChannelPartners
                                                  }
                                                  onChange={
                                                    this
                                                      .handleSelectAllChannelPartnersChange
                                                  }
                                                  name="selectAllChannelPartners"
                                                  color="primary"
                                                />
                                              }
                                              label="Select all Channel Partners"
                                              style={{
                                                width: "max-content",
                                                marginBottom: "10px",
                                                float: "right",
                                              }}
                                            />

                                            <Autocomplete
                                              multiple
                                              id="channel-partners-autocomplete"
                                              options={
                                                this.state.channelPartnersList
                                              }
                                              disableCloseOnSelect
                                              getOptionLabel={(option) =>
                                                option.label
                                              }
                                              value={this.state.channelPartnersList.filter(
                                                (partner) =>
                                                  this.state.selectedChannelPartners.includes(
                                                    partner.value
                                                  )
                                              )}
                                              onChange={
                                                this.handleChannelPartnerChange
                                              }
                                              getOptionSelected={(
                                                option,
                                                value
                                              ) => option.value === value.value}
                                              renderOption={(
                                                option,
                                                { selected }
                                              ) => (
                                                <Fragment>
                                                  <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                  />
                                                  {option.label}
                                                </Fragment>
                                              )}
                                              style={{ width: "100%" }}
                                              renderInput={(params) => (
                                                <MutextField
                                                  {...params}
                                                  label="Select Channel Partners"
                                                  placeholder=""
                                                />
                                              )}
                                            />
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            paddingLeft: "16px",
                                          }}
                                        >
                                          {this.state.clientsList &&
                                          this.state.clientsList.length > 0 ? (
                                            <div
                                              className="form-group"
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      this.state
                                                        .selectAllClients
                                                    }
                                                    onChange={
                                                      this
                                                        .handleSelectAllClientsChange
                                                    }
                                                    name="selectAll"
                                                    color="primary"
                                                  />
                                                }
                                                label="Select all Clients"
                                                style={{
                                                  width: "max-content",
                                                  marginBottom: "10px",
                                                  float: "right",
                                                }}
                                              />
                                              <Autocomplete
                                                multiple
                                                id="clients-autocomplete"
                                                options={this.state.clientsList}
                                                disableCloseOnSelect
                                                getOptionLabel={(option) =>
                                                  option.label
                                                }
                                                value={this.state.clientsList.filter(
                                                  (client) =>
                                                    this.state.selectedClients.includes(
                                                      client.value
                                                    )
                                                )}
                                                onChange={
                                                  this.handleClientChange
                                                }
                                                getOptionSelected={(
                                                  option,
                                                  value
                                                ) =>
                                                  option.value === value.value
                                                }
                                                renderOption={(
                                                  option,
                                                  { selected }
                                                ) => (
                                                  <Fragment>
                                                    <Checkbox
                                                      icon={icon}
                                                      checkedIcon={checkedIcon}
                                                      style={{ marginRight: 8 }}
                                                      checked={selected}
                                                    />
                                                    {option.label}
                                                  </Fragment>
                                                )}
                                                style={{ width: "100%" }}
                                                renderInput={(params) => (
                                                  <MutextField
                                                    {...params}
                                                    label="Select Clients"
                                                    placeholder=""
                                                  />
                                                )}
                                              />
                                            </div>
                                          ) : null}
                                        </td>
                                      </tr>
                                    ) : null}
                                    {this.state.channelPartnersList &&
                                    this.state.channelPartnersList.length >
                                      0 ? (
                                      <>
                                        
                                        <tr>
                                          <td>
                                            <div className="nk-modal-action  text-right">
                                              <a
                                                href="#"
                                                className="btn btn-lg btn-mw btn-primary "
                                                onClick={() => {
                                                  this.handleSubmitAssignFormAgents();
                                                }}
                                                disabled={
                                                  this.state.disableAssignBtn
                                                }
                                              >
                                                Assign to Channel Partners
                                              </a>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="nk-modal-action  text-right">
                                              <a
                                                href="#"
                                                className="btn btn-lg btn-mw btn-primary "
                                                onClick={() => {
                                                  this.handleSubmitAssignFormClient();
                                                }}
                                                disabled={
                                                  this.state.disableAssignBtn
                                                }
                                              >
                                                Assign to Clients
                                              </a>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ) : null}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END DELETE MODAL  */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Forms);
