import React from "react";
import HelperClass from "./../../config/helperClass";
import { THEME_COLOR } from "./../../config/config";
import $ from "jquery";
const auth = new HelperClass();

export default function Notifications(props) {
  const handleClick = () => {
    const excludedLogTypes = [
      "sharelinks",
      "settings",
      "agents_activation",
      "clients_activation",
    ];
    if (excludedLogTypes.includes(props.notification.log_type)) {
      return;
    }

    const baseURL = `${props.notification.log_url}?accountno=${
      props.notification.accountno || ""
    }`;

    let url = baseURL;

    switch (props.notification.log_type) {
      case "agreements":
        url += `&agreement_accountno=${
          props.notification.agreement_accountno || ""
        }&log_type=${props.notification.log_type || ""}`;
        break;
      case "clients":
      case "agents":
        url += `&log_type=${props.notification.log_type || ""}`;
        break;
      case "credit_application":
        url += `&credit_app_id=${
          props.notification.credit_app_id || ""
        }&log_type=${props.notification.log_type || ""}`;
        break;
      case "rfq":
        url += `&rfq_accountno=${
          props.notification.rfq_accountno || ""
        }&log_type=${props.notification.log_type || ""}`;
      case "orders":
          url += `&orderno=${
            props.notification.orderno || ""
          }&log_type=${props.notification.log_type || ""}`;  
        break;
    }

    window.location.href = url;
  };

  return (
    <div
      className="nk-notification-item dropdown-inner"
      style={{ padding: "0.2rem 1.75rem", cursor: "pointer" }}
      onClick={handleClick}
    >
      <div className="nk-notification-icon">
        <em className="icon icon-circle bg-warning-dim ni ni-info"></em>
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">
          {props.notification.is_read === 0 ? (
            <span className="unread_notification">
              {props.notification.title}
            </span>
          ) : (
            <span>{props.notification.title}</span>
          )}
        </div>
        <div className="nk-notification-time" style={{ textAlign: "left" }}>
          {props.notification.time}
        </div>
      </div>
    </div>
  );
}
