import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import {
  getMarketingCollaterals,
  listCollateralsGroups,
  deleteCollaterals,
  listClients,
  listAgents,
  getAttachedClients,
  deleteClientDocument,
  getAttachedAgents,
  deleteAgentDocument,
  getSingleMarketingCollaterals,
  getMarketingCollateralsArchived,
  approveAgentDocument,
  rejectAgentDocument,
  updateDocumentViewedStatusApi,
} from "./../../../config/collateral_apis";
import DataTable from "../../../components/Tables/DataTableExtended";
import DataTableArchived from "../../../components/Tables/DataTableExtended";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import { addSettings, addMsaInfo } from "../../../actions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { Fileupload } from "../../../components/Image/Fileupload";
import Tooltip from "@material-ui/core/Tooltip";
import MutextField from "@material-ui/core/TextField";
import dateFormat, { masks } from "dateformat";
import { APP_LIVE_URL, AGENT_PORTAL_URL } from "./../../../config/config";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "react-select";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Badge from "@material-ui/core/Badge";
const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Collaterals extends Component {
  constructor() {
    super();
    this.state = {
      tableData: [],
      tableDataArchived: [],
      errorMessage: "",
      successMessage: "",
      SmtpErrorMessage: "",
      SmtpSuccessMessage: "",
      smtp_reg_button: false,
      smtp_test_button: false,
      formLoader: true,
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: [],
      previously_file: "",
      document_file_name: "",
      document_for: "none",
      all_collaterals_groups: [],
      group_id: "",
      group_title: "",
      selected_group_id: null,
      tableLoader: true,
      collaterals_id: "",
      errorMessageDelete: "",
      successMessageDelte: "",
      isProcessing: false,
      clientsList: [],
      agentList: [],
      updatedclientsList: [],
      updatedagentList: [],
      selectedClients: [],
      selectedClientsUpdate: [],
      selectedAgents: [],
      selectedAgentsUpdate: [],
      selectAllClient: false,
      selectAllAgent: false,
      selectAllClientUpdate: false,
      selectAllAgentUpdate: false,
      selectedTab: "clients",
      client_details: [],
      client_id: "",
      errorMessageDeleteClientDoc: "",
      successMessageDelteClientDoc: "",
      agent_details: [],
      agent_id: "",
      errorMessageDeleteAgentDoc: "",
      successMessageDelteAgentDoc: "",
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
      single_document_details: [],
      selectAllUpdateAgent: [],
      selectAllUpdateClient: [],
      doc_id: "",
      allow_document_submission: "",
      is_approved: "",
      file_path: "",
      reject_reason: "",
      file_path: "",
      isApproved: false,
      isRejected: false,
      agent_viewed: "",
      client_viewed: "",
      total_viewed: "",
      activeAccordion: null,
      submitted_document_file: "",
      columnServices: [
        { name: "TITLE" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "DOWNLOAD",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Group" },
        {
          name: "ID",
          options: {
            display: "false",
          },
        },
        {
          name: "Staus",
          options: {
            display: "false",
          },
        },
        {
          name: "Agent Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "Client Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "Total Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "FOR",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedTo(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Submissions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedToView(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.deleteCollateral(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Documents</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => {
                                    this.setUpdateCollateral(value, tableMeta);
                                    setTimeout(() => {
                                      this.handleUpdateDocumentsClick();
                                    }, 3000);
                                  }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Update Document</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
      columnServicesArchived: [
        { name: "TITLE" },
        {
          name: "DATE",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "DOWNLOAD",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.viewDownloadBtns(value, tableMeta)}</div>;
            },
          },
        },
        { name: "Group" },
        {
          name: "ID",
          options: {
            display: "false",
          },
        },
        {
          name: "Staus",
          options: {
            display: "false",
          },
        },
        {
          name: "Agent Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "Client Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "Total Viewed",
          options: {
            display: "false",
          },
        },
        {
          name: "FOR",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedTo(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "Submissions",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.assignedToView(value, tableMeta)}</div>;
            },
          },
        },
        {
          name: "ACTIONS",
          options: {
            customBodyRender: (value, tableMeta) => {
              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.deleteCollateral(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-trash"></em>
                                  <span>Delete Documents</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() => {
                                    this.setUpdateCollateral(value, tableMeta);
                                    setTimeout(() => {
                                      this.handleUpdateDocumentsClick();
                                    }, 3000);
                                  }}
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Other Details"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em className="icon ni ni-edit"></em>
                                  <span>Update Document</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  async componentDidMount() {
    this.listClients();
    this.listAgents();
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
    this.listCollateralsGroups();
  }

  reloadGetCollaterals = async () => {
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Reload Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  listClients = async () => {
    const listAgentsResponce = await listClients(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("listClientsResponce: ", listAgentsResponce.data.data);
    if (
      listAgentsResponce.data.status === 403 ||
      listAgentsResponce.data.errors === "authentication missing" ||
      listAgentsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentsResponce.data.status === 200 &&
      listAgentsResponce.data.message === "success"
    ) {
      let clients = listAgentsResponce.data.data.map((client) => ({
        value: client[0],
        label: client[1] + "(" + client[2] + ")",
      }));
      //("clientsList clients: ", clients);
      this.setState({
        clientsList: clients,
        selectedClients: clients,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  listAgents = async () => {
    const listAgentsResponce = await listAgents(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("listAgentsResponce: ", listAgentsResponce.data.data);
    if (
      listAgentsResponce.data.status === 403 ||
      listAgentsResponce.data.errors === "authentication missing" ||
      listAgentsResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (listAgentsResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      listAgentsResponce.data.status === 200 &&
      listAgentsResponce.data.message === "success"
    ) {
      let agents = listAgentsResponce.data.data.map((agent) => ({
        value: agent[8],
        label: agent[1] + "(" + agent[2] + ")",
      }));
      //console.log("agentsList agents: ", agents);
      this.setState({
        agentList: agents,
        selectedAgents: agents,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          group_id: changed_value.value,
          group_title: changed_value.label,
          selected_group_id: changed_value,
        }),
        () => {
          console.log(
            "Updated state:",
            this.state.group_id,
            this.state.group_title
          );
        }
      );
    } else {
      this.setState(
        {
          group_id: null,
          group_title: null,
          selected_group_id: null,
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearchUpdate = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          group_id: changed_value.value,
          group_title: changed_value.label,
          selected_group_id: changed_value,
          selected_accountno: {
            value: changed_value.label,
            label: changed_value.label,
          },
        }),
        () => {
          // console.log(
          //   "Updated state:",
          //   this.state.group_id,
          //   this.state.group_title,
          //   this.state.selected_accountno
          // );
        }
      );
    } else {
      this.setState(
        {
          group_id: null,
          group_title: null,
          selected_group_id: null,
          selected_accountno: null,
        },
        () => {
          //console.log("Cleared selection");
        }
      );
    }
  };

  handleChangeSearchBar = async (changed_value) => {
    //console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      this.setState(
        (prevState) => ({
          group_id: changed_value.value,
          group_title: changed_value.label,
          selected_group_id: changed_value,
        }),
        () => {
          // console.log(
          //   "Updated state:",
          //   this.state.group_id,
          //   this.state.group_title
          // );
          this.reloadClients();
        }
      );
    } else {
      this.setState(
        {
          group_id: null,
          group_title: null,
          selected_group_id: null,
        },
        () => {
          //console.log("Cleared selection");
          this.reloadClients();
        }
      );
    }
  };

  getMarketingCollateralsArchived = async () => {
    this.setState({
      tableLoader: true,
    });
    const servicesResponce = await getMarketingCollateralsArchived(
      auth.getAccount(),
      auth.getToken()
    );
    console.log("Hash Data Archived: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableDataArchived: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableDataArchived: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableDataArchived: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  reloadClients = async () => {
    this.setState({
      tableLoader: true,
    });
    const servicesResponce = await getMarketingCollaterals(
      auth.getAccount(),
      auth.getToken(),
      this.state.group_title
    );
    console.log("Hash Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        tableData: servicesResponce.data.data,
        tableLoader: false,
      });
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  listCollateralsGroups = async () => {
    const servicesResponce = await listCollateralsGroups(
      auth.getAccount(),
      auth.getToken()
    );
    //console.log("listCollateralsGroups: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      //auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "not_found"
    ) {
      this.setState({
        errorMessage: "Groups not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_groups = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label: servicesResponce.data.data[i][0],
            value: servicesResponce.data.data[i][1],
          };
          all_groups.push(newArr);
        }
      }

      this.setState({
        all_collaterals_groups: all_groups,
      });
    } else {
      this.setState({
        errorMessage: "There is some error while displaying groups.",
      });
    }
  };

  deleteCollateral = (value, tableMeta) => {
    let collaterals_id = tableMeta.rowData[4];
    this.setState({
      collaterals_id: collaterals_id,
      errorMessageDelete: "",
      successMessageDelte: "",
    });
    window.$("#modalDeleteAlert").modal("show");
  };

  deleteMarketingCollaterals = async () => {
    this.setState({ isProcessing: true });
    let collaterals_id = this.state.collaterals_id;
    const servicesResponse = await deleteCollaterals(
      auth.getAccount(),
      auth.getToken(),
      collaterals_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelte: "Document deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlert").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDelete: "Error document not found.",
      });
    } else {
      this.setState({
        errorMessageDelete: "Error failed to delete document.",
      });
    }
  };

  handleDeleteClient = (id) => {
    let client_id = id;
    this.setState({
      client_id: client_id,
      errorMessageDeleteClientDoc: "",
      successMessageDelteClientDoc: "",
    });
    window.$("#modalDeleteAlertClientDoc").modal("show");
  };

  deleteClientDoc = async () => {
    this.setState({ isProcessing: true });
    let client_id = this.state.client_id;
    const servicesResponse = await deleteClientDocument(
      auth.getAccount(),
      auth.getToken(),
      client_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelteClientDoc: "Client Document deleted successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlertClientDoc").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDeleteClientDoc: "Error document not found.",
      });
    } else {
      this.setState({
        errorMessageDeleteClientDoc: "Error failed to delete document.",
      });
    }
  };

  handleDeleteAgent = (id) => {
    let agent_id = id;
    this.setState({
      agent_id: agent_id,
      errorMessageDeleteAgentDoc: "",
      successMessageDelteAgentDoc: "",
    });
    window.$("#modalDeleteAlertAgentDoc").modal("show");
  };

  handleAproveAgent = (id, file_path, document_file, approvedStatus) => {
    let agent_id = id;
    this.setState({
      agent_id: agent_id,
      is_approved: approvedStatus,
      file_path: file_path,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
      submitted_document_file: document_file,
    });
    window.$("#modalApproveAlertAgent").modal("show");
  };

  handleRejectAgent = (id, file_path, approvedStatus) => {
    let agent_id = id;
    this.setState({
      agent_id: agent_id,
      is_approved: approvedStatus,
      file_path: file_path,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
    });
    window.$("#modalRejectAlertAgent").modal("show");
  };

  handleAproveClient = (id, approvedStatus) => {
    let agent_id = id;
    this.setState({
      agent_id: agent_id,
      is_approved: approvedStatus,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
    });
    window.$("#modalApproveAlertAgent").modal("show");
  };

  deleteAgentDoc = async () => {
    let agent_id = this.state.agent_id;
    let collaterals_id = this.state.collaterals_id;
    this.setState({ isProcessing: true });
    this.setState({
      agent_id: agent_id,
      errorMessageDeleteAgentDoc: "",
      successMessageDelteAgentDoc: "",
    });
    const servicesResponse = await deleteAgentDocument(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      this.state.selectedTab,
      collaterals_id
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageDeleteAgentDoc: "Error document not found.",
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageDelteAgentDoc: "Document unassign successfully.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalDeleteAlertAgentDoc").modal("hide");
            this.setState({ successMessage: "" });
            window.location.reload();
          }, 3000);
        }
      );
    }
  };

  approveAgentDoc = async () => {
    let agent_id = this.state.agent_id;
    let collaterals_id = this.state.collaterals_id;
    this.setState({ isProcessing: true });
    this.setState({
      agent_id: agent_id,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
      isApproved: false,
    });
    const servicesResponse = await approveAgentDocument(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      this.state.selectedTab,
      collaterals_id,
      this.state.is_approved,
      this.state.file_path
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageApproveAgentDoc: "Error document not found.",
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState({
        successMessageApproveAgentDoc: "Successfully aproved.",
        isProcessing: false,
        isApproved: true,
      });
    }
    //this.reloadopenAttachedUsers();
  };

  rejectAgentDoc = async () => {
    let { agent_id, collaterals_id, reject_reason, isRejected } = this.state;

    // Check if reject_reason is empty
    if (!reject_reason || reject_reason.trim() === "") {
      this.setState({
        errorMessageApproveAgentDoc: "Please provide a reason for rejection.",
        isProcessing: false,
      });

      // Hide the error message after 3 seconds
      setTimeout(() => {
        this.setState({ errorMessageApproveAgentDoc: "" });
      }, 3000);

      return; // Stop further execution
    }

    this.setState({
      isProcessing: true,
      agent_id: agent_id,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
    });

    const servicesResponse = await rejectAgentDocument(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      this.state.selectedTab,
      collaterals_id,
      this.state.is_approved,
      reject_reason,
      this.state.file_path
    );

    //console.log("servicesResponse", servicesResponse);

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageApproveAgentDoc: "Error document not found.",
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message ===
        "Document rejected, recorded, and deleted successfully"
    ) {
      this.setState(
        {
          successMessageApproveAgentDoc: "Document rejected successfully",
          isProcessing: false,
          isRejected: true,
        },
        () => {
          setTimeout(() => {
            window.$("#modalRejectAlertAgent").modal("hide");
            this.reloadopenAttachedUsers();
          }, 1000);
        }
      );
    }
  };

  approveClientDoc = async () => {
    let agent_id = this.state.agent_id;
    let collaterals_id = this.state.collaterals_id;
    this.setState({ isProcessing: true });
    this.setState({
      agent_id: agent_id,
      errorMessageApproveAgentDoc: "",
      successMessageApproveAgentDoc: "",
    });
    const servicesResponse = await approveAgentDocument(
      auth.getAccount(),
      auth.getToken(),
      agent_id,
      this.state.selectedTab,
      collaterals_id,
      this.state.is_approved,
      this.state.file_path,
      this.state.submitted_document_file
    );

    if (
      servicesResponse.data.status === 403 ||
      servicesResponse.data.errors === "authentication missing" ||
      servicesResponse.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponse.data.status === 404 &&
      servicesResponse.data.message === "not_found"
    ) {
      this.setState({
        errorMessageApproveAgentDoc: "Error document not found.",
      });
    } else if (
      servicesResponse.data.status === 200 &&
      servicesResponse.data.message === "success"
    ) {
      this.setState(
        {
          successMessageApproveAgentDoc: "Successfully Approved.",
          isProcessing: false,
        },
        () => {
          setTimeout(() => {
            window.$("#modalApproveAlertAgent").modal("hide");
            this.reloadopenAttachedUsers();
          }, 1000);
        }
      );
    }
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  modalHideDelClientDoc = () => {
    window.$("#modalDeleteAlertClientDoc").modal("hide");
  };

  modalHideDelAgentDoc = () => {
    window.$("#modalDeleteAlertAgentDoc").modal("hide");
  };

  modalHideApproveAgentDoc = () => {
    window.$("#modalApproveAlertAgent").modal("hide");
  };

  modalHideRejectAgentDoc = () => {
    window.$("#modalRejectAlertAgent").modal("hide");
  };

  modalHideApproveClientDoc = () => {
    window.$("#modalApproveAlertClient").modal("hide");
  };

  downloadCreditAppPdf = async (d_f_name) => {
    //console.log("d_f_name", d_f_name);

    var url = APP_LIVE_URL + "files_data/collaterals/" + d_f_name;
    //console.log("url: ", url);

    let file_extension = d_f_name.split(".").pop().toLowerCase();

    if (["png", "jpg", "jpeg", "pdf"].includes(file_extension)) {
      window.open(url, "_blank");
      return;
    }

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  downloadCreditAppPdfSubmission = async (d_f_name) => {
    var url = d_f_name;
    //console.log("url: ", url);

    let file_extension = d_f_name.split(".").pop().toLowerCase();

    if (["png", "jpg", "jpeg", "pdf"].includes(file_extension)) {
      window.open(url, "_blank");
      return;
    }

    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      });
  };

  setNewCollateral = async () => {
    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      document_file: "",
    });
    window.$("#modalNewCollateral").modal("show");
    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  setUpdateCollateral = async (value, tableMeta) => {
    let document_id = tableMeta.rowData[4];

    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      allow_document_submission: "",
      selected_accountno: null,
      doc_id: document_id,
    });

    const servicesResponce = await getSingleMarketingCollaterals(
      auth.getAccount(),
      auth.getToken(),
      document_id
    );
    //console.log("Single Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        single_document_details: [],
        tableLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const documentData = servicesResponce.data.data[0];
      this.setState({
        single_document_details: servicesResponce.data.data,
        selectAllUpdateAgent: servicesResponce.data.AgentInfo,
        selectAllUpdateClient: servicesResponce.data.ClientInfo,
        document_title: documentData[0],
        previously_file: documentData[2],
        group_title: documentData[3],
        group_id: documentData[7],
        allow_document_submission: documentData[8],
        tableLoader: false,
      });

      // Update selected_accountno here
      this.setState({
        selected_accountno: {
          value: this.state.group_title,
          label: this.state.group_title,
        },
      });

      window.$("#modalUpdateCollateral").modal("show");
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
    }

    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  setUpdateCollateralByID = async (collaterals_id) => {
    this.setState({
      docErrorMessage: "",
      docSuccessMessage: "",
      document_title: "",
      allow_document_submission: "",
      selected_accountno: null,
      doc_id: collaterals_id,
    });

    const servicesResponce = await getSingleMarketingCollaterals(
      auth.getAccount(),
      auth.getToken(),
      collaterals_id
    );
    //console.log("Single Data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        single_document_details: [],
        tableLoader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const documentData = servicesResponce.data.data[0];
      this.setState({
        single_document_details: servicesResponce.data.data,
        selectAllUpdateAgent: servicesResponce.data.AgentInfo,
        selectAllUpdateClient: servicesResponce.data.ClientInfo,
        document_title: documentData[0],
        previously_file: documentData[2],
        group_title: documentData[3],
        group_id: documentData[7],
        allow_document_submission: documentData[8],
        tableLoader: false,
      });

      // Update selected_accountno here
      this.setState({
        selected_accountno: {
          value: this.state.group_title,
          label: this.state.group_title,
        },
      });

      window.$("#modalUpdateCollateral").modal("show");
    } else {
      this.setState({
        tableData: [],
        tableLoader: false,
      });
    }

    setTimeout(() => {
      this.setState({
        formLoader: false,
      });
    }, 2000);
  };

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM TT");
  };

  defaultText = (value, tableMeta) => {
    let is_default = value;

    if (is_default === 0) {
      return (
        <span className="badge badge-outline-danger">
          <em className="icon ni ni-signin"></em> Inactive
        </span>
      );
    } else if (is_default === 1) {
      return (
        <span className="badge badge-outline-success">
          <em className="icon ni ni-done"></em> Active
        </span>
      );
    }
  };

  assignedTo = (value, tableMeta) => {
    return (
      <span
        className="badge badge-outline-secondary"
        //onClick={() => this.openAttachedUsers(value, tableMeta)}
        onClick={() => {
          this.setUpdateCollateral(value, tableMeta);
          setTimeout(() => {
            this.handleUpdateDocumentsClick();
          }, 3000);
        }}
        style={{ cursor: "pointer" }}
      >
        Assigned Users
      </span>
    );
  };

  assignedToView = (value, tableMeta) => {
    // Check if total_viewed is greater than 1
    const shouldShowBadge = tableMeta.rowData[8];

    //console.log("shouldShowBadge", shouldShowBadge);

    return (
      <>
        {shouldShowBadge > 0 ? (
          <Badge
            badgeContent={shouldShowBadge}
            color="error"
            classes={{ anchorOriginTopRightRectangle: "custom-badge-position" }}
          >
            <span
              className="badge badge-outline-secondary"
              onClick={() => this.openAttachedUsers(value, tableMeta)}
              style={{ cursor: "pointer" }}
            >
              View Submissions
            </span>
          </Badge>
        ) : (
          <span
            className="badge badge-outline-secondary"
            onClick={() => this.openAttachedUsers(value, tableMeta)}
            style={{ cursor: "pointer" }}
          >
            View Submissions
          </span>
        )}
      </>
    );
  };

  openAttachedClients = async (value, tableMeta) => {
    let document_title = tableMeta.rowData[0];
    let document_id = tableMeta.rowData[4];
    this.setState({
      client_details: [],
      document_id: document_id,
    });
    const servicesResponce = await getAttachedClients(
      auth.getAccount(),
      auth.getToken(),
      document_title
    );
    //console.log("openAttachedClients.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "Clients not found."
    ) {
      this.setState({
        approveErrorMessage: "Clients not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        client_details: servicesResponce.data.data,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the client files. Please try again.",
        disabled: false,
      });
    }
    //
    window.$("#modalAttachedClients").modal("show");
  };

  openAttachedUsers = async (value, tableMeta) => {
    let collaterals_id = tableMeta.rowData[4];
    let document_title = tableMeta.rowData[0];
    const client_viewed = tableMeta.rowData[7];
    const agent_viewed = tableMeta.rowData[6];

    this.setState({
      agent_details: [],
      collaterals_id: collaterals_id,
      document_title: document_title,
      client_viewed: client_viewed,
      agent_viewed: agent_viewed,
      total_records: 0,
      total_approved: 0,
      total_rejected: 0,
      total_approved_agents: 0,
      total_rejected_agents: 0,
      total_approved_clients: 0,
      total_rejected_clients: 0,
      total_records_agents: 0,
      total_records_clients: 0,
      total_pending: 0,
      total_pending_agents: 0,
      total_pending_clients: 0,
    });

    const servicesResponce = await getAttachedAgents(
      auth.getAccount(),
      auth.getToken(),
      document_title,
      collaterals_id,
      this.state.selectedTab
    );

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "Agents not found."
    ) {
      this.setState({
        approveErrorMessage: "Agents not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      const agentDetails = servicesResponce.data.data.agents || [];
      const clientDetails = servicesResponce.data.data.clients || [];
      const totalRecords = agentDetails.length + clientDetails.length;

      const total_records_agents = agentDetails.length;
      const total_records_clients = clientDetails.length;

      // Combine agentDetails and clientDetails to calculate overall approved and rejected
      const combinedDetails = [...agentDetails, ...clientDetails];

      const totalApproved = combinedDetails.filter(
        (item) => item.is_approved === 1
      ).length;
      const totalRejected = combinedDetails.filter(
        (item) => item.is_approved === 0
      ).length;
      const totalPending = combinedDetails.filter(
        (item) => item.is_approved === null
      ).length;

      // Calculate approved and rejected separately for agents and clients
      const totalApprovedAgents = agentDetails.filter(
        (agent) => agent.is_approved === 1
      ).length;
      const totalRejectedAgents = agentDetails.filter(
        (agent) => agent.is_approved === 0
      ).length;
      const totalPendingAgents = agentDetails.filter(
        (agent) => agent.is_approved === null
      ).length;

      const totalApprovedClients = clientDetails.filter(
        (client) => client.is_approved === 1
      ).length;
      const totalRejectedClients = clientDetails.filter(
        (client) => client.is_approved === 0
      ).length;
      const totalPendingClients = clientDetails.filter(
        (client) => client.is_approved === null
      ).length;

      this.setState({
        agent_details: agentDetails,
        client_details: clientDetails,
        total_records: totalRecords, // Store the sum of total records
        total_approved: totalApproved, // Store the total approved records (combined)
        total_rejected: totalRejected, // Store the total rejected records (combined)
        total_approved_agents: totalApprovedAgents, // Store the total approved agents
        total_rejected_agents: totalRejectedAgents, // Store the total rejected agents
        total_approved_clients: totalApprovedClients, // Store the total approved clients
        total_rejected_clients: totalRejectedClients, // Store the total rejected clients
        total_records_agents: total_records_agents,
        total_records_clients: total_records_clients,
        total_pending: totalPending,
        total_pending_agents: totalPendingAgents,
        total_pending_clients: totalPendingClients,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the agent files. Please try again.",
        disabled: false,
      });
    }

    this.updateDocumentViewedStatus();

    window.$("#modalAttachedAgents").modal("show");
  };

  reloadopenAttachedUsers = async () => {
    const servicesResponce = await getAttachedAgents(
      auth.getAccount(),
      auth.getToken(),
      this.state.document_title,
      this.state.collaterals_id,
      this.state.selectedTab
    );
    //console.log("openAttachedUsers.data: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      servicesResponce.data.status === 404 &&
      servicesResponce.data.message === "Agents not found."
    ) {
      this.setState({
        approveErrorMessage: "Agetns not found.",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agent_details: servicesResponce.data.data.agents,
        client_details: servicesResponce.data.data.clients,
      });
    } else {
      this.setState({
        approveErrorMessage:
          "There is an error while displaying the agent files. Please try again.",
        disabled: false,
      });
    }
  };

  updateDocumentViewedStatus = async (selectedTab) => {
    try {
      const servicesResponse = await updateDocumentViewedStatusApi(
        auth.getAccount(),
        auth.getToken(),
        selectedTab,
        this.state.collaterals_id
      );
      //console.log("updateDocumentViewedStatus.data: ", servicesResponse.data);

      if (
        servicesResponse.data.status === 403 ||
        servicesResponse.data.errors === "authentication missing" ||
        servicesResponse.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        servicesResponse.data.status === 404 &&
        servicesResponse.data.message === "Agents not found."
      ) {
        this.setState({
          approveErrorMessage: "Agents not found.",
        });
      } else if (servicesResponse.data.status === 200) {
        const { tableUpdated, totalViewedNoCount, message } =
          servicesResponse.data;

        if (tableUpdated === "clients") {
          this.setState({
            client_viewed: 0,
            total_viewed: totalViewedNoCount,
          });
        } else if (tableUpdated === "agents") {
          this.setState({
            agent_viewed: 0,
            total_viewed: totalViewedNoCount,
          });
        }

        if (message === "success") {
          console.log("Document viewed status successfully updated.");
        } else if (message === "no rows affected") {
          console.log("No rows were affected by the update.");
        }
      }
    } catch (error) {
      console.error("Error updating document viewed status: ", error);
    }
    this.reloadGetCollaterals();
  };

  viewDownloadBtns = (value, tableMeta) => {
    const rowIndex = tableMeta.rowIndex;
    let domain_str = value;

    return (
      <div>
        <a
          onClick={() => {
            this.setState({ downloadingIndex: rowIndex });

            // Start loader and stop after 1 second
            setTimeout(() => {
              this.setState({ downloadingIndex: null });
              // Start download after loader stops
              this.downloadCreditAppPdf(value);
            }, 2000);
          }}
          style={{ cursor: "pointer" }}
          title="Download Document"
        >
          {this.state.downloadingIndex === rowIndex ? (
            <div className="text-center">
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <span className="badge badge-outline-secondary">
              {" "}
              <em className="icon ni ni-file-pdf"></em> View
            </span>
          )}
        </a>
      </div>
    );
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation
    const allowedFileTypes = [
      ".png",
      ".jpg",
      ".jpeg",
      ".doc",
      ".docx",
      ".pdf",
      ".csv",
      ".xlsx",
      ".xls",
    ];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        docErrorMessage:
          "Invalid file type. Please select a valid file format.",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 5MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        docErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      document_file: selectedFile,
      docErrorMessage: "",
      document_file_name: selectedFile.name,
    });
    //return true;
  };

  saveNewCollateral = async () => {
    this.setState({ isProcessing: true });

    const {
      document_file,
      document_file_name,
      document_title,
      group_id,
      group_title,
      selectedClients,
      selectedAgents,
      selectedTab,
      allow_document_submission,
    } = this.state;
    if (document_title === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Title field is required.",
        isProcessing: false,
      });
    } else if (document_file_name === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a document to upload.",
        isProcessing: false,
      });
    } else if (group_id === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a group to upload.",
        isProcessing: false,
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", document_file);
      formData.append("document_title", document_title);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", document_file_name);
      formData.append("collateral_group_id", group_id);
      formData.append("collateral_group_title", group_title);
      formData.append("selectedClients", JSON.stringify(selectedClients));
      formData.append("selectedAgents", JSON.stringify(selectedAgents));
      formData.append("allow_document_submission", allow_document_submission);

      axios
        .post(
          process.env.REACT_APP_API_URL +
            "collaterals/add_marketing_collaterals",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404 && res.data.error === "error") {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: res.data.message,
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              docSuccessMessage: "Document added successfully.",
              docErrorMessage: "",
              document_file_name: "",
              document_file: "",
              isProcessing: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage:
                "There is some error while adding the marketing document.",
              isProcessing: false,
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "",
      });
    }, 3000);
  };

  saveUpdateCollateral = async () => {
    this.setState({ isProcessing: true });

    const {
      document_file,
      document_file_name,
      document_title,
      allow_document_submission,
      group_id,
      group_title,
      selectedClients,
      selectedAgents,
      selectedTab,
      selectedClientsUpdate,
      selectedAgentsUpdate,
      doc_id,
      previously_file,
    } = this.state;
    if (document_title === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Title field is required.",
        isProcessing: false,
      });
    } else if (group_id === "") {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "Please choose a group to upload.",
        isProcessing: false,
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("file", document_file);
      formData.append("document_title", document_title);
      formData.append("accountno", auth.getAccount());
      formData.append("fileName", document_file_name);
      formData.append("collateral_group_id", group_id);
      formData.append("collateral_group_title", group_title);
      formData.append("selectedClients", JSON.stringify(selectedClients));
      formData.append("allow_document_submission", allow_document_submission);
      formData.append(
        "selectedClientsUpdate",
        JSON.stringify(selectedClientsUpdate)
      );
      formData.append("selectedAgents", JSON.stringify(selectedAgents));
      formData.append(
        "selectedAgentsUpdate",
        JSON.stringify(selectedAgentsUpdate)
      );
      formData.append("doc_id", JSON.stringify(doc_id));
      formData.append("previously_file", previously_file);

      axios
        .post(
          process.env.REACT_APP_API_URL +
            "collaterals/update_marketing_collaterals",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
            },
          },
          {}
        )
        .then((res) => {
          console.log("File Upload RES ", res.data);
          if (
            res.data.status === 403 ||
            res.data.errors === "authentication missing" ||
            res.data.errors === "jwt expired"
          ) {
            auth.logout();
          } else if (res.data.status === 404 && res.data.error === "error") {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage: res.data.message,
            });
            //window.location.replace("/error");
          } else if (
            res.data.status === 200 &&
            res.data.message === "success"
          ) {
            this.setState({
              docSuccessMessage: "Document updated successfully.",
              docErrorMessage: "",
              document_file_name: "",
              document_file: "",
              isProcessing: false,
            });
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          } else {
            this.setState({
              docSuccessMessage: "",
              docErrorMessage:
                "There is some error while adding the marketing document.",
              isProcessing: false,
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        docSuccessMessage: "",
        docErrorMessage: "",
      });
    }, 3000);
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  // handleSelectChangeClient = (selectedOptions) => {
  //   this.setState({ selectedClients: selectedOptions }, () => {
  //     let temp = false;
  //     if (this.state.clientsList.length === this.state.selectedClients.length) {
  //       temp = true;
  //     }
  //     this.setState({ selectAllClient: temp });
  //   });
  // };

  // handleSelectChangeAgent = (selectedOptions) => {
  //   this.setState({ selectedAgents: selectedOptions }, () => {
  //     let temp = false;
  //     if (this.state.agentList.length === this.state.selectedAgents.length) {
  //       temp = true;
  //     }
  //     this.setState({ selectAllAgent: temp });
  //   });
  // };

  // handleSelectAllChangeClient = () => {
  //   const { clientsList, selectAllClient } = this.state;
  //   if (!selectAllClient) {
  //     this.setState({
  //       selectedClients: clientsList,
  //       selectAllClient: true,
  //     });
  //   } else {
  //     // Unselect all agents
  //     this.setState({
  //       selectedClients: [],
  //       selectAllClient: false,
  //     });
  //   }
  // };

  // handleSelectAllChangeAgent = () => {
  //   const { agentList, selectAllAgent } = this.state;
  //   if (!selectAllAgent) {
  //     this.setState({
  //       selectedAgents: agentList,
  //       selectAllAgent: true,
  //     });
  //   } else {
  //     // Unselect all agents
  //     this.setState({
  //       selectedAgents: [],
  //       selectAllAgent: false,
  //     });
  //   }
  // };

  handleSelectChange = (selectedOptions) => {
    const { selectedTab } = this.state;
    if (selectedTab === "clients") {
      this.setState({ selectedClients: selectedOptions }, () => {
        let temp = false;
        if (
          this.state.clientsList.length === this.state.selectedClients.length
        ) {
          temp = true;
        }
        this.setState({ selectAllClient: temp });
      });
    } else if (selectedTab === "agents") {
      this.setState({ selectedAgents: selectedOptions }, () => {
        let temp = false;
        if (this.state.agentList.length === this.state.selectedAgents.length) {
          temp = true;
        }
        this.setState({ selectAllAgent: temp });
      });
    }
  };

  handleSelectChangeUpdate = (selectedOptions) => {
    const {
      selectedTab,
      selectAllUpdateClient,
      clientsList,
      selectAllUpdateAgent,
      agentList,
    } = this.state;

    // console.log("Selected Options:", selectedOptions);
    // console.log("selectAllUpdateClient:", selectAllUpdateClient);

    if (selectedTab === "clients") {
      const filteredSelectedOptions = selectedOptions.filter(
        (option) =>
          !selectAllUpdateClient.some(
            (client) => client.accountno === option.value
          )
      );

      // console.log("Filtered Selected Clients:", filteredSelectedOptions);

      this.setState(
        {
          selectedClients: selectedOptions,
          selectedClientsUpdate: filteredSelectedOptions,
        },
        () => {
          let temp = false;
          if (clientsList.length === this.state.selectedClients.length) {
            temp = true;
          }
          // console.log("Are all clients selected?", temp);
          this.setState({ selectAllClientUpdate: temp });
        }
      );
    } else if (selectedTab === "agents") {
      const filteredSelectedOptions = selectedOptions.filter(
        (option) =>
          !selectAllUpdateAgent.some(
            (client) => client.accountno === option.value
          )
      );

      // console.log("Filtered Selected Agents:", filteredSelectedOptions);

      this.setState(
        {
          selectedAgents: selectedOptions,
          selectedAgentsUpdate: filteredSelectedOptions,
        },
        () => {
          let temp = false;
          if (
            this.state.agentList.length === this.state.selectedAgents.length
          ) {
            temp = true;
          }
          // console.log("Are all agents selected?", temp);
          this.setState({ selectAllAgentUpdate: temp });
        }
      );
    }
  };

  handleSelectAllChange = () => {
    const {
      clientsList,
      agentList,
      selectAllClient,
      selectAllAgent,
      selectedTab,
    } = this.state;
    if (selectedTab === "clients") {
      if (!selectAllClient) {
        this.setState({
          selectedClients: clientsList,
          selectAllClient: true,
        });
      } else {
        this.setState({
          selectedClients: [],
          selectAllClient: false,
        });
      }
    } else if (selectedTab === "agents") {
      if (!selectAllAgent) {
        this.setState({
          selectedAgents: agentList,
          selectAllAgent: true,
        });
      } else {
        this.setState({
          selectedAgents: [],
          selectAllAgent: false,
        });
      }
    }
  };

  handleSelectAllChangeUpdate = () => {
    const {
      clientsList,
      agentList,
      selectAllClientUpdate,
      selectAllAgentUpdate,
      selectedTab,
    } = this.state;
    if (selectedTab === "clients") {
      if (!selectAllClientUpdate) {
        this.setState({
          selectedClients: clientsList,
          selectAllClientUpdate: true,
        });
      } else {
        this.setState({
          selectedClients: [],
          selectAllClientUpdate: false,
        });
      }
    } else if (selectedTab === "agents") {
      if (!selectAllAgentUpdate) {
        this.setState({
          selectedAgents: agentList,
          selectAllAgentUpdate: true,
        });
      } else {
        this.setState({
          selectedAgents: [],
          selectAllAgentUpdate: false,
        });
      }
    }
  };

  handleUpdateDocumentsClick = () => {
    const {
      clientsList,
      selectAllUpdateClient,
      agentList,
      selectAllUpdateAgent,
    } = this.state;

    const filteredClients = clientsList.filter((client) =>
      selectAllUpdateClient.some((agent) => agent.accountno === client.value)
    );

    const filteredAgents = agentList.filter((agent) =>
      selectAllUpdateAgent.some((client) => client.accountno === agent.value)
    );

    this.setState({
      selectedClients: filteredClients,
      selectedAgents: filteredAgents,
    });
  };

  handleUpdateDocumentsClickDivert = () => {
    const {
      clientsList,
      selectAllUpdateClient,
      agentList,
      selectAllUpdateAgent,
    } = this.state;

    this.setState({
      selectedClients: [],
      selectedAgents: [],
    });
  };

  validateIPAddress = (ipAddress) => {
    const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
    const isValid = ipRegex.test(ipAddress);
    //console.log(`Is IP Address valid? ${isValid}`);
    return isValid;
  };

  validateDomain = (domain) => {
    const domainRegex = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = domainRegex.test(domain);
    //console.log(`Is domain valid? ${isValid}`);
    if (isValid) {
      return true;
    }
    return false;
  };

  validateEmailAdd = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(email);
    //console.log("isValidEmail: " + " " + email + " = " + isValidEmail);
    if (isValidEmail) {
      return true;
    }
    return false;
  };

  handleAccordionToggle(id, index) {
    const element = document.getElementById(id);
    const isActive = this.state.activeAccordion === index;

    // Close all accordions first
    const allAccordionItems = document.querySelectorAll(".accordion-row");
    allAccordionItems.forEach((item) => {
      item.classList.remove("show");
    });

    // Toggle the clicked accordion
    if (!isActive) {
      element.classList.add("show");
      this.setState({ activeAccordion: index });
    } else {
      this.setState({ activeAccordion: null });
    }
  }

  render() {
    console.log("Agent Views", this.state.agent_viewed);
    const {
      selectedClients,
      selectedAgents,
      selectAllClient,
      selectAllAgent,
      selectedTab,
      selectAllClientUpdate,
      selectAllAgentUpdate,
    } = this.state;

    return (
      <div className="nk-content " id="Services_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">
                      <PageTitle
                        name="Documents"
                        icon="icon ni ni-inbox-fill"
                      />
                    </h3>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                      <div
                        className="toggle-expand-content"
                        data-content="pageMenu"
                      >
                        <ul className="nk-block-tools g-3">
                          <li className="nk-block-tools-opt">
                            <button
                              type="button"
                              onClick={() => {
                                this.setNewCollateral();
                                setTimeout(() => {
                                  this.handleUpdateDocumentsClickDivert();
                                }, 100);
                              }}
                              className="btn btn-primary"
                            >
                              <em className="icon ni ni-plus-round"></em>
                              &nbsp;Add New Document
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#tabItem1"
                    >
                      Documents
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#tabItem2"
                      onClick={this.getMarketingCollateralsArchived}
                    >
                      Archived
                    </a>
                  </li>
                </ul>
                {/* Table 5th Col Start */}
                <div class="tab-content">
                  <div class="tab-pane active" id="tabItem1">
                    <div className="row g-gs">
                      <div className="col-xxl-12">
                        <div className="nk-block nk-block-lg">
                          <div class="card" style={{ borderRadius: "0px" }}>
                            <div class="card-inner">
                              <div className="row g-gs mb-3">
                                <div className="col-md-6">
                                  <div class="form-group">
                                    <div class="form-label-group">
                                      <label class="form-label">
                                        Select Document Group
                                        <span class="text-danger"></span>
                                      </label>
                                    </div>
                                    <div class="form-control-group">
                                      <Select
                                        options={
                                          this.state.all_collaterals_groups
                                        }
                                        name="selected_accountno"
                                        placeholder="Select a document group."
                                        value={this.state.selected_accountno}
                                        autoComplete="off"
                                        emptyMessage="Clients not found"
                                        onChange={this.handleChangeSearchBar}
                                        isClearable
                                        isSearchable
                                        components={animatedComponents}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            lineHeight: "40px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTable
                              columns={this.state.columnServices}
                              tableData={this.state.tableData}
                              title=""
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="tabItem2">
                    <div className="row g-gs">
                      <div className="col-xxl-12">
                        <div className="nk-block nk-block-lg">
                          {/* START DATATABLE */}
                          {this.state.tableLoader === true ? (
                            tableLoader()
                          ) : (
                            <DataTableArchived
                              columns={this.state.columnServicesArchived}
                              tableData={this.state.tableDataArchived}
                              title=""
                            />
                          )}

                          {/* END TABLE */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}

                  {/* Table 5th Col End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalNewCollateral"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Add New Document
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.docErrorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.docErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.docSuccessMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.docSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="document_title"
                                      name="document_title"
                                      type="text"
                                      label="Title"
                                      defaultValue={this.state.document_title}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 -_]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Title of the document."
                                      autoFocus
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <Select
                                      options={
                                        this.state.all_collaterals_groups
                                      }
                                      name="selected_accountno"
                                      placeholder="Select a document group"
                                      //value={this.state.selected_accountno}
                                      autoComplete="off"
                                      emptyMessage="Clients not found"
                                      onChange={this.handleChangeSearch}
                                      isClearable
                                      isSearchable
                                      components={animatedComponents}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          lineHeight: "40px",
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      id="allow_document_submission"
                                      name="allow_document_submission"
                                      select
                                      label="Allow document submission"
                                      value={
                                        this.state.allow_document_submission
                                      }
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText="Allow document submission here."
                                      variant="outlined"
                                      fullWidth
                                      error={
                                        this.state.errorClientSubmission ===
                                        "error"
                                      }
                                    >
                                      <option key="" value=""></option>
                                      <option key="5" value="Yes">
                                        Yes
                                      </option>
                                      <option key="10" value="No">
                                        No
                                      </option>
                                    </MutextField>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "clients"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "clients",
                                            })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <em class="icon ni ni-user-check"></em>
                                          <span>Client</span>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "agents"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "agents",
                                            })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <em class="icon ni ni-user-check"></em>
                                          <span>Agent</span>
                                        </a>
                                      </li>
                                    </ul>
                                    <div class="tab-content">
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "clients"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllClient}
                                                onChange={
                                                  this.handleSelectAllChange
                                                }
                                                name="selectAllClient"
                                              />
                                            }
                                            label="Select all Clients"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedClients"
                                            value={selectedClients}
                                            isMulti
                                            name="clients"
                                            options={this.state.clientsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectChange}
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "agents"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllAgent}
                                                onChange={
                                                  this.handleSelectAllChange
                                                }
                                                name="selectAllAgent"
                                              />
                                            }
                                            label="Select all Agents"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedAgents"
                                            value={selectedAgents}
                                            isMulti
                                            name="agents"
                                            options={this.state.agentList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={this.handleSelectChange} // Change this to handleSelectChange
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group"></div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <label className="form-label">
                                      Upload File
                                      <sup> *</sup>
                                    </label>
                                  </div>
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="document_file"
                                      className="file-block"
                                      name="document_file"
                                      defaultValue={this.state.document_file}
                                      accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .csv, .xlsx, .xls"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.saveNewCollateral()}
                                  className="btn btn-primary"
                                  disabled={
                                    this.state.disabled ||
                                    this.state.isProcessing
                                  } // disable button when processing
                                >
                                  {this.state.isProcessing ? (
                                    <span>Processing...</span>
                                  ) : (
                                    <span>Submit</span>
                                  )}
                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        Supported file formats include: PNG,
                                        JPG, JPEG, DOC, DOCX, PDF, CSV, XLSX,
                                        XLS.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                  {/* START DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlert"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDel();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Delete Collateral?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDelete !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessageDelete}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelte !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessageDelte}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="caption-text">
                                Are you sure you want to delete this document
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDel();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteMarketingCollaterals();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>DELETING...</span>
                                ) : (
                                  <span>DELETE</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END DELETE MODAL  */}
                  {/**** Open Attach Client Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalAttachedClients"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Clients</h5>
                        </div>
                        <div class="modal-body">
                          <div class="g-4">
                            <div id="no-more-tables-new">
                              <table class="table table-bordered">
                                <thead class="thead-light">
                                  <tr>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Account
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Name</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Compnay
                                      </span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">Email</span>
                                    </th>
                                    <th class="tb-col-ip">
                                      <span class="overline-title">
                                        Delete Document
                                      </span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.client_details &&
                                  this.state.client_details.length > 0 ? (
                                    <>
                                      {this.state.client_details.map(
                                        (details, index) => (
                                          <tr key={`idx${index}`}>
                                            <td class="tb-col-os">
                                              {details[1]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[2]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[3]}{" "}
                                            </td>
                                            <td class="tb-col-os">
                                              {details[4]}{" "}
                                            </td>
                                            <td className="tb-col-os">
                                              <span
                                                className="badge badge-outline-danger"
                                                onClick={() =>
                                                  this.handleDeleteClient(
                                                    details[0]
                                                  )
                                                } // Call handleDownload function with file URL
                                                style={{ cursor: "pointer" }}
                                              >
                                                <em
                                                  className="icon ni ni-trash"
                                                  style={{ fontSize: "18px" }}
                                                ></em>
                                              </span>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </>
                                  ) : (
                                    <tr>
                                      <td class="tb-col-os" colSpan={4}>
                                        <strong className="text-danger">
                                          Record not found
                                        </strong>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Attach Client Modal ***/}

                  {/**** Open Attach Agent Modal ***/}
                  <div
                    class="modal fade"
                    tabindex="-1"
                    id="modalAttachedAgents"
                  >
                    <div
                      class="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div class="modal-content">
                        <a
                          href="#"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em class="icon ni ni-cross"></em>
                        </a>
                        <div class="modal-header">
                          <h5 class="modal-title">Assigned Users</h5>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="form-control-group">
                              <ul className="nav nav-tabs">
                                <li className="nav-item">
                                  {this.state.client_viewed > 0 ? (
                                    <a
                                      className={`nav-link ${
                                        selectedTab === "clients"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        this.setState({
                                          selectedTab: "clients",
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <em className="icon ni ni-user-check"></em>
                                      <Badge
                                        badgeContent={this.state.client_viewed}
                                        color="error"
                                        classes={{
                                          anchorOriginTopRightRectangle:
                                            "custom-badge-position",
                                        }}
                                      >
                                        <span>Clients</span>
                                      </Badge>
                                    </a>
                                  ) : (
                                    <a
                                      className={`nav-link ${
                                        selectedTab === "clients"
                                          ? "active"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        this.setState({
                                          selectedTab: "clients",
                                        });
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <em className="icon ni ni-user-check"></em>
                                      <span>Client</span>
                                    </a>
                                  )}
                                </li>
                                <li className="nav-item">
                                  {this.state.agent_viewed > 0 ? (
                                    <a
                                      className={`nav-link ${
                                        selectedTab === "agents" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            selectedTab: "agents",
                                          },
                                          () => {
                                            // This callback is executed after the state has been updated
                                            if (this.state.agent_viewed !== 0) {
                                              this.updateDocumentViewedStatus(
                                                this.state.selectedTab
                                              );
                                            }
                                          }
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <em className="icon ni ni-user-check"></em>
                                      <Badge
                                        badgeContent={this.state.agent_viewed}
                                        color="error"
                                        classes={{
                                          anchorOriginTopRightRectangle:
                                            "custom-badge-position",
                                        }}
                                      >
                                        <span>Channel Partner</span>
                                      </Badge>
                                    </a>
                                  ) : (
                                    <a
                                      className={`nav-link ${
                                        selectedTab === "agents" ? "active" : ""
                                      }`}
                                      onClick={() => {
                                        this.setState(
                                          {
                                            selectedTab: "agents",
                                          },
                                          () => {
                                            // This callback is executed after the state has been updated
                                            if (this.state.agent_viewed !== 0) {
                                              this.updateDocumentViewedStatus(
                                                this.state.selectedTab
                                              );
                                            }
                                          }
                                        );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <em className="icon ni ni-user-check"></em>
                                      <span>Channel Partner</span>
                                    </a>
                                  )}
                                </li>

                                <li className="nav-item ml-auto mr-4">
                                  <a className="nav-link">
                                    <>
                                      Total Submissions:{" "}
                                      {this.state.total_records}&nbsp;| Total
                                      Approved:&nbsp;
                                      <span style={{ color: "green" }}>
                                        {this.state.total_approved}
                                      </span>
                                      &nbsp;| Total Rejected:&nbsp;
                                      <span style={{ color: "red" }}>
                                        {this.state.total_rejected}
                                      </span>
                                      &nbsp;| Total Pending:&nbsp;
                                      <span style={{ color: "orange" }}>
                                        {this.state.total_pending}
                                      </span>
                                    </>
                                  </a>
                                </li>
                              </ul>

                              <div class="tab-content">
                                <div
                                  className={`tab-pane ${
                                    selectedTab === "clients" ? "active" : ""
                                  }`}
                                >
                                  <div class="modal-body">
                                    <div
                                      class="g-4"
                                      style={{ margin: "-2rem" }}
                                    >
                                      <div
                                        style={{
                                          textAlign: "end",
                                        }}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{
                                            color: "#526484",
                                            fontWeight: 600,
                                            color: "#526484",
                                          }}
                                        >
                                          <>
                                            Submissions:{" "}
                                            {this.state.total_records_clients} |
                                            Approved:{" "}
                                            <span style={{ color: "green" }}>
                                              {
                                                this.state
                                                  .total_approved_clients
                                              }
                                            </span>{" "}
                                            | Rejected:{" "}
                                            <span style={{ color: "red" }}>
                                              {
                                                this.state
                                                  .total_rejected_clients
                                              }
                                            </span>{" "}
                                            | Pending:{" "}
                                            <span style={{ color: "orange" }}>
                                              {this.state.total_pending_clients}
                                            </span>
                                          </>
                                        </a>
                                      </div>
                                      <div id="no-more-tables-new">
                                        <table class="table table-bordered">
                                          <thead class="thead-light">
                                            <tr>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Info
                                                </span>
                                              </th>
                                              {/* <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Title
                                                </span>
                                              </th> */}
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Name
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Email
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Submission
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  View
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Status
                                                </span>
                                              </th>
                                              <th class="tb-col-ip">
                                                <span class="overline-title">
                                                  Action
                                                </span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.client_details &&
                                            this.state.client_details.length >
                                              0 ? (
                                              <>
                                                {this.state.client_details.map(
                                                  (details, index) => (
                                                    <>
                                                      <tr
                                                        key={`idx${index}`}
                                                        // onClick={() =>
                                                        //   this.handleAccordionToggle(
                                                        //     `accordion-item-${index}`,
                                                        //     index
                                                        //   )
                                                        // }
                                                        id={`line-item-${index}`}
                                                      >
                                                        <td className="tb-col-ip text-center">
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleAccordionToggle(
                                                                `accordion-item-${index}`,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            <em
                                                              className={`icon ni ${
                                                                this.state
                                                                  .activeAccordion ===
                                                                index
                                                                  ? "ni-minus"
                                                                  : "ni-plus"
                                                              }`}
                                                            ></em>
                                                          </span>
                                                        </td>
                                                        {/* <td className="tb-col-os">
                                                          {
                                                            this.state
                                                              .document_title
                                                          }
                                                        </td> */}
                                                        <td className="tb-col-os">
                                                          {details.name}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.email}
                                                        </td>
                                                        <td className="tb-col-os text-center">
                                                          <span
                                                            className={`badge ${
                                                              details.submission ===
                                                              "no"
                                                                ? "badge-outline-danger"
                                                                : "badge-outline-success"
                                                            }`}
                                                          >
                                                            {details.submission ===
                                                            "yes"
                                                              ? "Yes"
                                                              : "No"}
                                                          </span>
                                                        </td>
                                                        <td className="tb-col-os text-center">
                                                          {details.submission ===
                                                          "no" ? (
                                                            <span
                                                              style={{
                                                                opacity: 0.5,
                                                              }}
                                                            >
                                                              -----
                                                            </span>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",

                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                              onClick={() => {
                                                                this.downloadCreditAppPdfSubmission(
                                                                  details.file_path
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-download"></em>
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.submission ===
                                                          "no" ? (
                                                            <span
                                                              style={{
                                                                opacity: 0.5,
                                                              }}
                                                            >
                                                              -----
                                                            </span>
                                                          ) : details.is_approved ===
                                                            1 ? (
                                                            <span className="badge badge-outline-success">
                                                              Approved
                                                            </span>
                                                          ) : details.is_approved ===
                                                            0 ? (
                                                            <span className="badge badge-outline-danger">
                                                              Rejected
                                                            </span>
                                                          ) : (
                                                            <span className="badge badge-outline-warning">
                                                              Pending Approval
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            {details.submission ===
                                                              "no" ||
                                                            details.is_approved ===
                                                              0 ? (
                                                              <Tooltip
                                                                title="Remove this document."
                                                                placement="top"
                                                              >
                                                                <span
                                                                  className="badge badge-outline-dark"
                                                                  onClick={() =>
                                                                    this.handleDeleteAgent(
                                                                      details.accountno
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    marginTop:
                                                                      "4px",
                                                                  }}
                                                                >
                                                                  Remove
                                                                </span>
                                                              </Tooltip>
                                                            ) : (
                                                              <>
                                                                {details.is_approved ===
                                                                1 ? (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      gap: "5px",
                                                                      marginTop:
                                                                        "4px",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title="Remove this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-dark"
                                                                        onClick={() =>
                                                                          this.handleDeleteAgent(
                                                                            details.accountno
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Remove
                                                                      </span>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Reject this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-danger"
                                                                        onClick={() =>
                                                                          this.handleRejectAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            false
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Reject
                                                                      </span>
                                                                    </Tooltip>
                                                                  </div>
                                                                ) : (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      gap: "5px",
                                                                      marginTop:
                                                                        "4px",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title="Approve this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-success"
                                                                        onClick={() =>
                                                                          this.handleAproveAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            details.document_file,
                                                                            true
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Approve
                                                                      </span>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Reject this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-danger"
                                                                        onClick={() =>
                                                                          this.handleRejectAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            false
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Reject
                                                                      </span>
                                                                    </Tooltip>
                                                                  </div>
                                                                )}
                                                              </>
                                                            )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr
                                                        id={`accordion-item-${index}`}
                                                        className="accordion-row collapse"
                                                      >
                                                        <td colSpan="7">
                                                          <div
                                                            className="accordion-inner"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              padding: "15px",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginBottom:
                                                                  "15px",
                                                                color: "#333",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              Document Info
                                                            </h4>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.6",
                                                                color: "#555",
                                                              }}
                                                            >
                                                              <div>
                                                                <strong>
                                                                  Account#:
                                                                </strong>{" "}
                                                                {
                                                                  details.accountno
                                                                }
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Name:
                                                                </strong>{" "}
                                                                {details.name}
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Email:
                                                                </strong>{" "}
                                                                {details.email}
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Status:{" "}
                                                                </strong>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      details.is_approved ===
                                                                      1
                                                                        ? "green"
                                                                        : details.is_approved ===
                                                                          0
                                                                        ? "red"
                                                                        : "orange",
                                                                  }}
                                                                >
                                                                  {details.is_approved ===
                                                                  1
                                                                    ? "Approved"
                                                                    : details.is_approved ===
                                                                      0
                                                                    ? "Rejected"
                                                                    : "Pending Approval"}
                                                                </span>
                                                              </div>
                                                              {details.is_approved ===
                                                                0 && (
                                                                <div>
                                                                  <strong>
                                                                    Reason:
                                                                  </strong>{" "}
                                                                  {
                                                                    details.reason
                                                                  }
                                                                </div>
                                                              )}
                                                              <div>
                                                                <strong>
                                                                  Date Added:
                                                                </strong>{" "}
                                                                {this.format_date(
                                                                  details.addedOn
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <tr>
                                                <td
                                                  className="tb-col-os"
                                                  colSpan={7}
                                                >
                                                  <strong className="text-danger">
                                                    No clients are currently
                                                    assigned to this document.
                                                  </strong>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane ${
                                    selectedTab === "agents" ? "active" : ""
                                  }`}
                                >
                                  <div className="modal-body">
                                    <div
                                      className="g-4"
                                      style={{ margin: "-2rem" }}
                                    >
                                      <div
                                        style={{
                                          textAlign: "end",
                                        }}
                                      >
                                        <a
                                          className="nav-link"
                                          style={{
                                            color: "#526484",
                                            fontWeight: 600,
                                            color: "#526484",
                                          }}
                                        >
                                          <>
                                            Submissions:{" "}
                                            {this.state.total_records_agents} |
                                            Approved:{" "}
                                            <span style={{ color: "green" }}>
                                              {this.state.total_approved_agents}
                                            </span>{" "}
                                            | Rejected:{" "}
                                            <span style={{ color: "red" }}>
                                              {this.state.total_rejected_agents}
                                            </span>{" "}
                                            | Pending:{" "}
                                            <span style={{ color: "orange" }}>
                                              {this.state.total_pending_agents}
                                            </span>
                                          </>
                                        </a>
                                      </div>
                                      <div id="no-more-tables-new">
                                        <table className="table table-bordered">
                                          <thead className="thead-light">
                                            <tr>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Info
                                                </span>
                                              </th>
                                              {/* <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Title
                                                </span>
                                              </th> */}
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Name
                                                </span>
                                              </th>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Email
                                                </span>
                                              </th>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Submission
                                                </span>
                                              </th>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  View
                                                </span>
                                              </th>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Status
                                                </span>
                                              </th>
                                              <th className="tb-col-ip">
                                                <span className="overline-title">
                                                  Action
                                                </span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.agent_details &&
                                            this.state.agent_details.length >
                                              0 ? (
                                              <>
                                                {this.state.agent_details.map(
                                                  (details, index) => (
                                                    <>
                                                      <tr
                                                        key={`idx${index}`}
                                                        id={`line-item-${index}`}
                                                      >
                                                        <td className="tb-col-ip text-center">
                                                          <span
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            onClick={() =>
                                                              this.handleAccordionToggle(
                                                                `accordion-item-agent-${index}`,
                                                                index
                                                              )
                                                            }
                                                          >
                                                            <em
                                                              className={`icon ni ${
                                                                this.state
                                                                  .activeAccordion ===
                                                                index
                                                                  ? "ni-minus"
                                                                  : "ni-plus"
                                                              }`}
                                                            ></em>
                                                          </span>
                                                        </td>
                                                        {/* <td className="tb-col-os">
                                                          {
                                                            this.state
                                                              .document_title
                                                          }
                                                        </td> */}
                                                        <td className="tb-col-os">
                                                          {details.name}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.email}
                                                        </td>
                                                        <td className="tb-col-os text-center">
                                                          <span
                                                            className={`badge ${
                                                              details.submission ===
                                                              "no"
                                                                ? "badge-outline-danger"
                                                                : "badge-outline-success"
                                                            }`}
                                                          >
                                                            {details.submission ===
                                                            "yes"
                                                              ? "Yes"
                                                              : "No"}
                                                          </span>
                                                        </td>
                                                        <td className="tb-col-os text-center">
                                                          {details.submission ===
                                                          "no" ? (
                                                            <span
                                                              style={{
                                                                opacity: 0.5,
                                                              }}
                                                            >
                                                              -----
                                                            </span>
                                                          ) : (
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",

                                                                fontSize:
                                                                  "18px",
                                                              }}
                                                              onClick={() => {
                                                                this.downloadCreditAppPdfSubmission(
                                                                  details.file_path
                                                                );
                                                              }}
                                                            >
                                                              <em className="icon ni ni-download"></em>
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          {details.submission ===
                                                          "no" ? (
                                                            <span
                                                              style={{
                                                                opacity: 0.5,
                                                              }}
                                                            >
                                                              -----
                                                            </span>
                                                          ) : details.is_approved ===
                                                            1 ? (
                                                            <span className="badge badge-outline-success">
                                                              Approved
                                                            </span>
                                                          ) : details.is_approved ===
                                                            0 ? (
                                                            <span className="badge badge-outline-danger">
                                                              Rejected
                                                            </span>
                                                          ) : (
                                                            <span className="badge badge-outline-warning">
                                                              Pending Approval
                                                            </span>
                                                          )}
                                                        </td>
                                                        <td className="tb-col-os">
                                                          <div
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                            }}
                                                          >
                                                            {details.submission ===
                                                              "no" ||
                                                            details.is_approved ===
                                                              0 ? (
                                                              <Tooltip
                                                                title="Remove this document."
                                                                placement="top"
                                                              >
                                                                <span
                                                                  className="badge badge-outline-dark"
                                                                  onClick={() =>
                                                                    this.handleDeleteAgent(
                                                                      details.accountno
                                                                    )
                                                                  }
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                    marginTop:
                                                                      "4px",
                                                                  }}
                                                                >
                                                                  Remove
                                                                </span>
                                                              </Tooltip>
                                                            ) : (
                                                              <>
                                                                {details.is_approved ===
                                                                1 ? (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      gap: "5px",
                                                                      marginTop:
                                                                        "4px",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title="Remove this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-dark"
                                                                        onClick={() =>
                                                                          this.handleDeleteAgent(
                                                                            details.accountno
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Remove
                                                                      </span>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Reject this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-danger"
                                                                        onClick={() =>
                                                                          this.handleRejectAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            false
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Reject
                                                                      </span>
                                                                    </Tooltip>
                                                                  </div>
                                                                ) : (
                                                                  <div
                                                                    style={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      gap: "5px",
                                                                      marginTop:
                                                                        "4px",
                                                                    }}
                                                                  >
                                                                    <Tooltip
                                                                      title="Approve this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-success"
                                                                        onClick={() =>
                                                                          this.handleAproveAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            details.document_file,
                                                                            true
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Approve
                                                                      </span>
                                                                    </Tooltip>
                                                                    <Tooltip
                                                                      title="Reject this document."
                                                                      placement="top"
                                                                    >
                                                                      <span
                                                                        className="badge badge-outline-danger"
                                                                        onClick={() =>
                                                                          this.handleRejectAgent(
                                                                            details.accountno,
                                                                            details.file_path,
                                                                            false
                                                                          )
                                                                        }
                                                                        style={{
                                                                          cursor:
                                                                            "pointer",
                                                                        }}
                                                                      >
                                                                        Reject
                                                                      </span>
                                                                    </Tooltip>
                                                                  </div>
                                                                )}
                                                              </>
                                                            )}
                                                          </div>
                                                        </td>
                                                      </tr>
                                                      <tr
                                                        id={`accordion-item-agent-${index}`}
                                                        className="accordion-row collapse"
                                                      >
                                                        <td colSpan="8">
                                                          <div
                                                            className="accordion-inner"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              padding: "15px",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginBottom:
                                                                  "15px",
                                                                color: "#333",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              Agent Info
                                                            </h4>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.6",
                                                                color: "#555",
                                                              }}
                                                            >
                                                              <div>
                                                                <strong>
                                                                  Account#:
                                                                </strong>{" "}
                                                                {
                                                                  details.accountno
                                                                }
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Name:
                                                                </strong>{" "}
                                                                {details.name}
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Email:
                                                                </strong>{" "}
                                                                {details.email}
                                                              </div>
                                                              <div>
                                                                <strong>
                                                                  Status:{" "}
                                                                </strong>
                                                                <span
                                                                  style={{
                                                                    color:
                                                                      details.is_approved ===
                                                                      1
                                                                        ? "green"
                                                                        : details.is_approved ===
                                                                          0
                                                                        ? "red"
                                                                        : "orange",
                                                                  }}
                                                                >
                                                                  {details.is_approved ===
                                                                  1
                                                                    ? "Approved"
                                                                    : details.is_approved ===
                                                                      0
                                                                    ? "Rejected"
                                                                    : "Pending Approval"}
                                                                </span>
                                                              </div>
                                                              {details.is_approved ===
                                                                0 && (
                                                                <div>
                                                                  <strong>
                                                                    Reason:
                                                                  </strong>{" "}
                                                                  {
                                                                    details.reason
                                                                  }
                                                                </div>
                                                              )}
                                                              <div>
                                                                <strong>
                                                                  Date Added:
                                                                </strong>{" "}
                                                                {this.format_date(
                                                                  details.addedOn
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </>
                                            ) : (
                                              <tr>
                                                <td
                                                  className="tb-col-os"
                                                  colSpan={8}
                                                >
                                                  <strong className="text-danger">
                                                    No agents are currently
                                                    assigned to this document.
                                                  </strong>
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/**** Open Attach Agent Modal ***/}

                  {/* START Client DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlertClientDoc"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDelClientDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Delete Client Document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDeleteClientDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageDeleteClientDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelteClientDoc !==
                              "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {
                                          this.state
                                            .successMessageDelteClientDoc
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div className="caption-text">
                                Are you sure you want to delete the client
                                document
                              </div>
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDelClientDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteClientDoc();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>DELETING...</span>
                                ) : (
                                  <span>DELETE</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Client DELETE MODAL  */}

                  {/* START Agent DELETE MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalDeleteAlertAgentDoc"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideDelAgentDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              Do you want to unassign the document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageDeleteAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageDeleteAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageDelteAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {this.state.successMessageDelteAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideDelAgentDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.deleteAgentDoc();
                                }}
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>UNASSIGNING...</span>
                                ) : (
                                  <span>UNASSIGN</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Client DELETE MODAL  */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalUpdateCollateral"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-users"
                              style={{ marginRight: "15px" }}
                            ></em>{" "}
                            Update Document
                          </h5>
                        </div>
                        <div className="modal-body">
                          {this.state.formLoader === true ? (
                            FormLoader()
                          ) : (
                            <div className="row g-4">
                              {this.state.docErrorMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>Error</h4>
                                        <p>{this.state.docErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.docSuccessMessage !== "" ? (
                                <div className="col-md-12 mb-3">
                                  <div
                                    className="example-alert example-alert-revoke"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <div className="alert alert-pro alert-success">
                                      <div className="alert-text">
                                        <h4>Success</h4>
                                        <p>{this.state.docSuccessMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      required
                                      id="document_title"
                                      name="document_title"
                                      type="text"
                                      label="Title"
                                      value={this.state.document_title}
                                      inputProps={{ maxLength: 64 }}
                                      onInput={(e) => {
                                        e.target.value = e.target.value.replace(
                                          /[^a-zA-Z0-9 -_]/gi,
                                          ""
                                        );
                                      }}
                                      helperText="Title of the document."
                                      autoFocus
                                      onChange={this.handleChange}
                                      variant="outlined"
                                      fullWidth
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <Select
                                      options={
                                        this.state.all_collaterals_groups
                                      }
                                      name="selected_accountno"
                                      placeholder="Select a document group"
                                      value={this.state.selected_accountno}
                                      autoComplete="off"
                                      emptyMessage="Clients not found"
                                      onChange={this.handleChangeSearchUpdate}
                                      isClearable
                                      isSearchable
                                      components={animatedComponents}
                                      styles={{
                                        control: (baseStyles, state) => ({
                                          ...baseStyles,
                                          lineHeight: "40px",
                                        }),
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <MutextField
                                      id="allow_document_submission"
                                      name="allow_document_submission"
                                      select
                                      label="Allow document submission"
                                      value={
                                        this.state.allow_document_submission
                                      }
                                      onChange={this.handleChange}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      helperText="Allow document submission here."
                                      variant="outlined"
                                      fullWidth
                                      error={
                                        this.state.errorClientSubmission ===
                                        "error"
                                      }
                                    >
                                      <option key="" value=""></option>
                                      <option key="5" value="Yes">
                                        Yes
                                      </option>
                                      <option key="10" value="No">
                                        No
                                      </option>
                                    </MutextField>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div className="form-control-group">
                                    <ul class="nav nav-tabs">
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "clients"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "clients",
                                            })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <em class="icon ni ni-user"></em>
                                          <span>Client</span>
                                        </a>
                                      </li>
                                      <li class="nav-item">
                                        <a
                                          className={`nav-link ${
                                            selectedTab === "agents"
                                              ? "active"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            this.setState({
                                              selectedTab: "agents",
                                            })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <em class="icon ni ni-user"></em>
                                          <span>Agent</span>
                                        </a>
                                      </li>
                                    </ul>
                                    <div class="tab-content">
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "clients"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllClientUpdate}
                                                onChange={
                                                  this
                                                    .handleSelectAllChangeUpdate
                                                }
                                                name="selectAllClientUpdate"
                                              />
                                            }
                                            label="Select all Clients"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedClients"
                                            value={selectedClients}
                                            isMulti
                                            name="clients"
                                            options={this.state.clientsList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={
                                              this.handleSelectChangeUpdate
                                            }
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`tab-pane ${
                                          selectedTab === "agents"
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        <div
                                          className="form-group"
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={selectAllAgentUpdate}
                                                onChange={
                                                  this
                                                    .handleSelectAllChangeUpdate
                                                }
                                                name="selectAllAgentUpdate"
                                              />
                                            }
                                            label="Select all Agents"
                                            style={{
                                              width: "max-content",
                                              marginBottom: "10px",
                                              float: "right",
                                            }}
                                          />
                                          <Select2
                                            id="selectedAgents"
                                            value={selectedAgents}
                                            isMulti
                                            name="agents"
                                            options={this.state.agentList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            onChange={
                                              this.handleSelectChangeUpdate
                                            } // Change this to handleSelectChange
                                            styles={{
                                              menu: (base) => ({
                                                ...base,
                                                zIndex: 9999,
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="form-group">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: "15px",
                                    }}
                                  >
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        Upload File<sup> *</sup>
                                      </label>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "5px",
                                      }}
                                    >
                                      <a
                                        onClick={() => {
                                          setTimeout(() => {
                                            this.downloadCreditAppPdf(
                                              this.state.previously_file
                                            );
                                          }, 2000);
                                        }}
                                        style={{
                                          cursor: "pointer",
                                          marginBottom: "10px",
                                        }}
                                        title="Download Document"
                                      >
                                        <span className="badge badge-outline-secondary">
                                          <em className="icon ni ni-file-pdf"></em>{" "}
                                          View
                                        </span>
                                      </a>
                                      <span
                                        style={{
                                          marginBottom: "7px",
                                        }}
                                      >
                                        {this.state.previously_file}
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    className="form-control-group"
                                    id="fileContainer"
                                  >
                                    <input
                                      type="file"
                                      id="document_file"
                                      className="file-block"
                                      name="document_file"
                                      defaultValue={this.state.document_file}
                                      accept=".png, .jpg, .jpeg, .doc, .docx, .pdf, .csv, .xlsx, .xls"
                                      onChange={this.handleFileChange}
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-12 d-flex justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => this.saveUpdateCollateral()}
                                  className="btn btn-primary"
                                  disabled={
                                    this.state.disabled ||
                                    this.state.isProcessing
                                  } // disable button when processing
                                >
                                  {this.state.isProcessing ? (
                                    <span>Processing...</span>
                                  ) : (
                                    <span>Submit</span>
                                  )}
                                  <em className="icon ni ni-emails"></em>
                                </button>
                              </div>
                              <div className="col-md-12">
                                <div className="example-alert">
                                  <div className="alert alert-light">
                                    <ul className="list">
                                      <li>
                                        Supported file formats include: PNG,
                                        JPG, JPEG, DOC, DOCX, PDF, CSV, XLSX,
                                        XLS.
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                  {/* START Agent Approve MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalApproveAlertAgent"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideApproveAgentDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-check bg-success"></em>

                            <h4 className="nk-modal-title">
                              Do you want to approve the document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageApproveAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageApproveAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageApproveAgentDoc !==
                              "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {
                                          this.state
                                            .successMessageApproveAgentDoc
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideApproveAgentDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.approveClientDoc();
                                }}
                                disabled={this.state.isProcessing}
                              >
                                {this.state.isProcessing ? (
                                  <span>UPDATING...</span>
                                ) : (
                                  <span>
                                    {this.state.is_approved === 1
                                      ? "UNAPPROVE"
                                      : "APPROVE"}
                                  </span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Agent Approve MODAL  */}
                  {/* START Agent Reject MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalRejectAlertAgent"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideRejectAgentDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-cross bg-success"></em>

                            <h4 className="nk-modal-title">
                              Do you want to reject the document?
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageApproveAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageApproveAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageApproveAgentDoc !==
                              "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {
                                          this.state
                                            .successMessageApproveAgentDoc
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>

                            <div
                              className="tb-odr-id"
                              style={{
                                textAlign: "left",
                                marginBottom: "8px",
                                marginRight: "17rem",
                              }}
                            >
                              Reason to reject
                            </div>
                            <div className="card card-bordered card-preview mt-1">
                              <TextareaAutosize
                                maxrows={4}
                                name="reject_reason"
                                id="reject_reason"
                                aria-label=""
                                placeholder=""
                                value={this.state.reject_reason}
                                helperText="Reason to reject this document"
                                onChange={this.handleChange}
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  borderColor: "rgba(0, 0, 0, 0.125)",
                                  borderRadius: "4px",
                                }}
                                variant="outlined"
                              />
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideRejectAgentDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.rejectAgentDoc();
                                }}
                                disabled={this.state.isProcessing}
                              >
                                {this.state.isProcessing ? (
                                  <span>UPDATING...</span>
                                ) : (
                                  <span>Reject</span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Agent Reject MODAL  */}
                  {/* START Client Approve MODAL  */}
                  <div
                    className="modal fade"
                    tabIndex="-1"
                    id="modalApproveAlertClient"
                  >
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <a
                          style={{ cursor: "pointer" }}
                          className="close"
                          onClick={() => {
                            this.modalHideApproveClientDoc();
                          }}
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-body modal-body-lg text-center">
                          <div className="nk-modal">
                            <em className="nk-modal-icon icon icon-circle icon-circle-xxl ni ni-trash bg-success"></em>

                            <h4 className="nk-modal-title">
                              {this.state.is_approved === 1
                                ? "The document is currently approved. Do you want to unapprove it?"
                                : "The document is not approved yet. Do you want to approve it?"}
                            </h4>

                            <div className="nk-modal-text">
                              {this.state.errorMessageApproveAgentDoc !== "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>
                                        {this.state.errorMessageApproveAgentDoc}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.successMessageApproveAgentDoc !==
                              "" ? (
                                <div
                                  className="example-alert"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>
                                        {
                                          this.state
                                            .successMessageApproveAgentDoc
                                        }
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            <div className="nk-modal-action">
                              <a
                                style={{ cursor: "pointer" }}
                                className="btn btn-lg btn-mw btn-primary"
                                onClick={() => {
                                  this.modalHideApproveClientDoc();
                                }}
                              >
                                CANCEL
                              </a>
                              {"  "}
                              <a
                                style={{
                                  cursor: "pointer",
                                }}
                                className="btn btn-lg btn-mw btn-danger"
                                onClick={() => {
                                  this.approveAgentDoc();
                                }}
                                disabled={this.state.isProcessing}
                              >
                                {this.state.isProcessing ? (
                                  <span>UPDATING...</span>
                                ) : (
                                  <span>
                                    {this.state.is_approved === 1
                                      ? "UNAPPROVE"
                                      : "APPROVE"}
                                  </span>
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* END Agent Approve MODAL  */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Collaterals);
