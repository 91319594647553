import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import $ from "jquery";
import { Redirect } from "react-router-dom";

import PROMO_A from "../../../assets/images/promo-a.png";
import PROMO_B from "../../../assets/images/promo-b.png";
import PROMO_C from "../../../assets/images/promo-c.png";

import { COMPANY_NAME, LOGO, THEME_COLOR, APP_LIVE_URL } from "../../../config/config";

import { ValidateEmail, axiosConfig } from "../../../config/utility";
import ReCAPTCHA from "react-google-recaptcha";

import helpherClass from "../../../config/helperClass";
const auth = new helpherClass();

export default class Login extends Component {
  constructor() {
    super();
    this.state = {
      errorEmail: "",
      errorPass: "",
      email: "",
      password: "",
      errorMessage: "",
      disabled: false,
      loader: "",
      successMessage: "",
    };
    this.site_key = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    this.captcha_token = null;
  }

  async componentDidMount() {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}resellers/get_reseller_logo`,
        JSON.stringify({
          token: process.env.REACT_APP_API_TOKEN,
          api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
        }),
        axiosConfig
      );

      if (
        response.data.status === 200 &&
        response.data.message === "success" &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        // Encrypt and store the logo in cookies
        Cookies.set(
          "userlogo__",
          CryptoJS.AES.encrypt(
            response.data.data[0].user_logo,  // Access user_logo within data array
            process.env.REACT_APP_SIGNATURE
          ).toString()
        );
      } else {
        // Handle specific error cases
        this.setState({
        });
      }
    } catch (error) {
      console.log("Error fetching logo:", error);
      this.setState({
      });
    }
  }

  getUserLogo() {
    if (
      Cookies.get("userlogo__") == null ||
      Cookies.get("userlogo__") === undefined ||
      Cookies.get("userlogo__") === ""
    ) {
      return false;
    } else {
      var secByte = CryptoJS.AES.decrypt(
        Cookies.get("userlogo__"),
        process.env.REACT_APP_SIGNATURE
      );
      if (secByte == "") {
        return false;
      }
      var userlogo__ = secByte.toString(CryptoJS.enc.Utf8);

      if (userlogo__ == "") {
        return false;
      } else {
        return userlogo__;
      }
    }
  }

  handleChange = (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };
  handleCaptchaChange = (value) => {
    this.captcha_token = value;
  };

  formSubmit = (e) => {
    e.preventDefault();
    let { email, password } = this.state;

    if (email === "") {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Email is required.",
      });
    } else if (ValidateEmail(email) === false) {
      return this.setState({
        errorEmail: "error",
        errorMessage: "Invalid email format.",
      });
    } else if (password === "") {
      return this.setState({
        errorPass: "error",
        errorEmail: "",
        errorMessage: "Password is required.",
      });
    } else if (this.captcha_token === "" || this.captcha_token === null) {
      return this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "Invalid captcha, please retry.",
      });
    } else {
      this.setState({
        errorPass: "",
        errorEmail: "",
        errorMessage: "",
        disabled: true,
        loader: "show",
      });

      try {
        axios
          .post(
            process.env.REACT_APP_API_URL + "login",
            JSON.stringify({
              email: email,
              password: password,
              token: process.env.REACT_APP_API_TOKEN,
              api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            }),
            axiosConfig
          )
          .then((response) => {
            //console.log("Login: ",response);
            if (
              response.data.status === 404 ||
              response.data.status === 422 ||
              response.data.errors !== ""
            ) {
              this.setState({
                errorMessage: response.data.errors,
                disabled: false,
              });
            }

            if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                successMessage:
                  "Please wait, redirecting you to the dashboard.",
                errorMessage: "",
              });
              Cookies.set(
                "tkn__",
                CryptoJS.AES.encrypt(
                  response.data.token,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "acn__",
                CryptoJS.AES.encrypt(
                  response.data.accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "eml__",
                CryptoJS.AES.encrypt(
                  response.data.email,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "comp_nme__",
                CryptoJS.AES.encrypt(
                  response.data.comp_name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "name__",
                CryptoJS.AES.encrypt(
                  response.data.name,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "phone__",
                CryptoJS.AES.encrypt(
                  response.data.phone,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "title__",
                CryptoJS.AES.encrypt(
                  response.data.title,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "usertype__",
                CryptoJS.AES.encrypt(
                  response.data.user_type,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "userlogo__",
                CryptoJS.AES.encrypt(
                  response.data.user_logo,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "useracct__",
                CryptoJS.AES.encrypt(
                  response.data.user_accountno,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "usertkn__",
                CryptoJS.AES.encrypt(
                  response.data.sub_user_token,
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );
              Cookies.set(
                "decimalPoint__",
                CryptoJS.AES.encrypt(
                  response.data.decimalPoint.toString(),
                  process.env.REACT_APP_SIGNATURE
                ).toString()
              );

              setTimeout(function () {
                //this.props.history.push("/dashboard");
                //  window.location = "/dashboard";
                window.location.replace("/dashboard");
              }, 1000);
            }
          });
      } catch (error) {
        console.log("catch : ", error);
      }
    }
  };

  render() {
    if (auth.isLogin() === true) {
      return <Redirect to="/dashboard" />;
    }
    if (auth.isAdminLogin() === true) {
      return <Redirect to="/admin_dashboard" />;
    }
    return (
      <div className="nk-body npc-crypto ui-clean pg-auth">
        <div className="nk-app-root">
          {/* <div className="progress progress-sm">
            <div className="progress-bar" data-progress="95"></div>
          </div> */}
          <div className="nk-split nk-split-page nk-split-md">
            <div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container">
              <div className="absolute-top-right d-lg-none p-3 p-sm-5">
                <a
                  href="#"
                  className="toggle btn-white btn btn-icon btn-light"
                  data-target="athPromo"
                >
                  <em className="icon ni ni-info"></em>
                </a>
              </div>
              <div className="nk-block nk-block-middle nk-auth-body">
              <div className="brand-logo pb-5">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={
                        this.getUserLogo()
                          ? `${APP_LIVE_URL}files_data/logos/${this.getUserLogo()}`
                          : LOGO
                      }
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={
                        this.getUserLogo()
                          ? `${APP_LIVE_URL}files_data/logos/${this.getUserLogo()}`
                          : LOGO
                      }
                      alt="logo-dark"
                    />
                  </a>
                </div>
                {/*<div className="brand-logo pb-5">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img logo-img-lg"
                      src={LOGO}
                      alt="logo-dark"
                    />
                  </a>
                </div>*/}
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h5 className="nk-block-title">Sign-In</h5>

                    <div className="nk-block-des">
                      <p>Use your verified email and password.</p>

                      {this.state.errorMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-danger">
                            <div className="alert-text">
                              <h4>Error</h4>
                              <p>{this.state.errorMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      {this.state.successMessage !== "" ? (
                        <div className="example-alert">
                          <div className="alert alert-pro alert-success">
                            <div className="alert-text">
                              <h4>Success</h4>
                              <p>{this.state.successMessage}</p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <br />
                      {/* <p
                        style={{
                          color: "red",
                          fontSize: "20px",
                          fontWeight: 600,
                        }}
                      >
                        {this.state.errorMessage}
                      </p> */}
                    </div>
                  </div>
                </div>
                <form
                  method="post"
                  className="loginForm"
                  onSubmit={this.formSubmit}
                >
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="default-01">
                        Email
                      </label>
                      {/* <a
                        className="link link-primary link-sm"
                        tabIndex="-1"
                        href="#"
                      >
                        Need Help?
                      </a> */}
                    </div>
                    <input
                      type="email"
                      className={
                        "form-control form-control-lg " + this.state.errorEmail
                      }
                      id="email"
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter your email address"
                    />
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="password">
                        Password
                      </label>
                      <a
                        className="link link-primary link-sm"
                        tabIndex="-1"
                        href="/forgot"
                        style={{ color: "#006393" }}
                      >
                        Forgot Password?
                      </a>
                    </div>
                    <div className="form-control-wrap">
                      <a
                        tabIndex="-1"
                        href="#"
                        className="form-icon form-icon-right passcode-switch"
                        data-target="password"
                      >
                        <em className="passcode-icon icon-show icon ni ni-eye"></em>
                        <em className="passcode-icon icon-hide icon ni ni-eye-off"></em>
                      </a>
                      <input
                        type="password"
                        className={
                          "form-control form-control-lg " + this.state.errorPass
                        }
                        id="password"
                        name="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                        placeholder="Enter your password"
                      />
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey={this.site_key}
                    onChange={this.handleCaptchaChange}
                    style={{ padding: "0px 0px 15px" }}
                  />
                  <div className="form-group">
                    <button
                      className="btn btn-lg btn-block"
                      disabled={this.state.disabled}
                      type="submit"
                      style={{ backgroundColor: THEME_COLOR, color: "white" }}
                    >
                      Sign in
                    </button>
                  </div>
                </form>
                <div className="form-note-s2 pt-4">
                  {" "}
                  {/*New on our platform? <a href="/register">Create an account</a>*/}
                </div>
              </div>
              <div className="nk-block nk-auth-footer">
                {/* <div className="nk-block-between">
                  <ul className="nav nav-sm">
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Terms & Condition
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Privacy Policy
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" href="#">
                        Help
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="mt-3">
                  <p>
                    &copy; {new Date().getFullYear()}{" "}
                    <a
                      href="https://onboardsoft.com/"
                      style={{ color: "#526484" }}
                    >
                      OnBoardsoft LLC
                    </a>
                    . All Rights Reserved.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="nk-split-content nk-split-stretch bg-lighter d-flex toggle-break-lg toggle-slide toggle-slide-right"
              data-content="athPromo"
              data-toggle-screen="lg"
              data-toggle-overlay="true"
            >
              <div className="slider-wrap w-100 w-max-550px p-3 p-sm-5 m-auto">
                <div
                  className="slider-init"
                  data-slick='{"dots":true, "arrows":false}'
                >
                  <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_A} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          Enter your details here to login in to our system.
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_B} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="slider-item">
                    <div className="nk-feature nk-feature-center">
                      <div className="nk-feature-img">
                        <img className="round" src={PROMO_C} alt="" />
                      </div>
                      <div className="nk-feature-content py-4 p-sm-5">
                        <h4>{COMPANY_NAME}</h4>
                        <p>
                          You can start to create your products easily with its
                          user-friendly design & most completed responsive
                          layout.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="slider-dots"></div>
                <div className="slider-arrows"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
